import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { certificateTemplateOne } from "../../store/certificates/actions";

export const GetOneCertTemplate = (trigger: boolean, certificateId: string) => {
  const dispatch = useDispatch();

  const {
    loadingOneCertTemplate,
    responseOneCertTemplate,
    errorOneCertTemplate,
  } = useSelector((state: any) => ({
    loadingOneCertTemplate: state.Certificates.loadingOneCertTemplate,
    responseOneCertTemplate: state.Certificates.responseOneCertTemplate,
    errorOneCertTemplate: state.Certificates.errorOneCertTemplate,
  }));

  useEffect(() => {
    if (trigger) {
      dispatch(
        certificateTemplateOne({
          id: certificateId,
        }),
      );
    }
  }, [trigger, certificateId]);

  return {
    loadingOneCertTemplate,
    responseOneCertTemplate,
    errorOneCertTemplate,
  };
};
