import { useEffect, useState } from "react";
import { CertificatesEditorView } from "./certificatesEditorView";
import { useForm } from "react-hook-form";
import { CertificatesEditorProps } from "../../interface/certificatesEditor";

export const CertificatesEditorAFY = ({
  onSaveData = () => {},
  onCancelButton = () => {},
  onCloseDrawer = () => {},
  onOpenDrawer = () => {},
  ...props
}: CertificatesEditorProps) => {
  const [isLayoutReady, setIsLayoutReady] = useState(false);
  const [dataMergeFieldsSb, setDataMergeFieldsSb] = useState(
    props.mergeFieldsData,
  );
  const [initialValue, setInitialValue] = useState<string>("");
  const [openDrawerSb, setOpenDrawerSb] = useState(props.openDrawer);
  const {
    register,
    control,
    formState: { errors, isValid },
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    setIsLayoutReady(true);
    return () => setIsLayoutReady(false);
  }, []);

  useEffect(() => {
    setInitialValue(props.initialData || "");
  }, [props.initialData]);

  useEffect(() => {
    setOpenDrawerSb(props.openDrawer);
  }, [props.openDrawer]);

  useEffect(() => {
    setDataMergeFieldsSb(props.mergeFieldsData);
  }, [props.mergeFieldsData]);

  useEffect(() => {
    if (props.dataForm) {
      for (const key in props.dataForm) {
        // @ts-ignore
        setValue(key, props.dataForm[key]);
      }
      if (Object.keys(props.dataForm).length > 0) {
        trigger();
      }
    }
  }, [props.dataForm]);

  return (
    <CertificatesEditorView
      productTitle={props.productTitle}
      productTitleColor={props.productTitleColor}
      productName={props.productName}
      productNameColor={props.productNameColor}
      titleCancelButton={props.titleCancelButton}
      onCancelButton={onCancelButton}
      titleNextButton={props.titleNextButton}
      onSaveData={onSaveData}
      dataMergeFieldsSb={dataMergeFieldsSb}
      isLayoutReady={isLayoutReady}
      heightGrid={props.heightGrid}
      language={props.language}
      initialValue={initialValue}
      licenseKey={props.licenseKey}
      placeholder={props.placeholder}
      labelFileName={props.labelFileName}
      //
      openDrawerSb={openDrawerSb}
      onOpenDrawer={onOpenDrawer}
      onCloseDrawer={onCloseDrawer}
      descriptionDrawer={props.descriptionDrawer}
      titleSaveButton={props.titleSaveButton}
      labelTypeCertificate={props.labelTypeCertificate}
      TypeListDrawer={props.TypeListDrawer}
      register={register}
      errors={errors}
      setError={setError}
      clearErrors={clearErrors}
      control={control}
      isValid={isValid}
      getValues={getValues}
      dataForm={props.dataForm}
      errorTextFileName={props.errorTextFileName}
      errorTextTypeFile={props.errorTextTypeFile}
    />
  );
};
