import showToast from "../../../services/toast";
import { HeaderTitlePage } from "../../../components/resources/headerTitlePage/headerTitlePage";
import { AlertLoadingHeader } from "../../../components/alertLoadingComponent/alertLoading";
import React from "react";

export const PaymentConfirmationHeader = (props: any) => {
  return (
    <>
      <AlertLoadingHeader
        loading={props.responsePolicyConfirmLoading}
        seeToast={props.seeToast}
        showToast={showToast}
        setSeeToast={props.setSeeToast}
        titleLoading={"Enviando correo..."}
      />

      <HeaderTitlePage
        breadCrumbs={props.breadCrumbs}
        titlePage={"Pago"}
        steps={props.steps}
        hideStep={false}
        stepsTop={"-20px"}
        completed={true}
      />
    </>
  );
};
