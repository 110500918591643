import React, { useEffect } from "react";
import { useStyles } from "./style";
import "./style.css";

import {
  BillingAFY,
  GenerateInvoicesAFY,
  icons,
  InformationAFY,
  sizesIcon,
  variants,
  ButtonAFY,
  sizes,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { themeCore } from "../../assets/themes/theme";
import { Title } from "../../components/layout/style";
import {
  Container,
} from "./style";
import { ca } from "date-fns/locale";
import { use } from "i18next";

const View = (props: any) => {
  const classes = useStyles();
  const [products, setProducts] = React.useState<any>(props.products);
  const [mes, setMes] = React.useState<string>(props.mes);
  const [collection, setCollection] = React.useState<any>(props.collection);
  const [numberMaximumInvoicesValue, setNumberMaximumInvoicesValue] = React.useState<string>(props.numberMaximumInvoicesValue);
  const [cancelAfterValue, setCancelAfterValue] = React.useState<string>(props.cancelAfterValue);
  const [numberMaximumValue, setNumberMaximumValue] = React.useState<string>(props.numberMaximumValue);
  const [splitArchivesPerProductValue, setSplitArchivesPerProductValue] = React.useState<boolean>(props.splitArchivesPerProductValue);
  const [noCancelExpiredPolicyValue, setNoCancelExpiredPolicyValue] = React.useState<boolean>(props.noCancelExpiredPolicyValue);
  const [noExpiredPolicyAgeValue, setNoExpiredPolicyAgeValue] = React.useState<boolean>(props.noExpiredPolicyAgeValue);
  const [justNewInvoiceNoRebillValue, setJustNewInvoiceNoRebillValue] = React.useState<boolean>(props.justNewInvoiceNoRebillValue);
  const [justRebillNoNewInvoiceValue, setJustRebillNoNewInvoiceValue] = React.useState<boolean>(props.justRebillNoNewInvoiceValue);

  useEffect(() => {
    if (props.products && props.products.length > 0) {
      setProducts(props.products);
    }
  }, [props.products]);
  const onClickBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    props.onClickBack && props.onClickBack(e);
  };


  useEffect(() => {
    setMes(props.mes);
  }
    , [props.mes]);





  useEffect(() => {
    setCollection(props.collection);
  }
    , [props.collection]);
  return (
    <React.Fragment>
      <Container>
        <ButtonAFY
          variant={variants.text}
          label={"Regresar"}
          icon={icons.ArrowBackIosNew}
          size={sizes.small}
          color={themeCore.colors.primaryText}
          onClick={onClickBack}
        />
      </Container>

      <Title>{props.title}<span className={` status ${props.status || ''} `}>{props.estado}</span></Title>

      <div className="containerPanel" style={{ marginTop: "15px" }}>
        {products.length > 0 && (
          <GenerateInvoicesAFY
            backgroundColorBack={themeCore.colors.primary}
            backgroundColorNext={themeCore.colors.primary}
            title={""}
            drawerTitle={"¡Listo!"}
            drawerText={
              "Se ha enviado la información de facturación a los proyectos seleccionados."
            }
            register={null}
            onClickDefaultValues={props.onClickDefaultValues}
            onClickNext={props.onClickNext}
            onClickBack={props.onClickBack}
            periodLabels={{
              readOnly: props.readOnly,
              tipoCobranzaLabel: "Periodo de cobranza",
              tipoCobranzaValue: [
                {
                  id: "GENERAL",
                  name: "Cobranza General",
                  value: "GENERAL",
                },
                {
                  id: "NEW",
                  name: "Nuevos cobros",
                  value: "NEW",
                },
                {
                  id: "REBILLING",
                  name: "Recobranza únicamente",
                  value: "REBILLING",
                },
              ],
              title: "Periodo de cobranza",
              cobranzaMonthDateLabel: "Mes de cobranza",
              maximumLimitLabel: "Límitar máxima cantidad de meses a recobrar",
              cobranzaMonthDateValue: mes,
              primePeriodDateLabel: "Periodo de prima",
              primePeriodStartDateValue: "2021-09-01",
              primePeriodEndDateValue: "2021-09-30",
              primeEvaluationDateLabel: "Fecha de evaluación de prima",
              primeEvaluationDateValue: "2021-09-30",
              processDateLabel: "Fecha de proceso",
              processDateValue: "2021-09-30",
              newCobranzaDateLabel: "Fecha de nueva factura",
              newCobranzaDateValue: "2021-09-30",
              recobranzaDateLabel: "Fecha de refacturación",
              recobranzaDateValue: "2021-09-30",
              involunteerCancellationDateLabel: "Fecha de cancelación involuntaria",
              involunteerCancellationDateValue: "2021-09-30",
              defaultValuesLabel: "Restaurar valores predeterminados",
              exchangeRateLabel: "Separar archivos por producto",
              exchangeRateValue: "false",
              exchangeRateMultiplierLabel: "Multiplicador de tasa de cambio",
              exchangeRateMultiplierValue: 0,
              notesLabel: "Opciones de facturación",
              cancelAfterLabel: "Cancelar la póliza después de (meses)",
              numberMaximumLabel: "Periodos máximos a cobrar",
              noRebillLabel: "No recobrar facturas después de",
              noCancelExpiredPolicyLabel: "No cancelar póliza con pagos vencidos",
              noExpiredPolicyAgeLabel: "No expirar pólizas por edad",
              justRebillNoNewInvoiceLabel: "Solo recobranza (no nuevas notas de cobro)",
              justNewInvoiceNoRebillLabel: "Solo nuevas notas de cobro (no recobranza)",
              sixMonthsExistingPolicyLabel: "Crear facturas no emitidas de los últimos 6 meses - Póliza existente",
              sixMonthsNewPolicyLabel: "Crear facturas no emitidas de los últimos 6 meses - Póliza nueva",
              ignoreLabel: "Ignorar: no procesar controles de facturación de pólizas individuales",
              numberMaximumInvoicesLabel: "No. máximo de facturas por poliza",
              maximumLimitInvoicesLabel: "Límite máximo de refacturación",

              numberMaximumValue: numberMaximumValue,
              cancelAfterValue: cancelAfterValue,
              numberMaximumInvoicesValue: numberMaximumInvoicesValue,

              splitArchivesPerProductValue: splitArchivesPerProductValue,
              noCancelExpiredPolicyValue: noCancelExpiredPolicyValue,
              noExpiredPolicyAgeValue: noExpiredPolicyAgeValue,
              justNewInvoiceNoRebillValue: justNewInvoiceNoRebillValue,
              justRebillNoNewInvoiceValue: justRebillNoNewInvoiceValue,
            }}
            projectsLabels={{
              title: "Productos a facturar",
              cobranzaSponsorsLabel: "Facturar todos los Sponsors y productos",
              selectSponsorLabel: "Seleccionar los Sponsors y productos a facturar",
              unselectSponsorLabel: "Deseleccionar todo",
              columns: [
                {
                  field: 'type',
                  headerName: 'Código',
                  width: 150,
                  editable: false,
                  flex: 1,
                },
                {
                  field: 'sponsor',
                  headerName: 'Sponsor',
                  width: 150,
                  editable: false,
                  flex: 1,
                },
                {
                  field: 'product',
                  headerName: 'Producto',
                  width: 150,
                  editable: false,
                  flex: 1,
                },
                {
                  field: 'currency',
                  headerName: 'Moneda',
                  width: 150,
                  editable: false,
                  flex: 1,
                }],
              rows: products,
            }}
            next={{
              nextLabel: props.readOnly ? "" : "Generar",
              previousLabel: "Cancelar",
              nextDisabled: false,
            }}
          />
        )}
      </div>

    </React.Fragment>
  );
};

export default View;
