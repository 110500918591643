
const usePaymentConfirmation = (
    policyId: any,
    dispatch: any,
    policyConfirm: any,
) => {

    const handleSendEmail = (email: string) => {
        let body = {
            id: policyId,
            includeAttachments: false,
            ccEmail: [email],
        };
        dispatch(policyConfirm(body));
    };
    return {
        handleSendEmail,
    };
};

export default usePaymentConfirmation;