import { useTranslation } from "react-i18next";


const useVehicle = (
  setOpenModalEditVehicle:any
) => {
    const { t } = useTranslation();
      // Helper functions for status handling
  const getFuelTypeName = (combustion: any) => {
    switch (combustion) {
      case "GAS": return t(`carDetails.fullType.gas`);
      case "ELECTRIC": return t(`carDetails.fullType.eléctrico`);
      case "DIESEL": return t(`carDetails.fullType.diesel`);
      case "GASOLINE": return t(`carDetails.fullType.gasolina`);
      default: return "";
    }
  };
  // Helper function to prepare car details
  const getCarDetails = (responseData: any, price: any) => {
    const car = {
      id: 1,
      brand: responseData?.contentMap?.brand?.name,
      model: responseData?.contentMap?.model?.name,
      fueltype: responseData?.contentMap?.combustion,
      color: responseData?.contentMap?.colors?.name,
      price: `RD$ ${price}`,
      cylinders: responseData?.contentMap?.cylinders,
      plate: responseData?.contentMap?.plate,
    };
    return car;
  };

    const updateVehicle = (e: any) => {
       
        switch (e.id) {
          case "1":
            setOpenModalEditVehicle(true);
        }
      };

    return {
        updateVehicle,
        getFuelTypeName,
        getCarDetails,
    };
};

export default useVehicle;