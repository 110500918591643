import LocalStorageService from "../../../services/localStorage";
import { STEP_PLANDETAILS } from "../../../const/catalog";
import React, { useEffect, useState } from "react";
import {
  Benefit,
  breadcrumb,
  MenuItem,
  select,
  typeAlert,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { navigateNext, stepProgress } from "../../../dataBase/stepProgress";
import { PlanDetailsView } from "./planDetailView";
import { useNavigate } from "react-router-dom";
import { Steps } from "../../../enum/steps";
import { PlanModel } from "../../../model/plan";
import { useGetPlansAllV2 } from "../../../fnx/plans/planGetAllV2";
import { GetPaymentMode } from "../../../fnx/paymentMode/getPaymentMode";
import { StatusOptionsModel } from "../../../model/insurancePoliciesSummary";
import { paymentModeClearData } from "../../../store/paymentModes/action";
import { useDispatch, useSelector } from "react-redux";
import { showErrorToast } from "../../../fnx/showError";
import { useTranslation } from "react-i18next";
import showToast from "../../../services/toast";
import { plansClearData } from "../../../store/plans/actions";
import {
  quoteAddFields,
  quoteAddFieldsClearData,
  quoteOne,
} from "../../../store/generateQuote/actions";
import { paymentMode, quoteBodyPatch } from "./planDetailDto";
import { usePatchQuote } from "../../../fnx/quotes/patchQuote";
import { formatToTwoDecimals } from "../../../utils/getPrice";
import { patchQuoteClearData } from "../../../store/updateQuote/actions";
import { AlertLoadingHeader } from "../../../components/alertLoadingComponent/alertLoading";

const storageKey2 = "afy-login";
const storageKey3 = "afy-steps";
const lsLogin = new LocalStorageService(storageKey2);
const lsSteps = new LocalStorageService(storageKey3);
const STEP = STEP_PLANDETAILS;

export const PlanDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [steps, setSteps] = React.useState<MenuItem[]>([]);
  const [price, setPrice] = React.useState<string>("");
  const [currencySymbol, setCurrencySymbol] = React.useState<string>("");
  const [breadCrumbs, setBreadCrumbs] = React.useState<breadcrumb[]>([]);
  const [trigger, setTrigger] = useState(false);
  const [triggerPaymentMode, setTriggerPaymentMode] = useState(false);
  const [triggerPatch, setTriggerPatch] = useState(false);
  const [quoteResponse, setQuoteResponse] = useState(false);
  const [listBenefit, setListBenefit] = useState<Benefit[]>([]);
  const quoteId = lsSteps.getStepStored().steps[Steps.customer].quoteId;
  const [clientId] = React.useState<string>(
    lsLogin.getUserLoginStored().clientId,
  );
  const [userId, setUserId] = React.useState<string>(
    lsLogin.getUserLoginStored()?.userId || "",
  );
  const [periodPlanName, setPeriodPlanName] = React.useState<string>(
    t("planDetail.periodPlan"),
  );

  const [enableNextBtn, setEnableNextBtn] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [autoClose] = React.useState<number>(10000);
  const [autoCloseActive] = React.useState<boolean>(false);
  const [msgDescription] = React.useState<string>(
    "No es posible generar la cotización, por favor contacte con la aseguradora",
  );
  const [paymentModeId, setPaymentModeId] = React.useState<string>("");
  const [paymentModeName, setPaymentModeName] = React.useState<string>("");
  const [paymentMode, setPaymentMode] = React.useState<paymentMode>();
  const [bodyPatchQuote, setBodyPatchQuote] = React.useState<quoteBodyPatch>();
  const [paymentModeList, setPaymentModeList] = React.useState<select[]>([
    {
      id: "",
      name: "",
      value: "Anual",
    },
  ]);
  const [valuePlanId] = React.useState<string>(
    lsSteps.getStepStored().steps[Steps.plan].id,
  );
  const [ProjectId] = React.useState<string>(
    lsSteps.getStepStored().steps[Steps.insurance].id,
  );

  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  const { responseQuoteAddFields, errorQuoteAddFields, loadingQuoteAddFields } =
    useSelector((state: any) => ({
      responseQuoteAddFields: state.Quote.responseQuoteAddFields,
      errorQuoteAddFields: state.Quote.errorQuoteAddFields,
      loadingQuoteAddFields: state.Quote.loadingQuoteAddFields,
    }));

  const { responseOneQuote, errorOneQuote, loadingOneQuote } = useSelector(
    (state: any) => ({
      responseOneQuote: state.Quote.responseOneQuote,
      errorOneQuote: state.Quote.errorOneQuote,
      loadingOneQuote: state.Quote.loadingOneQuote,
    }),
  );

  const { responsePlans, errorPlans, loadingPlans } = useGetPlansAllV2(
    trigger,
    valuePlanId,
  );

  /*Llamada al PATCH QUOTE*/
  const { responsePatchQuote, errorPatchQuote, loadingPatchQuote } =
    usePatchQuote(triggerPatch, bodyPatchQuote, quoteId);

  const { responsePaymentMode, errorPaymentMode, loadingPaymentMode } =
    GetPaymentMode(triggerPaymentMode, ProjectId);
  const callQuoteOne = (quoteId: string) => {
    dispatch(
      quoteOne({
        id: quoteId,
        fullBody: true,
      }),
    );
  };

  useEffect(() => {
    setBreadCrumbs(stepProgress(STEP, true));
    setSteps(stepProgress(STEP));
    setTrigger(true);
    setTriggerPaymentMode(true);
  }, []);

  useEffect(() => {
    if (errorQuoteAddFields) {
      showErrorToast(errorQuoteAddFields, setSeeToast, t);
    }
  }, [errorQuoteAddFields]);

  useEffect(() => {
    if (errorPlans) {
      showErrorToast(errorPlans, setSeeToast, t);
      dispatch(plansClearData());
    }
  }, [errorPaymentMode]);

  useEffect(() => {
    if (errorPaymentMode) {
      showErrorToast(errorPaymentMode, setSeeToast, t);
      dispatch(paymentModeClearData());
    }
  }, [errorPaymentMode]);

  useEffect(() => {
    if (responseQuoteAddFields && responseQuoteAddFields.data) {
      lsSteps.setStepStored({
        id: lsSteps.getStepStored().steps[Steps.plan].id,
        name: lsSteps.getStepStored().steps[Steps.plan].name,
        step: Steps.plan,
        completed: false,
        campaign: lsSteps.getStepStored().steps[Steps.plan].campaign,
        campaignSelected:
          lsSteps.getStepStored().steps[Steps.plan].campaignSelected,
        coverType: lsSteps.getStepStored().steps[Steps.plan].coverType,
        paymentModeSelected: paymentMode,
        price: {
          amount: responseQuoteAddFields.data.lastPrice.amount,
          currency: responseQuoteAddFields.data.lastPrice.currencySymbol,
        },
      });

      if (!quoteResponse) {
        if (quoteId !== undefined) {
          callQuoteOne(quoteId);
          setQuoteResponse(true);
        }
      }
      dispatch(quoteAddFieldsClearData());
    }
  }, [responseQuoteAddFields]);

  useEffect(() => {
    if (responsePaymentMode?.length > 0) {
      if (quoteId !== undefined) {
        callQuoteOne(quoteId);
      }
    }
  }, [responsePaymentMode]);

  useEffect(() => {
    if (errorPatchQuote) {
      setTriggerPatch(false);
      showErrorToast(errorPatchQuote, setSeeToast, t);
    }
  }, [errorPatchQuote]);

  useEffect(() => {
    const paymentModeSelected = paymentModeList.find(
      (item) => item.value === "Anual",
    );
    if (paymentModeSelected) {
      setPaymentModeId(paymentModeSelected.id || "");
    }
  }, [paymentModeList]);

  useEffect(() => {
    if (responsePaymentMode?.length > 0) {
      const paymentRecurrence = responsePaymentMode?.map(
        (item: StatusOptionsModel) => ({
          id: item?.catalogPaymentMode?.id,
          name: item?.catalogPaymentMode?.description,
          value: item?.catalogPaymentMode?.code,
        }),
      );
      setPaymentModeList(paymentRecurrence);

      const payment: paymentMode = {
        id: paymentRecurrence?.[0]?.id,
        description: paymentRecurrence?.[0]?.name,
        code: paymentRecurrence?.[0]?.value,
      };
      setPaymentMode(payment);
      setPaymentModeId(payment.id);
      setPaymentModeName(payment.code);
      dispatch(paymentModeClearData());
    }
  }, [responsePaymentMode]);

  useEffect(() => {
    if (responseOneQuote && responseOneQuote?.data) {
      const quotePatch: quoteBodyPatch = {
        planId: responseOneQuote.data.planId.id,
        coverTypeId: responseOneQuote.data.planId.coverTypes?.[0].id,
        campaignId: responseOneQuote.data.campaignId.id,
        catalogStatusQuote: responseOneQuote.data.catalogStatusQuote.id,
        branchId: responseOneQuote.data.branch,
        userId: userId,
        catalogPaymentMode: paymentModeId,
      };

      if (!quoteResponse) {
        if (paymentModeId !== "") {
          setBodyPatchQuote(quotePatch);
          setTriggerPatch(true);
        }
      }

      if (quoteResponse) {
        const numericValue: number = parseFloat(
          responseOneQuote.data.lastPrice.amount
            ? responseOneQuote.data.lastPrice.amount
            : "0.00",
        );

        if (paymentModeName === "MON") {
          //const fNumber: number = numericValue / 12;
          const fNumber: number = numericValue;
          setPrice(
            responseOneQuote.data.lastPrice.amount
              ? formatToTwoDecimals(fNumber.toString())
              : "0.00",
          );
        } else {
          setPrice(
            responseOneQuote.data.lastPrice.amount
              ? formatToTwoDecimals(responseOneQuote.data.lastPrice.amount)
              : "0.00",
          );
        }

        setCurrencySymbol(responseOneQuote.data.lastPrice.currencySymbol);
        setQuoteResponse(false);
        if (responseOneQuote.data.lastPrice.amount === "0.00") {
          setShowAlert(true);
          setEnableNextBtn(false);
        } else {
          setShowAlert(false);
        }
      }
      //dispatch(quoteOneClearData());
    }
  }, [responseOneQuote, responsePaymentMode]);

  /*Valida respuesta cuando ya se actualizo el registro*/
  useEffect(() => {
    if (responsePatchQuote) {
      setTriggerPatch(false);
      /*Llama al api para recuperar el precio*/
      callQuoteAddFields();
      dispatch(patchQuoteClearData());
    }
  }, [responsePatchQuote]);

  /*Valida respuesta del getAll y mapea objetos*/
  useEffect(() => {
    if (responsePlans && responsePlans.length > 0) {
      const list = responsePlans?.map((item: PlanModel) => ({
        benefits: item.benefits,
      }));

      const listB = list[0].benefits;
      if (listB) {
        setListBenefit(listB);
      } else {
        setListBenefit([]);
      }
      setTrigger(false);
    } else if (responsePlans && responsePlans.data?.length === 0) {
      setTrigger(false);
      setListBenefit([]);
    }
  }, [responsePlans]);

  const onApplySelected = (item: any) => {
    switch (item.valueSelected.value) {
      case "MON":
        setPeriodPlanName(t("planDetail.periodPlanII"));
        break;
      case "ANU":
        setPeriodPlanName(t("planDetail.periodPlan"));
        break;
      default:
        setPeriodPlanName(t("planDetail.periodPlan"));
        break;
    }

    setPaymentModeId(item?.valueSelected?.id);
    setPaymentModeName(item.valueSelected.value);
    const payment: paymentMode = {
      id: item.valueSelected.id,
      description: item.valueSelected.name,
      code: item.valueSelected.value,
    };
    setPaymentMode(payment);
    console.log("responseOneQuote:::::", responseOneQuote);
    const quotePatch: quoteBodyPatch = {
      planId: responseOneQuote?.data?.planId?.id,
      coverTypeId: responseOneQuote?.data?.planId?.coverTypes?.[0].id,
      campaignId: responseOneQuote?.data?.campaignId?.id,
      catalogStatusQuote: responseOneQuote?.data?.catalogStatusQuote?.id,
      branchId: responseOneQuote?.data?.branch,
      userId: userId,
      catalogPaymentMode: payment?.id,
    };
    setBodyPatchQuote(quotePatch);
    setTriggerPatch(true);
  };

  const onClickNext = () => {
    callQuoteAddFields();
    navigateNext(STEP, navigate);
  };

  const callQuoteAddFields = () => {
    const body = {
      value: paymentModeId,
    };
    dispatch(
      quoteAddFields({ id: quoteId, key: "paymentFrequency", body: body }),
    );
  };

  return (
    <>
      <AlertLoadingHeader
        loading={
          loadingPlans ||
          loadingOneQuote ||
          loadingQuoteAddFields ||
          loadingPatchQuote
        }
        seeToast={seeToast}
        showToast={showToast}
        setSeeToast={setSeeToast}
        titleLoading={t("planDetail.loadingMsg")}
      />
      <PlanDetailsView
        apap={
          lsSteps.getStepStored().steps[Steps.plan].name === "LEY"
            ? false
            : true
        }
        title={t("planDetail.title")}
        btnLblNext={t("planDetail.btnLblNext")}
        currencyLbl={currencySymbol}
        defaultSelect={paymentMode?.code}
        periodPlan={periodPlanName}
        subtitleBenefit={t("planDetail.subtitleBenefit")}
        subtitleLimit={t("planDetail.subtitleLimit")}
        titleDetail={t("planDetail.titleDetail")}
        price={price}
        steps={steps}
        breadCrumbs={breadCrumbs}
        onClickNext={onClickNext}
        listBen={listBenefit}
        applySelect={onApplySelected}
        paymentModeList={paymentModeList}
        msgDescription={msgDescription}
        showAlert={showAlert}
        autoClose={autoClose}
        autoCloseActive={autoCloseActive}
        setShowAlert={setShowAlert}
        enableNextBtn={parseInt(price) > 0 ? false : true} // Para produccion  utilizar props.enableNextBtn
      />
    </>
  );
};
