import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createCertificate } from "../../store/certificates/actions";

export const useApiCreateCertificate = (
  trigger: boolean,
  certificateBody: any,
) => {
  const dispatch = useDispatch();

  const {
    loadingCreateCertificate,
    responseCreateCertificate,
    errorCreateCertificate,
  } = useSelector((state: any) => ({
    loadingCreateCertificate: state.Certificates.loadingCreateCertificate,
    responseCreateCertificate: state.Certificates.responseCreateCertificate,
    errorCreateCertificate: state.Certificates.errorCreateCertificate,
  }));

  useEffect(() => {
    if (trigger) {
      dispatch(createCertificate(certificateBody));
    }
  }, [trigger]);

  return {
    loadingCreateCertificate,
    responseCreateCertificate,
    errorCreateCertificate,
  };
};
