import {
  breadcrumb,
  MenuItem,
  select,
  typeAlert,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { STEP_CLIENTRECORD } from "../../const/catalog";
import { stepProgress } from "../../dataBase/stepProgress";
import { Steps } from "../../enum/steps";
import { getCustomerAction } from "../../fnx/customer";
import { IdentificationType } from "../../model/identificationType";
import { hasPermission } from "../../services/getNavigationPages";
import LocalStorageService from "../../services/localStorage";
import showToast from "../../services/toast";

import { quoteAll, quoteAllClearData } from "../../store/generateQuote/actions";
import { identificationType } from "../../store/identificationType/actions";
import { personById, personByIdClearData } from "../../store/person/actions";
import { formattedDate } from "../../utils/date";
import {
  beneficiaryColumns,
  Columns,
  insuredColumns,
} from "./resources/clientRecordDTO";

import "./assets/style.css";
import { ClientRecordView } from "./ui/view";
import useSelectors from "./components/selectors";
import useUI from "./components/ui";
import useClientRecord from "./components/clientRecord";
import { ClientRecordHeader } from "./ui/header";
import { Modal } from "./ui/modals";

const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);

export const ClientRecordPage = () => {
  const STEP = STEP_CLIENTRECORD;
  const dispatch = useDispatch();

  const { id } = useParams();
  const [enable, setEnable] = React.useState<boolean>(true);
  const [page, setPage] = React.useState<number>(1);
  const [company] = React.useState<string>(
    lsLogin.getUserLoginStored().subdomain || "",
  );
  const [triggerPermissionList, setTriggerPermissionList] =
    React.useState<boolean>(false);

  const [limit, setLimit] = React.useState<number>(5000);
  const [loadingAllQuote] = useState<boolean>(false);
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [clientInfo, setClientInfo] = useState<any>([]);
  const [rowsQuote, setRowsQuote] = useState<any[]>([]);
  const [showBeneficiariesModal, setShowBeneficiariesModal] =
    useState<boolean>(false);
  const [steps, setSteps] = React.useState<MenuItem[]>([]);
  const [showInsuredModal, setShowInsuredModal] = useState<boolean>(false);
  const [beneficiariesRows, setBeneficiariesRows] = useState<any[]>([]);
  const [insuredRows, setInsuredRows] = useState<any[]>([]);
  const [documentNumber, setDocumentNumber] = useState<string>("");
  const [documentType, setDocumentType] = useState<string>("");
  const [filters, setFilters] = useState<any[]>([]);
  const [breadCrumbs, setBreadCrumbs] = React.useState<breadcrumb[]>([]);
  const [showNextDisabled, setShowNextDisabled] = useState<boolean>(true);
  const storageKeyVariables = "afy-variables";
  const lsVariables = new LocalStorageService(storageKeyVariables);
  const [documentName, setDocumentName] = useState<string>("");

  const [listIdentificationType, setListIdentificationType] = useState<
    select[]
  >([]);
  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });
  const permissions = {
    create: hasPermission("sale-record-client-view"),
  };

  const storageKey = "afy-steps";
  const localStorageService = new LocalStorageService(storageKey);

  const {
    responsePerson,
    errorPerson,
    responseAllQuote,
    responseIdentificationType,
    responseBeneficiary,
    responseInsured,
    loadingPerson,
    loadingIdentificationType,
    loadingBeneficiary,
  } = useSelectors();

  const {
    handleChange,
    handleChangeSelect,
    handleSearch,
    isValid,
    validDocument,
  } = useClientRecord(
    setShowNextDisabled,
    setDocumentType,
    setDocumentName,
    setDocumentNumber,
    documentNumber,
    setSeeToast,
    typeAlert,
    listIdentificationType,
    lsVariables,
    documentType,
  );

  const { handleClickContextMenu, handleClickNext, handleClickPrevious } =
    useUI(
      setShowBeneficiariesModal,
      setShowInsuredModal,
      id,
      STEP,
      isValid,
      setSeeToast,
    );

  useEffect(() => {
    dispatch(personByIdClearData());
    dispatch(quoteAllClearData());
    dispatch(identificationType({ enable: enable, page: page, limit: limit }));
    setBreadCrumbs(stepProgress(STEP, true));
    setSteps(stepProgress(STEP));
    // Comes from the client page
    if (id) {
      setDocumentNumber(id);
      dispatch(
        //personById({ enable: enable, page: page, limit: limit, numberId: id }),
        personById(id),
      );

      if (getCustomerAction().customerAuto.includes(company)) {
        setFilters([
          {
            name: "Producto",
            label: "N/A",
            type: "label",
          },
          {
            name: "Plan",
            label: "N/A",
            type: "label",
            showChangeButton: false,
          },
        ]);
      } else {
        setFilters([
          {
            name: "Tipo de seguro",
            label: "N/A",
            type: "label",
          },
          {
            name: "Plan",
            label: "N/A",
            type: "label",
          },
          {
            name: "Periodicidad de pago",
            label: "N/A",
            type: "label",
          },
          {
            name: "Tipo de cobertura",
            label: "N/A",
            type: "label",
          },
          {
            name: "Campaña",
            label: "N/A",
            type: "label",
          },
        ]);
      }
    } else {
      if (getCustomerAction().customerAuto.includes(company)) {
        if (
          localStorageService
            .getStepStored()
            .steps[Steps.insurance]?.name.toLowerCase() === "vida termino" ||
          localStorageService
            .getStepStored()
            .steps[Steps.insurance]?.name.toLowerCase() === "vida término"
        ) {
          setFilters([
            {
              name: "Producto",
              label:
                localStorageService.getStepStored().steps[Steps.insurance]
                  ?.name ?? "",
              type: "label",
            },
          ]);
        } else {
          setFilters([
            {
              name: "Producto",
              label:
                localStorageService.getStepStored().steps[Steps.insurance]
                  ?.name ?? "",
              type: "label",
            },
            {
              name: "Plan",
              label:
                localStorageService.getStepStored().steps[Steps.plan]?.name ??
                "",
              type: "label",
            },
            {
              name: "Año",
              label:
                localStorageService.getStepStored().steps[Steps.vehiclesDetails]
                  ?.vehiclesDetails.year ?? "",
              type: "label",
            },
            {
              name: "Modelo",
              label:
                localStorageService.getStepStored().steps[Steps.vehiclesDetails]
                  ?.vehiclesDetails.carDetails?.model?.name ?? "",
              type: "label",
            },
            {
              name: "Marca",
              label:
                localStorageService.getStepStored().steps[Steps.vehiclesDetails]
                  ?.vehiclesDetails.carDetails?.brand?.name ?? "",
              type: "label",
            },
            {
              name: "Chasis",
              label:
                localStorageService.getStepStored().steps[Steps.vehiclesDetails]
                  ?.vehiclesDetails.chassisNumber ?? "",
              type: "label",
            },
            {
              name: "Placa",
              label:
                localStorageService.getStepStored().steps[Steps.vehiclesDetails]
                  ?.vehiclesDetails.registrationNumber ?? "",
              type: "label",
            },
            {
              name: "Estado del Vehiculo",
              label:
                localStorageService.getStepStored().steps[Steps.vehiclesDetails]
                  ?.vehiclesDetails?.carCondition?.name,
              type: "label",
            },
          ]);
        }
      } else {
        setFilters([
          {
            name: "Tipo de seguro",
            label:
              localStorageService.getStepStored().steps[Steps.insurance]
                ?.name ?? "",
            type: "label",
          },
          {
            name: "Plan",
            label:
              localStorageService.getStepStored().steps[Steps.plan]?.name ?? "",
            type: "label",
          },
          {
            name: "Periodicidad de pago",
            label:
              localStorageService.getStepStored().steps[Steps.plan]
                .paymentModeSelected?.name ?? "",
            type: "label",
          },
          {
            name: "Tipo de cobertura",
            label:
              localStorageService.getStepStored().steps[Steps.plan].coverType
                ?.name ?? "",
            type: "label",
          },
          {
            name: "Campaña",
            label:
              localStorageService.getStepStored().steps[Steps.plan]
                .campaignSelected?.name ?? "",
            type: "label",
          },
        ]);
      }
    }
  }, []);

  useEffect(() => {
    if (responsePerson) {
      setShowInfo(true);
      if (id) {
        setShowNextDisabled(true);
      } else {
        setShowNextDisabled(false);
      }

      dispatch(
        quoteAll({
          clientId: responsePerson.clientId,
          enable: enable,
          page: page,
          limit: limit,
          numberId: responsePerson.numberId,
          fields: "abc",
        }),
      );
      setClientInfo([
        {
          label: "Nombre completo",
          value:
            responsePerson.lastNameA +
            " " +
            responsePerson.lastNameB +
            ", " +
            responsePerson.firstNameA +
            " " +
            responsePerson.firstNameB,
        },
        {
          label: "Fecha de nacimiento",
          value: formattedDate(responsePerson.dob),
        },
        {
          label: "Identificación",
          value: documentNumber ? documentNumber : responsePerson.numberId,
        },
        { label: "Teléfono casa", value: responsePerson.phone1 },
        { label: "Email", value: responsePerson.email },
        { label: "Dirección", value: responsePerson.addressA },
      ]);
    } /* else {
      if (responsePerson === null) {
        setShowInfo(false);
        setShowNextDisabled(true);
        setSeeToast({
          show: true,
          message: "¡No se encontraron datos para la busqueda!",
          type: typeAlert.info,
        });
      }
    } */
    if (id) {
      setShowNextDisabled(true);
    } else {
      setShowNextDisabled(
        validDocument(documentName, documentNumber) ? false : true,
      );
    }
  }, [responsePerson]);

  useEffect(() => {
    if (errorPerson) {
      if (errorPerson.errors[0]?.code === 404) {
        setShowInfo(false);
        setShowNextDisabled(
          validDocument(documentName, documentNumber) ? false : true,
        );
        setSeeToast({
          show: true,
          message: "¡No se encontraron datos para la busqueda!",
          type: typeAlert.info,
        });
      }
    }
  }, [errorPerson]);

  useEffect(() => {
    setRowsQuote([]);
    if (responseAllQuote && responseAllQuote.length > 0) {
      var srq: {
        id: any;
        estado: any;
        cotizacion: any;
        poliza: any;
        producto: any;
        plan: any;
        prima: any;
        productId: string;
      }[] = [];
      responseAllQuote.map((item: any) => {
        let prima =
          item.lastPrice?.currencySymbol + " " + item.lastPrice?.amount;
        srq.push({
          id: item.id,
          estado: item.catalogStatusQuote?.description,
          cotizacion: item.code,
          poliza: item.policyId?.code,
          producto: item.productId?.description,
          plan: item.planId?.name,
          prima: prima,
          productId: item.productId?.id,
        });
      });
      setRowsQuote(srq);
    }
  }, [responseAllQuote]);

  useEffect(() => {
    if (showBeneficiariesModal && showInsuredModal) {
      setShowBeneficiariesModal(false);
      setShowInsuredModal(false);
    }
  }, [showBeneficiariesModal, showInsuredModal]);

  useEffect(() => {
    setInsuredRows([]);
    if (responseInsured && responseInsured.data?.length > 0) {
      responseInsured.data.map((item: any) => {
        setInsuredRows((prev) => [
          ...prev,
          {
            id: item.id,
            type: item.catalogInsuredTypeInfo?.description,
            name: item.personInfo?.firstNameA,
            secondName: item.personInfo?.firstNameB,
            lastName: item.personInfo?.lastNameA,
            secondLastName: item.personInfo?.lastNameB,
            marriedName: item.personInfo?.marriedName,
            phone: item.personInfo?.phone1,
            email: item.personInfo?.email,
            dob: item.personInfo?.dob,
          },
        ]);
      });
    }
  }, [responseInsured]);

  useEffect(() => {
    setBeneficiariesRows([]);
    if (responseBeneficiary && responseBeneficiary?.data?.length > 0) {
      responseBeneficiary.data.map((item: any) => {
        setBeneficiariesRows((prev) => [
          ...prev,
          {
            id: item.id,
            type: item.catalogBeneficiaryTypeInfo?.description,
            name: item.personInfo?.firstNameA,
            secondName: item.personInfo?.firstNameB,
            lastName: item.personInfo?.lastNameA,
            secondLastName: item.personInfo?.lastNameB,
            marriedName: item.personInfo?.marriedName,
            percentage: item.percentage,
            phone: item.personInfo?.phone1,
            email: item.personInfo?.email,
            dob: item.personInfo?.dob,
          },
        ]);
      });
    }
  }, [responseBeneficiary?.data]);

  useEffect(() => {
    if (responseIdentificationType?.data) {
      const data: select[] = responseIdentificationType.data?.map(
        (identification: IdentificationType) => {
          return {
            id: identification.id,
            value: identification.id,
            name: identification.description,
          };
        },
      );
      setListIdentificationType(data);
      setDocumentType(data[0]?.id ?? "");
      setDocumentName(data[0]?.name ?? "");
    }
  }, [responseIdentificationType]);

  const handleClickSeeDetails = (data: any) => {};

  return (
    <React.Fragment>
      <ClientRecordHeader
        loading={loadingPerson || loadingIdentificationType || loadingAllQuote}
        seeToast={seeToast}
        showToast={showToast}
        setSeeToast={setSeeToast}
        id={id}
        handleClickPrevious={handleClickPrevious}
      />

      <Modal
        title={"Beneficiarios"}
        openModal={showBeneficiariesModal}
        columns={beneficiaryColumns}
        rows={beneficiariesRows}
        close={() => setShowBeneficiariesModal(false)}
      />
      <Modal
        title={"Asegurados"}
        openModal={showInsuredModal}
        columns={insuredColumns}
        rows={insuredRows}
        close={() => setShowInsuredModal(false)}
      />

      {listIdentificationType.length > 0 && (
        <ClientRecordView
          title={"Registro de cliente"}
          hideFilter={id ? true : false}
          breadcrumbs={breadCrumbs}
          stepProgress={steps}
          previousLabel={"Anterior"}
          nextLabel={"Nueva cotización"}
          nextDisabled={permissions.create ? !showNextDisabled : false}
          showInfo={showInfo}
          showBeneficiariesModal={false}
          showInsuredModal={false}
          clientInfo={clientInfo}
          documentTypeList={listIdentificationType}
          rows={rowsQuote}
          filters={filters}
          columns={Columns(handleClickContextMenu)}
          handleChange={handleChange}
          onClickSeeDetails={handleClickSeeDetails}
          onSearch={handleSearch}
          handleChangeSelect={handleChangeSelect}
          onClickNext={handleClickNext}
          onClickPrevious={handleClickPrevious}
        />
      )}
    </React.Fragment>
  );
};
