import React, { useEffect, useState } from "react";
import { PlanCreationView } from "./view";
import { dataForm } from "./planCreationsDTO";
import { useDispatch, useSelector } from "react-redux";
import LocalStorageService from "../../../services/localStorage";
import { ModalDelete } from "../../../adminCore/insurances/resources/modalDelete";
import { CoverTypePlanRequest } from "../../../model/coverTypePlan";
import { deleteEntity } from "../../../store/delete/actions";
import { hasPermission } from "../../../services/getNavigationPages";
import {
  LoadingAFY,
  position,
  typeAlert,
  typeLoaders,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";

import { showErrorToast } from "../../../fnx/showError";
import showToast from "../../../services/toast";
import { themeCore } from "../../../assets/themes/theme";
import { GetCatalogPaymentMode } from "../../../fnx/catalog/getCatalogPaymentMode";

import { planPost, planPut } from "../../../store/plan/actions";
import { planOne } from "../../../store/plans/actions";
import {
  planBenefit,
  planBenefitCreate,
  planBenefitUpdate,
} from "../../../store/planBenefit/actions";
import { useNavigate, useParams } from "react-router-dom";

import { coverType } from "../../../store/coverType/actions";
import { useTranslation } from "react-i18next";

import { getCatalogPaymentModeClearData } from "../../../store/catalog/actions";
import "./style.css";

const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);

export const PlanCreation = () => {
  const { t } = useTranslation();
  const [clientId] = React.useState<string>(
    lsLogin.getUserLoginStored().clientId,
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { idPlan } = useParams();
  const { idProduct } = useParams();
  const { isEdit } = useParams();
  const { tab } = useParams();
  const [enable, setEnable] = React.useState<boolean>(true);
  const [page, setPage] = React.useState<number>(1);
  const [limit] = React.useState<number>(1000);
  const [trigger, setTrigger] = useState(true);
  const [coverTypeId, setCoverTypeId] = useState<string>("");
  const [planId, setPlanId] = useState<string>("");
  const [currentId, setCurrentId] = useState<string>("");
  const [rowId, setRowId] = useState<string>("");
  const [dataFormBenefits, setDataFormBenefits] = useState<any>([]);
  const [coverTypes, setCoverTypes] = useState<any>([]);
  const [dataFormPrimes, setDataFormPrimes] = useState<any>({
    Age: [18, 65],
  });
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [coverageTypesPrime, setCoverageTypesPrime] = useState<any>([]);
  const [triggerCoverTypePlanPost, setTriggerCoverTypePlanPost] =
    useState(false);
  const [showCancelButton, setShowCancelButton] =
    React.useState<boolean>(false);
  const [scenarios, setScenarios] = React.useState<any>([]);
  const [openModalConfirmDelete, setOpenModalConfirmDelete] =
    useState<boolean>(false);
  const [disableBenefits, setDisableBenefits] = useState<boolean>(true);
  const [disablePrimes, setDisablePrimes] = useState<boolean>(true);
  const [editBenefit, setEditBenefit] = useState<boolean>(false);
  const [editPrime, setEditPrime] = useState<boolean>(false);
  const [coverageType, setCoverageType] = React.useState<any>([]);
  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [panelActive, setPanelActive] = React.useState<number>(0);
  const [autoClose] = React.useState<number>(10000);
  const [autoCloseActive] = React.useState<boolean>(false);
  const [showBenefits, setShowBenefits] = React.useState<boolean>(false);
  const [showPrimes, setShowPrimes] = React.useState<boolean>(false);
  const [planData, setPlanData] = React.useState<any>(dataForm);
  const [isEditPlan, setIsEditPlan] = React.useState<boolean>(false);
  const [benefitsData, setBenefitsData] = React.useState<any>([]);
  const [primesData, setPrimesData] = React.useState<any>([]);
  const [paymentModeList, setPaymentModeList] = React.useState<any>([]);
  const [coverTypePlanRequest, setCoverTypePlanRequest] =
    useState<CoverTypePlanRequest>({
      id: "",
      coverTypeId: "",
      premiumEvalOptionId: "",
      planId: "",
    });

  const { responseCoverType, errorCoverType, loadingCoverType } = useSelector(
    (state: any) => ({
      responseCoverType: state.CoverType.responseCoverType,
      errorCoverType: state.CoverType.errorCoverType,
      loadingCoverType: state.CoverType.loadingCoverType,
    }),
  );

  const { responsePlan, errorPlan, loadingPlan } = useSelector(
    (state: any) => ({
      responsePlan: state.Plan.responsePlanPost,
      errorPlan: state.Plan.errorPlanPost,
      loadingPlan: state.Plan.loadingPlanPost,
    }),
  );

  const { responseOnePlan, errorPlans, loadingPlans } = useSelector(
    (state: any) => ({
      responseOnePlan: state.Plans.responseOnePlans?.data,
      errorPlans: state.Plans.errorPlans,
      loadingPlans: state.Plans.loadingPlans,
    }),
  );

  const { responsePlanPut, errorPlanPut, loadingPlanPut } = useSelector(
    (state: any) => ({
      responsePlanPut: state.Plan.responsePlanPut,
      errorPlanPut: state.Plan.errorPlanPut,
      loadingPlanPut: state.Plan.loadingPlanPut,
    }),
  );

  const { responsePlanBenefit, errorPlanBenefit, loadingPlanBenefit } =
    useSelector((state: any) => ({
      responsePlanBenefit: state.PlanBenefit.responsePlanBenefit,
      errorPlanBenefit: state.PlanBenefit.errorPlanBenefit,
      loadingPlanBenefit: state.PlanBenefit.loadingPlanBenefit,
    }));

  const {
    responsePlanBenefitCreate,
    errorPlanBenefitCreate,
    loadingPlanBenefitCreate,
  } = useSelector((state: any) => ({
    responsePlanBenefitCreate: state.PlanBenefit.responsePlanBenefitCreate,
    errorPlanBenefitCreate: state.PlanBenefit.errorPlanBenefitCreate,
    loadingPlanBenefitCreate: state.PlanBenefit.loadingPlanBenefitCreate,
  }));

  const {
    responsePlanBenefitUpdate,
    errorPlanBenefitUpdate,
    loadingPlanBenefitUpdate,
  } = useSelector((state: any) => ({
    responsePlanBenefitUpdate: state.PlanBenefit.responsePlanBenefitUpdate,
    errorPlanBenefitUpdate: state.PlanBenefit.errorPlanBenefitUpdate,
    loadingPlanBenefitUpdate: state.PlanBenefit.loadingPlanBenefitUpdate,
  }));

  const { responseDeleteEntity, loadingDeleteEntity, errorDeleteEntity } =
    useSelector((state: any) => ({
      responseDeleteEntity: state.DeleteEntity.responseDeleteEntity,
      loadingDeleteEntity: state.DeleteEntity.loadingDeleteEntity,
      errorDeleteEntity: state.DeleteEntity.errorDeleteEntity,
    }));

  const {
    responseGetCatalogPaymentMode,
    errorGetCatalogPaymentMode,
    loadingGetCatalogPaymentMode,
  } = GetCatalogPaymentMode(trigger, enable, page, limit, clientId);
  useEffect(() => {
    if (errorGetCatalogPaymentMode) {
      showErrorToast(errorGetCatalogPaymentMode, setSeeToast, t);
    }
  }, [errorGetCatalogPaymentMode]);

  const {
    responsePostCoverTypePlan,
    errorPostCoverTypePlan,
    loadingPostCoverTypePlan,
  } = useSelector((state: any) => ({
    responsePostCoverTypePlan: state.CoverTypePlan.responsePostCoverTypePlan,
    errorPostCoverTypePlan: state.CoverTypePlan.errorPostCoverTypePlan,
    loadingPostCoverTypePlan: state.CoverTypePlan.loadingPostCoverTypePlan,
  }));

  const permissions = {
    create: hasPermission("admin-product-plan-create"),
    update: hasPermission("admin-product-plan-edit"),
    delete: hasPermission("admin-product-plan-delete"),
  };

  useEffect(() => {
    if (errorPostCoverTypePlan) {
      showErrorToast(errorPostCoverTypePlan, setSeeToast, t);
    }
  }, [errorPostCoverTypePlan]);

  useEffect(() => {
    if (responseGetCatalogPaymentMode?.length > 0) {
      setPaymentModeList([]);
      let paymentMode = responseGetCatalogPaymentMode.map((item: any) => ({
        id: item.id,
        name: item.description,
        value: item.id,
      }));

      setPaymentModeList(paymentMode);
      dispatch(getCatalogPaymentModeClearData());
    }
  }, [responseGetCatalogPaymentMode]);

  useEffect(() => {
    if (
      responsePlanBenefit &&
      responsePlanBenefit.data &&
      responsePlanBenefit.data.length > 0
    ) {
      setBenefitsData([]);
      responsePlanBenefit.data.map((item: any) => {
        setBenefitsData((prev: any) => [
          ...prev,
          { id: item.id, description: item.description, value: item.value },
        ]);
      });
    }
    if (errorPlanBenefit) {
      showErrorToast(errorPlanBenefit, setSeeToast, t);
    }
  }, [responsePlanBenefit, errorPlanBenefit]);

  useEffect(() => {
    if (
      responsePlanBenefitCreate &&
      responsePlanBenefitCreate.data &&
      responsePlanBenefitCreate.data.id
    ) {
      dispatch(planBenefit({ enable, page, limit, planId }));

      setSeeToast({
        show: true,
        message: "Beneficio creado correctamente",
        type: typeAlert.success,
      });
    }
    if (errorPlanBenefitCreate) {
      showErrorToast(errorPlanBenefitCreate, setSeeToast, t);
    }
  }, [responsePlanBenefitCreate, errorPlanBenefitCreate]);

  useEffect(() => {
    if (
      responsePlanBenefitUpdate &&
      responsePlanBenefitUpdate.data &&
      responsePlanBenefitUpdate.data.id
    ) {
      setSeeToast({
        show: true,
        message: "Beneficio actualizado correctamente",
        type: typeAlert.success,
      });

      dispatch(planBenefit({ enable, page, limit, planId }));
    }
    if (errorPlanBenefitUpdate) {
      showErrorToast(errorPlanBenefitUpdate, setSeeToast, t);
    }
  }, [responsePlanBenefitUpdate, errorPlanBenefitUpdate]);

  useEffect(() => {
    if (responseDeleteEntity && responseDeleteEntity.id) {
      setSeeToast({
        show: true,
        message: "Registro eliminado correctamente",
        type: typeAlert.success,
      });

      dispatch(planBenefit({ enable, page, limit, planId }));
    }
    if (errorDeleteEntity) {
      showErrorToast(errorDeleteEntity, setSeeToast, t);
    }
  }, [responseDeleteEntity, errorDeleteEntity]);

  useEffect(() => {
    if (isEditPlan == true) {
      setPanelActive(0);
      setShowCancelButton(true);
      setDisableBenefits(false);
      setDisablePrimes(false);
    }
  }, [isEditPlan]);

  useEffect(() => {
    if (responsePlan && responsePlan.data && responsePlan.data.id) {
      setPlanId(responsePlan.data.id);
      // let ct = coverTypes;
      /* coverTypes.map((item: any) => {
         let bodyRequest = {
           planId: responsePlan.data.id,
           coverTypeId: item.id,
           premiumEvalOptionId: "953abee0-fa8f-4341-91c0-af822a658918",
         };
 
         dispatch(coverTypePlanPost(bodyRequest));
       }); */
      setSeeToast({
        show: true,
        message: "Plan creado correctamente",
        type: typeAlert.success,
      });
      setTimeout(() => {
        setPlanId(responsePlan.data.id);
        dispatch(planOne({ id: responsePlan.data.id }));
        setIsEditPlan(true);
      }, 3000);
    }
    if (errorPlan) {
      showErrorToast(errorPlan, setSeeToast, t);
    }
  }, [responsePlan, errorPlan]);

  useEffect(() => {
    if (responseOnePlan && responseOnePlan.id) {
      setPlanId(responseOnePlan.id);
      setPlanData({
        Name: responseOnePlan.name,
        Code: responseOnePlan.code,
      });

      let sc: {
        id: number;
        minAge: number;
        maxAge: number;
        paymentModeId: string;
        premiumEvalTypeId: string;
        price: number;
        coverTypeId: string;
        paymentMode: string;
        coverType: string;
      }[] = [];
      responseOnePlan.scenariosPricing?.map((item: any, index: number) => {
        sc.push({
          id: index,
          minAge: item?.minAge,
          maxAge: item?.maxAge,
          paymentModeId: item.paymentMode?.id,
          premiumEvalTypeId: "953abee0-fa8f-4341-91c0-af822a658918",
          price: item?.price,
          coverTypeId: item.coverType?.id,
          paymentMode: item.paymentMode?.description,
          coverType: item.coverType?.description,
        });
      });

      setScenarios(sc);
      setCoverageTypesPrime([]);

      //setCoverageType([]);
      let ctSelected: any[] = [];
      let ctAll: any[] = [];
      let ct = responseOnePlan.coverTypes;

      console.log("coverageType", coverageType);
      coverageType.map((item: any) => {
        ct.map((item2: any) => {
          if (item.id == item2.id) {
            item.active = true;
            ctSelected.push(item);
          }
        });

        ctAll.push(item);
      });
      console.log("ctSelected", ctSelected);
      console.log("ctAll", ctAll);
      setCoverTypes(ctSelected);
      setCoverageType(ctAll);
    }
    if (errorPlans) {
      showErrorToast(errorPlans, setSeeToast, t);
    }
  }, [responseOnePlan, errorPlans]);

  useEffect(() => {
    if (responsePlanPut && responsePlanPut.data && responsePlanPut.data.id) {
      /* coverTypes.map((item: any) => {
 
         let bodyRequest = { planId: planId, coverTypeId: item.id, premiumEvalOptionId: "953abee0-fa8f-4341-91c0-af822a658918" };
         dispatch(
           coverTypePlanPost(bodyRequest),
         );
       }
 
       );*/

      dispatch(planOne({ id: planId }));

      setSeeToast({
        show: true,
        message: "Plan actualizado correctamente",
        type: typeAlert.success,
      });

      //localStorage.setItem("tab", currentTab.toString());
      //window.location.reload();
    }
  }, [responsePlanPut]);

  useEffect(() => {
    if (errorPlanPut) {
      showErrorToast(errorPlanPut, setSeeToast, t);
    }
  }, [errorPlanPut]);

  useEffect(() => {
    if (
      responseCoverType &&
      responseCoverType.data &&
      responseCoverType.data.length > 0
    ) {
      setCoverageType([]);
      responseCoverType.data.map((item: any) => {
        setCoverageType((prev: any) => [
          ...prev,
          { id: item.id, name: item.description, value: item.id },
        ]);
      });
      dispatch(planOne({ id: idPlan }));
    }
    if (errorCoverType) {
      showErrorToast(errorCoverType, setSeeToast, t);
    }
  }, [responseCoverType, errorCoverType]);

  useEffect(() => {
    dispatch(coverType({ trigger, enable, page, limit }));

    let currentT = localStorage.getItem("tab");
    setCurrentTab(0);
    if (idPlan != undefined) {
      console.log("idPlan", idPlan);
      dispatch(planBenefit({ enable, page, limit, planId: idPlan }));
    }
  }, []);

  useEffect(() => {
    if (idPlan) {
      setPlanId(idPlan);
      //dispatch(planOne({ id: idPlan }));
      setIsEditPlan(true);
    }
  }, [idPlan, idProduct]);

  const createBenefit = (form: any) => {
    let data = {
      description: form.Name,
      value: form.Value,
      planId: planId,
    };
    if (editBenefit) {
      dispatch(planBenefitUpdate({ data }, currentId));
    } else {
      dispatch(planBenefitCreate({ data }));
    }
    setShowBenefits(false);
  };
  const createPrime = (form: any) => {
    let data = {
      minAge: form.Age[0],
      maxAge: form.Age[1],
      paymentModeId: form.IdType,
      premiumEvalTypeId: "953abee0-fa8f-4341-91c0-af822a658918",
      price: parseFloat(form.Prime),
      coverTypeId: form.IdCoverType,
    };

    let sc = [...scenarios];

    if (editPrime) {
      sc[form.id] = data;
    } else {
      sc.push(data);
    }

    setScenarios(sc);

    handleUpdateScenario(sc);

    setShowPrimes(false);
  };

  const handleUpdateScenario = (form: any) => {
    let sc: {
      minAge: any;
      maxAge: any;
      paymentModeId: any;
      premiumEvalTypeId: any;
      price: any;
      coverTypeId: any;
    }[] = [];
    form.map((item: any) => {
      sc.push({
        minAge: item.minAge,
        maxAge: item.maxAge,
        paymentModeId: item.paymentModeId,
        premiumEvalTypeId: item.premiumEvalTypeId,
        price: item.price,
        coverTypeId: item.coverTypeId,
      });
    });

    let data = {
      name: planData.Name,
      code: planData.Code,
      projectId: idProduct,
      premiumEvalOptionId: "953abee0-fa8f-4341-91c0-af822a658918",
      scenarios: sc,
    };

    dispatch(planPut(data, planId));
  };

  const handleSubmitPlan = (form: any) => {
    let ct: { coverTypeId: string; premiumEvalOptionId: string }[] = [];
    coverTypes.map((item: any) => {
      let bodyRequest = {
        coverTypeId: item.id,
        premiumEvalOptionId: "953abee0-fa8f-4341-91c0-af822a658918",
      };
      ct.push(bodyRequest);
    });

    let data = {
      name: form.Name,
      code: form.Code,
      projectId: idProduct,
      scenarios: scenarios,
      coverTypes: ct,
    };
    if (isEditPlan) {
      dispatch(planPut(data, planId));
    } else {
      dispatch(planPost(data));
    }
  };

  const onClickBack = () => {
    navigate("/product-creation" + "/" + idProduct + "/" + true + "/" + true);
  };

  const addPrimes = (data: any) => {
    setShowPrimes(true);
    setEditPrime(false);
  };

  const addBenefits = (data: any) => {
    setDataFormBenefits([]);
    setEditBenefit(false);
    setShowBenefits(true);
  };
  const handleAction = (item: any, row: any, type: string) => {
    setCurrentTab(type == "benefits" ? 0 : 1);
    setRowId(row.idrow);
    setCurrentId(row.id);
    if (item.id == "delete") {
      setOpenModalConfirmDelete(true);
    }
    if (item.id == "edit") {
      if (type == "benefits") {
        setEditBenefit(true);
        setShowBenefits(true);
        setDataFormBenefits({
          Name: row.description,
          Value: row.value,
        });
      }

      if (type == "primes") {
        setShowPrimes(true);
        setEditPrime(true);
        setDataFormPrimes({
          id: row.id,
          Age: [row.minAge, row.maxAge],
          IdType: row.paymentModeId,
          Prime: row.price,
          IdCoverType: row.coverTypeId,
        });
      }
    }
  };

  const onClickDelete = () => {
    setOpenModalConfirmDelete(false);
    if (currentTab == 0) {
      dispatch(
        deleteEntity({
          id: currentId,
          entity: "BenefitEntity",
          idUser: lsLogin.getUserLoginStored().userId,
        }),
      );
    }
    if (currentTab == 1) {
      const updatedScenarios = scenarios.filter(
        (scenario: any, index: any) => index !== rowId,
      );
      setScenarios(updatedScenarios);
      handleUpdateScenario(updatedScenarios);
    }
  };

  const handleSetCoverTypes = (e: any) => {
    setCoverTypes(e);
  };

  return (
    <>
      {seeToast.show &&
        showToast(position.topRight, seeToast.type, seeToast.message, () => {
          setSeeToast({ show: false, message: "", type: typeAlert.success });
        })}
      <LoadingAFY
        loading={
          loadingCoverType ||
          loadingPlan ||
          loadingPlans ||
          loadingPlanPut ||
          loadingPlanBenefit ||
          loadingPlanBenefitCreate ||
          loadingPlanBenefitUpdate ||
          loadingDeleteEntity ||
          loadingPostCoverTypePlan ||
          loadingGetCatalogPaymentMode
        }
        backgroundColor={themeCore.colors.backgroundLoader}
        typeAnimation={typeLoaders.LogoAfy}
        sizeAnimation={65}
        textLoading={"Cargando..."}
        textLoadingColor={themeCore.colors.third}
        spinnerColor={themeCore.colors.third}
      />
      <ModalDelete
        openModalConfirmDelete={openModalConfirmDelete}
        setOpenModalConfirmDelete={setOpenModalConfirmDelete}
        itemCard={[]}
        onClickDelete={onClickDelete}
      />
      <PlanCreationView
        currentTab={currentTab}
        permissions={permissions}
        onUpdateCoverTypeSelected={(e) => handleSetCoverTypes(e)}
        dataFormPrimes={dataFormPrimes}
        dataFormBenefits={dataFormBenefits}
        coverageType={coverageType}
        coverageTypesPrime={coverTypes}
        planData={planData}
        scenarios={scenarios}
        onClickAddBenefits={addBenefits}
        onClickAddPrimes={addPrimes}
        openModalBenefit={showBenefits}
        onCloseModalBenefit={() => setShowBenefits(false)}
        openModalPrime={showPrimes}
        onCloseModalPrime={() => setShowPrimes(false)}
        onSaveBenefit={createBenefit}
        onSavePrime={createPrime}
        handleSubmitPlan={handleSubmitPlan}
        handleClickContextMenu={handleAction}
        showAlert={showAlert}
        panelActive={currentTab}
        autoClose={autoClose}
        autoCloseActive={autoCloseActive}
        setShowAlert={setShowAlert}
        onClickBack={onClickBack}
        disableBenefits={disableBenefits}
        disablePrimes={disablePrimes}
        idPlan={planId}
        isEdit={isEditPlan.toString()}
        benefitsData={benefitsData}
        showCancelButton={showCancelButton}
        activateEditMode={!isEditPlan}
        coverageTypeList={coverageType}
        paymentModeList={paymentModeList}
        // Remove the duplicate attribute 'panelActive={currentTab}'
        // panelActive={currentTab}
        setShowCancelButton={function (value: boolean | (() => boolean)): void {
          throw new Error("Function not implemented.");
        }}
      />
    </>
  );
};
