import { useCallback, useMemo, useState } from "react";
import { BodyRequest } from "../../../model/certificates";

const useFnCreateCertificate = () => {
  const [bodyRequest, setBodyRequest] = useState<BodyRequest>();

  const createCertificate = useCallback(
    (
      data: string | undefined,
      fileName: string,
      typeFile: string,
      setTriggerCreate: (value: boolean | (() => boolean)) => void,
    ) => {
      setBodyRequest({
        title: fileName,
        type: typeFile,
        fileName: fileName,
        html: data,
        physicalName: fileName,
        quickPrintable: false,
      });

      setTriggerCreate(true);
    },
    [],
  );

  return useMemo(
    () => ({
      createCertificate,
      bodyRequest,
    }),
    [createCertificate, bodyRequest],
  );
};

export default useFnCreateCertificate;
