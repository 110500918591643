
import { useSelector } from 'react-redux';

const useSelectors = () => {
    const {
        responsePerson,
        errorPerson,
        loadingPerson,
    } = useSelector((state: any) => ({
        responsePerson:
            state.Person.responsePerson?.data,
        errorPerson: state.Person.errorPerson,
        loadingPerson: state.Person.loadingPerson,
    }));
    return {
        responsePerson,
        errorPerson,
        loadingPerson
    }
}
export default useSelectors;