import React from "react";
import {
  breadcrumb,
  FormClientRecordAFY,
  select,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { GridColDef } from "@mui/x-data-grid";
import { HeaderTitlePage } from "../../../components/resources/headerTitlePage/headerTitlePage";

interface ClientsViewProps {
  title?: string;
  breadcrumbs?: breadcrumb[];
  showInfo?: boolean;
  tableHeight?: string;
  handleChange?: (e: any, a: any) => void;
  handleChangeSelect?: (e: any, a: any) => void;
  onClickSeeDetails?: (data: any) => void;
  columns?: GridColDef[];
  rows?: any[];
  beneficiariesRows?: any[];
  beneficiariesColumns?: GridColDef[];
  insuredRows?: any[];
  insuredColumns?: GridColDef[];
  clientInfo?: any;
  documentType?: select[];
  onSearch?: (term: string, value: string) => void;
  filters?: any[];
  documentTypeList?: select[];
  stepProgress?: any;
  nextLabel?: string;
  previousLabel?: string;
  onClickPrevious?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onClickNext?: (data: any) => void;
  nextDisabled?: boolean;
  showPrevious?: boolean;
  showBeneficiariesModal?: boolean;
  showInsuredModal?: boolean;
  hideFilter?: boolean;
}

export const ClientRecordView = (props: ClientsViewProps) => {
  return (
    <React.Fragment>
      <HeaderTitlePage
        breadCrumbs={props.breadcrumbs}
        titlePage={props.title}
        steps={props.stepProgress}
        hideStep={false}
        stepsTop={"-20px"}
      />
      {/*<BreadcrumbAFY options={props.breadcrumbs} />*/}
      <FormClientRecordAFY
        // title={props.title}
        // stepProgress={props.stepProgress}
        hideFilter={props.hideFilter}
        showInfo={props.showInfo}
        handleChange={props.handleChange}
        columns={props.columns}
        clientInfo={props.clientInfo}
        tableHeight={props.tableHeight}
        rows={props.rows}
        onSearch={props.onSearch}
        filters={props.filters}
        documentTypeList={props.documentTypeList}
        onClickSeeDetails={props.onClickSeeDetails}
        nextLabel={props.nextLabel}
        previousLabel={props.previousLabel}
        onClickPrevious={props.onClickPrevious}
        onClickNext={props.onClickNext}
        showNext={props.nextDisabled}
        nextDisabled={!props.nextDisabled}
        showPrevious={props.showPrevious}
        handleChangeSelect={props.handleChangeSelect}
        minHeightGrid="calc(100vh - 193px)"
      />
    </React.Fragment>
  );
};
