import { useCallback, useMemo } from "react";
import { CertificateTemplate } from "../../../model/certificates";

const useTableRows = (
  setRowsTable: React.Dispatch<React.SetStateAction<CertificateTemplate[]>>,
) => {
  const setDataRowsTable = useCallback(
    (responseCertificates: CertificateTemplate[]) => {
      const rowsTable = responseCertificates.map((item) => ({
        id: item.id,
        title: item.title,
        type: item.type,
        physicalName: item.physicalName,
        quickPrintable: item.quickPrintable,
        tablesMetadata: item.tablesMetadata,
        thumbnails: item.thumbnails,
      }));
      setRowsTable(rowsTable);
    },
    [setRowsTable],
  );

  return useMemo(
    () => ({
      setDataRowsTable,
    }),
    [setDataRowsTable],
  );
};

export default useTableRows;
