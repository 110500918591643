import { useSelector } from "react-redux";
import { useGetCatalogAccountType } from "../../../fnx/getCatalogAccountType";
import { GetPersonById } from "../../../fnx/person/getPersonById";
const useSelectors = (
    trigger: any,
    projectId: any,
    personTrigger: any,
    personId: any
) => {
    const { responseCreatePolicy, errorCreatePolicy, loadingCreatePolicy } =
        useSelector((state: any) => ({
            responseCreatePolicy: state.Policy.responseCreatePolicy,
            errorCreatePolicy: state.Policy.errorCreatePolicy,
            loadingCreatePolicy: state.Policy.loadingCreatePolicy,
        }));

    const { responseCatalogAccountType, errorCatalogAccountType } =
        useGetCatalogAccountType(trigger, projectId);

    const { responsePersonById, errorPersonById, loadingPersonById } =
        GetPersonById(personTrigger, personId);

    const { responseOneQuote, errorOneQuote, loadingOneQuote } = useSelector(
        (state: any) => ({
            responseOneQuote: state.Quote.responseOneQuote?.data,
            errorOneQuote: state.Quote.errorOneQuote,
            loadingOneQuote: state.Quote.loadingOneQuote,
        })
    );
    return {
        responseCreatePolicy,
        errorCreatePolicy,
        loadingCreatePolicy,
        responseCatalogAccountType,
        errorCatalogAccountType,
        responsePersonById,
        errorPersonById,
        loadingPersonById,
        responseOneQuote,
        errorOneQuote,
        loadingOneQuote,
    };
}
export default useSelectors;