import { useDispatch, useSelector } from "react-redux";
import { GetProjectOne } from "../../../../fnx/project/projectOne";
import { PostUploadAttachments } from "../../../../fnx/apaAuditDocuments/quoteUploadAttachtments";
import { GetQuoteOne } from "../../../../fnx/quotes/getQuoteOne";


const useSelectors = (
    trigger: boolean,
    idQuoteParams: any,
    quoteId: any,
    projectTrigger: boolean,
    projectId: any,
    uploadTrigger: boolean,
    fileName: any,
    pdfFile: any,
    documentCode: any
) => {
    const {
        responseUpdateStatusAuditDocs,
        errorUpdateStatusAuditDocs,
        loadingUpdateStatusAuditDocs,
    } = useSelector((state: any) => ({
        responseUpdateStatusAuditDocs: state.Quote.responseUpdateStatusAuditDocs,
        errorUpdateStatusAuditDocs: state.Quote.errorUpdateStatusAuditDocs,
        loadingUpdateStatusAuditDocs: state.Quote.loadingUpdateStatusAuditDocs,
    }));

    const { responseOneQuote, errorOneQuote, loadingOneQuote } = GetQuoteOne(
        trigger,
        idQuoteParams ? idQuoteParams : quoteId
    );

    const { responseOneProject, errorOneProject, loadingOneProject } =
        GetProjectOne(projectTrigger, projectId);

    const { responseUploadAttach, errorUploadAttach, loadingUploadAttach } =
        PostUploadAttachments(
            uploadTrigger,
            idQuoteParams ? idQuoteParams : quoteId,
            fileName,
            pdfFile,
            documentCode
        );
    return {
        responseUpdateStatusAuditDocs,
        errorUpdateStatusAuditDocs,
        loadingUpdateStatusAuditDocs,
        responseOneQuote,
        errorOneQuote,
        loadingOneQuote,
        responseOneProject,
        errorOneProject,
        loadingOneProject,
        responseUploadAttach,
        errorUploadAttach,
        loadingUploadAttach,

    };
}

export default useSelectors;
