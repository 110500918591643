import { Navigate, Route, Routes } from "react-router-dom";
import Insurance from "../salesCore/insurance/insurance";
import Plan from "../salesCore/plan/plan";
import Dashboard from "../salesCore/dashboard/dashboard";
import CustomerForms from "../salesCore/customerForms/customerForms";
import ConfirmationForm from "../salesCore/confirmationForm/confirmationForm";
import PaymentSummary from "../salesCore/paymentSummary/paymentSummary";
import PaymentConfirmation from "../salesCore/paymentConfirmation/paymentConfirmation";
import InsurancePoliciesSummary from "../salesCore/insurancePoliciesSummary/insurancePoliciesSummary";
import { hasAuditRole, hasPermission } from "../services/getNavigationPages";
import { SponsorAdmin } from "../adminCore/sponsor/sponsor";
import { BranchesAdmin } from "../adminCore/branches/branches";
import { AccountTypeAdmin } from "../adminCore/accountType/accountType";
import { InsurancesAdmin } from "../adminCore/insurances/insurances";
import { InsuranceTypeAdmin } from "../adminCore/insuranceType/insuranceType";
import { RegionAdmin } from "../adminCore/region/region";
import { BranchAdmin } from "../adminCore/branch/branch";
import { PlanAdmin } from "../adminCore/plan/plan";
import { UserAdmin } from "../adminCore/user/user";
import { IdentificationTypeAdmin } from "../adminCore/identificationType/identificationType";
import { PolicyReport } from "../salesCore/policyReport/policyReport";
import { GridPolicyReport } from "../salesCore/policyReport/resources/gridPolicyReport";
import { ReportPage } from "../salesCore/reportsPage/reportPage";
import { Completed } from "../salesCore/reportsPage/completedSales/completed";
import CataloguesAdmin from "../adminCore/catalogue/catalogue";
import ProjectTypesAdmin from "../adminCore/projectType/projectType";
import { ClientsPage } from "../salesCore/clients/clients";
import { ClientRecordPage } from "../salesCore/clientRecord/clientRecord";
import GeneralCatalogAdmin from "../adminCore/generalCatalog/generalCatalog";
import { ProductCreation } from "../adminCore/insurances/productCreation/productCreation";
import { PlanCreation } from "../adminCore/plan/planCreation/planCreation";
import { RolAdmin } from "../adminCore/rol/rol";

import { AgentDashboard } from "../salesCore/agentDashboard/agentDashboard";
import { BranchDashboard } from "../adminCore/branchDashboard/branchDashboard";
import { VehicleDetails } from "../apap/salesCore/vehicleDetail/vehicleDetails";
import { AuditPage } from "../apap/salesCore/auditPage/auditPage";
import { PlanDetails } from "../apap/salesCore/planDetail/planDetail";
import { PdfViewerApap } from "../apap/salesCore/pdfViewer/pdfViewer";
import { ApplicantDeclarationPage } from "../salesCore/applicantDeclaration/applicantDeclaration";
import { FinancialInformation } from "../apap/salesCore/finalcialInformation/financialInformation";
import { InitialInfoPage } from "../salesCore/initialInfo/initialInfo";
import { MedicalInformationPage } from "../salesCore/medicalInformation/medicalInformation";
import { ApplicantInformation } from "../apap/salesCore/applicantInformation/applicantInformation";
import LocalStorageService from "../services/localStorage";
import { getPermission } from "../services/nav";
import { PendingPage } from "../salesCore/pendingPage/pendingPage";
import { CertificatesPage } from "../adminCore/certificates/ui/certificatesPage";
import { CertificatesEditorPage } from "../adminCore/certificatesEditor/certificatesEditorPage";
import Collect from "../adminCore/collect/collect";
import Billing from "../adminCore/cobranza/cobranza";
import GenerateBilling from "../adminCore/billing/billing";

export const PrivateRoutes = () => {
  const storageKey = "afy-login";
  const localStorageService = new LocalStorageService(storageKey);
  const permissions = getPermission();

  let showSale = permissions.find((p) => p.name === "admin-sales");

  return (
    <Routes>
      {showSale ? (
        <Route path="/" element={<Insurance />} />
      ) : (
        <Route path="/" element={<InsurancePoliciesSummary />} />
      )}

      <Route path="/insurance" element={<Insurance />} />
      <Route path="/plan" element={<Plan />} />
      <Route path="/customerForm" element={<CustomerForms />} />
      <Route
        path="/confirmationForm/:id?/:isQuote?/:isPlan?/:idProject?/:isDocumentClient?"
        element={<ConfirmationForm />}
      />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/checkout/payment-method" element={<PaymentSummary />} />
      <Route
        path="/checkout/payment-confirmation"
        element={<PaymentConfirmation />}
      />
      <Route
        path="/insurance-policies-summary"
        element={<InsurancePoliciesSummary />}
      />
      <Route
        path="/insurance-policies-summary"
        element={<InsurancePoliciesSummary />}
      />
      {hasPermission("admin-product-view") && (
        <Route path="/insurances-admin" element={<InsurancesAdmin />} />
      )}
      <Route path="/sponsor-admin" element={<SponsorAdmin />} />
      <Route path="/branches-admin" element={<BranchesAdmin />} />
      <Route
        path="/identification-type-admin"
        element={<IdentificationTypeAdmin />}
      />
      <Route path="/account-type-admin" element={<AccountTypeAdmin />} />
      {hasPermission("admin-product-type-view") && (
        <Route path="/insurance-type-admin" element={<InsuranceTypeAdmin />} />
      )}
      <Route path="/cataloguesadmin" element={<CataloguesAdmin />} />
      <Route path="/project-types-admin" element={<ProjectTypesAdmin />} />
      {hasPermission("admin-catalog-view") && (
        <Route
          path="/general-catalog-admin"
          element={<GeneralCatalogAdmin />}
        />
      )}
      <Route path="/rol-admin" element={<RolAdmin />} />
      <Route
        path="/identificationtypeadmin"
        element={<IdentificationTypeAdmin />}
      />
      <Route path="/branch-admin" element={<BranchAdmin />} />
      <Route path="/region-admin" element={<RegionAdmin />} />
      <Route path="/planadmin" element={<PlanAdmin />} />
      {hasPermission("admin-users-view") && (
        <Route path="/user-admin" element={<UserAdmin />} />
      )}
      <Route path="/policy-report" element={<PolicyReport />} />
      <Route path="/report" element={<ReportPage />} />
      <Route path="/clients" element={<ClientsPage />} />
      <Route path="/client-record/:id?" element={<ClientRecordPage />} />
      <Route path="/report/sales/:id?" element={<Completed />} />
      <Route path="/grid-policy-report" element={<GridPolicyReport />} />
      {hasPermission("admin-product-plan-view") && (
        <Route
          path="/product-creation/:idProduct?/:isEdit?/:plans?"
          element={<ProductCreation />}
        />
      )}
      <Route path="/agent-dashboard" element={<AgentDashboard />} />
      <Route path="/branch-dashboard" element={<BranchDashboard />} />
      <Route path="/certificates" element={<CertificatesPage />} />
      <Route
        path="/certificates-editor/:certificateId?"
        element={<CertificatesEditorPage />}
      />
      <Route
        path="/certificates/:created?/:edited?"
        element={<CertificatesPage />}
      />
      <Route
        path="/certificates-editor/:certificateId?/:isUpdate?"
        element={<CertificatesEditorPage />}
      />
      <Route
        path="/certificates/:created?/:edited?"
        element={<CertificatesPage />}
      />
      <Route
        path="/certificates-editor/:certificateId?/:isUpdate?"
        element={<CertificatesEditorPage />}
      />
      <Route
        path="/certificates/:created?/:edited?"
        element={<CertificatesPage />}
      />
      <Route
        path="/certificates-editor/:certificateId?/:isUpdate?"
        element={<CertificatesEditorPage />}
      />
      <Route
        path="/plan-creation/:idProduct?/:idPlan?/:tab?"
        element={<PlanCreation />}
      />
      
      <Route path="/billing" element={<Billing />} />
      <Route path="/admin/collect/:id?" element={<Collect />} />
      <Route path="/admin/generate-billing" element={<GenerateBilling />} />

      <Route path="*" element={<Navigate to="/" replace />} />
      {/*ROUTE APAP*/}

      {hasPermission("sales-apap-vehicle-details-view") && (
        <Route path="/datos-vehiculo-apap-auto" element={<VehicleDetails />} />
      )}
      {(hasPermission("sales-apap-audit-view") || hasAuditRole("audit")) && (
        <Route
          path="/fiscalizacion-apap-auto/:idQuoteParams?"
          element={<AuditPage />}
        />
      )}
      {hasPermission("sales-apap-plan-details-view") && (
        <Route path="/plan-detail-apap-auto" element={<PlanDetails />} />
      )}

      <Route
        path="/visor-pdf/:idDocument?/:idQuote?"
        element={<PdfViewerApap />}
      />

      <Route
        path="/applicant-secured-declaration/"
        element={<ApplicantDeclarationPage />}
      />
      <Route path="/financial-info/" element={<FinancialInformation />} />
      <Route path="/initial-info/" element={<InitialInfoPage />} />
      <Route path="/medical-info/" element={<MedicalInformationPage />} />
      <Route path="/pending-info-page/" element={<PendingPage />} />
      <Route path="/applicant-info/" element={<ApplicantInformation />} />
     

      {/*endROUTE APAP*/}
    </Routes>
  );
};
