import React, { useEffect } from "react";
import { useStyles } from "../assets/style";
import "../assets/style.css";

import {
  ButtonAFY,
  CobranzaAFY,
  sizes,
  variants,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { themeCore } from "../../../assets/themes/theme";
import { Title } from "../../../components/layout/style";
import MetaTags from "react-meta-tags";


const View = (props: any) => {
  const classes = useStyles();
  const [products, setProducts] = React.useState<any>(props.products);

  useEffect(() => {
    if (props.products && props.products.length > 0) {
      setProducts(props.products);
    }
  }
    , [props.products]); 
  const onClickNext = (e: React.MouseEvent<HTMLButtonElement>) => {
    props.onClickNext && props.onClickNext(e);
  };

  const onClickBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    props.onClickBack && props.onClickBack(e);
  };
  return (
    <React.Fragment>

      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Title>{props.title}</Title>
        <ButtonAFY
          variant={variants.contained}
          size={sizes.large}
          label={"Generar Cobro"}
          onClick={(e) => {
            e.stopPropagation();
            props.onClickGenerate();
          }}
        />
      </div>
      

      <div className="containerPanel" style={{ marginTop: "15px" }}>

        <CobranzaAFY
          title={"   Cobranza  "}
          data={props.data}
          columns={props.columns}
          onClickGenerate={props.onClickGenerate}
          onClickDownload={props.onClickDownload}
          backgroundColorBack={props.backgroundColorBack}
          backgroundColorNext={props.backgroundColorNext}
          onClickNext={onClickNext}
          onClickBack={onClickBack}
          next={{
            nextLabel: "",
            previousLabel: "",
            nextDisabled: false,
          }
          }
        />
      </div>
    </React.Fragment>
  );
};

export default View;
