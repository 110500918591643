import {
  ListDropdownMultipleProps,
  MenuItem,
  typeAlert,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { GetCertificatesTemplate } from "../../../fnx/certificate/getCertificatesTemplate";
import { CertificateTemplate } from "../../../model/certificates";
import {
  certificateTemplateClearData,
  certificateToProjectClearData,
} from "../../../store/certificates/actions";
import { View } from "./view";
import { useNavigate, useParams } from "react-router-dom";
import showToast from "../../../services/toast";
import useTableRows from "../helpers/tableRows";
import useDataCards from "../helpers/dataCards";
import useViewTypes from "../helpers/pageViewTypes";
import usePagination from "../helpers/pagination";
import { useSponsorGetAll } from "../../../fnx/sponsor/sponsorGetAll";
import { GetProjectAll } from "../../../fnx/project/projectAll";
import { LabelAutoCompleteProps } from "../../../model/user";
import { projectClearData } from "../../../store/project/actions";
import { sponsorClearData } from "../../../store/sponsor/actions";
import useAssociateProduct from "../helpers/drawerAssociateProduct";
import { PostCertificatesToProject } from "../../../fnx/certificate/certificateToProject";
import { showErrorToast } from "../../../fnx/showError";
import { CertificatesEditorHeader } from "./header";
import { Project } from "../../../model/project";
import { showToastSuccessMessage } from "../../../fnx/sendSuccessMessage";
import useFilters from "../helpers/filters";
import { GetAllSchemasTemplate } from "../../../fnx/certificate/getAllSchemasTemplate";
import useGetMergeFields from "../helpers/getMergeFields";

export const CertificatesPage = () => {
  const { created } = useParams<{ created: string }>();
  const { edited } = useParams<{ edited: string }>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dataCards, setDataCards] = useState<CertificateTemplate[]>([]);
  const [rowsTable, setRowsTable] = React.useState<CertificateTemplate[]>([]);
  const [projectList, setProjectList] = React.useState<
    ListDropdownMultipleProps[]
  >([]);
  const [productListDrawer, setProductListDrawer] = React.useState<
    LabelAutoCompleteProps[]
  >([]);
  const [hasProjectList, setHasProjectList] = React.useState<Project[]>([]);
  const [certificateName, setCertificateName] = useState<string>("");
  const [searchProjectId, setSearchProjectId] = useState<string>("");
  const [certType, setCertType] = useState<string>("");
  const [searchTitleCertificate, setSearchTitleCertificate] =
    useState<string>("");
  const [certificateId, setCertificateId] = React.useState<string>("");
  const [projectId, setProjectId] = React.useState<string>("");
  const [sponsorsIds, setSponsorsIds] = React.useState<string>("");
  const [triggerGetSchemas, setTriggerGetSchemas] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [showPagination, setShowPagination] = useState<boolean>(false);
  const [certificateTrigger, setCertificateTrigger] = useState<boolean>(false);
  const [sponsorTrigger, setSponsorTrigger] = useState<boolean>(false);
  const [projectTrigger, setProjectTrigger] = React.useState(false);
  const [responseData, setResponseData] = React.useState([]);
  const [certificateToProjectTrigger, setCertificateToProjectTriggerTrigger] =
    React.useState(false);
  const [enable, setEnable] = useState<boolean>(true);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [sponsorLimit, setSponsorLimit] = useState<number>(24);
  const [limit, setLimit] = useState<number>(24);
  const [sponsorPage, setSponsorPage] = useState<number>(1);
  const [page, setPage] = useState<number>(1);

  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  //call-apis
  const {
    loadingCertificateTemplate,
    responseCertificateTemplate,
    errorCertificateTemplate,
  } = GetCertificatesTemplate(
    certificateTrigger,
    enable,
    page,
    limit,
    searchTitleCertificate,
    searchProjectId,
    certType,
  );

  const { loadingSponsor, responseSponsor, errorSponsor } = useSponsorGetAll(
    sponsorTrigger,
    "",
    "",
    sponsorPage,
    sponsorLimit,
  );

  const { loadingProject, responseProject, errorProject } = GetProjectAll(
    projectTrigger,
    enable,
    sponsorPage,
    sponsorLimit,
    sponsorsIds,
  );

  const {
    loadingCertificateToProject,
    responseCertificateToProject,
    errorCertificateToProject,
  } = PostCertificatesToProject(
    certificateToProjectTrigger,
    certificateId,
    projectId,
  );

  const {
    loadingSchemasTemplate,
    responseSchemasTemplate,
    errorSchemasTemplate,
  } = GetAllSchemasTemplate(triggerGetSchemas);

  //call-Utils-helpers
  const { setDataCardsList, optionsCards } = useDataCards(setDataCards);
  const { setDataRowsTable } = useTableRows(setRowsTable);
  const { onClickTypeView } = useViewTypes();
  const { onChangePage, onChangeItemPerPage } = usePagination();
  const { listProjects } = useAssociateProduct();
  const { filterCertType, filterProject, onChangeFullTextSearch, clearFilter } =
    useFilters(
      setCertType,
      setSearchProjectId,
      setSearchTitleCertificate,
      setCertificateTrigger,
    );
  const { getMergeFields, mergeFieldsData } = useGetMergeFields();

  useEffect(() => {
    setCertificateTrigger(true);
    setSponsorTrigger(true);
    setTriggerGetSchemas(true);
  }, []);

  useEffect(() => {
    sessionStorage.setItem("mergeFieldsData", JSON.stringify(mergeFieldsData));
  }, [mergeFieldsData]);

  useEffect(() => {
    setCertificateTrigger(true);
  }, [certType, searchProjectId, searchTitleCertificate]);

  useEffect(() => {
    const hasShownToast = sessionStorage.getItem("hasShownToast");

    if (!hasShownToast && (created === "true" || edited === "true")) {
      const status = created === "true" ? "created" : "edited";

      showToastSuccessMessage(
        t(`certificatesEditor.${status}`),
        typeAlert.success,
        setSeeToast,
      );
      sessionStorage.setItem("hasShownToast", "true");
    }
  }, [created, edited]);

  useEffect(() => {
    if (responseCertificateTemplate && responseCertificateTemplate.data) {
      setDataCardsList(responseCertificateTemplate.data); //llena la data de los cards
      setDataRowsTable(responseCertificateTemplate.data); // llena data vista tabla

      if (responseCertificateTemplate.pagingInfo) {
        setTotalItems(responseCertificateTemplate.pagingInfo.totalItems);
      }

      setCertificateTrigger(false);

      dispatch(certificateTemplateClearData());
    }
  }, [responseCertificateTemplate]);

  useEffect(() => {
    setShowPagination(totalItems > limit);
  }, [totalItems]);

  useEffect(() => {
    if (responseSponsor && responseSponsor.data?.length > 0) {
      const idSponsor = responseSponsor.data
        .map((item: any) => item.id)
        .join("&sponsorId=");

      setSponsorsIds(idSponsor);

      setProjectTrigger(true);
      dispatch(sponsorClearData());
    }
  }, [responseSponsor]);

  useEffect(() => {
    if (responseProject?.data) {
      const { drawerProjectList, ProjectList } = listProjects(
        responseProject.data,
      );
      setProjectList(ProjectList);
      setProductListDrawer(drawerProjectList);
      dispatch(projectClearData());
      setProjectTrigger(false);
    }
  }, [responseProject]);

  useEffect(() => {
    if (responseCertificateToProject) {
      showToastSuccessMessage(
        t("certificates.added"),
        typeAlert.success,
        setSeeToast,
      );
      setOpenDrawer(false);
      setCertificateTrigger(true);
      setCertificateToProjectTriggerTrigger(false);
      dispatch(certificateToProjectClearData());
    }
  }, [responseCertificateToProject]);

  useEffect(() => {
    if (errorCertificateToProject) {
      showErrorToast(errorCertificateToProject, setSeeToast, t);
      setCertificateToProjectTriggerTrigger(false);
      dispatch(certificateToProjectClearData());
    }
  }, [errorCertificateToProject]);

  useEffect(() => {
    if (errorCertificateTemplate) {
      showErrorToast(errorCertificateTemplate, setSeeToast, t);
    }
  }, [errorCertificateTemplate]);

  useEffect(() => {
    if (errorSchemasTemplate) {
      showErrorToast(errorSchemasTemplate, setSeeToast, t);
    }
  }, [errorSchemasTemplate]);

  useEffect(() => {
    setProductListDrawer((prevList) =>
      prevList.filter(
        (product) =>
          !hasProjectList.some((project) => project.id === product.id),
      ),
    );
  }, [hasProjectList]);

  useEffect(() => {
    if (responseSchemasTemplate?.data) {
      getMergeFields(responseSchemasTemplate.data.schemas);
    }
  }, [responseSchemasTemplate]);

  const onClickButtonTypeView = (option: MenuItem) =>
    onClickTypeView(option, setPage, setCertificateTrigger);

  const optionCard = (item: MenuItem, data: CertificateTemplate) =>
    optionsCards(
      item,
      data,
      navigate,
      setCertificateName,
      setCertificateId,
      setOpenDeleteModal,
      setOpenDrawer,
      setHasProjectList,
    );

  const turnPage = (page: number) =>
    onChangePage(setPage, page, setCertificateTrigger);

  const itemPerPage = (item: number) =>
    onChangeItemPerPage(setLimit, item, setCertificateTrigger);

  const associateCertificateWithProduct = (data: any) => {
    setProjectId(data?.productList?.id);
    setCertificateToProjectTriggerTrigger(true);
    setCertificateTrigger(true);
    setProjectTrigger(true);
    setHasProjectList([]);
  };

  return (
    <React.Fragment>
      <CertificatesEditorHeader
        loading={
          loadingCertificateTemplate ||
          loadingSponsor ||
          loadingProject ||
          loadingCertificateToProject ||
          loadingSchemasTemplate
        }
        seeToast={seeToast}
        showToast={showToast}
        setSeeToast={setSeeToast}
      />
      <View
        t={t}
        totalItems={totalItems}
        page={page}
        limit={limit}
        onClickButtonTypeView={onClickButtonTypeView}
        certificatesList={dataCards}
        optionCard={optionCard}
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        showPagination={showPagination}
        onChangePage={turnPage}
        onChangeRowsPerPage={itemPerPage}
        rowsTable={rowsTable}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        setProjectTrigger={setProjectTrigger}
        certificateName={certificateName}
        projectList={projectList}
        productListDrawer={productListDrawer}
        associateCertificateWithProduct={associateCertificateWithProduct}
        navigate={navigate}
        filterCertType={filterCertType}
        clearFilter={clearFilter}
        filterProject={filterProject}
        onChangeFullTextSearch={onChangeFullTextSearch}
      />
    </React.Fragment>
  );
};
