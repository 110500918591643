import {
  CardProps,
  PendingPageAFY,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { HeaderTitlePage } from "../../components/resources/headerTitlePage/headerTitlePage";
import React from "react";
import { columnsFn } from "./DTO";

export const PendingPageView = (props: {
  title: string;
  handleClickContextMenu: (item: any, param: any) => void;
  dataCard?: CardProps[];
  rows?: any[];
}) => {
  return (
    <>
      <HeaderTitlePage
        breadCrumbs={[]}
        titlePage={props.title}
        steps={[]}
        hideStep={true}
        stepsTop={""}
      />

      <PendingPageAFY
        heightGrid="calc(100vh - 351px)"
        dataCard={props.dataCard}
        columns={columnsFn(props.handleClickContextMenu)}
        rows={props.rows}
      />
    </>
  );
};
