import {
  CertificatesPageAFY,
  icons,
  ListDropdownMultipleProps,
  MenuItem,
  sizesIcon,
  variantsAvatar,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { TFunction } from "i18next";
import { CertificateTemplate } from "../../../model/certificates";
import {
  buttonOptions,
  columns,
  defaultInsuranceItems,
  defaultOtherItems,
  dropdownCertTypeList,
  insurersView,
} from "../resources/certificatesDTOS";
import { DeleteModal } from "../../../components/deleteModal/deleteModal";
import { LabelAutoCompleteProps } from "../../../model/user";
import { NavigateFunction } from "react-router-dom";

export const View = (props: {
  totalItems: number;
  page: number;
  limit: number;
  onClickButtonTypeView: (item: MenuItem) => void;
  optionCard: (item: MenuItem, data: CertificateTemplate) => void;
  certificatesList: CertificateTemplate[];
  openDrawer: boolean;
  setOpenDrawer: (value: boolean | (() => boolean)) => void;
  showPagination: boolean;
  onChangePage: (i: number) => void;
  onChangeRowsPerPage: (i: number) => void;
  rowsTable: any[];
  openDeleteModal: boolean;
  setOpenDeleteModal: (value: boolean | (() => boolean)) => void;
  setProjectTrigger: (value: boolean | (() => boolean)) => void;
  certificateName: string;
  projectList: ListDropdownMultipleProps[];
  productListDrawer: LabelAutoCompleteProps[];
  t: TFunction;
  associateCertificateWithProduct: (data: any) => void;
  navigate: NavigateFunction;
  filterCertType: (
    data: ListDropdownMultipleProps[] | ListDropdownMultipleProps,
  ) => void;
  filterProject: (
    data: ListDropdownMultipleProps[] | ListDropdownMultipleProps,
  ) => void;
  clearFilter: () => void;
  onChangeFullTextSearch: (e: string) => void;
}) => {
  return (
    <>
      <DeleteModal
        openModalConfirmDelete={props.openDeleteModal}
        setOpenModalConfirmDelete={props.setOpenDeleteModal}
        modalTitle={props.certificateName}
      />
      <CertificatesPageAFY
        pageTitle={props.t(`certificates.title`)}
        labelDropdownMultiple={props.t(`certificates.titleSelect1`)}
        labelDropdownCertType={props.t(`certificates.titleSelect2`)}
        titleClearButton={props.t(`certificates.titleClearButton`)}
        title2={props.t(`certificates.titleCard`)} //card
        titleDrawer=""
        descriptionDrawer={props.t(`certificates.descriptionDrawer`)}
        titleButtonAdd={props.t(`certificates.titleButtonDrawer`)}
        titlePagination={props.t(`certificates.titlePagination`)}
        placeholderTextSearch={"Buscar"}
        titleSearchButton={"Buscar"}
        titleInputCloseSearch={"Cerrar"}
        heightCard="113px"
        heightGrid={`calc(${props.showPagination ? "100vh" : "98vh"} - ${props.showPagination ? 302 : 213
          }px)`}
        backgroundColorSearch="#F6F7FB"
        iconBgColor="#E8EEFF"
        iconColor="#0D1BD9"
        iconColorSearch="#3A56A1"
        pageTitleColor="#0D0D0D"
        openDrawer={props.openDrawer}
        showPagination={props.showPagination}
        avatarVariant={variantsAvatar.rounded}
        iconAvatar={icons.Search}
        iconSearch={icons.Search}
        sizeIcon={sizesIcon.standard}
        sizesIconSearch={sizesIcon.standard}
        heightAddNew={113}
        pagePagination={props.page}
        countPagination={props.totalItems}
        rowsPerPage={props.limit}
        insurersView={insurersView}
        rowsPerPageOption={[24, 35, 50]}
        data={props.certificatesList}
        menuItem={buttonOptions}
        dropdownCertificateTypeList={dropdownCertTypeList}
        dropdownProductTypeList={props.projectList}
        productListDrawer={props.productListDrawer}
        defaultInsuranceItems={defaultInsuranceItems}
        defaultOtherItems={defaultOtherItems}
        columnsTable={columns(props.optionCard)}
        rowsTable={props.rowsTable}
        onClickButtonView={props.onClickButtonTypeView}
        onClickAdd={() => {
          props.navigate("/certificates-editor");
          sessionStorage.removeItem("hasShownToast");
        }}
        onClickContextMenu={props.optionCard}
        onCloseDrawer={() => {
          props.setOpenDrawer(false);
          props.setProjectTrigger(true);
        }}
        onChangePage={props.onChangePage}
        onChangeRowsPerPage={props.onChangeRowsPerPage}
        onSendLinkProduct={props.associateCertificateWithProduct}
        onClickSelect={props.filterProject}
        onClickSelectFilters={props.filterCertType}
        onBlurSearch={function noRefCheck() { }}
        onChangeInput={function noRefCheck() { }}
        onChangeSearch={props.onChangeFullTextSearch}
        onClearFilter={props.clearFilter}
        onCloseSearch={props.clearFilter}
      />
    </>
  );
};
