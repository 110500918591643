import { ListDropdownMultipleProps } from "@affinitysystemsgroup/github-packages-affinity-components/dist/src/interfaces/interface";
import { useCallback, useMemo } from "react";

const useFilters = (
  setCertType: React.Dispatch<React.SetStateAction<string>>,
  setSearchProjectId: React.Dispatch<React.SetStateAction<string>>,
  setSearchTitleCertificate: (value: string | (() => string)) => void,
  setCertificateTrigger: (value: boolean | (() => boolean)) => void,
) => {
  const handleFilterChange = useCallback(
    (setter: React.Dispatch<React.SetStateAction<string>>) =>
      (data: ListDropdownMultipleProps[] | ListDropdownMultipleProps) => {
        if (Array.isArray(data)) {
          data.forEach((item) => setter(item.id));
        } else {
          setter(data.id);
        }
      },
    [],
  );

  const filterCertType = handleFilterChange(setCertType);
  const filterProject = handleFilterChange(setSearchProjectId);

  const onChangeFullTextSearch = useCallback((e: string) => {
    const trimmed = e.trim();
    if (trimmed.length > 2 || trimmed === "") {
      setSearchTitleCertificate(trimmed);
    }
  }, []);

  const clearFilter = useCallback(() => {
    [setCertType, setSearchProjectId, setSearchTitleCertificate].forEach((fn) =>
      fn(""),
    );
    setCertificateTrigger(true);
  }, []);

  return useMemo(
    () => ({
      filterCertType,
      filterProject,
      onChangeFullTextSearch,
      clearFilter,
    }),
    [filterCertType, filterProject, onChangeFullTextSearch, clearFilter],
  );
};

export default useFilters;
