import React from "react";
import {
  LoadingAFY,
  typeLoaders,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { themeCore } from "../../assets/themes/theme";
import { BranchDashboardView } from "./branchDashboardView";

export const BranchDashboard = () => {
  const [loading, setLoading] = React.useState(false);
  return (
    <>
      <LoadingAFY
        loading={loading}
        backgroundColor={themeCore.colors.backgroundLoader}
        typeAnimation={typeLoaders.LogoAfy}
        sizeAnimation={65}
        textLoading={"Cargando..."}
        textLoadingColor={themeCore.colors.third}
        spinnerColor={themeCore.colors.third}
      />

      <BranchDashboardView />
    </>
  );
};
