import React from "react";
import {
  GridAFY,
  LoadingAFY,
  typeLoaders,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { dataColum } from "../data";
import { themeCore } from "../../../assets/themes/theme";

export const GridPolicyReport = (props: any) => {
  return (
    <React.Fragment>
      <LoadingAFY
        loading={props.loadingAllQuote}
        backgroundColor={themeCore.colors.backgroundLoader}
        typeAnimation={typeLoaders.LogoAfy}
        sizeAnimation={65}
        textLoading={"Cargando Pólizas."}
        textLoadingColor={themeCore.colors.third}
        spinnerColor={themeCore.colors.third}
      />
      <GridAFY
        width={"100%"}
        height={"100%"}
        pageSize={10}
        columns={dataColum}
        rows={[]}
        multipleSelection={false}
      />
    </React.Fragment>
  );
};
