import {
    breadcrumb,
    icons,
    itemPlanProps,
    LoadingAFY,
    MenuItem,
    position,
    select,
    sizesIcon,
    typeAlert,
    typeLoaders,
  } from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { on } from "events";

const useUI = (
  navigateBack: any,
  STEP: any,
    navigate: any,
    setPlanPage: any,
    setPlanLimit: any,
    setShowDetails: any,
    setShowDetail: any,
    setShowCompareDetail: any,
    setShowModalQuotes: any,
    plan: any,
    setPlan: any,
    setNamePlan: any
) => {
    const onClickBack = () => {
        navigateBack(STEP, navigate);
        //navigate("/insurance");
      };
      const onChangePage = (e: number) => {
        setPlanPage(e);
      };
    
      const onChangeRowsPerPage = (e: number) => {
        setPlanLimit(e);
      };
      const closeDetail = () => {
        setShowDetails(false);
        setShowDetail(false);
        setShowCompareDetail(false);
      };
    
      const onClickNewQuoteModal = () => {
        setShowModalQuotes(false);
        navigate("/customerForm");
      };
      const clickView = (view: MenuItem) => {
        let insuranceObj = { ...plan, viewType: view.id };
        setPlan(insuranceObj);
      };
    
      const searchFilter = (term: string) => {
        setNamePlan(term);
      };
    
    return {
        onClickBack,
        onChangePage,
        onChangeRowsPerPage,
        closeDetail,
        onClickNewQuoteModal,
        clickView,
        searchFilter
    };
}
export default useUI;