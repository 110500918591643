import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ViewInsurances } from "./view";
import { InsuranceTypeModel } from "../../model/insuranceType";
import LocalStorageService from "../../services/localStorage";
import { project, projectClearData } from "../../store/project/actions";
import { sponsor } from "../../store/sponsor/actions";
import { deleteEntity } from "../../store/delete/actions";

import {
  CardProps,
  itemPlanProps,
  ListDropdownMultipleProps,
  LoadingAFY,
  MenuItem,
  position,
  typeAlert,
  typeLoaders,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";

import showToast from "../../services/toast";
import { SponsorModel } from "../../model/sponsor";
import { themeCore } from "../../assets/themes/theme";
import { GetInsurancesTypes } from "../../fnx/insurances/getInsurancesTypes";
import { StatusOptionsModel } from "../../model/insurancePoliciesSummary";
import { useNavigate } from "react-router-dom";
import { showErrorToast } from "../../fnx/showError";
import { Project } from "../../model/project";
import { showToastSuccessMessage } from "../../fnx/sendSuccessMessage";
import { useTranslation } from "react-i18next";
import { GetProjectAll } from "../../fnx/project/projectAll";
import { hasPermission } from "../../services/getNavigationPages";

const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);

export const InsurancesAdmin = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [enable, setEnable] = React.useState<boolean>(true);
  const [page, setPage] = React.useState<number>(1);
  const [limit] = React.useState<number>(1000);
  const [insurancesList, setInsurancesList] = React.useState<CardProps[]>([]);
  const [insuranceTypeList, setInsuranceTypeList] = React.useState<
    InsuranceTypeModel[]
  >([]);
  const [insuranceTypeOptions, setInsuranceTypeOptions] = useState<
    ListDropdownMultipleProps[]
  >([]);
  const [sponsorOptions, setSponsorOptions] = useState<
    ListDropdownMultipleProps[]
  >([]);
  const [sponsorList, setSponsorList] = React.useState<SponsorModel[]>([]);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [openModalConfirmClone, setOpenModalConfirmClone] =
    useState<boolean>(false);
  const [clientId] = React.useState<string>(
    lsLogin.getUserLoginStored().clientId,
  );
  const [sponsorsIds, setSponsorsIds] = React.useState("");
  const [trigger, setTrigger] = React.useState(false);
  const [triggerProject, setTriggerProject] = React.useState(false);
  const [itemModal, setitemModal] = React.useState<any>(null);
  const [nameSearch, setNameSearch] = React.useState<string>("");
  const [filtersItem, setFiltersItem] = React.useState(null);
  const [insurancePage, setInsurancePage] = useState<number>(1);
  const [insuranceLimit, setInsuranceLimit] = useState<number>(20);
  const [countPagination, setCountPagination] = useState<number>(0);

  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });
  const [seeToastSuccess, setSeeToastSuccess] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  const permissions = {
    create: hasPermission("admin-product-create"),
    read: hasPermission("admin-product-view"),
    update: hasPermission("admin-product-edit"),
    delete: hasPermission("admin-product-delete"),
    clone: hasPermission("admin-product-clone"),
    detail: true, //hasPermission("admin-produt-view-details"),
  };

  const [openModalConfirmDelete, setOpenModalConfirmDelete] =
    useState<boolean>(false);
  const [itemCard, setItemCard] = useState<CardProps>({ id: "", title: "" });

  const { responseInsuranceType, errorInsuranceType, loadingInsuranceType } =
    GetInsurancesTypes(trigger, enable, page, limit, clientId);

  const { responseSponsor, errorSponsor, loadingSponsor } = useSelector(
    (state: any) => ({
      responseSponsor: state.Sponsor.responseSponsor?.data,
      errorSponsor: state.Sponsor.errorSponsor,
      loadingSponsor: state.Sponsor.loadingSponsor,
    }),
  );

  const { responseDeleteEntity, loadingDeleteEntity, errorDeleteEntity } =
    useSelector((state: any) => ({
      responseDeleteEntity: state.DeleteEntity.responseDeleteEntity,
      loadingDeleteEntity: state.DeleteEntity.loadingDeleteEntity,
      errorDeleteEntity: state.DeleteEntity.errorDeleteEntity,
    }));

  const { responseProject, errorProject, loadingProject } = GetProjectAll(
    triggerProject,
    enable,
    insurancePage,
    insuranceLimit,
    sponsorsIds,
  );

  useEffect(() => {
    dispatch(
      sponsor({
        enable: enable,
        page: page,
        limit: limit,
        clientId: clientId,
      }),
    );
    setTrigger(true);
  }, []);

  useEffect(() => {
    if (errorProject) {
      showErrorToast(errorProject, setSeeToast, t);
    }
  }, [errorProject]);

  useEffect(() => {
    if (errorSponsor) {
      showErrorToast(errorSponsor, setSeeToast, t);
    }
  }, [errorSponsor]);

  useEffect(() => {
    if (errorInsuranceType) {
      showErrorToast(errorInsuranceType, setSeeToast, t);
    }
  }, [errorInsuranceType]);

  // busqueda por nombre
  useEffect(() => {
    if (nameSearch) {
      getInsurances();
    } else {
      onCloseSearch();
    }
  }, [nameSearch]);

  //Llena la data para el filtro tipo de producto
  useEffect(() => {
    if (responseInsuranceType && responseInsuranceType?.data?.length > 0) {
      const newInsurancesTypeList = responseInsuranceType.data.map(
        (item: InsuranceTypeModel) => ({
          id: item.id,
          name: item.description,
          value: item.id,
          active: false,
        }),
      );
      setInsuranceTypeList(newInsurancesTypeList);
    }
    if (Array.isArray(responseInsuranceType)) {
      setInsuranceType(responseInsuranceType);
    }
  }, [responseInsuranceType]);

  //Llena la data para el filtro de los sponsor
  useEffect(() => {
    if (responseSponsor && responseSponsor?.length > 0) {
      const newSponsorList = responseSponsor.map((item: SponsorModel) => ({
        id: item.id,
        name: item.name,
        value: item.id,
        active: false,
      }));

      setSponsorList(newSponsorList);

      const idSponsor = responseSponsor
        .map((item: any) => item.id)
        .join("&sponsorId=");

      setSponsorsIds(idSponsor);

      setTriggerProject(true);
    }
    if (Array.isArray(responseSponsor)) {
      setSponsors(responseSponsor);
    }
  }, [responseSponsor]);

  //Llena la data para las card
  useEffect(() => {
    if (responseProject) {
      const newInsurancesList: CardProps[] = responseProject.data.map(
        (item: any) => ({
          ...item,
          id: item.id,
          title: item.description,
          logoUrl: item.logoUrl,
        }),
      );
      setInsurancesList(newInsurancesList);
    }
    if (responseProject?.pagingInfo) {
      setCountPagination(responseProject?.pagingInfo.totalItems);
    }
  }, [responseProject]);

  useEffect(() => {
    if (responseDeleteEntity) {
      showToastSuccessMessage(
        "Producto eliminado con éxito.",
        typeAlert.success,
        setSeeToastSuccess,
      );
      filterStatus(filtersItem);
      dispatch(projectClearData());
    } else if (errorDeleteEntity) {
      showErrorToast(errorDeleteEntity, setSeeToast, t);
    }
  }, [responseDeleteEntity, errorDeleteEntity]);

  const setInsuranceType = (responseInsuranceType: StatusOptionsModel[]) => {
    let dataBranches: ListDropdownMultipleProps[] = [];
    responseInsuranceType?.map((item: StatusOptionsModel) => {
      if (item.id && item.description) {
        dataBranches.push({
          id: item.id,
          name: item.description,
          active: false,
        });
      }
    });
    setInsuranceTypeOptions(dataBranches);
  };

  const setSponsors = (responseSponsor: StatusOptionsModel[]) => {
    let dataBranches: ListDropdownMultipleProps[] = [];
    responseSponsor?.map((item: StatusOptionsModel) => {
      if (item.id && item.name) {
        dataBranches.push({
          id: item.id,
          name: item.name,
          active: false,
        });
      }
    });
    setSponsorOptions(dataBranches);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleOpenModalConfirm = () => {
    // setOpenModalConfirm(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const productCreation = () => {
    navigate("/product-creation");
  };

  const showImage = (url?: string) => {};

  const clickView = (view: MenuItem) => {
    setitemModal(view);
  };

  const handleDelete = (item: Project) => {
    if (item) {
      setOpenModalConfirmDelete(false);
      if (
        item.campaign.length > 0 &&
        Object.keys(item.campaign[0]).length > 0
      ) {
        dispatch(
          deleteEntity({
            id: item.campaign[0]?.id,
            entity: "CampaignEntity",
            idUser: clientId,
          }),
        );
        dispatch(
          deleteEntity({
            id: item.id,
            entity: "ProjectEntity",
            idUser: clientId,
          }),
        );
      } else {
        dispatch(
          deleteEntity({
            id: item.id,
            entity: "ProjectEntity",
            idUser: clientId,
          }),
        );
      }
    }
  };

  const handleClone = () => {
    let body = {
      clientId: itemModal.clientId,
      name: itemModal.title,
    };
    setOpenModal(false);
    setOpenModalConfirmClone(false);
  };

  const searchFilter = (term: string) => {
    setNameSearch(term);
  };

  const onCloseSearch = () => {
    if (sponsorList.length > 0) {
      setNameSearch("");
      dispatch(
        project({
          enable: enable,
          page: insurancePage,
          limit: insuranceLimit,
          sponsorId: sponsorsIds,
          description: "",
        }),
      );
    }
  };

  const seeDetailsProduct = (item: itemPlanProps) => {
    navigate(`/product-creation/${item.id}`);
  };

  const editProduct = (product: CardProps) => {
    navigate(`/product-creation/${product.id}/true`);
  };

  const getInsurances = () => {
    dispatch(
      project({
        enable: enable,
        page: insurancePage,
        limit: insuranceLimit,
        sponsorId: sponsorsIds,
        description: nameSearch,
      }),
    );
  };

  const filterStatus = (item: any) => {
    if (sponsorList.length > 0) {
      setFiltersItem(item);
      const idInsuranceType = item?.insurance
        .map((item: any) => item.id)
        .join("&projectType=");
      const idSponsor = item?.sponsor
        .map((item: any) => item.id)
        .join("&sponsorId=");

      dispatch(projectClearData());
      dispatch(
        project({
          enable: enable,
          page: insurancePage,
          limit: insuranceLimit,
          projectType: idInsuranceType,
          sponsorId: idSponsor ? idSponsor : sponsorsIds,
        }),
      );
    }
  };

  const optionProduct = (op: MenuItem, product: CardProps) => {
    switch (op.id) {
      case "1":
        navigate(`/product-creation/${product.id}/true/true`);
        break;
      case "2":
        editProduct(product);
        break;
      case "3":
        setOpenModalConfirmClone(true);
        break;
      case "4":
        setOpenModalConfirmDelete(true);
        setItemCard(product);
        break;
    }
  };

  const onChangePage = (e: number) => {
    setInsurancePage(e);
    setTriggerProject(true);
  };

  const onChangeRowsPerPage = (e: number) => {
    setInsuranceLimit(e);
    setTriggerProject(true);
  };

  return (
    <React.Fragment>
      <LoadingAFY
        loading={loadingProject || loadingDeleteEntity}
        backgroundColor={themeCore.colors.backgroundLoader}
        typeAnimation={typeLoaders.LogoAfy}
        sizeAnimation={65}
        textLoading={
          loadingDeleteEntity ? "Eliminando producto" : "Cargando productos..."
        }
        textLoadingColor={themeCore.colors.third}
        spinnerColor={themeCore.colors.third}
      />
      {seeToast.show &&
        showToast(position.topRight, seeToast.type, seeToast.message, () => {
          setSeeToast({ show: false, message: "", type: typeAlert.success });
        })}
      {seeToastSuccess.show &&
        showToast(
          position.bottomRight,
          seeToastSuccess.type,
          seeToastSuccess.message,
          () => {
            setSeeToastSuccess({
              show: false,
              message: "",
              type: typeAlert.success,
            });
          },
        )}
      <ViewInsurances
        permissions={permissions}
        onCloseSearch={onCloseSearch}
        searchFilter={searchFilter}
        filterStatus={filterStatus}
        insuranceTypeOptions={insuranceTypeOptions}
        sponsorOptions={sponsorOptions}
        insurancesList={insurancesList} //data Cards
        insuranceTypeList={insuranceTypeList}
        sponsorList={sponsorList}
        titlePage="Productos"
        openModal={openModal}
        openModalConfirmDelete={openModalConfirmDelete}
        modalTitle={itemModal?.title}
        clickView={clickView}
        onSubmitClone={handleClone}
        onClose={handleCloseModal}
        handleOpenModal={handleOpenModal}
        handleOpenModalConfirm={handleOpenModalConfirm}
        onClickAdd={productCreation}
        onUpload={(url?: string): void => {
          showImage(url);
        }}
        setOpenModalConfirmDelete={setOpenModalConfirmDelete}
        optionProduct={optionProduct}
        itemCard={itemCard}
        onClickDelete={handleDelete}
        onClickDetails={seeDetailsProduct}
        openModalConfirmClone={openModalConfirmClone}
        setOpenModalConfirmClone={setOpenModalConfirmClone}
        insurancePage={insurancePage}
        insuranceLimit={insuranceLimit}
        countPagination={countPagination}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </React.Fragment>
  );
};

export default InsurancesAdmin;
