import React, { useEffect, useState } from "react";
import {
  typeAlert,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { ClientsView } from "./ui/view";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { person} from "../../store/person/actions";

import { options } from "./resources/clientsDTO"
import {
  Status,
} from "../../model/insurancePoliciesSummary";
import showToast from "../../services/toast";
import { hasPermission } from "../../services/getNavigationPages";
import { columns } from "./resources/clientsDTO";

import useUI from "./components/ui";
import useClients from "./components/clients";
import useSelectors from "./components/selectors";
import { ClientHeader } from "./ui/header";

export const ClientsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [enable, setEnable] = React.useState<boolean>(true);
  const [page, setPage] = React.useState<number>(1);
  const [limit, setLimit] = React.useState<number>(5000);
  const [rows, setRows] = useState<any[]>([]);
  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  const { handleClickSeeDetails } = useUI(navigate);
  const { handleSearch } = useClients(enable, page, limit);
  const { responsePerson, loadingPerson } = useSelectors();


  useEffect(() => {
    dispatch(
      person({ enable: enable, page: page, limit: limit }),
    );
  }, []);

  useEffect(() => {
    if (responsePerson) {
      setRows([]);
      responsePerson.map((item: any) => {
        setRows((prev) => [
          ...prev,
          {
            id: item.id,
            documentId: item.numberId,
            fullName: item.lastNameA + " " + item.lastNameB + ", " + item.firstNameA + " " + item.firstNameB,
            email: item.email,
            phone: item.phone1,
            dob: item.dob,
          },
        ]);
      });
    }

  }, [responsePerson]);

  return (
    <React.Fragment>
      <ClientHeader
        showToast={showToast}
        seeToast={seeToast}
        setSeeToast={setSeeToast}
        loading={loadingPerson}
      />
      <ClientsView
        title={""}
        columns={columns(handleClickSeeDetails, hasPermission)}
        rows={rows}
        onClickSeeDetails={handleClickSeeDetails}
        onSearch={handleSearch}
        listIdentificationType={options}
      />
    </React.Fragment>
  );
};
