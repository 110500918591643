import { useCallback, useMemo } from "react";
import { ListDropdownMultipleProps } from "../../../model/user";

const useAssociateProduct = () => {
  const listProjects = useCallback(
    (responseProject: ListDropdownMultipleProps[]) => {
      const drawerProjectList = responseProject.map((item) => ({
        id: item.id,
        name: item.description || "",
      }));

      const ProjectList = drawerProjectList.map((item) => ({
        ...item,
        value: item.id,
        active: false,
      }));

      return { drawerProjectList, ProjectList };
    },
    [],
  );
  return useMemo(() => ({ listProjects }), [listProjects]);
};

export default useAssociateProduct;
