import {
  LoadingAFY,
  position,
  typeAlert,
  typeLoaders,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { themeCore } from "../../assets/themes/theme";

export const AlertLoadingHeader = (props: {
  loading: boolean;
  seeToast: any;
  showToast: any;
  setSeeToast: any;
  titleLoading: string;
}) => {
  return (
    <>
      {props.seeToast.show &&
        props.showToast(
          position.topRight,
          props.seeToast.type,
          props.seeToast.message,
          () => {
            props.setSeeToast({
              show: false,
              message: "",
              type: typeAlert.success,
            });
          },
        )}
      <LoadingAFY
        loading={props.loading}
        backgroundColor={themeCore.colors.backgroundLoader}
        typeAnimation={typeLoaders.LogoAfy}
        sizeAnimation={65}
        textLoading={props.titleLoading}
        textLoadingColor={themeCore.colors.third}
        spinnerColor={themeCore.colors.third}
      />
    </>
  );
};
