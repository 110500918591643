import React, { useEffect, useState } from "react";
import {
  CardProps,
  icons,
  MenuItem,
  typeAlert,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { ReportView } from "./view";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getCatalogStatusQuote,
  getCatalogStatusQuoteClearData,
} from "../../store/catalog/actions";
import { showErrorToast } from "../../fnx/showError";
import {
  RowsModel,
  Status,
  StatusOptionsModel,
} from "../../model/insurancePoliciesSummary";
import showToast from "../../services/toast";
import { useTranslation } from "react-i18next";
import { quoteAll } from "../../store/generateQuote/actions";
import {
  getCurrentMonth,
  getCurrentSemester,
  getPreviousMonth,
  getQuarterDates,
} from "../../services/getDates";
import { categories, downData, seriesFn } from "./reportPageDTO";
import { formattedDateWithTime } from "../../utils/date";
import { buttonActionOptions } from "./completedSales/completedDTO";
import { downloadReports } from "../../fnx/reportsDowload";
import LocalStorageService from "../../services/localStorage";
import { GetAllPerson } from "../../fnx/person/getAllPerson";
import { AlertLoadingHeader } from "../../components/alertLoadingComponent/alertLoading";

const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);

export const ReportPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [enable, setEnable] = React.useState<boolean>(true);
  const [page, setPage] = React.useState<number>(1);
  const [limit, setLimit] = React.useState<number>(5000);
  const [trigger, setTrigger] = React.useState<boolean>(false);
  const [idStatus, setIdStatus] = useState<Status>({});
  const [newDownData, setNewDownData] = useState(downData);
  const [dataSeries, setDataSeries] = useState(seriesFn(t));
  const [dataCategories, setDataCategories] = useState(categories);
  const [isLoadin, setIsLoading] = React.useState<boolean>(false);
  const [startDate, setStartDate] = React.useState<string>("");
  const [endDate, setEndDate] = React.useState<string>("");
  const [clientId] = React.useState<string>(
    lsLogin.getUserLoginStored().clientId,
  );
  const fields =
    "policyId.code,code,productId.description,policyId.effectiveFrom,creationDate,firstNameA,firstNameB,lastNameA,lastNameB,numberId,customerAccount.accountType.description,customerAccount.accountNumber,customerAccount.amount,catalogStatusQuote.description,user.fullName,user.email,clientId.description,catalogCancellation.createdAt,catalogCancellation.description";
  const labelFields =
    "Póliza,Cotización,Producto,Fecha efectiva,Póliza creada el,Primer nombre,Segundo nombre,Primer apellido,Segundo apellido,No. Documento,Tipo de cuenta,No. de cuenta,Prima,Estado,Nombre del empleado,Usuario,Aseguradora,Fecha de cancelación,Motivo de cancelación";

  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  const {
    responseGetCatalogStatusQuote,
    errorGetCatalogStatusQuote,
    loadingGetCatalogStatusQuote,
  } = useSelector((state: any) => ({
    responseGetCatalogStatusQuote:
      state.Catalog.responseGetCatalogStatusQuote?.data,
    errorGetCatalogStatusQuote: state.Catalog.errorGetCatalogStatusQuote,
    loadingGetCatalogStatusQuote: state.Catalog.loadingGetCatalogStatusQuote,
  }));

  const { responseAllQuote, errorAllQuote, loadingAllQuote } = useSelector(
    (state: any) => ({
      responseAllQuote: state.Quote.responseAllQuote,
      errorAllQuote: state.Quote.errorAllQuote,
      loadingAllQuote: state.Quote.loadingAllQuote,
    }),
  );

  const { responsePerson, errorPerson, loadingPerson } = GetAllPerson(trigger);

  useEffect(() => {
    if (!responseGetCatalogStatusQuote) {
      dispatch(
        getCatalogStatusQuote({ enable: enable, page: page, limit: limit }),
      );
    }
    const { startDate, endDate } = getCurrentMonth();
    setDate(startDate, endDate);
    setStartDate(startDate);
    setEndDate(endDate);
    setTrigger(true);
  }, []);

  useEffect(() => {
    if (errorGetCatalogStatusQuote) {
      showErrorToast(errorGetCatalogStatusQuote, setSeeToast, t);
    }
  }, [errorGetCatalogStatusQuote]);

  useEffect(() => {
    if (errorPerson) {
      showErrorToast(errorPerson, setSeeToast, t);
    }
  }, [errorPerson]);

  useEffect(() => {
    if (errorAllQuote) {
      showErrorToast(errorAllQuote, setSeeToast, t);
    }
  }, [errorAllQuote]);

  useEffect(() => {
    if (Array.isArray(responseGetCatalogStatusQuote)) {
      setStatusOptionsSto(responseGetCatalogStatusQuote);
    }
  }, [responseGetCatalogStatusQuote]);

  useEffect(() => {
    if (responseAllQuote) {
      // LLENA DATA DE LOS DOWNCARD
      const filteredPolicy = responseAllQuote.filter(
        (item: RowsModel) => item.catalogStatusQuote.description === "Emitida",
      );
      const canceledPolicy = responseAllQuote.filter(
        (item: RowsModel) =>
          item.catalogPolicyStatus?.description === "Cancelada",
      );

      const quotedPolicy = responseAllQuote.filter(
        (item: RowsModel) => item.catalogStatusQuote.description === "Cotizada",
      );

      const salesCount = filteredPolicy.length;
      const canceledPolicyCount = canceledPolicy.length;
      const quotedPolicyCount = quotedPolicy.length;

      setNewDownData((prevData) =>
        prevData.map((card) => {
          switch (card.id) {
            case "1":
              return { ...card, numericalTitle: salesCount.toString() };
            case "2":
              return {
                ...card,
                numericalTitle: quotedPolicyCount.toString(),
              };
            case "3":
              return {
                ...card,
                numericalTitle: canceledPolicyCount.toString(),
              };
            default:
              return card;
          }
        }),
      );

      //LLENA LA DATA DEL CHART

      const productCount: {
        [key: string]: {
          description: string;
          countsByStatus: { [status: string]: number };
        };
      } = {};

      responseAllQuote.forEach((item: RowsModel) => {
        const productId = item?.productId?.id;
        const description = item?.productId?.description;
        const status = item?.catalogStatusQuote?.description;

        if (!productCount[productId]) {
          productCount[productId] = {
            description,
            countsByStatus: {},
          };
        }

        if (productCount[productId].countsByStatus[status]) {
          productCount[productId].countsByStatus[status] += 1;
        } else {
          productCount[productId].countsByStatus[status] = 1;
        }
      });

      const sortedProducts = Object.keys(productCount)
        .map((key) => {
          const product = productCount[key];
          const totalCount = Object.values(product.countsByStatus).reduce(
            (sum, count) => sum + count,
            0,
          );
          return { ...product, id: key, totalCount };
        })
        .sort((a, b) => b.totalCount - a.totalCount);

      const top5Products = sortedProducts.slice(0, 5);
      const issuedData = top5Products.map(
        (product) => product.countsByStatus["Emitida"] || 0,
      );
      const listedData = top5Products.map(
        (product) => product.countsByStatus["Cotizada"] || 0,
      );
      const categoriesData = top5Products.map((product) => product.description);

      setDataSeries((prevSeries: any) => {
        return [
          {
            ...prevSeries[0],
            data: issuedData,
          },
          {
            ...prevSeries[1],
            data: listedData,
          },
        ];
      });
      setDataCategories(categoriesData);
    }
  }, [responseAllQuote]);

  useEffect(() => {
    if (responsePerson) {
      const clientsCount = responsePerson.length;
      setNewDownData((prevData) =>
        prevData.map((card) => {
          switch (card.id) {
            case "4":
              return { ...card, numericalTitle: clientsCount.toString() };
            default:
              return card;
          }
        }),
      );
    }
  }, [responsePerson]);

  const setStatusOptionsSto = (
    responseGetCatalogStatusQuote: StatusOptionsModel[],
  ) => {
    let status: Status = {};

    responseGetCatalogStatusQuote.forEach((item) => {
      if (item.id && item.description) {
        if (item.description === "Emitida") {
          status.emitida = item.id;
        } else if (item.description === "Cancelada") {
          status.cancelada = item.id;
        }
      }
    });
    setIdStatus(status);
    dispatch(getCatalogStatusQuoteClearData());
  };

  const openReport = (action: MenuItem, item: CardProps) => {
    if (action.name === "Previsualizar") {
      switch (item.code) {
        case "vents":
          navigate(`/report/sales/${idStatus.emitida}`);
          break;
        case "polCancel":
          navigate(`/report/sales/${idStatus.cancelada}`);
          break;
        case "vents&cancel":
          navigate(`/report/sales/`);
          break;
      }
    }
    if (action.name === "Descargar") {
      const newItem: buttonActionOptions = {
        id: "1",
        icons: icons.DownloadForOffline,
        name: "Descargar Excel",
      };

      switch (item.code) {
        case "vents":
          onClickDownload(newItem, idStatus.emitida || "");
          break;
        case "polCancel":
          onClickDownload(newItem, idStatus.cancelada || "");
          break;
        case "vents&cancel":
          navigate(`/report/sales/`);
          break;
      }
    }
  };

  const onClickChip = (item: MenuItem) => {
    let startDate, endDate;
    switch (item.code) {
      case "currentMonth":
        ({ startDate, endDate } = getCurrentMonth());
        setDate(startDate, endDate);
        setStartDate(startDate);
        setEndDate(endDate);
        break;
      case "prevMonth":
        ({ startDate, endDate } = getPreviousMonth());
        setDate(startDate, endDate);
        setStartDate(startDate);
        setEndDate(endDate);
        break;
      case "currentQuarter":
        ({ startDate, endDate } = getQuarterDates("current"));
        setDate(startDate, endDate);
        setStartDate(startDate);
        setEndDate(endDate);
        break;
      case "prevQuarter":
        ({ startDate, endDate } = getQuarterDates("previous"));
        setDate(startDate, endDate);
        setStartDate(startDate);
        setEndDate(endDate);
        break;
      case "currentSemester":
        ({ startDate, endDate } = getCurrentSemester());
        setDate(startDate, endDate);
        setStartDate(startDate);
        setEndDate(endDate);
        break;
    }
  };

  const setDate = (date1: string, date2: string) => {
    dispatch(
      quoteAll({
        enable: enable,
        creationDateStart: date1,
        creationDateEnd: date2,
        page: page,
        limit: limit,
      }),
    );
  };
  const filterCalendar = (dates: any) => {
    const startDate =
      formattedDateWithTime(dates?.dateRange?.startDate) === "undefined" ||
      formattedDateWithTime(dates?.dateRange?.startDate) === "null" ||
      formattedDateWithTime(dates?.dateRange?.startDate) === "NA"
        ? ""
        : formattedDateWithTime(dates?.dateRange?.startDate);
    const endDate =
      formattedDateWithTime(dates?.dateRange?.endDate) === "undefined" ||
      formattedDateWithTime(dates?.dateRange?.endDate) === "null" ||
      formattedDateWithTime(dates?.dateRange?.endDate) === "NA"
        ? ""
        : formattedDateWithTime(dates?.dateRange?.endDate);

    setDate(startDate, endDate);
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const closeCalendar = () => {
    const { startDate, endDate } = getCurrentMonth();
    setDate(startDate, endDate);
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const onClickDownload = (item: buttonActionOptions, id: string) => {
    setIsLoading(true);
    downloadReports(
      item,
      clientId,
      enable,
      page,
      limit,
      id ? id : "",
      startDate,
      endDate,
      fields,
      labelFields,
    )
      .then(() => {
        setSeeToast({
          show: true,
          message: "Archivo descargado con éxito",
          type: typeAlert.success,
        });
      })
      .catch((error) => {
        console.error("Error al descargar el archivo:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <AlertLoadingHeader
        loading={
          loadingGetCatalogStatusQuote || loadingAllQuote || loadingPerson
        }
        seeToast={seeToast}
        showToast={showToast}
        setSeeToast={setSeeToast}
        titleLoading={"Cargando datos."}
      />
      <ReportView
        onClickOptionChips={onClickChip}
        openReport={openReport}
        newDownData={newDownData}
        dataSeries={dataSeries}
        dataCategories={dataCategories}
        closeCalendar={closeCalendar}
        filterCalendar={filterCalendar}
        t={t}
      />
    </React.Fragment>
  );
};
