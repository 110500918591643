export const formatPhoneNumber = (
  input: string,
  length: number = 10,
): string => {
  // Remove all non-numeric characters
  const cleaned = input?.toString().replace(/\D/g, "");

  // Check if the input is valid
  if (cleaned?.length !== length) {
    //console.log(input,"Input must be a 10-digit number.");
    return "";
  } else {
    // Format the number
    const formatted = `(${cleaned.slice(0, 3)}) ${cleaned.slice(
      3,
      6,
    )}-${cleaned.slice(6)}`;
    return formatted;
  }
};
