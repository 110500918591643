import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  DataForm,
  dataFormCertificates,
} from "./resources/certificatesEditorDTOS";
import { CertificateEditorView } from "./ui/certificateEditorView";
import { typeAlert } from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { AlertLoadingHeader } from "../../components/alertLoadingComponent/alertLoading";
import showToast from "../../services/toast";
import { useApiCreateCertificate } from "../../fnx/certificate/postCertificate";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  certificateTemplateOneClearData,
  createCertificateClearData,
  putCertTemplateClearData,
} from "../../store/certificates/actions";
import { showErrorToast } from "../../fnx/showError";
import useFnCreateCertificate from "./helpers/createCertificate";
import { GetOneCertTemplate } from "../../fnx/certificate/getOneCertTemplate";
import useFnUpdateCertificate from "./helpers/updateCertificate";
import { useApiUpdateCertificate } from "../../fnx/certificate/putCertTemplate";

export const CertificatesEditorPage = () => {
  const { t } = useTranslation();
  const certificateId = useParams();
  const isUpdate = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [triggerCreate, setTriggerCreate] = useState<boolean>(false);
  const [triggerUpdate, setTriggerUpdate] = useState<boolean>(false);
  const [triggerGetCert, setTriggerGetCert] = useState<boolean>(false);
  const [certificateHtml, setCertificateHtml] = useState<string>("");
  const [mergeFieldsData] = useState(() => {
    const data = sessionStorage.getItem("mergeFieldsData");
    return data ? JSON.parse(data) : [];
  });
  const [dataCertificate, setDataCertificate] =
    useState<DataForm>(dataFormCertificates);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  ///CALL HELPERS
  const { createCertificate, bodyRequest } = useFnCreateCertificate();
  const { updateCertificate, updateBodyRequest } = useFnUpdateCertificate();

  ///CALL APIS
  const {
    loadingCreateCertificate,
    responseCreateCertificate,
    errorCreateCertificate,
  } = useApiCreateCertificate(triggerCreate, bodyRequest);

  const {
    loadingOneCertTemplate,
    responseOneCertTemplate,
    errorOneCertTemplate,
  } = GetOneCertTemplate(triggerGetCert, certificateId.certificateId || "");

  const { loadingPutCertificate, responsePutCertificate, errorPutCertificate } =
    useApiUpdateCertificate(
      triggerUpdate,
      certificateId.certificateId || "",
      updateBodyRequest,
    );

  useEffect(() => {
    setCertificateHtml("");
  }, []);

  useEffect(() => {
    if (certificateId.certificateId) {
      setTriggerGetCert(true);
    }
  }, [certificateId]);

  useEffect(() => {
    if (responseCreateCertificate?.data) {
      dispatch(createCertificateClearData());
      navigate("/certificates/true");
    }
  }, [responseCreateCertificate]);

  useEffect(() => {
    if (responsePutCertificate?.data) {
      dispatch(putCertTemplateClearData());
      setOpenDrawer(false);
      navigate("/certificates/false/true");
    }
  }, [responsePutCertificate]);

  useEffect(() => {
    if (errorCreateCertificate) {
      showErrorToast(errorCreateCertificate, setSeeToast, t);
      setTriggerCreate(false);
      dispatch(certificateTemplateOneClearData());
    }
  }, [errorCreateCertificate]);

  useEffect(() => {
    if (errorPutCertificate) {
      showErrorToast(errorPutCertificate, setSeeToast, t);
      setTriggerUpdate(false);
      dispatch(putCertTemplateClearData());
    }
  }, [errorPutCertificate]);

  useEffect(() => {
    if (errorOneCertTemplate) {
      showErrorToast(errorOneCertTemplate, setSeeToast, t);
    }
  }, [errorOneCertTemplate]);

  useEffect(() => {
    if (responseOneCertTemplate?.data) {
      setCertificateHtml(responseOneCertTemplate.data.html);
      const type = responseOneCertTemplate.data.type;
      const formattedValue = type
        ? type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()
        : "";
      setDataCertificate({
        fileName: responseOneCertTemplate?.data?.title,
        productName:
          responseOneCertTemplate?.data?.projects[0]?.description || "",
        typeFile: {
          id: type,
          name: formattedValue,
        },
      });
    }
    dispatch(certificateTemplateOneClearData());
  }, [responseOneCertTemplate]);

  const onSaveCertificate = (
    data: string | undefined,
    fileName: string,
    typeFile: string,
  ) => {
    if (isUpdate.isUpdate === "true") {
      updateCertificate(data, fileName, typeFile, setTriggerUpdate);
    } else {
      createCertificate(data, fileName, typeFile, setTriggerCreate);
    }
  };

  return (
    <>
      <AlertLoadingHeader
        loading={
          loadingCreateCertificate ||
          loadingOneCertTemplate ||
          loadingPutCertificate
        }
        seeToast={seeToast}
        showToast={showToast}
        setSeeToast={setSeeToast}
        titleLoading={t("certificatesEditor.loading-1")}
      />
      <CertificateEditorView
        dataCertificate={dataCertificate}
        onSaveCertificate={onSaveCertificate}
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        translate={t}
        navigate={navigate}
        certificateHtml={certificateHtml}
        mergeFieldsData={mergeFieldsData}
      />
    </>
  );
};
