import {
  LoadingAFY,
  MenuItem,
  position,
  typeAlert,
  typeLoaders,
  IconButtonAFY,
  icons,
  InformationAFY,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { formattedDate } from "../../../utils/date";
export  const options = [
    { value: '0', name: 'Documento' },
    { value: '1', name: 'Nombre' },
    { value: '2', name: 'Apellido' },
    { value: '3', name: 'Email' },
  ];

 export const columns =(handleClickSeeDetails:any,hasPermission:any)=>  [
    {
      field: "actions",
      headerName: "",
      width: 80,
      renderCell: (params:any) => (
        <div style={{ display: "flex", gap: "10px" }}>

          <IconButtonAFY
            bgColorIconButton="#F4F8FA"
            icon={icons.Search}
            iconColor="#006ac6"
            title="Ver detalles"
            onClick={() => handleClickSeeDetails(params.row)}
            disabled={!hasPermission("customer-detail")}
          />


        </div>
      ),
    },
    {
      field: 'documentId',
      flex: 1,
      headerName: 'Documento',
      width: 150
    },
  
    {
      field: 'fullName',
      flex: 1,
      headerName: 'Nombre completo',
      width: 150
    },
    {
      field: 'email',
      flex: 1,
      headerName: 'Email',
      width: 160
    },
    {
      field: 'phone',
      flex: 1,
      headerName: 'Teléfono',
      width: 150
    },
    {
      field: 'dob',
      flex: 1,
      headerName: 'Fecha Nacimiento',
      width: 150,
      renderCell: (params:any) => (
      <div>
        {params.value ? formattedDate(params.value) : ""}
       
      </div>
      )
    },
  ]
