import { useState } from "react";
import {
    navigateBack,
} from "../../../dataBase/stepProgress";
import {
    typeAlert,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";

const useUI = (
    STEP: any,
    navigate: any,
) => {
    const [seeToast, setSeeToast] = useState({
        show: false,
        message: "",
        type: "success",
    });
    const handleBack = () => {
        navigateBack(STEP, navigate);
        //navigate("/plan");
    };
    const showToast1 = (show: boolean, title: string, type: typeAlert) => {
        setSeeToast({
            show: show,
            message: title,
            type: type,
        });
    };
    return {
        seeToast,
        handleBack,
        showToast1,
    };

}
export default useUI;