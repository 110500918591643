// useMySelectors.ts
import { useSelector } from "react-redux";


const useSelectors = () => {

    const { responsePlan, errorPlan, loading } = useSelector((state: any) => ({
        responsePlan: state.Plan.responsePlan,
        errorPlan: state.Plan.errorPlan,
        loading: state.Plan.loadingPlan,
      }));
    
      const { responseCampaign } = useSelector((state: any) => ({
        responseCampaign: state.Campaign.responseCampaign,
      }));
    
      const { responseCountries, errorCountries, loadingCountries } = useSelector(
        (state: any) => ({
          responseCountries: state.Countries.responseCountries,
          errorCountries: state.Countries.errorCountries,
          loadingCountries: state.Countries.loadingCountries,
        }),
      );
    
      const { responseRegion, errorRegion, loadingRegion } = useSelector(
        (state: any) => ({
          responseRegion: state.Region.responseRegion,
          errorRegion: state.Region.errorRegion,
          loadingRegion: state.Region.loadingRegion,
        }),
      );
    
      const { responseQuote, errorQuote, loadingQuote } = useSelector(
        (state: any) => ({
          responseQuote: state.Quote.responseQuote,
          errorQuote: state.Quote.errorQuote,
          loadingQuote: state.Quote.loadingQuote,
        }),
      );
    
      const { responsePerson, errorPerson, loadingPerson } = useSelector(
        (state: any) => ({
          responsePerson: state.Person.responsePerson,
          errorPerson: state.Person.errorPerson,
          loadingPerson: state.Person.loadingPerson,
        }),
      );
    
      const { responsePlans, errorPlans, loadingPlans } = useSelector(
        (state: any) => ({
          responsePlans: state.Plans.responsePlans?.data,
          errorPlans: state.Plans.errorPlans,
          loadingPlans: state.Plans.loadingPlans,
        }),
      );
    
      const { responseCatalogGender, errorCatalogGender } = useSelector(
        (state: any) => ({
          responseCatalogGender: state.Plan.responseCatalogGender,
          errorCatalogGender: state.Plan.errorCatalogGender,
        }),
      );

      return {
        responsePlan,
        errorPlan,
        loading,
        responseCampaign,
        responseCountries,
        errorCountries,
        loadingCountries,
        responseRegion,
        errorRegion,
        loadingRegion,
        responseQuote,
        errorQuote,
        loadingQuote,
        responsePerson,
        errorPerson,
        loadingPerson,
        responsePlans,
        errorPlans,
        loadingPlans,
        responseCatalogGender,
        errorCatalogGender,
      };
    }
    export default useSelectors;