import { FormFinalizePurchaseAFY } from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { hasPermission } from "../../../services/getNavigationPages";

export const PaymentConfirmationView = (props: any) => {
  return (
    <>
   
      <FormFinalizePurchaseAFY
        disabledDownload={!hasPermission("sale-payment-confirmation-download")}
        disabledSent={!hasPermission("sale-payment-confirmation-send-email")}
        nextLabel={props.nextLabel}
        visible={true}
        onNext={props.onNext}
        onClickDownload={props.onClickDownload}
        previousLabel={props.previousLabel}
        title={props.title}
        section1Title={props.section1Title}
        section2Title={props.section2Title}
        documents={props.documents}
        defaultEmail={props.defaultEmail}
        filters={props.filters}
        onBack={props.onBack}
        onClickSendEmail={props.onClickSendEmail}
        minHeightGrid="calc(100vh - 275px)"
        hideHeader={false}
      />
    </>
  );
};
