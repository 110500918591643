import { useDispatch } from "react-redux";
import { formattedDate, formattedDateWithTime } from "../../../utils/date";
import { buttonActionOptions } from "../../reportsPage/completedSales/completedDTO";
import { downloadReports } from "../../../fnx/reportsDowload";
import { PolicyRequest } from "../../../model/policy";
import {
  ListDropdownMultipleProps,
  typeAlert,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import {
  RowsModel,
  StatusOptionsModel,
} from "../../../model/insurancePoliciesSummary";
import {
  quoteAll,
  quoteAllClearData,
  quoteOne,
} from "../../../store/generateQuote/actions";
import { policyCancel } from "../../../store/policy/actions";
import { cancelQuote } from "../../../store/cancelQuote/actions";

const useInsurancePoliciesSummary = (
  setIdCatalogStatusQuote: any,
  setDateStart: any,
  setDateEnd: any,
  clientId: any,
  enable: any,
  page: any,
  limit: any,
  nameSearch: any,
  setIsLoading: any,
  idCatalogStatusQuote: any,
  dateStart: any,
  dateEnd: any,
  fields: any,
  labelFields: any,
  setSeeToast: any,
  setBranchesOptions: any,
  showToastSuccessMessage: any,
  setNameSearch: any,
  setInsuranceOptions: any,
  setStatusOptions: any,
  setRows: any,
  responseInsurance: any,
  localStorageService: any,
  navigate: any,
  Steps: any,
  setModalTitle: any,
  setPolicyId: any,
  setType: any,
  setOpenModal: any,
  setRow: any,
  setOpenModalConfirm: any,
  type: any,
  policyId: any,
) => {
  const dispatch = useDispatch();
  const handleCancelPolicy = (reasonId: any) => {
    setOpenModalConfirm(false);
    setOpenModal(false);
    //get current date and time
    let currentDateAndTime = new Date();
    let currentDate = currentDateAndTime.toISOString();
    if (type) {
      const dataSubmit: PolicyRequest = {
        data: {
          date: currentDate,
          userId: "d2bd577e-c8c6-4519-b30a-b8e674c42952",
          policyId: policyId,
          catalogReasonCancellation: reasonId,
        },
      };
      dispatch(policyCancel({ ...dataSubmit }));
    } else {
      dispatch(
        cancelQuote({
          id: policyId,
        }),
      );
    }
  };

  const filterStatus = (item: any) => {
    const idStatus = item?.state?.map((item: any) => item.id).join(",");
    const idBranch = item?.branchs?.map((item: any) => item.id).join(",");
    const idInsurance = item?.insurance?.map((item: any) => item.id).join(",");
    setIdCatalogStatusQuote(idStatus);
    const isValidDateRange =
      Array.isArray(item?.dateRange) && item.dateRange.length > 0;

    const getFormattedDate = (index: number) => {
      if (!isValidDateRange) return "";
      const formattedDate = formattedDateWithTime(item?.dateRange?.[index]);
      return formattedDate === "undefined" ||
        formattedDate === "null" ||
        formattedDate === "NA"
        ? ""
        : formattedDate;
    };

    const startDate = getFormattedDate(0);
    const endDate = getFormattedDate(1);

    setDateStart(startDate);
    setDateEnd(endDate);

    dispatch(
      quoteAll({
        clientId: clientId,
        enable: enable,
        statuses: idStatus,
        creationDateStart: startDate,
        creationDateEnd: endDate,
        branches: idBranch,
        projectIds: idInsurance,
        page: page,
        limit: limit,
      }),
    );
  };

  const onClickDownload = (item: buttonActionOptions) => {
    setIsLoading(true);
    downloadReports(
      item,
      clientId,
      enable,
      page,
      limit,
      idCatalogStatusQuote,
      dateStart,
      dateEnd,
      fields,
      labelFields,
    )
      .then(() => {
        showToastSuccessMessage(
          "Archivo descargado con éxito",
          typeAlert.success,
          setSeeToast,
        );
      })
      .catch((error) => {
        showToastSuccessMessage(
          "Ocurrio un error al descargar el archivo",
          typeAlert.error,
          setSeeToast,
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getQuoteAll = () => {
    dispatch(
      quoteAll({
        clientId: clientId,
        creationDateStart: null,
        creationDateEnd: null,
        enable: enable,
        page: page,
        limit: limit,
        search: nameSearch,
      }),
    );
  };
  const searchFilter = (term: string) => {
    setNameSearch(term);
  };

  const onCloseSearch = () => {
    setNameSearch("");
    dispatch(
      quoteAll({
        clientId: clientId,
        creationDateStart: null,
        creationDateEnd: null,
        enable: enable,
        page: page,
        limit: limit,
        search: "",
      }),
    );
  };

  const setBranchesOptionsStorybook = (
    responseBranches: StatusOptionsModel[],
  ) => {
    let dataBranches: ListDropdownMultipleProps[] = [];
    responseBranches?.map((item: StatusOptionsModel) => {
      if (item.id && item.name) {
        dataBranches.push({
          id: item.id,
          name: item.name,
          active: false,
        });
      }
    });
    setBranchesOptions(dataBranches);
  };
  const setInsuranceOptionsStorybook = (
    responseProject: StatusOptionsModel[],
  ) => {
    let dataInsurance: ListDropdownMultipleProps[] = [];
    responseProject?.map((item: StatusOptionsModel) => {
      if (item.id && item.description) {
        dataInsurance.push({
          id: item.id,
          name: item.description,
          active: false,
          src: item.logoUrl,
        });
      }
    });
    setInsuranceOptions(dataInsurance);
  };

  const setStatusOptionsStorybook = (
    responseGetCatalogStatusQuote: StatusOptionsModel[],
  ) => {
    let dataStatus: ListDropdownMultipleProps[] = [];
    responseGetCatalogStatusQuote?.map((item: StatusOptionsModel) => {
      if (item.id && item.description) {
        dataStatus.push({
          id: item.id,
          name: item.description,
          active: false,
        });
      }
    });
    setStatusOptions(dataStatus);
  };
  const setDataQuoteStorybook = (responseAllQuote: RowsModel[]) => {
    const dataR = responseAllQuote?.map((item: RowsModel) => ({
      id: item?.id,
      quotation: item?.code,
      certificate: item?.policyId && item?.policyId?.code,
      identification: item?.numberId,
      firstNameA: `${item?.firstNameA} ${item?.firstNameB}`,
      lastNameA: item?.lastNameA,
      lastNameB: item?.lastNameB,
      status: item?.catalogStatusQuote?.description,
      branch: item?.branchData?.name,
      user: item?.user?.fullName,
      product: item?.productId?.description,
      code: item?.productId?.code,
      insuranceCarrier: item?.sponsor?.name,
      cancellationDate:
        item?.catalogCancellation?.createdAt &&
        formattedDate(item?.catalogCancellation?.createdAt),
      reasonCancellation: item?.catalogCancellation?.description,
      policyId: item?.policyId?.id,
      brandVehicle: "",
      modelVehicle: "",
      productId: item?.productId?.id,
      // brandVehicle: item.contentMap.fkCatalogBrandVeh.value,
      // modelVehicle: item.contentMap.fkCatalogModelVeh.value,
    }));

    setRows(dataR);
    dispatch(quoteAllClearData());
  };
  const filterData = (array: any, key: string, value: string) => {
    let temp: any = [];
    temp = array.filter(function (e: any) {
      return e[key] == value;
    });
    let result: any = [];
    temp.map((item: any) => {
      result.push({ id: item.id, value: item.id, name: item.description });
    });
    return result;
  };

  const setLocalStorage = (params: any) => {
    let product = params.row.product.replace(/\s+/g, "");
    switch (product) {
      case "AutoFull":
        localStorageService.setStepStored({
          id: "",
          name: "",
          step: Steps.insurance,
          completed: true,
          campaign: responseInsurance[0].campaign,
          flow: responseInsurance[0].salesFlow,
        });
        localStorageService.setStepStored({
          id: "",
          name: "",
          step: Steps.customer,
          completed: true,
          quoteId: params.row.id,
          campaign: "",
          coverType: { id: "", name: "" },
          paymentModeSelected: { id: "", name: "" },
          campaignSelected: { id: "", name: "" },
        });
        break;
      default:
        break;
    }
  };
  const handleClickContextMenu = (item: any, params: any) => {
    switch (item.id) {
      case "1":
        handleDelete(params);
        break;
      case "2":
        if (
          params.row.status === "Cotizada" ||
          params.row.status === "cotizada"
        ) {
          navigate(
            `/confirmationForm/${params.row.id}/true/false/${params.row.productId}`,
          );
        } else {
          navigate(
            `/confirmationForm/${params.row.id}/false/false/${params.row.productId}`,
          );
        }
        break;
      case "3":
        setLocalStorage(params);
        if (params.row.code === "ALE") {
          showToastSuccessMessage(
            "Operación no permitida!",
            typeAlert.warning,
            setSeeToast,
          );
        } else {
          navigate("/fiscalizacion-apap-auto/" + params.row.id);
        }
        break;
      case "4":
        callQuoteOne(params.row.id);
        break;
      default:
        break;
    }
  };
  const handleDelete = (event: any) => {
    if (event.row.status === "Cotizada" || event.row.status === "cotizada") {
      setModalTitle("cotización");
      setPolicyId(event.row.id);
      setType(false);
    }
    if (event.row.status === "Emitida" || event.row.status === "emitida") {
      setModalTitle("póliza");
      setPolicyId(event.row.policyId);
      setType(true);
    }
    setRow(event.row);

    setOpenModal(true);
  };

  const callQuoteOne = (quoteId: string) => {
    if (quoteId !== "") {
      dispatch(
        quoteOne({
          id: quoteId,
          log: true,
        }),
      );
    }
  };

  return {
    filterStatus,
    onClickDownload,
    getQuoteAll,
    searchFilter,
    onCloseSearch,
    setBranchesOptionsStorybook,
    setInsuranceOptionsStorybook,
    setStatusOptionsStorybook,
    setDataQuoteStorybook,
    filterData,
    setLocalStorage,
    handleClickContextMenu,
    handleDelete,
    callQuoteOne,
    handleCancelPolicy,
  };
};
export default useInsurancePoliciesSummary;
