import { CKEditor } from "@ckeditor/ckeditor5-react";
import { DecoupledEditor } from "ckeditor5";
// import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import React, { useEffect, useRef } from "react";
import { editorCustom } from "./customCertificatesEditor";
import { DrawerCertificates } from "./resources/drawerCertificates";
import "ckeditor5/ckeditor5.css";
import "ckeditor5-premium-features/ckeditor5-premium-features.css";
import "./certificates.css";
import Grid from "@mui/material/Grid";
import {
  CertificatesProductName,
  CertificatesTitleLabel,
  ContentContainer,
  ContentFileName,
  ContentProductName,
  HeaderContainer,
  Title,
} from "./style";
import {
  ButtonAFY,
  icons,
  MergeFieldGroup,
  variants,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { themeCore } from "../../assets/themes/theme";
import { DataForm } from "../../adminCore/certificatesEditor/resources/certificatesEditorDTOS";

export const CertificatesEditorView = (props: {
  productTitleColor?: string;
  productTitle?: string;
  productNameColor?: string;
  productName?: string;
  titleCancelButton?: string;
  labelTypeCertificate?: string;
  onCancelButton: (event: React.MouseEvent<HTMLButtonElement>) => void;

  titleNextButton?: string;
  titleButtonAdd?: string;
  titleSaveButton?: string;
  onSaveData: (
    data: string | undefined,
    fileName: string,
    typeFile: string,
  ) => void;
  isLayoutReady: boolean;
  heightGrid?: string;
  language: string;
  initialValue?: string;
  licenseKey: string;
  placeholder?: string;
  dataMergeFieldsSb?: MergeFieldGroup[];
  labelFileName: string;
  //
  dataForm?: DataForm;
  titleDrawer?: string;
  descriptionDrawer?: string;
  openDrawerSb?: boolean;
  onOpenDrawer: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onCloseDrawer: (event: React.MouseEvent<HTMLButtonElement>) => void;
  TypeListDrawer?: any[];
  register?: any;
  errors: any;
  setError: any;
  clearErrors: any;
  control: any;
  isValid: boolean;
  getValues: any;
  //
  errorTextFileName?: string;
  errorTextTypeFile?: string;
}) => {
  const editorContainerRef = useRef<HTMLDivElement | null>(null);
  const editorMenuBarRef = useRef<HTMLDivElement | null>(null);
  const editorToolbarRef = useRef<HTMLDivElement | null>(null);
  const editorRef = useRef<HTMLDivElement | null>(null);
  const editorMinimapRef = useRef<HTMLDivElement | null>(null);
  const editorInstanceRef = useRef<DecoupledEditor | null>(null);
  const [isContentEmpty, setIsContentEmpty] = React.useState(true);

  const customConfigCkEditor = {
    language: props.language,
    initialValue: props.initialValue,
    licenseKey: props.licenseKey,
    container: editorMinimapRef.current,
    placeholder: props.placeholder,
    mergeFields: {
      definitions: props.dataMergeFieldsSb || [],
      enable: true,
      delimiter: ["{{", "}}"],
      preview: true,
    },
  };

  useEffect(() => {
    if (editorInstanceRef.current) {
      editorInstanceRef.current.config.set(
        "mergeFields",
        customConfigCkEditor.mergeFields,
      );
    }
  }, [customConfigCkEditor.mergeFields]);

  // useEffect(() => {
  //   if (editorInstanceRef.current) {
  //     const editor = editorInstanceRef.current;
  //
  //     editor.config.set("mergeFields", customConfigCkEditor.mergeFields);
  //
  //     if (editor.commands.get("mergeField")) {
  //       editor.execute("mergeField");
  //     }
  //
  //     editor.fire("change:data");
  //   }
  // }, [customConfigCkEditor.mergeFields]);

  return (
    <>
      <DrawerCertificates
        openDrawerSb={props.openDrawerSb}
        onCloseDrawer={props.onCloseDrawer}
        titleDrawer={props.titleDrawer}
        descriptionDrawer={props.descriptionDrawer}
        titleButtonAdd={props.titleButtonAdd}
        productTitleColor={props.productTitleColor}
        dataForm={props.dataForm}
        labelFileName={props.labelFileName}
        labelTypeCertificate={props.labelTypeCertificate}
        titleSaveButton={props.titleSaveButton}
        TypeListDrawer={props.TypeListDrawer}
        register={props.register}
        errors={props.errors}
        setError={props.setError}
        clearErrors={props.clearErrors}
        control={props.control}
        isValid={props.isValid}
        errorTextFileName={props.errorTextFileName}
        errorTextTypeFile={props.errorTextTypeFile}
        onSaveData={props.onSaveData}
        editorInstanceRef={editorInstanceRef}
        getValues={props.getValues}
      />
      <Grid container>
        <Grid item xs={12}>
          <HeaderContainer>
            <article
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <ContentContainer>
                {props.dataForm?.productName?.trim() !== "" && (
                  <ContentProductName>
                    <Title style={{ color: props.productTitleColor }}>
                      {props.productTitle}
                    </Title>
                    <CertificatesProductName
                      style={{ color: props.productNameColor }}
                    >
                      {props.dataForm?.productName}
                    </CertificatesProductName>{" "}
                  </ContentProductName>
                )}
                {props.dataForm?.fileName?.trim() !== "" && (
                  <ContentFileName>
                    <CertificatesTitleLabel
                      style={{ color: props.productTitleColor }}
                    >
                      {props.labelFileName}
                    </CertificatesTitleLabel>
                    <CertificatesProductName
                      style={{ color: props.productNameColor }}
                    >
                      {props.dataForm?.fileName}
                    </CertificatesProductName>
                  </ContentFileName>
                )}
              </ContentContainer>
              <div style={{ display: "flex", gap: "15px" }}>
                <ButtonAFY
                  backgroundColor="#076ff6"
                  color={themeCore.colors.primaryText}
                  icon={icons.Cancel}
                  label={props.titleCancelButton}
                  onClick={props.onCancelButton}
                  widthButton={"100px"}
                  variant={variants.outlined}
                />
                <ButtonAFY
                  backgroundColor={themeCore.colors.third}
                  color={themeCore.colors.primaryText}
                  disabled={isContentEmpty}
                  icon={icons.NavigateNext}
                  label={props.titleNextButton}
                  onClick={props.onOpenDrawer}
                  widthButton={"100px"}
                  variant={variants.contained}
                />
              </div>
            </article>
          </HeaderContainer>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            minHeight: props.heightGrid,
            marginTop: "8px",
          }}
        >
          <div className="main-container">
            <div
              className="editor-container editor-container_document-editor editor-container_include-minimap editor-container_include-pagination"
              ref={editorContainerRef}
            >
              <div
                className="editor-container__menu-bar"
                ref={editorMenuBarRef}
              ></div>
              <div
                className="editor-container__toolbar"
                ref={editorToolbarRef}
              ></div>
              <div className="editor-container__minimap-wrapper">
                <div className="editor-container__editor-wrapper">
                  <div className="editor-container__editor">
                    <div ref={editorRef}>
                      {props.isLayoutReady && (
                        <CKEditor
                          editor={DecoupledEditor}
                          data={props.initialValue}
                          config={editorCustom(customConfigCkEditor)}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setIsContentEmpty(data.trim() === "");
                          }}
                          onReady={(editor) => {
                            editor.config.set(
                              "mergeFields",
                              customConfigCkEditor.mergeFields,
                            );
                            editorInstanceRef.current = editor;
                            if (
                              editorToolbarRef.current &&
                              editorMenuBarRef.current
                            ) {
                              if (editor.ui.view.toolbar.element) {
                                editorToolbarRef.current.appendChild(
                                  editor.ui.view.toolbar.element,
                                );
                              }
                              if (editor.ui.view.menuBarView.element) {
                                editorMenuBarRef.current.appendChild(
                                  editor.ui.view.menuBarView.element,
                                );
                              }
                            }
                          }}
                          onAfterDestroy={() => {
                            editorInstanceRef.current = null;
                            if (
                              editorToolbarRef.current &&
                              editorMenuBarRef.current
                            ) {
                              Array.from(
                                editorToolbarRef.current.children,
                              ).forEach((child) => {
                                (child as HTMLElement).remove();
                              });
                              Array.from(
                                editorMenuBarRef.current.children,
                              ).forEach((child) => {
                                (child as HTMLElement).remove();
                              });
                            }
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="editor-container__sidebar editor-container__sidebar_minimap">
                  <div ref={editorMinimapRef}></div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
