import {
  AlertAFY,
  breadcrumb,
  BreadcrumbAFY,
  icons,
  MenuItem,
  ModalidadPlanAFY,
  select,
  severity,
  sizesSelect,
  StepProgressAFY,
  variantsAlert,
  variantsTextField,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import React from "react";
import { Title } from "../../../components/layout/style";

export const PlanDetailsView = (props: {
  title: string;
  btnLblNext: string;
  currencyLbl: string;
  periodPlan: string;
  subtitleBenefit: string;
  subtitleLimit: string;
  titleDetail: string;
  steps: MenuItem[];
  breadCrumbs: breadcrumb[];
  onClickNext: () => void;
  applySelect: (item: any) => void;
  price: string;
  listBen: any[];
  paymentModeList?: select[];
  defaultSelect?: string;
  apap: boolean;
  autoClose?: number;
  autoCloseActive?: boolean;
  showAlert?: boolean;
  setShowAlert?: React.Dispatch<React.SetStateAction<boolean>>;
  msgDescription?: string;
  enableNextBtn?: boolean;
}) => {
  return (
    <React.Fragment>
      <BreadcrumbAFY options={props.breadCrumbs} />
      <div
        style={{
          position: "relative",
          zIndex: 15,
          marginBottom: "30px",
          marginTop: "12px",
        }}
      >
        <AlertAFY
          // title={props.msgDescription ? props.msgDescription : ""}
          title={" "}
          isOpen={props.showAlert}
          setAutoClose={props.autoCloseActive}
          autoClose={props.autoClose}
          description={props.msgDescription ? props.msgDescription : ""}
          iconClose={icons.Close}
          iconCloseColor="#fff"
          onClose={() => {
            props.setShowAlert?.(false);
          }}
          variant={variantsAlert.filled}
          severity={severity.warning}
          backgroundColor={""}
          iconColor={"#FFF"}
          spacing={0}
          textColor={"#FFFFFF"}
          width={"100%"}
        />
      </div>
      <Title>
        {props.title}
        <article
          style={{
            position: "absolute",
            top: "-18px",
            right: "391px",
            zIndex: "9",
          }}
        >
          <StepProgressAFY
            onClickStepProgress={() => {}}
            stepProgress={props.steps}
            collapsed
            sticky
          />
        </article>
      </Title>
      <ModalidadPlanAFY
        apap={props.apap}
        backgroundColorLi="#ebebeb"
        bgItemColor="#fff"
        btnLblNext={props.btnLblNext}
        currency={props.currencyLbl}
        detail={props.listBen}
        fontSizeSelect={14}
        nameColorText="#000000"
        onSelectApply={props.applySelect}
        onClickNext={props.onClickNext}
        optionsSelect={props.paymentModeList}
        periodPlan={props.periodPlan}
        priceCurrency={props.price.toString()}
        selectSize={sizesSelect.small}
        selectVariant={variantsTextField.outlined}
        selectedValueDefault={props.defaultSelect}
        subTitleColLeft={props.subtitleBenefit}
        subTitleColRight={props.subtitleLimit}
        subTitleColorText="#666"
        subTitleTableColorText="#000000"
        titleDetail={props.titleDetail}
        titleDetailColorText="#3A56A1"
        disabledNextButton={props.enableNextBtn}
      />
    </React.Fragment>
  );
};
