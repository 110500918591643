import {
  LoadingAFY,
  position,
  typeAlert,
  typeLoaders,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { themeCore } from "../../../assets/themes/theme";
import showToast from "../../../services/toast";
import React from "react";

export const PlansHeader = (props: any) => {
  return (
    <>
      {props.seeToast.show &&
        showToast(
          position.topRight,
          props.seeToast.type,
          props.seeToast.message,
          () => {
            props.setSeeToast({
              show: false,
              message: "",
              type: typeAlert.success,
            });
          },
        )}
      {props.seeToastAlertNext.show &&
        showToast(
          position.bottomRight,
          props.seeToastAlertNext.type,
          props.seeToastAlertNext.message,
          () => {
            props.setSeeToastAlertNext({
              show: false,
              message: "",
              type: typeAlert.success,
            });
          },
        )}

      <LoadingAFY
        loading={props.loading}
        backgroundColor={themeCore.colors.backgroundLoader}
        typeAnimation={typeLoaders.LogoAfy}
        sizeAnimation={65}
        textLoading={""}
        textLoadingColor={themeCore.colors.third}
        spinnerColor={themeCore.colors.third}
      />
    </>
  );
};
