import { ChangeEvent } from "react";
import { PolicyRequest } from "../../../model/policy";
import LocalStorageService from "../../../services/localStorage";
import { getCustomerAction } from "../../../fnx/customer";
import { policyCreate } from "../../../store/policy/actions";
import { ApapAccountTypes } from "./../resources/paymentSummaryDto";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { showToastSuccessMessage } from "../../../fnx/sendSuccessMessage";
import { typeAlert } from "@affinitysystemsgroup/github-packages-affinity-components/dist";

const usePaymentSummary = (
  dataForm: any,
  responseOneQuote: any,
  company: any,
  accountType: any,
  accountName: any,
  accountNumber: any,
  cardSelected: any,
  setSubTitlePayment: any,
  setDataFormPayment: any,
  setCardSelected: any,
  setSeeToast: any,
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const storageKey2 = "afy-login";
  const lsLogin = new LocalStorageService(storageKey2);
  const handleChangePayment = (event: ChangeEvent<HTMLInputElement>) => {
    setCardSelected(false);
    const { value } = event.target;
    if (value === "1") {
      setDataFormPayment({
        AccountName: `${dataForm.Name} ${dataForm.LastNames}`,
      });
    }
    if (value === "4") {
      setSubTitlePayment("No. Recibo de pago");
    } else {
      setSubTitlePayment("");
    }
  };

  const createPolicy = (data: any) => {
    let dataSubmit: PolicyRequest = {
      data: {
        effectiveFrom: new Date().toISOString(),
        creationDate: new Date().toISOString(),
        userId: lsLogin.getUserLoginStored().userId,
        quoteId: dataForm.QuoteId,
        //catalogStatus: "60c01b66-f07a-42f3-9ce4-bd973f515fed",
        //paymentCurrency: "4442d70e-4013-4db2-b7e4-8d2265b219f0",
        //accountHolderType: "e9b361e2-def7-4789-90b2-b2168c696b92",
        accountType: getCustomerAction().customerAuto.includes(company)
          ? ""
          : data.type,
        accountTypeDescription: getCustomerAction().customerAuto.includes(
          company,
        )
          ? accountType
          : data.paymentName,
        accountName: getCustomerAction().customerAuto.includes(company)
          ? accountName
          : data.AccountName && data.AccountName.replace(/\s+/g, "")
          ? data.AccountName.replace(/\s+/g, "")
          : `${dataForm.Name} ${dataForm.LastNames}`,
        // accountName: getCustomerAction().customerAuto.includes(company)
        //   ? accountName
        //   : data.AccountName.replace(/\s+/g, "")
        //   ? data.AccountName
        //   : `${dataForm.Name} ${dataForm.LastNames}`,
        accountMonth: 12,
        accountYear: 2022,
        accountNumber: getCustomerAction().customerAuto.includes(company)
          ? accountNumber
          : data.AccountNumber,
      },
    };
    if (getCustomerAction().customerAuto.includes(company)) {
      // console.log("---Incluye apap auto---");
      // console.log(data);
      // data.VoucherNumber = parseInt(data.VoucherNumber, 10);

      if (data.paymentId === "4") {
        //console.log(data.paymentName);
        dataSubmit.data = {
          ...dataSubmit.data,
          accountName: `${dataForm.Name} ${dataForm.LastNames}`,
          accountNumber: data.VoucherNumber,
          accountType: ApapAccountTypes.APAP_VOUCHER,
          additionalPaymentData: {
            voucherDate: new Date().toISOString(),
            voucherAmount: parseFloat(responseOneQuote?.lastPrice.amount),
          },
        };
      }
      if (data.paymentId === "5") {
        //console.log(data.paymentName);
        if (!cardSelected) {
          showToastSuccessMessage(
            t(`paymentMethod.selectPaymentType`),
            typeAlert.info,
            setSeeToast,
          );
          return;
        }
        dataSubmit.data = {
          ...dataSubmit.data,
          accountName: accountName,
          accountType: ApapAccountTypes.APAP_ACCOUNT,
          accountNumber: accountNumber,
          additionalPaymentData: {
            voucherDate: new Date().toISOString(),
            voucherAmount: parseFloat(responseOneQuote?.lastPrice.amount),
          },
        };
      }
    }

    dispatch(policyCreate({ ...dataSubmit }));
  };

  return {
    handleChangePayment,
    createPolicy,
  };
};
export default usePaymentSummary;
