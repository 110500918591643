import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ViewIdentificationType } from "./view";
import {
  IdentificationType,
  IdentificationTypeRequest,
} from "../../model/identificationType";
import LocalStorageService from "../../services/localStorage";
import {
  identificationTypePatchClearData,
  identificationTypePostClearData,
} from "../../store/identificationType/actions";

import {
  deleteEntity,
  deleteEntityClearData,
} from "../../store/delete/actions";
import { useIdentificationTypeGetAll } from "../../fnx/identificationType/identificationTypeGetAll";
import { useIdentificationTypePost } from "../../fnx/identificationType/identificationTypePost";
import { useIdentificationTypePatch } from "../../fnx/identificationType/identificationTypePatch";
import {
  CardProps,
  DataFormClient,
  LoadingAFY,
  MenuItem,
  position,
  typeAlert,
  typeLoaders,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import showToast from "../../services/toast";
import { themeCore } from "../../assets/themes/theme";
import { showToastSuccessMessage } from "../../fnx/sendSuccessMessage";
import { showErrorToast } from "../../fnx/showError";
import { useTranslation } from "react-i18next";
import { identificationTypeDataForm } from "./identificationTypeDTO";

const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);

export const IdentificationTypeAdmin = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(20);
  const [countPagination, setCountPagination] = useState<number>(0);
  const [trigger, setTrigger] = useState<boolean>(false);
  const [triggerPost, setTriggerPost] = useState<boolean>(false);
  const [triggerPatch, setTriggerPatch] = useState<boolean>(false);
  const [list, setList] = useState<CardProps[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [openModalCloneConfirm, setOpenModalCloneConfirm] =
    useState<boolean>(false);
  const [clientId] = React.useState<string>(
    lsLogin.getUserLoginStored().clientId,
  );
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [itemModal, setitemModal] = useState<any>(null);
  const [nameSearch, setNameSearch] = useState<string>("");
  const [bodyRequest, setBodyRequest] = useState<IdentificationTypeRequest>();
  const [dataForm, setDataForm] = useState<DataFormClient>(
    identificationTypeDataForm,
  );
  const [seeToast, setSeeToast] = useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });
  const {
    responseIdentificationType,
    errorIdentificationType,
    loadingIdentificationType,
  } = useIdentificationTypeGetAll(trigger, nameSearch, nameSearch, page, limit);
  const {
    responsePostIdentificationType,
    errorPostIdentificationType,
    loadingPostIdentificationType,
  } = useIdentificationTypePost(triggerPost, bodyRequest!);

  const {
    responsePatchIdentificationType,
    errorPatchIdentificationType,
    loadingPatchIdentificationType,
  } = useIdentificationTypePatch(triggerPatch, bodyRequest!);

  const { responseDeleteEntity, loadingDeleteEntity, errorDeleteEntity } =
    useSelector((state: any) => ({
      responseDeleteEntity: state.DeleteEntity.responseDeleteEntity,
      loadingDeleteEntity: state.DeleteEntity.loadingDeleteEntity,
      errorDeleteEntity: state.DeleteEntity.errorDeleteEntity,
    }));

  const isNumericOptions = [
    { id: 1, name: "Si", value: "1" },
    { id: 2, name: "No", value: "2" },
  ];

  useEffect(() => {
    if (!responseIdentificationType?.data) {
      setTrigger(true);
    }
  }, []);

  useEffect(() => {
    if (responseIdentificationType && responseIdentificationType?.data) {
      const newList: CardProps[] = responseIdentificationType.data.map(
        (item: IdentificationType) => ({
          id: item.id,
          title: item.description,
          code: item.code,
          maxCharacter: item.maxCharacter,
          minCharacter: item.minCharacter,
          isNumeric: item.isNumeric,
          clientId: item.clientId,
        }),
      );
      setList(newList);
      setTrigger(false);
    }
    if (responseIdentificationType?.pagingInfo) {
      setCountPagination(responseIdentificationType?.pagingInfo.totalItems);
    }
  }, [responseIdentificationType]);

  useEffect(() => {
    if (
      responsePostIdentificationType &&
      responsePostIdentificationType?.data
    ) {
      showToastSuccessMessage(
        "Creado con éxito.",
        typeAlert.success,
        setSeeToast,
      );
      dispatch(identificationTypePostClearData());
    } else if (errorPostIdentificationType) {
      showErrorToast(errorPostIdentificationType, setSeeToast, t);
    }
    setTriggerPost(false);
    setTrigger(true);
  }, [responsePostIdentificationType, errorPostIdentificationType]);

  useEffect(() => {
    if (responseDeleteEntity) {
      showToastSuccessMessage(
        "Eliminado con éxito.",
        typeAlert.success,
        setSeeToast,
      );
      dispatch(deleteEntityClearData());
      setTrigger(true);
    } else if (errorDeleteEntity) {
      showErrorToast(errorDeleteEntity, setSeeToast, t);
    }
  }, [responseDeleteEntity, errorDeleteEntity]);

  useEffect(() => {
    if (responsePatchIdentificationType) {
      setOpenDrawer(false);
      showToastSuccessMessage(
        "Actualizado con éxito.",
        typeAlert.success,
        setSeeToast,
      );
      setTriggerPatch(false);
      dispatch(identificationTypePatchClearData());
    } else if (errorPatchIdentificationType) {
      showErrorToast(errorPatchIdentificationType, setSeeToast, t);
      setTriggerPatch(false);
    }
    setTrigger(true);
  }, [responsePatchIdentificationType, errorPatchIdentificationType]);

  const searchFilter = (term: string) => {
    setNameSearch(term);
  };

  useEffect(() => {
    if (nameSearch) {
      setTrigger(true);
    } else {
      onCloseSearch();
    }
  }, [nameSearch]);

  const onCloseSearch = () => {
    setNameSearch("");
    setTrigger(true);
  };

  const filterStatus = (item: any) => {
    const idSponsor = item?.sponsor.map((item: any) => item.id).join(",");
    setTrigger(true);
  };

  const showEdit = (row: any) => {
    setDataForm({
      ...dataForm,
      id: row.id,
      clientId: row.clientId,
      Description: row.title,
      Code: row.code,
      MaxCharacter: row.maxCharacter,
      MinCharacter: row.minCharacter,
      IsNumeric: row.isNumeric,
    });
  };

  const handleSubmit = (data: any, file: File) => {
    if (isUpdate) {
      setBodyRequest({
        data: {
          id: data.id,
          clientId: data.clientId,
          description: data.Description,
          code: data.Code,
          maxCharacter: data.MaxCharacter,
          minCharacter: data.MinCharacter,
          isNumeric: data.isNumeric === 1 ? true : false,
        },
      });
      setTriggerPatch(true);
    } else {
      setBodyRequest({
        data: {
          description: data.Description,
          code: data.Code,
          maxCharacter: data.MaxCharacter,
          minCharacter: data.MinCharacter,
          isNumeric: data.isNumeric === 1 ? true : false,
        },
      });
      setTriggerPost(true);
    }
    setOpenDrawer(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleOpenModalCloneConfirm = () => {
    setOpenModalCloneConfirm(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOnClickAdd = () => {
    setDataForm({
      ...dataForm,
      id: "",
      clientId: "",
      Description: "",
      Code: "",
      MaxCharacter: 0,
      MinCharacter: 0,
      IsNumeric: true,
    });
    setIsUpdate(false);
    setOpenDrawer(true);
  };

  const onClickContextMenu = (opt?: any, item?: any) => {
    switch (opt.id) {
      case "1":
        setIsUpdate(true);
        setOpenDrawer(true);
        showEdit(item);
        break;
      case "2":
        setOpenModalDelete(true);
        setitemModal(item);
        break;
      case "3":
        setitemModal(item);
        setOpenModalCloneConfirm(true);
    }
  };

  const clickView = (view: MenuItem) => {
    setitemModal(view);
  };

  const handleCloseCloneModal = () => {
    setOpenModal(false);
    setOpenModalCloneConfirm(false);
  };

  const handleDelete = () => {
    setOpenModalDelete(false);
    dispatch(
      deleteEntity({
        id: itemModal.id,
        entity: "IdentificationTypeEntity",
        idUser: clientId,
      }),
    );
  };

  const handleClone = () => {
    setOpenModal(false);
    setOpenModalCloneConfirm(false);
    dispatch(identificationTypePostClearData());
  };

  const onCloseDrawer = () => {
    setDataForm({
      ...dataForm,
      id: "",
      clientId: "",
      Description: "",
      Code: "",
      MaxCharacter: 0,
      MinCharacter: 0,
      IsNumeric: false,
    });
    setOpenDrawer(false);
  };

  const onChangeRowsPerPage = (e: number) => {
    setLimit(e);
    setTrigger(true);
  };

  const onChangePage = (e: number) => {
    setPage(e);
    setTrigger(true);
  };

  return (
    <React.Fragment>
      <LoadingAFY
        loading={
          loadingIdentificationType ||
          loadingPostIdentificationType ||
          loadingPatchIdentificationType ||
          loadingDeleteEntity
        }
        backgroundColor={themeCore.colors.backgroundLoader}
        typeAnimation={typeLoaders.LogoAfy}
        sizeAnimation={65}
        textLoading={
          loadingDeleteEntity
            ? "Eliminando Tipo de Identificacion"
            : "Cargando Tipo de Identificacion..."
        }
        textLoadingColor={themeCore.colors.third}
        spinnerColor={themeCore.colors.third}
      />
      {seeToast.show &&
        showToast(position.topRight, seeToast.type, seeToast.message, () => {
          setSeeToast({ show: false, message: "", type: typeAlert.success });
        })}
      <ViewIdentificationType
        loadingPost={loadingPostIdentificationType}
        list={list}
        openModal={openModal}
        openModalCloneConfirm={openModalCloneConfirm}
        dataForm={dataForm}
        modalTitle={itemModal?.title}
        isNumericOptions={isNumericOptions}
        onClickContextMenu={onClickContextMenu}
        onCloseSearch={onCloseSearch}
        searchFilter={searchFilter}
        filterStatus={filterStatus}
        clickView={clickView}
        onSubmit={handleDelete}
        onSubmitClone={handleClone}
        onClose={handleCloseModal}
        onCloseCloneModal={handleCloseCloneModal}
        handleOpenModal={handleOpenModal}
        handleOpenModalCloneConfirm={handleOpenModalCloneConfirm}
        onSaveData={handleSubmit}
        onClickAdd={handleOnClickAdd}
        editMode={isUpdate}
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        openModalDelete={openModalDelete}
        setOpenModalDelete={setOpenModalDelete}
        onCloseDrawer={onCloseDrawer}
        page={page}
        limit={limit}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        countPagination={countPagination}
      />
    </React.Fragment>
  );
};

export default IdentificationTypeAdmin;
