import React, { useEffect, useState } from "react";
import View from "./view";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate, useLocation } from "react-router-dom";
import {
  LoadingAFY,
  position,
  typeAlert,
  typeLoaders,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { sponsor } from "../../store/sponsor/actions";
import { SponsorModel, SponsorListModel } from "../../model/sponsor";

import showToast from "../../services/toast";
import { themeCore } from "../../assets/themes/theme";
import LocalStorageService from "../../services/localStorage";

import { GetProjectAll } from "../../fnx/project/projectAll";

import { postCollection } from "../../store/actions";
import { getCollection } from "../../resources/helper/collection";
import { useStyles } from "./style";
import "./style.css";
import { cancel } from "redux-saga/effects";
import { set } from "date-fns";

const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);

const GenerateBilling = () => {
  const { state } = useLocation();

  const readOnly = state?.readOnly ?? false;
  const id = state?.id ?? false;

  const dispatch = useDispatch();
  const [clientId] = React.useState<string>(
    lsLogin.getUserLoginStored()?.clientId ?? "",
  );
  const classes = useStyles();
  const navigate = useNavigate();
  const [sponsorList, setSponsorList] = React.useState<SponsorListModel[]>([]);
  const [enable, setEnable] = React.useState<boolean>(true);
  const [page, setPage] = React.useState<number>(1);
  const [limit, setLimit] = React.useState<number>(5000);
  const [rows, setRows] = useState<any>([]);
  const [sponsorsIds, setSponsorsIds] = React.useState("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [trigger, setTrigger] = React.useState(false);
  const [triggerProject, setTriggerProject] = React.useState(false);
  const [insurancePage, setInsurancePage] = useState<number>(1);
  const [insuranceLimit, setInsuranceLimit] = useState<number>(20);
  const [status, setStatus] = useState<string>("");
  const [month, setMonth] = useState<string>("");
  const [collection, setCollection] = useState<any>(null);
  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });
  const [insurancesList, setInsurancesList] = React.useState<any[]>([]);
  const today = new Date();
  //Periodos maximos a cobrar
  const [numberMaximumValue, setNumberMaximumValue] = React.useState<string>('2');
  //Numero maximo de facturas por poliza
  const [numberMaximumInvoicesValue, setNumberMaximumInvoicesValue] = React.useState<string>('2');
  //Numero de meses a cancelar
  const [cancelAfterValue, setCancelAfterValue] = React.useState<string>('2');
  //Separar archivos por producto
  const [splitArchivesPerProductValue, setSplitArchivesPerProductValue] = React.useState<boolean>(false);
  //No cancelar polizas vencidas
  const [noCancelExpiredPolicyValue, setNoCancelExpiredPolicyValue] = React.useState<boolean>(false);
  //No cancelar polizas vencidas por edad
  const [noExpiredPolicyAgeValue, setNoExpiredPolicyAgeValue] = React.useState<boolean>(false);
  //Solo recobranza (no nuevas notas de cobro)
  const [justNewInvoiceNoRebillValue, setJustNewInvoiceNoRebillValue] = React.useState<boolean>(false);
  //Solo nuevas notas de cobro (no recobranza)
  const [justRebillNoNewInvoiceValue, setJustRebillNoNewInvoiceValue] = React.useState<boolean>(false);

  const [dataLoaded, setDataLoaded] = useState(false);


  const formattedToday = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;

  const handleOnClickNext = (data: any) => {
    let projectsId = data.sponsors;
    if (projectsId.length > 0) {
      projectsId = projectsId.map((item: any) => item.id);
    }

    let dataCollection = {
      actualMonth: data.cobranzaMonthDate == '' ? formattedToday : data.cobranzaMonthDate,
      cancelMonth: parseInt(data.cancelAfter),
      maxInvoicesPerPolicy: parseInt(data.numberMaximumInvoices),
      projectsId: projectsId,
      filePerProduct: data.splitArchivesPerProduct ? true : false,
      billingType: data.tipoCobranza,
      noCancelPolicy: data.noCancelExpiredPolicy ? true : false,
      maxRebillingMonths: parseInt(data.numberMaximum),
      userId: lsLogin.getUserLoginStored()?.userId ?? "",
    };
    console.log("dataCollection", dataCollection);

    if (data.sponsors.length > 0) {
      setLoading(true)
      dispatch(postCollection(dataCollection));
    } else {
      setSeeToast({
        show: true,
        type: typeAlert.error,
        message: "No hay proyectos seleccionados.",
      });
    }

  };

  const { responseSponsor, errorSponsor, loadingSponsor } = useSelector(
    (state: any) => ({
      responseSponsor: state.Sponsor.responseSponsor?.data,
      errorSponsor: state.Sponsor.errorSponsor,
      loadingSponsor: state.Sponsor.loadingSponsor,
    }),
  );

  const { responseCollection, errorCollection, loadingCollection } =
    useSelector((state: any) => ({
      responseCollection: state.Collections.responsePostCollection,
      errorCollection: state.Collections.errorPostCollection,
      loadingCollection: state.Collections.loadingPostCollection,
    }));

  const [nameSearch, setNameSearch] = React.useState<string>("");
  const { responseProject, errorProject, loadingProject } = GetProjectAll(
    triggerProject,
    enable,
    insurancePage,
    insuranceLimit,
    sponsorsIds,
  );
  const getEstadoClass = (estado: string) => {
    switch (estado) {
      case "En Proceso":
        return "estado-en-proceso";
      case "Completado":
        return "estado-completado";
      case "Fallido":
        return "estado-fallido";
      case "Procesando Pagos":
        return "estado-procesando-pagos";
      case "Generado":
        return "estado-generado";
      default:
        return "estado-default";
    }
  };
  useEffect(() => {
    if (responseProject) {
      const newInsurancesList: any[] = responseProject.data.map(
        (item: any) => ({
          id: item.id,
          type: item.projectTypeInfo?.description,
          sponsor: item.fkSponsor?.name,
          product: item.description,
          currency: item.fkCurrency?.countryCode,
        }),
      );


      if (readOnly) {

        setLoading(true);
        getCollection(id).then((res) => {

          console.log("res", res);
          setMonth(res.parameters.actualMonth);
          setLoading(false);
          setStatus(getStatusLabel(res.status));
          setCollection(res)

          setNumberMaximumInvoicesValue(res.parameters.maxInvoicesPerPolicy ?? '0');
          setCancelAfterValue(res.parameters.cancelMonth ?? '0');
          setNumberMaximumValue(res.parameters.maxRebillingMonths ?? '0');

          setSplitArchivesPerProductValue(res.parameters.filePerProduct ?? false);
          setNoCancelExpiredPolicyValue(res.parameters.noCancelPolicy ?? false);
          //No vienen
          setNoExpiredPolicyAgeValue(res.parameters.noCancelPolicy ?? false);
          setJustNewInvoiceNoRebillValue(false);
          setJustRebillNoNewInvoiceValue(false);


          const filteredSponsorList = newInsurancesList.filter((sponsor) =>
            res.parameters.projectsId.includes(sponsor.id),
          );
          console.log("filteredSponsorList", filteredSponsorList);
          setInsurancesList(filteredSponsorList);
          setDataLoaded(true);

        }).catch((err) => {
          setLoading(false);
          console.log("Error al obtener la cobranza", err);
        }
        );

      } else {
        setInsurancesList(newInsurancesList);
        console.log("No es readonly");
        setMonth(formattedToday);
        setNumberMaximumInvoicesValue('2');
        setCancelAfterValue('2');
        setNumberMaximumValue('2');
        setSplitArchivesPerProductValue(false);
        setNoCancelExpiredPolicyValue(false);
        setNoExpiredPolicyAgeValue(false);
        setJustNewInvoiceNoRebillValue(false);
        setJustRebillNoNewInvoiceValue(false);
        setDataLoaded(true);
      }
    }
  }, [responseProject]);

  useEffect(() => {
    dispatch(
      sponsor({
        enable: enable,
        page: page,
        limit: limit,
        clientId: clientId,
      }),
    );
    setTrigger(true);
  }, []);

  //Llena la data para el filtro de los sponsor
  useEffect(() => {
    if (responseSponsor && responseSponsor?.length > 0) {
      const newSponsorList = responseSponsor.map((item: SponsorModel) => ({
        id: item.id,
        name: item.name,
        value: item.id,
        active: false,
      }));


      setSponsorList(newSponsorList);

      const idSponsor = responseSponsor
        .map((item: any) => item.id)
        .join("&sponsorId=");

      setSponsorsIds(idSponsor);

      setTriggerProject(true);


    }

  }, [responseSponsor]);
  const getStatusLabel = (estado: string) => {
    switch (estado) {
      case "BANK_PAYMENT_PENDING":
        return "En Proceso";
      case "COMPLETED":
        return "Completado";
      case "FAILED":
        return "Fallido";
      case "PROCESSING_BANK_FILE":
        return "Procesando Pagos";
      case "GENERATING_BANK_FILE":
        return "Generado";
      default:
        return estado;
    }
  };

  useEffect(() => {
    if (responseCollection) {
      setLoading(false);
      setSeeToast({
        show: true,
        type: typeAlert.success,
        message: "El cobro se ha generado correctamente.",
      });
      setTimeout(() => {
        navigate("/billing");
      }, 5000);

    }
  }, [responseCollection]);

  useEffect(() => {
    if (errorCollection) {
      setLoading(false);
      setSeeToast({
        show: true,
        type: typeAlert.error,
        message: errorCollection ? errorCollection : "Error al generar el cobro.",
      });
    }
  }, [errorCollection]);

  const clickBack = () => {
    navigate("/billing");
  };


  useEffect(() => {
    console.log("dataLoaded", dataLoaded);
  }, [dataLoaded]);

  return (
    <React.Fragment>
      {seeToast.show &&
        showToast(position.topRight, seeToast.type, seeToast.message, () => {
          setSeeToast({ show: false, message: "", type: typeAlert.success });
        })}

      <LoadingAFY
        loading={loading}
        backgroundColor={themeCore.colors.backgroundLoader}
        typeAnimation={typeLoaders.LogoAfy}
        sizeAnimation={65}
        textLoading={"Cargando..."}
        textLoadingColor={themeCore.colors.third}
        spinnerColor={themeCore.colors.third}
      />

      {dataLoaded &&
        <View
          title={"Generar Cobros"}
          status={getEstadoClass(status)}
          estado={status}
          onClickBack={clickBack}
          onClickNext={handleOnClickNext}
          products={insurancesList}
          readOnly={readOnly}
          mes={month}
          numberMaximumValue={numberMaximumValue}
          numberMaximumInvoicesValue={numberMaximumInvoicesValue}
          splitArchivesPerProductValue={splitArchivesPerProductValue}
          cancelAfterValue={cancelAfterValue}
          noCancelExpiredPolicyValue={noCancelExpiredPolicyValue}
          noExpiredPolicyAgeValue={noExpiredPolicyAgeValue}
          justNewInvoiceNoRebillValue={justNewInvoiceNoRebillValue}
          justRebillNoNewInvoiceValue={justRebillNoNewInvoiceValue}
          data={insurancesList}

          onClickDefaultValues={() => { }}
          collection={collection}
        />
      }

    </React.Fragment>
  );
};

export default GenerateBilling;
