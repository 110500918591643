import React, { useEffect } from "react";
import { useStyles } from "../assets/style";
import "../assets/style.css";

import {
  CollectAFY,
  ButtonAFY,
  variants,
  icons,
  sizes,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { themeCore } from "../../../assets/themes/theme";
import { Title } from "../../../components/layout/style";
import MetaTags from "react-meta-tags";
import {
  Container,
} from "../assets/style";

const View = (props: any) => {
  const classes = useStyles();
  const [products, setProducts] = React.useState<any>(props.products);

  useEffect(() => {
    if (props.products && props.products.length > 0) {
      setProducts(props.products);
    }
  }
    , [props.products]);
  const onClickNext = (e: React.MouseEvent<HTMLButtonElement>) => {
    props.onClickNext && props.onClickNext(e);
  };

  const onClickBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    props.onClickBack && props.onClickBack(e);
  };
  return (
    <React.Fragment>

   <Container> 
             <ButtonAFY
               variant={variants.text}
               label={"Regresar"}
               icon={icons.ArrowBackIosNew}
               size={sizes.small}
               color={themeCore.colors.primaryText}
               onClick={onClickBack}
             />
           </Container>

      <Title>{props.title}<span className={` status ${props.status || ''} `}>{props.estado}</span></Title>
      <div className="containerPanel" style={{ marginTop: "15px" }}>
        <CollectAFY
        showResumen={true}
          backgroundColorBack={props.backgroundColorBack}
          backgroundColorNext={props.backgroundColorNext}
          next={props.next}
          estado={props.estado}
          onClickNext={onClickNext}
          onUploadFiles={props.onUploadFiles}
          onClickBack={onClickBack}
          readOnly={props.readOnly}
          details={props.details}
          collectionSummary={props.collectionSummary}
          billingResults={props.billingResults}
          uploadFiles={props.uploadFiles}
          uploadedResults={props.uploadedResults}
        />
      </div>
    </React.Fragment>
  );
};

export default View;
