import { useDispatch } from "react-redux";
import {
    quoteAllClearData,
} from "../../../store/generateQuote/actions";
const useUI = (
    setOpenModal: any,
    setOpenModalConfirm: any,
    setOpenModalHistorial: any,
) => {
    const dispatch = useDispatch();

    const handleOpenModal = () => {
        setOpenModal(true);
        setOpenModalConfirm(false);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setOpenModalConfirm(false);
        setOpenModalHistorial(false);
        dispatch(quoteAllClearData());
    };

    const handleOpenModalConfirm = () => {
        setOpenModalConfirm(true);
        setOpenModal(false);
    };

    const handleCloseModalConfirm = () => {
        setOpenModal(false);
        setOpenModalConfirm(false);
    };

    const handleConfirm = () => {
        setOpenModalConfirm(true);
        setOpenModal(false);
    };

    return {
        handleOpenModal,
        handleCloseModal,
        handleOpenModalConfirm,
        handleCloseModalConfirm,
        handleConfirm,
    };
}
export default useUI;

