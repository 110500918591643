import {
  ContextMenuAFY,
  icons,
  ListDropdownMultipleProps,
  sizesIcon,
  variants,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { SeeDetailsQuote } from "../../../fnx/seeDetailsQuote";
import newExcel from "../../../assets/images/newExcel.svg";

import {
  hasAuditRole,
  hasPermission,
} from "../../../services/getNavigationPages";

const handleClickContextMenu = (item: any, params: any, handleDelete: any) => {
  switch (item.id) {
    case "1":
      handleDelete(params);
      break;
    case "2":
      SeeDetailsQuote(params);
      break;
    default:
      break;
  }
};

export const getColumnsHistory = [
  {
    field: "fechaCarga",
    headerName: "Fecha Carga",
    width: 170,
  },
  {
    field: "usuario",
    headerName: "Usuario",
    width: 270,
  },
  {
    field: "movimiento",
    headerName: "Movimiento",
    width: 170,
  },
  {
    field: "comentario",
    headerName: "Comentario",
    width: 350,
  },
];

export const getColumns = (handleDelete: any) => [
  {
    field: "actions",
    headerName: "Acciones",
    width: 80,
    renderCell: (params: any) => (
      <div style={{ display: "flex", gap: "10px" }}>
        <ContextMenuAFY
          colorButton="#fff"
          iconButton={icons.MoreVert}
          iconColor="#3A56A1"
          menuItem={[
            {
              disabled: params.row.status === "Cancelada",
              icon: {
                color: "#006ac6",
                icon: icons.Delete,
                size: sizesIcon.standard,
              },
              id: "1",
              name: "Cancelar",
            },

            {
              disabled: false,
              icon: {
                color: "#006ac6",
                icon: icons.Search,
                size: sizesIcon.standard,
              },
              id: "2",
              name: "Ver detalles",
            },
            {
              disabled: false,
              icon: {
                color: "#006ac6",
                icon: icons.GridView,
                size: sizesIcon.standard,
              },
              id: "3",
              name: "Historial",
            },
          ]}
          onClickContextMenu={(item: any) =>
            handleClickContextMenu(item, params, handleDelete)
          }
          sizeIcon={sizesIcon.standard}
        />
      </div>
    ),
  },

  {
    editable: false,
    field: "status",
    flex: 1,
    headerName: "Estado",
    renderCell: (params: any) => (
      <div style={{ display: "flex", gap: "10px" }}>
        <span className={params.value}></span>
        {params.value}
      </div>
    ),
    width: 70,
  },
  {
    field: "quotation",
    headerName: "Cotización",
    width: 170,
  },
  {
    field: "firstNameA",
    headerName: "Nombre",
    width: 100,
  },
  {
    field: "lastNameA",
    headerName: "Primer apellido",
    width: 100,
  },
  {
    field: "lastNameB",
    headerName: "Apellido",
    width: 100,
  },
  {
    field: "certificate",
    headerName: "No. certificado",
    width: 170,
  },
  {
    field: "identification",
    headerName: "Identificación",
    width: 150,
  },
  {
    field: "branch",
    headerName: "Sucursal",
    width: 150,
  },
  {
    field: "user",
    headerName: "Usuario",
    width: 150,
  },
  {
    field: "product",
    headerName: "Producto",
    width: 175,
  },
  {
    field: "insuranceCarrier",
    headerName: "Aseguradora",
    width: 150,
  },
  {
    field: "cancellationDate",
    headerName: "Fecha de cancelación",
    width: 250,
  },
  {
    field: "reasonCancellation",
    headerName: "Motivo de cancelación",
    width: 270,
  },
];

export const columns = (handleClickContextMenu: any) => [
  {
    field: "actions",
    headerName: "Acciones",
    width: 120,
    renderCell: (params: any) => {
      const isSinDocument = params.row.code !== "AFU";
      let isStatusEmitida = params.row.status === "Emitida" || isSinDocument;

      if (hasAuditRole("audit")) {
        isStatusEmitida = false;
      }

      let menuContext: any = [
        {
          disabled: isStatusEmitida,
          icon: {
            color: "#006ac6",
            icon: icons.UploadFile,
            size: sizesIcon.standard,
          },
          id: "3",
          name: "Fiscalizar",
        },
        {
          disabled: !hasPermission("sale-policies-sumary-see-detail"),
          icon: {
            color: "#006ac6",
            icon: icons.Search,
            size: sizesIcon.standard,
          },
          id: "2",
          name: "Ver detalles",
        },

        {
          disabled:
            !hasPermission("sale-policies-sumary-cancel") ||
            params.row.status === "Cancelada",
          icon: {
            color: "#006ac6",
            icon: icons.Edit,
            size: sizesIcon.standard,
          },
          id: "1",
          name: "Cancelar",
        },
        {
          disabled: false,
          icon: {
            color: "#006ac6",
            icon: icons.GridView,
            size: sizesIcon.standard,
          },
          id: "4",
          name: "Historial",
        },
      ];
      const menuContextV = isSinDocument ? menuContext.slice(1) : menuContext;

      return (
        <div style={{ display: "flex", gap: "10px" }}>
          <ContextMenuAFY
            colorButton="transparent"
            iconButton={icons.MoreVert}
            iconColor="#3A56A1"
            menuItem={menuContextV}
            onClickContextMenu={(item: any) =>
              handleClickContextMenu(item, params)
            }
            sizeIcon={sizesIcon.standard}
          />
        </div>
      );
    },
  },
  {
    editable: false,
    field: "status",
    headerName: "Estado",
    renderCell: (params: any) => (
      <div style={{ display: "flex", gap: "10px" }}>
        <span className={params.value}></span>
        {params.value}
      </div>
    ),
    width: 100,
  },
  {
    field: "quotation",
    headerName: "Cotización",
    width: 170,
  },
  {
    field: "certificate",
    headerName: "No. Póliza",
    width: 170,
  },
  {
    field: "firstNameA",
    headerName: "Nombres",
    width: 250,
  },
  {
    field: "lastNameA",
    headerName: "Primer apellido",
    width: 170,
  },
  {
    field: "lastNameB",
    headerName: "Segundo apellido",
    width: 170,
  },
  {
    field: "identification",
    headerName: "Identificación",
    width: 150,
  },

  {
    field: "branch",
    headerName: "Sucursal",
    width: 150,
  },
  {
    field: "user",
    headerName: "Usuario",
    width: 175,
  },
  {
    field: "product",
    headerName: "Producto",
    width: 175,
  },
  {
    field: "insuranceCarrier",
    headerName: "Aseguradora",
    width: 175,
  },
  {
    field: "cancellationDate",
    headerName: "Fecha de cancelación",
    width: 170,
  },
  {
    field: "reasonCancellation",
    headerName: "Motivo de cancelación",
    width: 170,
  },
];

export const getFilters = (
  insuranceOptions: any[],
  statusOptions: ListDropdownMultipleProps[],
  branchOptions: ListDropdownMultipleProps[],
) => [
    {
      active: true,
      backgroundColor: "#E8EEFF",
      border: true,
      borderRadius: "8px",
      borderType: "1px solid #96ABFF",
      color: "#0D1BD9",
      sizes: "40px",
      type: "fullTextSearch",
    },
    {
      backgroundColor: "#f5f7fa",
      color: "#3a56a1",
      fontWeight: 700,
      label: "Limpiar Filtros",
      type: "cleanButton",
      variantButtons: variants.contained,
      visible: true,
    },
    {
      disabled: !hasPermission("sale-policies-sumary-export"),
      label: "Exportar",
      listButtonDropdown: [
        {
          icons: icons.DownloadForOffline,
          id: "1",
          img: newExcel,
          name: "Descargar Excel",
        },
      ],
      showImage: true,
      type: "exportButton",
      variantButtons: variants.text,
      visible: true,
    },
    {
      id: "dateRange",
      label: "Rango de fechas",
      type: "dateRange",
    },
    {
      backgroundColor: "#3A56A1",
      color: "#FFFFFF",
      id: "insurance",
      initialSelected: 4,
      listAvatar: insuranceOptions,
      surplus: 2,
      type: "avatarSelection",
      width: "225px",
    },
    {
      dropdownIndex: 1,
      id: "state",
      label: "Estado",
      listDropDown: statusOptions,
      titleSearch: "buscar",
      type: "dropdownMultiple",
    },
    {
      dropdownIndex: 2,
      id: "branchs",
      label: "Sucursales",
      listDropDown: branchOptions,
      titleSearch: "buscar",
      type: "dropdownMultiple",
    },
  ];
