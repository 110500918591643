import React, { useCallback, useMemo } from "react";
import { CertificateTemplate } from "../../../model/certificates";
import { MenuItem } from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { Project } from "../../../model/project";

const useDataCards = (
  setDataCards: React.Dispatch<React.SetStateAction<CertificateTemplate[]>>,
) => {
  const setDataCardsList = useCallback(
    (responseCertificates: CertificateTemplate[]) => {
      const dataCards = responseCertificates.map((item) => ({
        ...item,
        id: item.id,
        title: item.title,
        type: item.type,
        physicalName: item.physicalName,
      }));
      setDataCards(dataCards);
    },
    [setDataCards],
  );

  const optionsCards = useCallback(
    (
      option: MenuItem,
      data: CertificateTemplate,
      navigate: any,
      setCertificateName: (value: string | (() => string)) => void,
      setCertificateId: (value: string | (() => string)) => void,
      setOpenDeleteModal: (value: boolean | (() => boolean)) => void,
      setOpenDrawer: (value: boolean | (() => boolean)) => void,
      setHasProjectList: (value: Project[] | (() => Project[])) => void,
    ) => {
      switch (option.id) {
        case "1": //Modificar certificado
          navigate(`/certificates-editor/${data.id}/true`);
          sessionStorage.removeItem("hasShownToast");
          break;
        case "2": // Eliminar certificado
          setCertificateName(data.title || "");
          setOpenDeleteModal(true);
          break;
        case "3": // asociar producto
          setCertificateId(data.id);
          setHasProjectList(data?.projects || []);
          setOpenDrawer(true);
      }
    },
    [],
  );
  return useMemo(
    () => ({
      setDataCardsList,
      optionsCards,
    }),
    [setDataCardsList, optionsCards],
  );
};

export default useDataCards;
