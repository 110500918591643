import {
  icons,
  MenuItem,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { NavigateStructure } from "../interface/nav";
import LocalStorageService from "../services/localStorage";
import flow from "../assets/flow.json";

const storageKey = "afy-login";
const localStorageService = new LocalStorageService(storageKey);

interface Role {
  name: string;
  permissions: Permission[];
}

interface Permission {
  type: string;
  name: string;
  id: string;
}

export const getPermission = () => {
  let roles: Role[] = localStorageService.getUserLoginStored().roles || [];
  const allPermission = roles
    ? roles.map((role) => role?.permissions).flat()
    : [];
  return allPermission;
};

const createMenuHeader = () => {
  const pp: Permission[] = getPermission();
  const principalModulesMenu = pp.filter((p) => p.type === "Admin-Option");

  let menuHeader: MenuItem[] = [];

  principalModulesMenu.map((p) => {
    switch (p.name) {
      case "admin-sales":
        if (!menuHeader.find((m) => m.id === "inicio")) {
          menuHeader.push({
            id: "inicio",
            name: "Ventas",
            active: true,
          });
        }
        break;
      case "admin-repo":
        if (!menuHeader.find((m) => m.id === "reporte")) {
          menuHeader.push({
            id: "reporte",
            name: "Reportes",
            active: false,
          });
        }

        break;
      case "admin-module":
        if (!menuHeader.find((m) => m.id === "admin")) {
          menuHeader.push({
            id: "admin",
            name: "Administración",
            active: false,
          });
        }
        break;
    }
  });

  return menuHeader;
};

// const createMenuSideBar = () => {
//   const pp: Permission[] = getPermission();
//   const principalModulesMenu = pp.filter((p) => p.type === "Admin-Option");
//   let menuSideBar: NavigateStructure[] = [];
//
//   principalModulesMenu.map((p) => {
//     switch (p.name) {
//       case "admin-sales":
//         if (!menuSideBar.find((m) => m.id === "venta")) {
//           menuSideBar.push({
//             id: "venta",
//             name: "Nueva Cotización",
//             icon: icons.Shield,
//             active: true,
//             route: "/",
//             divider: false,
//           });
//         }
//
//         break;
//       case "admin-clients":
//         if (!menuSideBar.find((m) => m.id === "clientes")) {
//           menuSideBar.push({
//             id: "clientes",
//             name: "Clientes",
//             icon: icons.Person,
//             active: false,
//             route: "/clients",
//             divider: false,
//           });
//         }
//
//         break;
//       case "admin-insurance-policies-summary":
//         if (!menuSideBar.find((m) => m.id === "poliza")) {
//           menuSideBar.push({
//             id: "poliza",
//             name: "Cotizaciones",
//             icon: icons.Favorite,
//             active: false,
//             route: "/insurance-policies-summary",
//             divider: false,
//           });
//         }
//         break;
//     }
//   });
//
//   return menuSideBar;
// };

const createMenuSideBar = () => {
  const pp: Permission[] = getPermission();
  const principalModulesMenu = pp.filter((p) => p.type === "Admin-Option");
  let menuSideBar: NavigateStructure[] = [];

  // Definir el orden deseado
  const menuOrder = [
    "admin-sales",
    "admin-clients",
    "admin-insurance-policies-summary",
  ];

  menuOrder.forEach((moduleName) => {
    const p = principalModulesMenu.find((p) => p.name === moduleName);
    if (!p) return;

    switch (p.name) {
      case "admin-sales":
        if (!menuSideBar.some((m) => m.id === "venta")) {
          menuSideBar.push({
            id: "venta",
            name: "Nueva Cotización",
            icon: icons.Shield,
            active: true,
            route: "/",
            divider: false,
          });
        }
        break;

      case "admin-clients":
        if (!menuSideBar.some((m) => m.id === "clientes")) {
          menuSideBar.push({
            id: "clientes",
            name: "Clientes",
            icon: icons.Person,
            active: false,
            route: "/clients",
            divider: false,
          });
        }
        break;

      case "admin-insurance-policies-summary":
        if (!menuSideBar.some((m) => m.id === "poliza")) {
          menuSideBar.push({
            id: "poliza",
            name: "Cotizaciones",
            icon: icons.Favorite,
            active: false,
            route: "/insurance-policies-summary",
            divider: false,
          });
        }
        break;
    }
  });

  return menuSideBar;
};

export const GetMenuSideBarHome = () => {
  return createMenuSideBar();
};

export const GetMenuHeader = () => {
  return createMenuHeader();
};

export const GetMenuSideBarAdministration = () => {
  const m: NavigateStructure[] = flow.data.adminMenu.navigationMenu;
  return m;
};

export const GetMenuSideBarReport = () => {
  const m: NavigateStructure[] = flow.data.reportsMenu.navigationMenu;
  return m;
};
