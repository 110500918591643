import {
  breadcrumb,
  ContextMenuAFY,
  icons,
  sizesIcon,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid";

export const beneficiaryColumns = [
  {
    editable: false,
    field: "type",
    flex: 1,
    headerName: "Tipo beneficiario",
    width: 150,
  },
  {
    editable: false,
    field: "name",
    flex: 1,
    headerName: "Nombres",
    width: 150,
    renderCell: (params: any) => {
      return params.value + " " + params.row.secondName;
    },
  },
  {
    editable: false,
    field: "lastName",
    flex: 1,
    headerName: "Apellidos",
    width: 150,
    renderCell: (params: any) => {
      return params.value + " " + params.row.secondLastName;
    },
  },

  {
    editable: false,
    field: "phone",
    flex: 1,
    headerName: "Teléfono",
    width: 150,
  },
  {
    editable: false,
    field: "dob",
    flex: 1,
    headerName: "Fecha nacimiento",
    width: 150,
    valueGetter: (params: any) => {
      const date = new Date(params.value);
      const day = ("0" + date.getDate()).slice(-2);
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    },
  },
  {
    editable: false,
    field: "percentage",
    flex: 1,
    headerName: "Porcentaje",
    width: 150,
    valueGetter: (params: any) => {
      return `${params.value} %`;
    },
  },
];

export const insuredColumns = [
  {
    editable: false,
    field: "type",
    flex: 1,
    headerName: "Tipo asegurado",
    width: 150,
  },
  {
    editable: false,
    field: "name",
    flex: 1,
    headerName: "Nombres",
    width: 150,
    renderCell: (params: any) => {
      return params.value + " " + params.row.secondName;
    },
  },
  {
    editable: false,
    field: "lastName",
    flex: 1,
    headerName: "Apellidos",
    width: 150,
    renderCell: (params: any) => {
      return params.value + " " + params.row.secondLastName;
    },
  },

  {
    editable: false,
    field: "marriedName",
    flex: 1,
    headerName: "Apellido de casada",
    width: 150,
  },
  {
    editable: false,
    field: "phone",
    flex: 1,
    headerName: "Teléfono",
    width: 150,
  },
  {
    editable: false,
    field: "dob",
    flex: 1,
    headerName: "Fecha nacimiento",
    width: 150,
    valueGetter: (params: any) => {
      const date = new Date(params.value);
      const day = ("0" + date.getDate()).slice(-2);
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    },
  },
];

export const Breadcrum: breadcrumb[] = [
  {
    name: "Producto",
    key: "seguro",
    ariaCurrent: "",
  },
  {
    name: "Plan",
    key: "plan",
    ariaCurrent: "",
  },
  {
    name: "Registro",
    key: "registro",
    ariaCurrent: "",
  },
];

export const Columns = (handleClickContextMenu: any) => {
  return [
    {
      field: "actions",
      headerName: "",
      width: 80,
      renderCell: (params: GridRenderCellParams) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <ContextMenuAFY
            colorButton="transparent"
            iconButton={icons.MoreVert}
            iconColor="#3A56A1"
            menuItem={[
              {
                disabled: false,
                icon: {
                  color: "#006ac6",
                  icon: icons.PermIdentity,
                  size: sizesIcon.standard,
                },
                id: "1",
                name: "Beneficiarios",
              },
              {
                disabled: false,
                icon: {
                  color: "#006ac6",
                  icon: icons.PermIdentity,
                  size: sizesIcon.standard,
                },
                id: "2",
                name: "Asegurados",
              },
              {
                disabled: false,
                icon: {
                  color: "#006ac6",
                  icon: icons.Search,
                  size: sizesIcon.standard,
                },
                id: "2",
                name: "Ver detalles",
              },
            ]}
            onClickContextMenu={(item: any) =>
              handleClickContextMenu(item, params)
            }
            sizeIcon={sizesIcon.standard}
          />
        </div>
      ),
    },
    {
      editable: false,
      field: "estado",
      flex: 1,
      headerName: "Estado",
      renderCell: (params: any) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <span className={params.value}></span>
          {params.value}
        </div>
      ),
      width: 70,
    },
    {
      editable: false,
      field: "cotizacion",
      flex: 1,
      headerName: "Cotización",
      width: 150,
    },
    {
      editable: false,
      field: "poliza",
      flex: 1,
      headerName: "Poliza",
      width: 150,
    },
    {
      editable: false,
      field: "producto",
      flex: 1,
      headerName: "Producto",
      width: 150,
    },
    {
      editable: false,
      field: "plan",
      flex: 1,
      headerName: "Plan",
      width: 150,
    },
    {
      field: "prima",
      flex: 1,
      headerName: "Prima",
      width: 160,
    },
  ];
};
