import React, { useEffect } from "react";
import {
  breadcrumb,
  BreadcrumbAFY,
  CardProps,
  itemPlanProps,
  MenuItem,
  ModalComponentAFY,
  PlanAFY,
  QuotesForExistingPolicesAFY,
  select,
  sizes,
  StepProgressAFY,
  variants,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { columnQuotes, InsurancePageProps } from "../resources/planDto";
import { Title, TitleCotizacionPoliza } from "../../../components/layout/style";
import { transformToSelect } from "../../../services/functions";
import "../assets/style.css";
import { themeCore } from "../../../assets/themes/theme";
import { hasPermission } from "../../../services/getNavigationPages";

export const PlanView = (props: {
  error?: boolean;
  helperText?: string;
  plan: InsurancePageProps;
  breadCrumbs: breadcrumb[];
  planBenefitData?:
    | {
        detail?: itemPlanProps[];
        values?: itemPlanProps;
      }
    | undefined;
  showDetail: boolean;
  planBenefitCompareData?:
    | { detail?: itemPlanProps[]; values?: itemPlanProps[] }
    | undefined;
  showCompareDetail: boolean;
  paymentMode: [];
  campaignInfo: [];
  steps: any;
  closeDetail: () => void;
  searchFilter: (term: string) => void;
  clickView: (view: MenuItem) => void;
  clickCard: (card: CardProps) => void;
  clickChip: (chips: MenuItem[]) => void;
  clickChangePaymentMode: (
    event: select,
    item: itemPlanProps,
    row: any
  ) => void;
  clickDetails: (items: itemPlanProps) => void;
  clickCompareDetail: (item: itemPlanProps[]) => void;
  onClickCompareItem: (items: itemPlanProps, status: MenuItem) => void;
  listIdentificationType: select[];
  onClickAcquire: any;
  onClickNext: () => void;
  onClickBack: () => void;
  insuranceType: string;
  insurance: string;
  extraInfoNames: itemPlanProps[];
  showExtraInfo?: boolean;
  showQuotes?: boolean;
  onCloseModalQuote?: () => void;
  filtersQuote?: [];
  rowsQuote?: [];
  onClickSelectRowQuote: (param: any) => void;
  onClickNewQuote: () => void;
  filterTitleInsuranceInformation?: string;
  showInformation?: boolean;
  onCancelSearch: () => void;
  planSelected?: any;
  insurancePage: number;
  insuranceLimit: number;
  countPagination: number;
  onChangePage: (e: number) => void;
  onChangeRowsPerPage: (e: number) => void;
}) => {
  const [paymentMode, setPaymentMode] = React.useState<any>(
    transformToSelect(props.paymentMode)
  );
  useEffect(() => {
    if (props) {
      setPaymentMode(transformToSelect(props.paymentMode));
    }
  }, [props.paymentMode]);

  return (
    <>
      <BreadcrumbAFY options={props.breadCrumbs} />
      <ModalComponentAFY
        openModal={props.showQuotes}
        backgroundColor="#ffffff"
        onClose={props.onCloseModalQuote}
        border={0}
        modalWidth={1300}
        padding={15}
      >
        <TitleCotizacionPoliza>Cotizacion/Póliza</TitleCotizacionPoliza>
        <QuotesForExistingPolicesAFY
          bgButton="#3A56A1"
          colorTextButton="#fff"
          columns={columnQuotes}
          filters={props.filtersQuote}
          onBlur={function noRefCheck() {}}
          onChange={function noRefCheck() {}}
          onClick={props.onClickSelectRowQuote}
          onClickButton={function noRefCheck() {}}
          onClickNewQuote={props.onClickNewQuote}
          rows={props.rowsQuote}
          sizeButton={sizes.large}
          title="Grid QuotesForExistingPolice"
          titleButton="Nuevo"
          variantButton={variants.contained}
        />
      </ModalComponentAFY>
      <Title>
        {props.plan.titlePage}
        <article
          style={{
            position: "absolute",
            top: "calc(100% - 45px)",
            right: "391px",
            zIndex: "9",
          }}
        >
          <StepProgressAFY
            onClickStepProgress={() => {}}
            stepProgress={props.steps}
            collapsed
            sticky
          />
        </article>
      </Title>
      <PlanAFY
        disableButtonGetPlan={!hasPermission("sale-plan-create")}
        detail={props.planSelected}
        modalWidthNew={"auto"}
        modalHeight={"90%"}
        backgroundColorBack={themeCore.colors.primary}
        backgroundColorNext={themeCore.colors.primary}
        bgColorSelectPlan={themeCore.colors.primary}
        minHeightGrid={"calc(100vh - 390px)"}
        catalog={{
          defaultFilters: true,
          filters: [],
          filtersInsuranceInformation: [
            {
              name: "Tipo de seguro",
              label: props.insuranceType,
              type: "label",
            },
            {
              name: "Seguro",
              label: props.insurance,
              type: "label",
            },
          ],
          filterTitleInsuranceInformation:
            props.filterTitleInsuranceInformation,
          label1: "Modalidad de pago",
          label2: "Tipo de coberturas",
          hide2: true,
          label3: "Campaña",
          options1: transformToSelect(paymentMode),
          options3: transformToSelect(props.campaignInfo),
          title: "Información preliminar",
        }}
        toggleDetail={props.showDetail}
        plansBenefitCompare={props.planBenefitCompareData}
        toggleCompareDetail={props.showCompareDetail}
        onCloseDetailModal={props.closeDetail}
        filter={props.plan.filter}
        planBenefit={props.planBenefitData}
        onChangeOptions={props.clickChangePaymentMode}
        onChangeFilterTerm={(term: any) =>
          props.searchFilter && props.searchFilter(term)
        }
        onClick={(item: any) => props.clickCard && props.clickCard(item)}
        onClickAcquire={props.onClickAcquire}
        onClickButtonView={function noRefCheck() {}}
        onClickChip={function noRefCheck() {}}
        onClickCompareDetails={props.clickCompareDetail}
        onClickCompareItem={props.onClickCompareItem}
        onClickDetails={props.clickDetails}
        plans={props.plan.data}
        seeDetailText={"Ver Detalle"}
        showFilterSearch
        title="Plan"
        bgColor={"rgb(142 142 142)"}
        next={{
          nextDisabled: false,
          nextLabel: "Siguiente",
          previousLabel: "Anterior",
          showNext: false,
        }}
        onClickNext={props.onClickNext}
        onClickBack={props.onClickBack}
        showPrice={!props.showExtraInfo}
        showExtraInfo={props.showExtraInfo}
        extraInfoTitle="Rangos de edad"
        extraInfo={props.extraInfoNames}
        showInformation={props.showInformation}
        onCancelSearch={props.onCancelSearch}
        titlePagination={"Registros por página"}
        countPagination={props.countPagination}
        rowsPerPage={props.insuranceLimit}
        onChangePage={props.onChangePage}
        rowsPerPageOption={[20, 25, 50, 100]}
        onChangeRowsPerPage={props.onChangeRowsPerPage}
        pagePagination={props.insurancePage}
      />
    </>
  );
};
