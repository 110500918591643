import {
  LoadingAFY,
  position,
  typeAlert,
  typeLoaders,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { themeCore } from "../../../assets/themes/theme";
import { HeaderTitlePage } from "../../../components/resources/headerTitlePage/headerTitlePage";
import React from "react";

export const ConfirmationHeader = (props: any) => {
  return (
    <>
      <LoadingAFY
        loading={props.loading}
        backgroundColor={themeCore.colors.backgroundLoader}
        typeAnimation={typeLoaders.LogoAfy}
        sizeAnimation={65}
        textLoading={props.text}
        textLoadingColor={themeCore.colors.third}
        spinnerColor={themeCore.colors.third}
      />
      {props.seeToast.show &&
        props.showToast(
          position.topRight,
          props.seeToast.type,
          props.seeToast.message,
          () => {
            props.setSeeToast({
              show: false,
              message: "",
              type: typeAlert.success,
            });
          },
        )}
      <HeaderTitlePage
        breadCrumbs={props.breadCrumbs}
        steps={props.steps}
        hideStep={false}
        completed={props.completed}
      />
    </>
  );
};
