import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Steps } from "../../../enum/steps";
import { ReadFlags } from "../../../fnx/readFlags";
import { removeNonNumeric } from "../../../services/functions";
import LocalStorageService from "../../../services/localStorage";
import { quoteOne } from "../../../store/generateQuote/actions";
import { pageActionFlagsApi } from "../../../store/pageActionFlags/actions";
import { updatePerson } from "../../../store/person/actions";
import { region } from "../../../store/region/actions";
import { formattedDateWithTime } from "../../../utils/date";

const useClient = (
  paymentMode: any,
  setPeriod: any,
  setPaymentModelSelected: any,
  setPaymentMode: any,
  paymentModelSelected: any,
  coverTypeSelected: any,
  plans: any,
  enable: any,
  page: any,
  limit: any,
  projectId: any,
  namePlan: any,
  clientId: any,
  setCoverTypeSelected: any,
  campaigns: any,
  setCampaignSelected: any,
  level2: any,
  level3: any,
  setListRegionLevel3: any,
  listGenders: any,
  setHideMarriedName: any,
  setCoverType: any,
  setPaymentSelected: any,
  setPlanSelected: any,
  setPlan: any,
  campaignSelected: any,
  personQuoteId: any,
  hideMarriedName: any,
  salesFlow: any,
  setCampaign: any,
  setCampaigns: any,
  setFileName: any,
  setPdfFile: any,
  setUploadTrigger: any,
  actionFlags: any,
  isQuote: any,
  setShowInsured: any,
  setAddInsured: any,
  setShowBeneficiary: any,
  setAddBeneficiary: any,
  setCanEditInsured: any,
  setCanEditBeneficiary: any,
  responseOneQuote: any,
  setTriggerCertificate: any,
  setUrlDocument: any,
  setopenModalPdfViewer: any,
) => {
  const dispatch = useDispatch();
  const storageKey = "afy-steps";
  const localStorageService = useMemo(
    () => new LocalStorageService(storageKey),
    [storageKey],
  );
  const { t } = useTranslation();
  const callDispatchPlan = useCallback(
    (selectedPM: any, selectedCT: any) => {
      if (selectedPM == null) {
        selectedPM = paymentModelSelected;
      }
      if (selectedCT == null) {
        selectedCT = coverTypeSelected;
      }
      if (paymentMode[0]?.id !== "-1") {
        dispatch(
          plans({
            enable,
            page,
            limit,
            projectId,
            name: namePlan,
            paymentModeId: selectedPM?.id,
            coverTypeId: selectedCT?.id,
            clientId,
          }),
        );
      }
    },
    [dispatch],
  );

  const handleSearch = useCallback(
    (
      value: any,
      isInsuranceFlag: any,
      setIsInsurance: any,
      setDocument: any,
      setTrigger: any,
    ) => {
      const document = value.target.value;
      if (document) {
        const cleanedDocument = document.replace(/-/g, "");
        const numberDocument = Number(cleanedDocument);
        if (!isNaN(numberDocument)) {
          setIsInsurance(isInsuranceFlag);
          setDocument(numberDocument);
          setTrigger(true);
        }
      }
    },
    [],
  );

  const searchPerson = useCallback(
    (value: any, setIsInsurance: any, setDocument: any, setTrigger: any) => {
      handleSearch(value, false, setIsInsurance, setDocument, setTrigger);
    },
    [handleSearch],
  );

  const handleOnChangePaymentMethod = useCallback(
    (event: any) => {
      let paymentPeriod = paymentMode.find((x: any) => x.id === event);

      setPeriod(paymentPeriod?.name);
      setPaymentModelSelected(paymentPeriod);

      const pm = paymentMode.map((item: any) => ({
        ...item,
        default: item.id === paymentPeriod?.id,
      }));
      setPaymentMode(pm);

      callDispatchPlan(paymentPeriod, null);
    },
    [callDispatchPlan],
  );

  const handleOnChangeCoverType = useCallback(
    (event: any) => {
      setCoverTypeSelected(event);
      callDispatchPlan(null, event);
    },
    [callDispatchPlan],
  );

  const handleOnChangeCampaign = useCallback((event: any) => {
    let campaignS = campaigns.find((x: any) => x.id === event);
    setCampaignSelected(campaignS);
  }, []);

  const handleChangeProvince = useCallback(
    (id: any) => {
      dispatch(
        region({
          enable,
          page,
          limit,
          level: level2,
          parentRegionId: id,
        }),
      );
      setListRegionLevel3([{ id: "-1", value: "-1", name: " " }]);
    },
    [dispatch],
  );

  const handleChangeTown = useCallback(
    (id: any) => {
      dispatch(
        region({
          enable,
          page,
          limit,
          level: level3,
          parentRegionId: id,
        }),
      );
    },
    [dispatch],
  );
  const getPaymentStatus = (status: any) => {
    switch (status) {
      case "PENDING":
        return t(`confirmationData.paymentStatuses.PENDING`);
      case "READY":
        return t(`confirmationData.paymentStatuses.READY`);
      default:
        return "";
    }
  };

  const getBillingStatus = (status: any) => {
    switch (status) {
      case "GENERATED":
        return t(`confirmationData.paymentStatuses.GENERATED`);
      case "EMMITED":
        return t(`confirmationData.paymentStatuses.EMMITED`);
      case "PAID":
        return t(`confirmationData.paymentStatuses.PAID`);
      case "CANCELLED":
        return t(`confirmationData.paymentStatuses.CANCELLED`);
      default:
        return "";
    }
  };

  const handleChangeSelect = useCallback(
    (event: any) => {
      const { value, name } = event.target;
      if (name === "ProvinceName") handleChangeProvince(value);
      else if (name === "TownName") handleChangeTown(value);

      const gender = listGenders.find((g: any) => g.id === value);
      setHideMarriedName(gender?.name === "Femenino" ? false : true);
    },
    [handleChangeProvince, handleChangeTown],
  );

  const handleDownload = useCallback((obj: any) => {
    window.open(obj.url, "_blank");
  }, []);

  const handleUpdatePerson = useCallback(
    (dataForm: any) => {
      const body = {
        clientId,
        identificationTypeId: dataForm.IdType,
        countryId: dataForm.Country,
        catalogGender: dataForm.Gender,
        catalogTitle: "3536c66a-2cf7-44b1-9a81-b7486902b997",
        catalogRegionLevel1: dataForm.Province,
        catalogRegionLevel2: dataForm.Town,
        catalogRegionLevel3: dataForm.Sector,
        catalogCivilStatus: "42bedbc9-91cf-4d40-818e-3fc643e13e51",
        catalogProfession: "811c8769-9e83-42a3-9381-0ae279faf525",
        numberId: dataForm.DocumentNumber,
        firstNameA: dataForm.Name,
        firstNameB: dataForm.SecondName,
        lastNameA: dataForm.FirstLastName,
        lastNameB: dataForm.SecondLastName,
        addressA: dataForm.Address1,
        addressB: dataForm.Address2,
        zip: dataForm.PostalCode,
        phone1: removeNonNumeric(dataForm.PhoneHome),
        phone2: removeNonNumeric(dataForm.PhoneOffice),
        phone3: removeNonNumeric(dataForm.PhoneNumber),
        email: dataForm.Email,
        marriedName: hideMarriedName ? "" : dataForm.MarriedSurName,
        dob: formattedDateWithTime(dataForm.Birthday),
      };
      dispatch(updatePerson({ body, id: personQuoteId }));
    },
    [dispatch],
  );

  const setLocalStorage = useCallback(
    (data: any, project: any) => {
      const { planId, catalogPaymentMode, campaignId } = data;
      const campaignData = {
        id: campaignId.id,
        name: campaignId.name,
        value: campaignId.id,
      };

      localStorageService.setStepStored({
        ...data,
        quoteId: data.id,
        step: Steps.customer,
      });
      setCampaignSelected(campaignData);
      setCampaign([campaignData]);
      setCampaigns([campaignData]);
    },
    [localStorageService],
  );

  const updateDataFormPerson = useCallback(
    (dataPerson: any, currentDataForm: any) => ({
      ...currentDataForm,
      Id: dataPerson.id,
      IdType: dataPerson.identificationTypeId,
      DocumentNumber: dataPerson.numberId,
      Name: dataPerson.firstNameA,
      SecondName: dataPerson.firstNameB,
      LastNameA: dataPerson.lastNameA,
      LastNameB: dataPerson.lastNameB,
      marriedName: dataPerson.marriedName,
      Birthday: dataPerson.dob,
      PhoneNumber: dataPerson.phone1,
    }),
    [],
  );

  const setCoverTypeStorybook = useCallback((ct: any) => {
    return ct.map((item: any) => ({
      name: item.description || "",
      value: item.id,
      id: item.id,
    }));
  }, []);

  const handleOnClickAcquire = useCallback(
    (item: any) => {
      const ct = coverTypeSelected || item.option[0];
      setCoverType(ct);
      setPaymentSelected(item.paymentMethod);
      setPlanSelected({ id: item.id, name: item.name });
      setPlan({ id: item.id, name: item.name });

      callDispatchPlan(item.paymentMethod, ct);
    },
    [callDispatchPlan],
  );

  const quoteUploadDocuments = useCallback((file: any) => {
    if (file) {
      setFileName(file[0].name.replace(".pdf", ""));
      setPdfFile(file[0]);
      setUploadTrigger(true);
    }
  }, []);

  const callQuoteOne = (quoteId: string) => {
    if (quoteId !== "") {
      dispatch(
        quoteOne({
          id: quoteId,
          fullBody: true,
        }),
      );
    }
  };

  const callDispatchPageActionFlags = (id: string) => {
    dispatch(
      pageActionFlagsApi({
        enable: enable,
        page: page,
        limit: limit,
        clientId: clientId,
        projectId: id,
      }),
    );
  };

  const getActionFlags = () => {
    if (actionFlags) {
      const rfShowInsured = ReadFlags(
        actionFlags ? actionFlags : [],
        "afy-confirm-data-show-insured",
      );

      const rfAddInsurance = ReadFlags(
        actionFlags ? actionFlags : [],
        "afy-confirm-data-add-insured",
      );

      const rfShowBeneficiary = ReadFlags(
        actionFlags ? actionFlags : [],
        "afy-confirm-data-add-beneficiary",
      );

      const rfAddBeneficiary = ReadFlags(
        actionFlags ? actionFlags : [],
        "afy-confirm-data-add-beneficiary",
      );

      setShowInsured(rfShowInsured !== "true");

      setAddInsured(
        isQuote !== undefined ? isQuote !== "true" : rfAddInsurance !== "true",
      );

      setShowBeneficiary(rfShowBeneficiary !== "true");

      setAddBeneficiary(
        isQuote !== undefined
          ? isQuote !== "true"
          : rfAddBeneficiary !== "true",
      );

      setCanEditInsured(isQuote === undefined || isQuote === "true");

      setCanEditBeneficiary(isQuote === undefined || isQuote === "true");
    }
  };

  const handleOnClickAutoDownload = () => {
    // LLAMA AL API DE COTIZACION
    setTriggerCertificate(true);
    //callDispatchPlan();
  };

  const handleOnClickAutoDownloadVida = (data: any) => {
    // LLAMA
    let certs = responseOneQuote?.data?.certificates;

    const cert = certs.find(
      (cert: any) =>
        cert.certificateDocumentInfo?.physicalName ===
        "suscripcion_individual_vida_termino.docx",
    );
    const urlC = cert?.url;
    if (urlC) {
      setUrlDocument(urlC);
      setopenModalPdfViewer(true);
    }
  };

  return useMemo(
    () => ({
      searchPerson,
      handleSearch,
      callDispatchPlan,
      handleOnChangePaymentMethod,
      handleOnChangeCoverType,
      handleOnChangeCampaign,
      handleChangeSelect,
      handleUpdatePerson,
      handleDownload,
      setLocalStorage,
      updateDataFormPerson,
      setCoverTypeStorybook,
      handleOnClickAcquire,
      quoteUploadDocuments,
      callQuoteOne,
      callDispatchPageActionFlags,
      getActionFlags,
      handleOnClickAutoDownload,
      handleOnClickAutoDownloadVida,
      getPaymentStatus,
      getBillingStatus,
    }),
    [
      searchPerson,
      handleSearch,
      callDispatchPlan,
      handleOnChangePaymentMethod,
      handleOnChangeCoverType,
      handleOnChangeCampaign,
      handleChangeSelect,
      handleUpdatePerson,
      handleDownload,
      setLocalStorage,
      updateDataFormPerson,
      setCoverTypeStorybook,
      handleOnClickAcquire,
      quoteUploadDocuments,
      callQuoteOne,
      callDispatchPageActionFlags,
      getActionFlags,
      handleOnClickAutoDownload,
      handleOnClickAutoDownloadVida,
      getPaymentStatus,
      getBillingStatus,
    ],
  );
};

export default useClient;
