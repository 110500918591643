import React from "react";
import {
  breadcrumb,
  CardProps,
  InsuranceAFY,
  ListDropdownMultipleProps,
  MenuItem,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import {getFilters, InsurancePageProps, viewTypeList} from "./insuranceDto";
import { useTranslation } from "react-i18next";

export const InsuranceView = (props: {
  insurance: InsurancePageProps;
  breadCrumbs: breadcrumb[];
  searchFilter: (term: string) => void;
  clickView: (view: MenuItem) => void;
  clickCard: (card: CardProps) => void;
  // clickChip: (chips: MenuItem[]) => void;
  insurancePage: number;
  insuranceLimit: number;
  countPagination: number;
  onChangePage: (e: number) => void;
  onChangeRowsPerPage: (e: number) => void;
  steps: any;
  showIcon: boolean;
  iconPage: any;
  logoImg: string;
  onChangeFilters: (filters: any) => void;
  insuranceFilters: ListDropdownMultipleProps[];
}) => {
  const { t } = useTranslation();
  return (
      <InsuranceAFY
        title={props.insurance.titlePage}
        titlePagination={t("recordsPerPage")}
        countPagination={props.countPagination}
        pagePagination={props.insurancePage}
        rowsPerPage={props.insuranceLimit}
        rowsPerPageOption={[10, 20, 25, 50]}
        data={props.insurance.data}
        filters={getFilters(props.insuranceFilters)}
        viewTypeList={viewTypeList}
        onClick={(item) => props.clickCard && props.clickCard(item)}
        onChangeFilterTerm={props.searchFilter}
        onClickButtonView={(option) => {
          props.clickView && props.clickView(option);
        }}
        onChangeFilter={(data) => {
          props.onChangeFilters(data);
        }}
      />
  );
};
