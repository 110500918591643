import showToast from "../../../services/toast";
import { AlertLoadingHeader } from "../../../components/alertLoadingComponent/alertLoading";
import React from "react";

export const InsurancePoliciesHeader = (props: any) => {
  return (
    <AlertLoadingHeader
      loading={props.loading}
      seeToast={props.seeToast}
      showToast={showToast}
      setSeeToast={props.setSeeToast}
      titleLoading={props.isLoadin ? "Descargando archivo" : "Cargando..."}
    />
  );
};
