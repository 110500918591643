import React, { useEffect, useState } from "react";
import View from "./ui/view";
import { useDispatch} from "react-redux";
import { getColumnsHistory, columns } from "./resources/insurancePoliciesDTO";


import {
  quoteAll,
  quoteAllClearData
} from "../../store/generateQuote/actions";
import { useNavigate } from "react-router-dom";
import {
  breadcrumb,
  ListDropdownMultipleProps,
  MenuItem,
  typeAlert,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";

import { breadcrumDTO } from "../../dataBase/layout";
import LocalStorageService from "../../services/localStorage";
import * as catalogConst from "../../const/catalog";
import { plan } from "../../store/plan/actions";

import { deleteEntityClearData } from "../../store/delete/actions";
import {
  cancelQuoteClearData,
} from "../../store/cancelQuote/actions";
import {
  policyCancelClearData,
} from "../../store/policy/actions";

import { useStyles } from "./assets/style";
import { getCatalogStatusQuote } from "../../store/catalog/actions";
import { formattedDate } from "../../utils/date";
import { sponsor } from "../../store/sponsor/actions";

import { Branches } from "../../store/branches/actions";
import { showErrorToast } from "../../fnx/showError";
import { useTranslation } from "react-i18next";
import { showToastSuccessMessage } from "../../fnx/sendSuccessMessage";
import { Steps } from "../../enum/steps";
import { InsurancePoliciesHeader } from "./ui/header";

import useSelectors from "./components/selectors";
import useUI from "./components/ui";
import useInsurancePoliciesSummary from "./components/insurancePoliciesSummary";

const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);
const storageKey = "afy-steps";
const localStorageService = new LocalStorageService(storageKey);

const InsurancePoliciesSummary = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [clientId] = React.useState<string>(
    lsLogin.getUserLoginStored().clientId
  );
  const classes = useStyles();
  const navigate = useNavigate();
  const [enable, setEnable] = React.useState<boolean>(true);
  const [page, setPage] = React.useState<number>(1);
  const [dataHistory, setDataHistory] = React.useState<any[]>([]);
  const [limit, setLimit] = React.useState<number>(5000);
  const [rows, setRows] = useState<any>([]);
  const [breadCrumbs, setBreadCrumbs] = React.useState<breadcrumb[]>([]);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [openModalHistorial, setOpenModalHistorial] =
    React.useState<boolean>(false);
  const [modalTitle, setModalTitle] = React.useState<string>("Póliza");
  const [openModalConfirm, setOpenModalConfirm] =
    React.useState<boolean>(false);
  const [reasonCancellation, setReasonCancellation] = React.useState<any>([]);
  const [policyId, setPolicyId] = React.useState<string>("");
  const [type, setType] = React.useState<boolean>(true); //cotizacion = false o poliza = true
  const [row, setRow] = React.useState<any>({});
  const [statusOptions, setStatusOptions] = useState<
    ListDropdownMultipleProps[]
  >([]);
  const [insuranceOptions, setInsuranceOptions] = useState<
    ListDropdownMultipleProps[]
  >([]);
  const [branchesOptions, setBranchesOptions] = useState<
    ListDropdownMultipleProps[]
  >([]);
  const [sponsorId, setSponsorId] = React.useState<string>("");
  const [idCatalogStatusQuote, setIdCatalogStatusQuote] =
    React.useState<string>("");
  const [trigger, setTrigger] = useState(false);
  const [dateStart, setDateStart] = React.useState<string>("");
  const [dateEnd, setDateEnd] = React.useState<string>("");
  const [isLoadin, setIsLoading] = React.useState<boolean>(false);

  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });
  const [nameSearch, setNameSearch] = useState<string>("");

  const fields =
    "numberId,code,policyId.code,productId.description,clientId.description,firstNameA,firstNameB,lastNameA,lastNameB,catalogStatusQuote.description,catalogCancellation.createdAt,catalogCancellation.description";

  const labelFields =
    "Número documento,Cotización,Póliza,Producto,Aseguradora,Primer nombre,Segundo nombre,Primer apellido,Segundo apellido,Estatus,Fecha de cancelación,Motivo de cancelación";

  const {
    responseCancelPolicy,
    loadingCancelPolicy,
    errorCancelPolicy,
    errorDeleteEntity,
    responseCancelQuote,
    loadingCancelQuote,
    responsePlan,
    errorPlan,
    responseProject,
    errorProject,
    responseGetCatalogStatusQuote,
    errorGetCatalogStatusQuote,
    responseAllQuote,
    errorAllQuote,
    loadingAllQuote,
    responseBranches,
    errorBranches,
    responseOneQuote,
    responseSponsor,
    errorSponsor,
    responseInsurance,
  } = useSelectors(
    clientId,
    trigger,
    sponsorId
  );


  const {
    handleOpenModal,
    handleCloseModal,
    handleOpenModalConfirm,
    handleCloseModalConfirm,
    handleConfirm,
  } = useUI(
    setOpenModal,
    setOpenModalConfirm,
    setOpenModalHistorial
  );

  const {
    filterStatus,
    onClickDownload,
    getQuoteAll,
    searchFilter,
    onCloseSearch,
    setBranchesOptionsStorybook,
    setInsuranceOptionsStorybook,
    setStatusOptionsStorybook,
    setDataQuoteStorybook,
    filterData,
    handleClickContextMenu,
    handleCancelPolicy,
  } = useInsurancePoliciesSummary(
    setIdCatalogStatusQuote,
    setDateStart,
    setDateEnd,
    clientId,
    enable,
    page,
    limit,
    nameSearch,
    setIsLoading,
    idCatalogStatusQuote,
    dateStart,
    dateEnd,
    fields,
    labelFields,
    setSeeToast,
    setBranchesOptions,
    showToastSuccessMessage,
    setNameSearch,
    setInsuranceOptions,
    setStatusOptions,
    setRows,
    responseInsurance,
    localStorageService,
    navigate,
    Steps,
    setModalTitle,
    setPolicyId,
    setType,
    setOpenModal,
    setRow,
    setOpenModalConfirm,
    type,
    policyId,
  );
  useEffect(() => {
    if (responseOneQuote && responseOneQuote?.data) {
      if (Array.isArray(responseOneQuote?.data)) {
        const formattedResponse = responseOneQuote?.data.map(
          (hItem: { fechaCarga: string | undefined }) => {
            return {
              ...hItem,
              fechaCarga: formattedDate(hItem.fechaCarga),
            };
          }
        );
        setDataHistory(formattedResponse);
        setOpenModalHistorial(true);
      }
    }
  }, [responseOneQuote]);

  useEffect(() => {
    setBreadCrumbs(breadcrumDTO());
    if (!responseAllQuote) {
      getQuoteAll();
    }
    if (!responseBranches) {
      dispatch(
        Branches({
          enable: enable,
          page: page,
          limit: limit,
          clientId: clientId,
        })
      );
    }
    if (!responseSponsor) {
      dispatch(
        sponsor({
          enable: enable,
          page: page,
          limit: limit,
          clientId: clientId,
        })
      );
    }
    if (!responsePlan) {
      dispatch(plan({ enable: enable, page: page, limit: limit }));
    }
    if (!responseGetCatalogStatusQuote) {
      dispatch(
        getCatalogStatusQuote({ enable: enable, page: page, limit: limit })
      );
    }
    dispatch(deleteEntityClearData());
  }, []);

  useEffect(() => {
    if (responseOneQuote && responseOneQuote?.data) {
      if (Array.isArray(responseOneQuote?.data)) {
        const formattedResponse = responseOneQuote?.data?.map(
          (hItem: { fechaCarga: string | undefined }) => {
            return {
              ...hItem,
              fechaCarga: formattedDate(hItem.fechaCarga),
            };
          }
        );

        setDataHistory(formattedResponse);
        setOpenModalHistorial(true);
      }
    }
  }, [responseOneQuote]);

  useEffect(() => {
    if (errorCancelPolicy) {
      showErrorToast(errorCancelPolicy, setSeeToast, t);
    }
  }, [errorCancelPolicy]);

  useEffect(() => {
    if (errorDeleteEntity) {
      showErrorToast(errorDeleteEntity, setSeeToast, t);
    }
  }, [errorDeleteEntity]);

  useEffect(() => {
    if (errorSponsor) {
      showErrorToast(errorSponsor, setSeeToast, t);
    }
  }, [errorSponsor]);

  useEffect(() => {
    if (errorPlan) {
      showErrorToast(errorPlan, setSeeToast, t);
    }
  }, [errorPlan]);

  useEffect(() => {
    if (errorProject) {
      showErrorToast(errorProject, setSeeToast, t);
    }
  }, [errorProject]);

  useEffect(() => {
    if (errorGetCatalogStatusQuote) {
      showErrorToast(errorGetCatalogStatusQuote, setSeeToast, t);
    }
  }, [errorGetCatalogStatusQuote]);

  useEffect(() => {
    if (errorAllQuote) {
      showErrorToast(errorAllQuote, setSeeToast, t);
    }
  }, [errorAllQuote]);

  useEffect(() => {
    if (errorBranches) {
      showErrorToast(errorBranches, setSeeToast, t);
    }
  }, [errorBranches]);

  useEffect(() => {
    if (responseCancelQuote) {
      showToastSuccessMessage(
        "Cotizacion eliminada correctamente",
        typeAlert.success,
        setSeeToast
      );
      dispatch(
        quoteAll({
          clientId: clientId,
          enable: enable,
          page: page,
          limit: limit,
        })
      );
      dispatch(quoteAllClearData());
      dispatch(cancelQuoteClearData());
    }
  }, [responseCancelQuote]);

  useEffect(() => {
    if (responseCancelPolicy && responseCancelPolicy.data) {
      showToastSuccessMessage(
        "Póliza cancelada correctamente",
        typeAlert.success,
        setSeeToast
      );
      dispatch(quoteAllClearData());
      dispatch(
        quoteAll({
          clientId: clientId,
          enable: enable,
          page: page,
          limit: limit,
        })
      );
      dispatch(policyCancelClearData());
    }
  }, [responseCancelPolicy]);

  useEffect(() => {
    let listSponsorFilter = "";
    responseSponsor?.map((sponsor: MenuItem, idx: number) => {
      if (sponsor.id) {
        listSponsorFilter += `&sponsorId=${sponsor.id}`;
      }
    });
    setSponsorId(listSponsorFilter);
  }, [responseSponsor]);

  useEffect(() => {
    if (responsePlan && responsePlan.data) {
      /* Filtrar por beneficiary type */
      let result = filterData(
        responsePlan.data,
        "type",
        catalogConst.CATALOG_REASON_CANCELLATION
      );
      if (result.length > 0) {
        setReasonCancellation(result);
      }
    }
  }, [responsePlan]);

  useEffect(() => {
    if (Array.isArray(responseAllQuote)) {
      setDataQuoteStorybook(responseAllQuote);
    }
  }, [responseAllQuote]);

  useEffect(() => {
    if (Array.isArray(responseGetCatalogStatusQuote)) {
      setStatusOptionsStorybook(responseGetCatalogStatusQuote);
    }
  }, [responseGetCatalogStatusQuote]);

  useEffect(() => {
    if (Array.isArray(responseInsurance)) {
      setInsuranceOptionsStorybook(responseInsurance);
    }
  }, [responseInsurance]);

  useEffect(() => {
    if (Array.isArray(responseBranches)) {
      setBranchesOptionsStorybook(responseBranches);
    }
  }, [responseBranches]);

  useEffect(() => {
    setBreadCrumbs(breadcrumDTO());
    if (!responseProject) {
    }
  }, [responseSponsor]);

  useEffect(() => {
    if (nameSearch) {
      getQuoteAll();
    } else {
      onCloseSearch();
    }
  }, [nameSearch]);


  useEffect(() => {
    if (nameSearch) {
      getQuoteAll();
    }
  }, [nameSearch]);


  return (
    <React.Fragment>
      <InsurancePoliciesHeader
        seeToast={seeToast}
        loading={
          loadingAllQuote ||
          loadingCancelQuote ||
          loadingCancelPolicy ||
          isLoadin
        }
        setSeeToast={setSeeToast}
        isLoadin={isLoadin}
      />

      <View
        onCloseSearch={onCloseSearch}
        searchFilter={searchFilter}
        columns={columns(handleClickContextMenu)}
        getQuoteAll={getQuoteAll}
        getColumnsHistory={getColumnsHistory}
        filterStatus={filterStatus}
        rows={rows}
        insuranceOptions={insuranceOptions}
        statusOptions={statusOptions}
        branchOptions={branchesOptions}
        handleOpenModal={handleOpenModal}
        OpenModalHistorial={openModalHistorial}
        rowHistory={dataHistory}
        onClose={handleCloseModal}
        openModal={openModal}
        onConfirm={handleConfirm}
        reasonCancellation={reasonCancellation}
        handleOpenModalConfirm={handleOpenModalConfirm}
        onCloseConfirm={handleCloseModalConfirm}
        openModalConfirm={openModalConfirm}
        breadCrumbs={breadCrumbs}
        modalTitle={modalTitle}
        title={"Resumen pólizas"}
        onSubmit={handleCancelPolicy}
        row={row}
        onClickDownload={onClickDownload}
      />
    </React.Fragment>
  );
};

export default InsurancePoliciesSummary;
