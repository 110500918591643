import React from "react";
import Affinity from "../../../assets/images/logoAffinity.svg";
import { Grid } from "@mui/material";
import {
  CertificatesTitleLabel,
  ContainerRows,
  StyleColumns,
  StyleColumnsRight,
} from "../style";
import { Controller } from "react-hook-form";
import {
  ButtonAFY,
  DataFormCkEditor,
  DrawerAFY,
  icons,
  InputAFY,
  LabelAutoCompleteAFY,
  sizesTextField,
  variants,
  variantsTextField,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { themeCore } from "../../../assets/themes/theme";

export const DrawerCertificates = (props: {
  openDrawerSb?: boolean;
  onCloseDrawer: (event: React.MouseEvent<HTMLButtonElement>) => void;
  titleDrawer?: string;
  descriptionDrawer?: string;
  titleButtonAdd?: string;
  onSaveData: (
    data: string | undefined,
    fileName: string,
    typeFile: string,
  ) => void;
  dataEditor?: string;
  productTitleColor?: string;
  dataForm?: DataFormCkEditor;
  labelFileName?: string;
  labelTypeCertificate?: string;
  titleSaveButton?: string;
  TypeListDrawer?: any[];
  register?: any;
  errors: any;
  setError: any;
  clearErrors: any;
  control: any;
  isValid: boolean;
  errorTextFileName?: string;
  errorTextTypeFile?: string;
  editorInstanceRef?: any;
  getValues: any;
}) => {
  const validations = {
    required: {
      value: true,
      message: "Requerido",
    },
  };

  const handleSaveButtonClick = () => {
    const fileName = props.getValues("fileName") || "";
    const typeFile = props.getValues("typeFile");
    const typeFileName = typeFile ? typeFile.id : "";
    const ckEditorHtml = props.editorInstanceRef.current?.getData();
    props.onSaveData(ckEditorHtml, fileName, typeFileName);
    const body = {
      html: ckEditorHtml,
      name: "Doc#1",
    };
    console.log("Body", JSON.stringify(body));
  };

  return (
    <DrawerAFY
      view={1}
      img={Affinity}
      BgBackdrop={themeCore.colors.backgroundLoader}
      BgcCloseButton={themeCore.colors.third}
      bgColorDrawer={themeCore.colors.third}
      openDrawer={props.openDrawerSb}
      colorIcon={themeCore.colors.primaryText}
      title={props.titleDrawer}
      text={props.descriptionDrawer}
      onClose={props.onCloseDrawer}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <ContainerRows>
            <StyleColumns>
              <CertificatesTitleLabel
                style={{
                  color: props.productTitleColor,
                }}
              >
                {props.labelFileName}
              </CertificatesTitleLabel>
            </StyleColumns>
            <StyleColumnsRight>
              <Controller
                name={"fileName"}
                control={props.control}
                rules={validations}
                render={({ field }) => (
                  <InputAFY
                    {...field}
                    id={"fileName"}
                    disableLine={true}
                    fullWidth
                    onBlur={(e) => {
                      const value = e.target.value;

                      if (value === "") {
                        props.setError("fileName", {
                          type: "manual",
                          message: props.errorTextFileName,
                        });
                      } else {
                        props.clearErrors("fileName");
                      }
                    }}
                    defaultValue={props.dataForm?.fileName}
                    size={sizesTextField.small}
                    variant={variantsTextField.filled}
                    helperText={
                      props.errors.fileName! === undefined
                        ? ""
                        : props.errorTextFileName
                    }
                    register={props.register}
                    error={props.errors.fileName}
                    validations={{
                      required: {
                        value: true,
                        message: props.errorTextFileName,
                      },
                    }}
                  />
                )}
              />
            </StyleColumnsRight>
          </ContainerRows>
        </Grid>
        <Grid item xs={12}>
          <ContainerRows>
            <StyleColumns>
              <CertificatesTitleLabel
                style={{
                  color: props.productTitleColor,
                }}
              >
                {props.labelTypeCertificate}
              </CertificatesTitleLabel>
            </StyleColumns>
            <StyleColumnsRight>
              <Controller
                name={"typeFile"}
                control={props.control}
                rules={validations}
                render={({ field }) => (
                  <LabelAutoCompleteAFY
                    {...field}
                    id={"typeFile"}
                    helperText={
                      props.errors.typeFile! === undefined
                        ? ""
                        : props.errorTextTypeFile
                    }
                    error={!!props.errors.typeFile}
                    labelText=""
                    size={sizesTextField.medium}
                    disableLine={true}
                    loading={false}
                    onBlur={(e) => {
                      if (e === "") {
                        props.setError("typeFile", {
                          type: "manual",
                          message: props.errorTextTypeFile,
                        });
                      } else {
                        props.clearErrors("typeFile");
                      }
                    }}
                    onSelectOption={(options) => {
                      if (Object.keys(options).length > 0) {
                        field.onChange(options);
                        props.clearErrors("typeFile");
                      } else {
                        props.setError("typeFile", {
                          type: "manual",
                          message: props.errorTextTypeFile,
                        });
                      }
                    }}
                    variantLabel={variantsTextField.filled}
                    dataList={props.TypeListDrawer}
                    key={props.dataForm?.typeFile?.id}
                    defaultValue={props.dataForm?.typeFile}
                  />
                )}
              />
            </StyleColumnsRight>
          </ContainerRows>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "15px",
            }}
          >
            <ButtonAFY
              backgroundColor={themeCore.colors.third}
              disabled={!props.isValid}
              color={themeCore.colors.primaryText}
              icon={icons.Save}
              label={props.titleSaveButton}
              onClick={handleSaveButtonClick}
              widthButton={"100px"}
              variant={variants.contained}
            />
          </div>
        </Grid>
      </Grid>
    </DrawerAFY>
  );
};
