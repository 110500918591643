import { useSelector } from "react-redux";

const useSelectors = (

) => {
const { responsePlans, errorPlans, loadingPlans } = useSelector(
    (state: any) => ({
      responsePlans: state.Plans.responsePlans,
      errorPlans: state.Plans.errorPlans,
      loadingPlans: state.Plans.loadingPlans,
    })
  );

  // const { responsePlan, errorPlan, loading } = useSelector((state: any) => ({
  //   responsePlan: state.Plan.responsePlan,
  //   errorPlan: state.Plan.errorPlan,
  //   loading: state.Plan.loadingPlan,
  // }));

  const { responsePaymentMode, errorPaymentMode, loadingPaymentMode } =
    useSelector((state: any) => ({
      responsePaymentMode: state.PaymentMode.responsePaymentMode,
      errorPaymentMode: state.PaymentMode.errorPaymentMode,
      loadingPaymentMode: state.PaymentMode.loadingPaymentMode,
    }));

  const { responseAllQuote, errorAllQuote, loadingAllQuote } = useSelector(
    (state: any) => ({
      responseAllQuote: state.Quote.responseAllQuote,
      errorAllQuote: state.Quote.errorAllQuote,
      loadingAllQuote: state.Quote.loadingAllQuote,
    })
  );

    return {
        responsePlans,
        errorPlans,
        loadingPlans,
        responsePaymentMode,
        errorPaymentMode,
        loadingPaymentMode,
        responseAllQuote,
        errorAllQuote,
        loadingAllQuote,
        
    };
}
export default useSelectors;