import { HeaderTitlePage } from "../../../components/resources/headerTitlePage/headerTitlePage";
import showToast from "../../../services/toast";

import { useTranslation } from "react-i18next";
import { AlertLoadingHeader } from "../../../components/alertLoadingComponent/alertLoading";
import React from "react";

export const PaymentHeader = (props: any) => {
  const { t } = useTranslation();

  return (
    <>
      <AlertLoadingHeader
        loading={props.loadingCreatePolicy || props.loading}
        seeToast={props.seeToast}
        showToast={showToast}
        setSeeToast={props.setSeeToast}
        titleLoading={
          props.loadingCreatePolicy
            ? t(`paymentMethod.loading.loading1`)
            : t(`paymentMethod.loading.loading2`)
        }
      />
      <HeaderTitlePage
        breadCrumbs={props.breadCrumbs}
        steps={props.steps}
        hideStep={false}
        stepsTop={"-5px"}
      />
    </>
  );
};
