import {
  CERTIFICATE_QUOTE,
  CERTIFICATE_QUOTE_CLEAR_DATA,
  CERTIFICATE_QUOTE_ERROR,
  CERTIFICATE_QUOTE_SUCCESS,
  CERTIFICATE_TEMPLATE,
  CERTIFICATE_TEMPLATE_CLEAR_DATA,
  CERTIFICATE_TEMPLATE_ERROR,
  CERTIFICATE_TEMPLATE_ONE,
  CERTIFICATE_TEMPLATE_ONE_CLEAR_DATA,
  CERTIFICATE_TEMPLATE_ONE_ERROR,
  CERTIFICATE_TEMPLATE_ONE_SUCCESS,
  CERTIFICATE_TEMPLATE_SUCCESS,
  CERTIFICATE_TO_PROJECT,
  CERTIFICATE_TO_PROJECT_CLEAR_DATA,
  CERTIFICATE_TO_PROJECT_ERROR,
  CERTIFICATE_TO_PROJECT_SUCCESS,
  CREATE_CERTIFICATE,
  CREATE_CERTIFICATE_CLEAR_DATA,
  CREATE_CERTIFICATE_ERROR,
  CREATE_CERTIFICATE_SUCCESS,
  PUT_CERT_TEMPLATE,
  PUT_CERT_TEMPLATE_CLEAR_DATA,
  PUT_CERT_TEMPLATE_ERROR,
  PUT_CERT_TEMPLATE_SUCCESS,
  SCHEMAS_TEMPLATE,
  SCHEMAS_TEMPLATE_CLEAR_DATA,
  SCHEMAS_TEMPLATE_ERROR,
  SCHEMAS_TEMPLATE_SUCCESS,
} from "./actionTypes";

export const certificateQuote = (certificateQuote, history) => {
  return {
    type: CERTIFICATE_QUOTE,
    payload: { certificateQuote, history },
  };
};

export const certificateQuoteSuccess = (success) => {
  return {
    type: CERTIFICATE_QUOTE_SUCCESS,
    payload: success,
  };
};

export const certificateQuoteError = (error) => {
  return {
    type: CERTIFICATE_QUOTE_ERROR,
    payload: error,
  };
};

export const certificateQuoteClearData = () => {
  return {
    type: CERTIFICATE_QUOTE_CLEAR_DATA,
    payload: {},
  };
};

/////

export const certificateTemplate = (certificateTemplate) => {
  return {
    type: CERTIFICATE_TEMPLATE,
    payload: { certificateTemplate },
  };
};

export const certificateTemplateSuccess = (success) => {
  return {
    type: CERTIFICATE_TEMPLATE_SUCCESS,
    payload: success,
  };
};

export const certificateTemplateError = (error) => {
  return {
    type: CERTIFICATE_TEMPLATE_ERROR,
    payload: error,
  };
};

export const certificateTemplateClearData = () => {
  return {
    type: CERTIFICATE_TEMPLATE_CLEAR_DATA,
    payload: {},
  };
};

/////

export const certificateToProject = (certificateToProject) => {
  return {
    type: CERTIFICATE_TO_PROJECT,
    payload: { certificateToProject },
  };
};

export const certificateToProjectSuccess = (success) => {
  return {
    type: CERTIFICATE_TO_PROJECT_SUCCESS,
    payload: success,
  };
};

export const certificateToProjectError = (error) => {
  return {
    type: CERTIFICATE_TO_PROJECT_ERROR,
    payload: error,
  };
};

export const certificateToProjectClearData = () => {
  return {
    type: CERTIFICATE_TO_PROJECT_CLEAR_DATA,
    payload: {},
  };
};

////

export const createCertificate = (certificateBody) => {
  return {
    type: CREATE_CERTIFICATE,
    payload: { certificateBody },
  };
};

export const createCertificateSuccess = (success) => {
  return {
    type: CREATE_CERTIFICATE_SUCCESS,
    payload: success,
  };
};

export const createCertificateError = (error) => {
  return {
    type: CREATE_CERTIFICATE_ERROR,
    payload: error,
  };
};

export const createCertificateClearData = () => {
  return {
    type: CREATE_CERTIFICATE_CLEAR_DATA,
    payload: {},
  };
};

////

export const certificateTemplateOne = (getCertificateOne) => {
  return {
    type: CERTIFICATE_TEMPLATE_ONE,
    payload: { getCertificateOne },
  };
};

export const certificateTemplateOneSuccess = (success) => {
  return {
    type: CERTIFICATE_TEMPLATE_ONE_SUCCESS,
    payload: success,
  };
};

export const certificateTemplateOneError = (error) => {
  return {
    type: CERTIFICATE_TEMPLATE_ONE_ERROR,
    payload: error,
  };
};

export const certificateTemplateOneClearData = () => {
  return {
    type: CERTIFICATE_TEMPLATE_ONE_CLEAR_DATA,
    payload: {},
  };
};

////

export const putCertTemplate = (certificateBody, id) => {
  return {
    type: PUT_CERT_TEMPLATE,
    payload: { certificateBody, id },
  };
};

export const putCertTemplateSuccess = (success) => {
  return {
    type: PUT_CERT_TEMPLATE_SUCCESS,
    payload: success,
  };
};

export const putCertTemplateError = (error) => {
  return {
    type: PUT_CERT_TEMPLATE_ERROR,
    payload: error,
  };
};

export const putCertTemplateClearData = () => {
  return {
    type: PUT_CERT_TEMPLATE_CLEAR_DATA,
    payload: {},
  };
};

////

export const allSchemasTemplate = (schemas) => {
  return {
    type: SCHEMAS_TEMPLATE,
    payload: { schemas },
  };
};

export const allSchemasTemplateSuccess = (success) => {
  return {
    type: SCHEMAS_TEMPLATE_SUCCESS,
    payload: success,
  };
};

export const allSchemasTemplateError = (error) => {
  return {
    type: SCHEMAS_TEMPLATE_ERROR,
    payload: error,
  };
};

export const allSchemasTemplateClearData = () => {
  return {
    type: SCHEMAS_TEMPLATE_CLEAR_DATA,
    payload: {},
  };
};
