import { useCallback, useMemo, useState } from "react";

interface Definition {
  id: string;
  label: string;
}

export interface Schemas {
  definitions: Definition[];
  groupId: string;
  groupLabel: string;
}

const useGetMergeFields = () => {
  const [mergeFieldsData, setMergeFieldsData] = useState<Schemas[]>([]);

  const getMergeFields = useCallback((responseData: any[]) => {
    // const limitedData = responseData.slice(0, 2);
    const mergeFieldsData = [];

    for (const item of responseData) {
      for (const [schemaName, schemaData] of Object.entries(item)) {
        const group = {
          definitions: [] as { id: string; label: string }[],
          groupId: schemaName,
          groupLabel: schemaName,
        };

        const schemaFields = schemaData as Record<string, unknown>;

        for (const [fieldName] of Object.entries(schemaFields)) {
          const cleanFieldName = fieldName.replace(/~/g, "");
          group.definitions.push({
            id: `${schemaName}.${cleanFieldName}`,
            label: `${schemaName}.${cleanFieldName}`,
          });
        }

        mergeFieldsData.push(group);
      }
      setMergeFieldsData(mergeFieldsData);
    }
  }, []);

  return useMemo(
    () => ({
      getMergeFields,
      mergeFieldsData,
    }),
    [getMergeFields, mergeFieldsData],
  );
};

export default useGetMergeFields;
