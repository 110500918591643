import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { certificateQuote } from "../../store/certificates/actions";

export const useCertificateQuote = (
  trigger: boolean,
  title: string,
  quoteId: string,
  page: number,
  limit: number,
) => {
  const [enable, setEnable] = useState<boolean>(true);
  const dispatch = useDispatch();

  const {
    responseCertificateQuote,
    errorCertificateQuote,
    loadingCertificateQuote,
  } = useSelector((state: any) => ({
    responseCertificateQuote: state.Certificates.responseCertificateQuote,
    errorCertificateQuote: state.Certificates.errorCertificateQuote,
    loadingCertificateQuote: state.Certificates.loadingCertificateQuote,
  }));

  useEffect(() => {
    if (trigger) {
      dispatch(
        certificateQuote({
          title: title,
          enable: enable,
          quoteId: quoteId,
          page: page,
          limit: limit,
        }),
      );
    }
  }, [trigger, quoteId]);

  return {
    responseCertificateQuote,
    errorCertificateQuote,
    loadingCertificateQuote,
    setEnable,
  };
};
