import React, { useEffect, useState } from "react";
// @ts-ignore
import {
  breadcrumb,
  DataFormClient,
  DataFormPayment,
  iconsSvg,
  LoadingAFY,
  MenuItem,
  position,
  select,
  typeAlert,
  typeLoaders,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { navigateNext, stepProgress } from "../../dataBase/stepProgress";

import { PaymentHeader } from "./ui/header";
import { Steps } from "../../enum/steps";
import { getCustomerAction, isApapAuto, isCustomer } from "../../fnx/customer";
import { PlanModel } from "../../model/plan";
import { Account } from "../../model/policy";
import LocalStorageService from "../../services/localStorage";
import { policyCreateClearData } from "../../store/policy/actions";
import {
  dataFilter,
  paymentSummaryStorybook,
} from "./resources/paymentSummaryDto";
import { PaymentSummaryView } from "./ui/view";
import { Customer } from "../../enum/customer";

import { useTranslation } from "react-i18next";
import { STEP_PAYMENTMETHOD } from "../../const/catalog";

import { showToastSuccessMessage } from "../../fnx/sendSuccessMessage";
import { showErrorToast } from "../../fnx/showError";
import { quoteOne } from "../../store/generateQuote/actions";
import { personByIdClearData } from "../../store/person/actions";
import { catalogAccountTypeClearData } from "../../store/plan/actions";
import { formatToTwoDecimals } from "../../utils/getPrice";
import usePaymentSummary from "./components/paymentSummary";
import useUI from "./components/ui";
import useSelectors from "./components/selectors";

const STEP = STEP_PAYMENTMETHOD;
const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);
const storageKey = "afy-steps";
const localStorageService = new LocalStorageService(storageKey);

const PaymentSummary = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [trigger, setTrigger] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [accountType, setAccountType] = useState("");
  const [accountList, setAccountList] = useState<Account[]>([]);
  const [personId, setPersonId] = useState<string>("");
  const [accountNumber, setAccountNumber] = useState("");
  const [personTrigger, setPersonTrigger] = useState(false);
  const [steps, setSteps] = React.useState<MenuItem[]>([]);
  const [subTitlePayment, setSubTitlePayment] = useState<string>("");
  const [listAccountTypes, setListAccountTypes] = useState<select[]>([]);
  const [breadCrumbs, setBreadCrumbs] = React.useState<breadcrumb[]>([]);
  const [dataFormPayment, setDataFormPayment] = useState<DataFormPayment>({});
  const [projectId, setProjectId] = React.useState<string>("");
  const [cardSelected, setCardSelected] = useState<boolean>(false);
  const [nextDisabled, setNextDisabled] = useState<boolean>(false);

  const [company] = React.useState<string>(
    lsLogin.getUserLoginStored().subdomain || "",
  );

  const [quote] = React.useState<string | undefined>(
    localStorageService.getStepStored().steps[Steps.customer].quoteId,
  );

  const [paymentListOptions, setPaymentListOptions] = useState<
    { id: string; name: string; option: number; disabled: boolean }[]
  >([]);
  const [paymentListOption, setPaymentListOption] = useState({
    id: "",
    name: "",
    option: 0,
  });
  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  const [dataForm, setDataForm] = useState<
    DataFormClient & { Insurance?: string } & { CoverType?: string } & {
      PaymentMode?: string;
    } & { Currency?: string } & { Prima?: string } & { QuoteId?: string }
  >({
    Name: "",
    LastNames: "",
    DocumentNumber: "",
    Email: "",
    Insurance: "",
    PlanTypeName: "",
    CampaignName: "",
    CoverType: "",
    PaymentMode: "",
    Currency: "",
    Prima: "",
    QuoteId: "",
  });

  const {
    responseOneQuote,
    loadingOneQuote,
    errorOneQuote,
    responsePersonById,
    loadingPersonById,
    responseCreatePolicy,
    loadingCreatePolicy,
    errorCreatePolicy,
    responseCatalogAccountType,
    errorCatalogAccountType,
  } = useSelectors(trigger, projectId, personTrigger, personId);

  const { handleChangePayment, createPolicy } = usePaymentSummary(
    dataForm,
    responseOneQuote,
    company,
    accountType,
    accountName,
    accountNumber,
    cardSelected,
    setSubTitlePayment,
    setDataFormPayment,
    setCardSelected,
    setSeeToast,
  );

  const { clickCard, handlePrevious } = useUI(
    STEP,
    navigate,
    setAccountType,
    setAccountNumber,
    setAccountName,
    setCardSelected,
  );

  useEffect(() => {
    setTrigger(true);
    setSteps(stepProgress(STEP));
    setBreadCrumbs(stepProgress(STEP, true));
    dispatch(
      quoteOne({
        id: quote,
        fullBody: true,
      }),
    );
  }, []);

  useEffect(() => {
    if (responsePersonById?.data) {
      setSubTitlePayment("");
      if (getCustomerAction().customerAuto.includes(company)) {
        //console.log("Es Apap-auto y es cliente");
        if (!responsePersonById.data.additionalContentMap?.accounts?.length) {
          //console.log("No tiene otros tipos de pago, solo es pago en caja");
          showToastSuccessMessage(
            t(`paymentMethod.accountsAlert`),
            typeAlert.info,
            setSeeToast,
          );
          if (isCustomer(Customer.FULL) || isCustomer(Customer.AUTO_FULL)) {
            setSubTitlePayment("No hay cuentas disponibles para hacer el pago");
            setPaymentListOption(paymentSummaryStorybook.paymentOptionV2);
            setPaymentListOptions(paymentSummaryStorybook.paymentOptionsV4);
          } else {
            setSubTitlePayment("No. Recibo de pago");
            setPaymentListOption(paymentSummaryStorybook.paymentOptionV3);
            setPaymentListOptions(paymentSummaryStorybook.paymentOptionsV3);
          }
        } else {
          const accountListNew =
            responsePersonById.data.additionalContentMap?.accounts.map(
              (accounts: any, idx: number) => ({
                id: idx + 1,
                cardTitles: [
                  t(`paymentMethod.cardTitles.accountType`),
                  t(`paymentMethod.cardTitles.accountNumber`),
                  t(`paymentMethod.cardTitles.accountHolder`),
                ],
                cardValues: [accounts.type, accounts.number, accounts.category],
                iconSvg: iconsSvg.canceledSales,
              }),
            );
          const filteredAccountList: Account[] = accountListNew.filter(
            (account: Account) => account.cardValues[0] === "CREDIT_CARD",
          );

          //console.log("Otros tipos de pago ",filteredAccountList);
          setAccountList(filteredAccountList);
          if (filteredAccountList.length === 0) {
            setSubTitlePayment("No hay cuentas disponibles para hacer el pago");
          }

          //Validar si es auto para mostrar las opciones de pago en
          //  if (getCustomerAction().customerAuto.includes(company)) {

          if (isCustomer(Customer.FULL) || isCustomer(Customer.AUTO_FULL)) {
            //console.log("Es cliente FULL");
            if (filteredAccountList.length === 0) {
              //console.log("No tiene cuentas disponibles");
              setSubTitlePayment(
                "No hay cuentas disponibles para hacer el pago",
              );
            } else {
              //console.log("Tiene cuentas disponibles");
              setSubTitlePayment("");
            }
            setPaymentListOption(paymentSummaryStorybook.paymentOptionV2);
            setPaymentListOptions(paymentSummaryStorybook.paymentOptionsV4);
          } else {
            setSubTitlePayment("No. Recibo de pago");
            setPaymentListOption(paymentSummaryStorybook.paymentOptionV3);
            setPaymentListOptions(paymentSummaryStorybook.paymentOptionsV2);
          }
          //}
        }
      }
      dispatch(personByIdClearData());
    }
  }, [responsePersonById]);

  useEffect(() => {
    if (errorOneQuote) {
      showErrorToast(errorOneQuote, setSeeToast, t);
    }
  }, [errorOneQuote]);

  useEffect(() => {
    if (errorCreatePolicy) {
      showErrorToast(errorCreatePolicy, setSeeToast, t);
      setNextDisabled(true);
    }
  }, [errorCreatePolicy]);

  useEffect(() => {
    if (errorCatalogAccountType) {
      showErrorToast(errorCatalogAccountType, setSeeToast, t);
    }
  }, [errorCatalogAccountType]);

  useEffect(() => {
    if (!getCustomerAction().customerAuto.includes(company)) {
      /* console.log(
         "No es apap-auto, se obtienen los tipos de cuenta desde el responseCatalogAccountType"
       ); */
      if (
        responseCatalogAccountType &&
        responseCatalogAccountType.data?.length > 0
      ) {
        const data: select[] = responseCatalogAccountType.data?.map(
          (account: PlanModel) => {
            return {
              id: account.catalogAccountTypeId,
              value: account.catalogAccountTypeId,
              name: account.catalogAccountType?.description,
            };
          },
        );
        setListAccountTypes(data);
        dispatch(catalogAccountTypeClearData());
        setPaymentListOption(paymentSummaryStorybook.paymentOptionV3);
        setPaymentListOptions(paymentSummaryStorybook.paymentOptionsV2);
      } else if (
        responseCatalogAccountType &&
        responseCatalogAccountType.data?.length === 0
      ) {
        setPaymentListOptions(paymentSummaryStorybook.paymentOptionsV3);
        setPaymentListOption(paymentSummaryStorybook.paymentOptionV3);
        /* if (getCustomerAction().customerAuto.includes(company)) {
        setSubTitlePayment("No. Recibo de pago");
        setPaymentListOptions(paymentSummaryStorybook.paymentOptionsV2);
      }*/
      }
    }
  }, [responseCatalogAccountType]);

  useEffect(() => {
    if (responseOneQuote) {
      setDataForm({
        Name: `${responseOneQuote?.firstNameA} ${responseOneQuote?.firstNameB}`,
        LastNames: `${responseOneQuote?.lastNameA} ${responseOneQuote?.lastNameB}`,
        DocumentNumber: responseOneQuote?.numberId,
        Email: responseOneQuote?.email,
        Insurance: responseOneQuote?.productId?.description,
        PlanTypeName: responseOneQuote?.planId?.name,
        CampaignName: responseOneQuote.campaignId?.name,
        CoverType: responseOneQuote?.coverType?.description,
        PaymentMode: getCustomerAction().customerAuto.includes(
          lsLogin.getUserLoginStored().subdomain || "",
        )
          ? responseOneQuote?.catalogPaymentMode?.description
          : responseOneQuote?.catalogPaymentMode?.description,
        Currency: getCustomerAction().customerAuto.includes(
          lsLogin.getUserLoginStored().subdomain || "",
        )
          ? responseOneQuote?.lastPrice.currencySymbol
          : responseOneQuote?.planId.price.currencySymbol,
        Prima: getCustomerAction().customerAuto.includes(
          lsLogin.getUserLoginStored().subdomain || "",
        )
          ? responseOneQuote?.lastPrice.amount
            ? formatToTwoDecimals(responseOneQuote?.lastPrice.amount)
            : "0.00"
          : responseOneQuote?.planId.price.amount,
        QuoteId: responseOneQuote.id,
      });
      setPersonId(responseOneQuote.insured.personId);
      setPersonTrigger(true);
      setProjectId(responseOneQuote?.planId.projectId);
    }
  }, [responseOneQuote]);

  useEffect(() => {
    if (responseCreatePolicy && responseCreatePolicy.data) {
      localStorageService.setStepStored({
        id: responseCreatePolicy.data.id,
        name: "Pay form",
        step: Steps.pay,
        completed: true,
        quoteId: "",
        campaign: "",
        policyId: responseCreatePolicy.data.id,
        email: dataForm.Email,
      });
      setNextDisabled(false);
      dispatch(policyCreateClearData());
      //navigate("/checkout/payment-confirmation/");
      navigateNext(STEP, navigate);
      dispatch(policyCreateClearData());
    }
  }, [responseCreatePolicy]);

  return (
    <React.Fragment>
      <PaymentHeader
        loading={loadingOneQuote || loadingPersonById}
        loadingCreatePolicy={loadingCreatePolicy}
        breadCrumbs={breadCrumbs}
        steps={steps}
        seeToast={seeToast}
        setSeeToast={setSeeToast}
      />
      <PaymentSummaryView
        breadCrumbs={breadCrumbs}
        steps={steps}
        seeToast={seeToast}
        setSeeToast={setSeeToast}
        accountName={`${dataForm.Name} ${dataForm.LastNames}`}
        nextLabel={paymentSummaryStorybook.nextLabel}
        title={paymentSummaryStorybook.title}
        options={listAccountTypes}
        paymentOption={paymentListOption}
        paymentOptions={paymentListOptions}
        hideSubTitlePayment={true}
        subTitlePayment={subTitlePayment}
        previousLabel={paymentSummaryStorybook.previousLabel}
        stepProgress={paymentSummaryStorybook.stepProgress}
        onBack={handlePrevious}
        filters={dataFilter(dataForm)}
        dataForm={dataForm}
        onNext={createPolicy}
        onChangePayment={handleChangePayment}
        accountList={accountList}
        onClickCard={clickCard}
        voucherMaxLength={isApapAuto(company) ? 12 : 20}
        voucherMinLength={isApapAuto(company) ? 12 : 1}
        voucherIsAlphaNumeric={isApapAuto(company) ? true : false}
        nextDisabled={nextDisabled}
        loading={loadingOneQuote || loadingPersonById}
        loadingCreatePolicy={loadingCreatePolicy}
      />
    </React.Fragment>
  );
};

export default PaymentSummary;
