

import { useDispatch, useSelector } from "react-redux";
import { person, personByDocumentClearData, personByIdClearData } from "../../../store/person/actions";
const useUI = (navigate: any) => {
    const dispatch = useDispatch();

    const handleClickSeeDetails = (data: any) => {
        dispatch(personByDocumentClearData());
        dispatch(personByIdClearData());
        navigate(`/client-record/${data.documentId}`);
    }

    return {
        handleClickSeeDetails
    }
}

export default useUI;
