import { FormConfirmationAFY } from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { PDFViewer } from "../../../components/pdfViewer/PDFViewer";
import { ModalEditVehicle } from "../resources/modalEditVehicle";
import { ModalConfirmation } from "../resources/modalConfirmation";
import { ModalInsurance } from "../resources/modalInsurance";
import { ModalBeneficiary } from "../resources/modalBeneficiary";

export const ConfirmationFormView = (props: any) => {
  return (
    <>
      <PDFViewer
        openModal={props.openModal}
        setOpenModal={props.setOpenModal}
        documentName={props.nameDocument}
        urlDocument={props.urlDocument}
      />
      {/*//documentos cliente*/}
      <PDFViewer
        openModal={props.openModalClient}
        setOpenModal={props.setOpenModalClient}
        documentName={props.documentNameClient}
        urlDocument={props.urlDocumentClient}
      />
      <ModalEditVehicle
        openModalEditVehicle={props.openModalEditVehicle}
        setOpenModalEditVehicle={props.setOpenModalEditVehicle}
        dataFormVehicle={props.dataFormVehicle}
        callQuoteOneApi={props.callQuoteOneApi}
        quoteId={props.quoteId}
        planId={props.planId}
        coverTypeId={props.coverTypeId}
        campaignId={props.campaignId}
        catalogPaymentMode={props.catalogPaymentMode}
        catalogStatusQuote={props.catalogStatusQuote}
        branchId={props.branchId}
        userId={props.userId}
        idQuoteVehicle={props.idQuoteVehicle}
        productId={props.productId}
        t={props.t}
        planName={props.planName}
        productType={props.productType}
        projectTypeLocal={props.projectTypeLocal}
        salesFlow={props.salesFlow}
      />
      <ModalBeneficiary
        openModalBeneficiary={props.openModalBeneficiary}
        setOpenModalBeneficiary={props.setOpenModalBeneficiary}
        isMaskedDocument={props.isMaskedDocument}
        dataFormBeneficiary={props.dataFormBeneficiary}
        titleBeneficiary={props.titleBeneficiary}
        beneficiaryType={props.beneficiaryType}
        listIdentificationType={props.listIdentificationType}
        initialPercentage={props.initialPercentage}
        handleSubmitBeneficiary={props.handleSubmitBeneficiary}
        searchPerson={props.searchPerson}
        t={props.t}
      />

      <ModalInsurance
        openModal={props.openModalInsured}
        setOpenModal={props.setOpenModalInsured}
        isMaskedDocument={props.isMaskedDocument}
        dataFormInsurance={props.dataFormInsurance}
        titleInsured={props.titleInsured}
        insuredType={props.insuredType}
        listIdentificationType={props.listIdentificationType}
        handleSubmitInsured={props.handleSubmitInsured}
        searchInsurancePerson={props.searchInsurancePerson}
        t={props.t}
      />

      <ModalConfirmation
        openModalBackConfirm={props.openModalBackConfirm}
        handleCloseBackModal={props.handleCloseBackModal}
        handleBackFromModal={props.handleBackFromModal}
        classes={props.classes}
        t={props.t}
      />

      <FormConfirmationAFY
        formCompleted={props.isQuote === "false" || props.isPlan === "false"}
        isRequired={props.isRequired}
        tabAdditionalDocumentation={props.tabAdditionalDocumentation}
        countBadgeContent={props.countBadgeContent}
        customBadgeColor={props.customBadgeColor}
        badgeColorText={props.badgeColorText}
        additionalDocumentationColumns={props.additionalDocumentationColumns}
        additionalDocumentationRows={props.additionalDocumentationRows}
        hideMarriedName={props.hideMarriedName}
        titleDrawer={props.titleDrawer}
        descriptionDrawer={props.descriptionDrawer}
        titleUpload={props.titleUpload}
        heightDrawer={props.heightDrawer}
        disableSelectCountry={props.disableSelectCountry}
        openDrawerUpload={props.openDrawerUpload}
        onCloseDrawer={props.onCloseDrawer}
        onUpload={props.onUpload}
        tabAuto={props.tabAuto}
        tabAutoDescription={props.tabAutoDescription}
        btnLblDownload={props.btnLblDownload}
        tabStatement={props.tabStatement}
        tabStatementColumns={props.tabStatementColumns}
        tabStatementRows={props.tabStatementRows}
        showButtonDownloadApap={props.showButtonDownloadApap}
        TabAutoColumns={props.TabAutoColumns}
        TabAutoRows={props.TabAutoRows}
        onChangeSelect={props.onChangeSelect}
        onClickAutoDownload={props.onClickAutoDownload}
        onClickAutoDownloadVida={props.onClickAutoDownloadVida}
        labelNumberQuote={props.labelNumberQuote}
        title={props.title}
        identificationList={props.identificationList}
        GenderList={props.GenderList}
        CountryList={props.CountryList}
        ProvinceList={props.ProvinceList}
        TownList={props.TownList}
        SectorList={props.SectorList}
        dataForm={props.dataForm}
        hideTabs={props.hideTabs}
        hideAddButton={props.hideAddButton}
        showAdd={props.showAdd}
        showActions={props.showActions}
        showDownloads={props.showDownloads}
        onClickDownload={props.onClickDownload}
        certificates={props.certificates}
        dateLabel={props.dateLabel}
        downloadsTitle={props.downloadsTitle}
        labelAdd={props.labelAdd}
        tab1={props.tab1}
        tab1Description={props.tab1Description}
        tab2={props.tab2}
        tab2Description={props.tab2Description}
        tab3={props.tab3}
        tab3Description={props.tab3Description}
        Tab1Columns={props.Tab1Columns}
        Tab1Rows={props.Tab1Rows}
        Tab2Columns={props.Tab2Columns}
        Tab2Rows={props.Tab2Rows}
        Tab3Columns={props.Tab3Columns}
        Tab3Rows={props.Tab3Rows}
        hide2={props.hide2}
        options1={props.options1}
        options2={props.options2}
        options3={props.options3}
        firstSubtitle={props.firstSubtitle}
        secondSubtitle={props.secondSubtitle}
        thirdSubtitle={props.thirdSubtitle}
        fourthSubtitle={props.fourthSubtitle}
        plan={props.plan}
        plans={props.plans}
        paymentMethodData={props.paymentMethodData}
        campaignData={props.campaignData}
        showPaymentMethod={props.showPaymentMethod}
        showCampaign={props.showCampaign}
        onClickAdd={props.onClickAdd}
        onClickRowGrid={props.onClickRowGrid}
        onClickAddBeneficiary={props.onClickAddBeneficiary}
        filters={props.filters}
        titleSelectButton={props.titleSelectButton}
        previousLabel={props.previousLabel}
        nextLabel={props.nextLabel}
        showPrevious={props.showPrevious}
        nextDisabled={props.nextDisabled}
        onBack={props.onBack}
        onClickSave={props.onClickSave}
        onNext={props.onNext}
        onClickPrevious={props.onClickPrevious}
        onBlurInput={props.onBlurInput}
        onChangeInput={props.onChangeInput}
        onClickCancel={props.onClickCancel}
        disableDocumentType={props.disableDocumentType}
        disableDocumentNumber={props.disableDocumentNumber}
        onChangePaymentMethod={props.onChangePaymentMethod}
        onChangeCampaign={props.onChangeCampaign}
        onChangeCoverType={props.onChangeCoverType}
        onClickAcquire={props.onClickAcquire}
        btnLblDownloadVida={props.btnLblDownloadVida}
        showButtonDownloadVida={props.showButtonDownloadVida}
        disabledDownloadVida={props.disabledDownloadVida}
      />
    </>
  );
};
