import { StatusAuditDocument } from "../../../../enum/statusAuditDocument";
import { useDispatch, useSelector } from "react-redux";
import {
    quoteOneClearData,
    updateStatusAuditDoc,
    updateStatusAuditDocClearData,
  } from "../../../../store/generateQuote/actions";

  const useAuditPage = (
    setFileName: any,
    setPdfFile: any,
    setUploadTrigger: any,
    idQuote: any,
    idDocument: any,
    setOpenDrawerRejed: any
) => {
      const dispatch = useDispatch();

const getCombustionLabel = (combustionType: any) => {
    switch (combustionType) {
      case "ELECTRIC":
        return "Eléctrico";
      case "GASOLINE":
        return "Gasolina";
      case "DIESEL":
        return "Diésel";
      case "GAS":
        return "Gas";
      default:
        return combustionType;
    }
  };
  const getStateFromStatus = (status: string): string => {
    switch (status) {
      case "PENDING":
        return "Pendiente";
      case "REJECTED":
        return "Rechazado";
      default:
        return "Aprobado";
    }
  };

  const areRowsEqual = (rows1: any[], rows2: any[]): boolean => {
    return JSON.stringify(rows1) === JSON.stringify(rows2);
  };

  const quoteUploadAttachments = (file: File[]) => {
    if (file) {
      setFileName(file[0].name.replace(".pdf", ""));
      setPdfFile(file[0]);
      setUploadTrigger(true);
    }
  };
  const updateStatusAuditDocument = (
    status: StatusAuditDocument,
    comment?: string
  ) => {
    const body = {
      comment: comment,
    };
    dispatch(
      updateStatusAuditDoc({
        quoteId: idQuote,
        documentId: idDocument,
        status: status,
        body: body,
      })
    );
  };

  const applyValue = (data: any) => {
    updateStatusAuditDocument(
      StatusAuditDocument.REJECTED,
      data.descriptionValue.value
    );
    setOpenDrawerRejed(false);
  };

    return {
        getCombustionLabel,
        getStateFromStatus,
        areRowsEqual,
        quoteUploadAttachments,
        updateStatusAuditDocument,
        applyValue,
    }
}

export default useAuditPage;