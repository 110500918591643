import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { View } from "./view";
import LocalStorageService from "../../services/localStorage";

import {
  CardGeneralCatalogProps,
  icons,
  MenuItem,
  sizesIcon,
  typeAlert,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { hasPermission } from "../../services/getNavigationPages";

import showToast from "../../services/toast";
import { showErrorToast } from "../../fnx/showError";
import { useGeneralCatalogGetAll } from "../../fnx/generalCatalog/generalCatalogGetAll";
import { Catalogue } from "../../model/catalogue";
import {
  optionSelect,
  optionsSelectClient,
  selectValueDefault,
} from "./generalCatalogDto";
import { SelectChangeEvent } from "@mui/material";
import { GeneralCatalogRequest } from "../../model/catalog";
import { useGeneralCatalogPost } from "../../fnx/generalCatalog/generalCatalogPost";
import { useGeneralCatalogPatch } from "../../fnx/generalCatalog/generalCatalogPatch";
import { useTranslation } from "react-i18next";
import { AlertLoadingHeader } from "../../components/alertLoadingComponent/alertLoading";

const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);

export const GeneralCatalogAdmin = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [listGeneralCatalog, setListGeneralCatalog] = useState<
    CardGeneralCatalogProps[]
  >([]);
  const [trigger, setTrigger] = useState(false);
  const [triggerPost, setTriggerPost] = useState(false);
  const [triggerPatch, setTriggerPatch] = useState(false);
  const [idGeneralCatalog, setIdGeneralCatalog] = useState("");
  const [valueType, setValueType] = useState();
  const [valueCode, setValueCode] = useState();
  const [valueDescription, setValueDescription] = useState();
  const [pageCatalog, setPageCatalog] = useState<number>(1);
  const [limitCatalog, setLimitCatalog] = useState<number>(20);
  const [countPagination, setCountPagination] = useState<number>(0);

  const [bodyRequest, setBodyRequest] = useState<GeneralCatalogRequest>({
    data: {
      clientId: "",
    },
  });
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalConfirm, setOpenModalConfirm] = useState<boolean>(false);
  const [openModalCloneConfirm, setOpenModalCloneConfirm] =
    useState<boolean>(false);
  const [clientId] = React.useState<string>(
    lsLogin.getUserLoginStored().clientId,
  );

  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [itemModal, setitemModal] = useState<any>(null);

  /*Para los campos del formulario*/
  const [dataForm, setDataForm] = useState<any>({
    Id: "",
    Type: "",
    Code: "",
    Description: "",
  });

  const [seeToast, setSeeToast] = useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  const permissions = {
    create: hasPermission("admin-catalog-create"),
    edit: hasPermission("admin-catalog-edit"),
    delete: hasPermission("admin-catalog-delete"),
  };
  /*Llamada al getAll para recuperar todos los registros*/
  const { responseGeneralCatalog, errorGeneralCatalog, loadingGeneralCatalog } =
    useGeneralCatalogGetAll(
      trigger,
      clientId,
      valueType,
      valueCode,
      valueDescription,
      pageCatalog,
      limitCatalog,
    );

  /*Llamada al POST */
  const {
    responseGeneralCatalogPost,
    errorGeneralCatalogPost,
    loadingGeneralCatalogPost,
  } = useGeneralCatalogPost(triggerPost, bodyRequest);

  /*Llamada al PATCH*/
  const {
    responseGeneralCatalogPatch,
    errorGeneralCatalogPatch,
    loadingGeneralCatalogPatch,
  } = useGeneralCatalogPatch(triggerPatch, bodyRequest, idGeneralCatalog);

  // const { responseDeleteEntity, loadingDeleteEntity, errorDeleteEntity } =
  //   useSelector((state: any) => ({
  //     responseDeleteEntity: state.DeleteEntity.responseDeleteEntity,
  //     loadingDeleteEntity: state.DeleteEntity.loadingDeleteEntity,
  //     errorDeleteEntity: state.DeleteEntity.errorDeleteEntity,
  //   }));

  /*Call Trigger para GetAll*/
  const callGeneralCatalog = () => {
    setTrigger(!trigger);
  };

  useEffect(() => {
    callGeneralCatalog();
  }, []);

  /*Call Trigger para post*/
  const callGeneralCatalogPost = () => {
    setTriggerPost(!triggerPost);
  };

  /*Call Trigger para Patch*/
  const callGeneralCatalogPatch = () => {
    setTriggerPatch(!triggerPatch);
  };

  /*Valida respuesta del getAll y mapea objetos*/
  useEffect(() => {
    if (responseGeneralCatalog && responseGeneralCatalog.data?.length > 0) {
      const listGeneralCatalog: CardGeneralCatalogProps[] =
        responseGeneralCatalog?.data.map((item: Catalogue) => ({
          id: item.id,
          type: item.type,
          code: item.code,
          description: item.description,
          clientId: item.clientId,
          fontSizeType: 11,
          fontSizeDescription: 12,
          codeLabel: "Codigo: ",
          typeLabel: "Tipo: ",
        }));
      setListGeneralCatalog(listGeneralCatalog);
      setTrigger(false);
    } else if (
      responseGeneralCatalog &&
      responseGeneralCatalog.data?.length == 0
    ) {
      setTrigger(false);
      setListGeneralCatalog([]);
    }
    if (responseGeneralCatalog?.pagingInfo) {
      setCountPagination(responseGeneralCatalog?.pagingInfo.totalItems);
    }
  }, [responseGeneralCatalog]);

  /*Valida respuesta cuando ya se inserto el registro*/
  useEffect(() => {
    if (responseGeneralCatalogPost) {
      setSeeToast({
        show: true,
        message: "Creado con éxito",
        type: typeAlert.success,
      });

      setOpenModal(false);
      setTriggerPost(false);
      callGeneralCatalog();
    }
  }, [responseGeneralCatalogPost]);

  /*Valida respuesta cuando ya se actualizo el registro*/
  useEffect(() => {
    if (responseGeneralCatalogPatch) {
      setSeeToast({
        show: true,
        message: "Registro actualizado con éxito",
        type: typeAlert.success,
      });

      setOpenModal(false);
      setTriggerPatch(false);
      callGeneralCatalog();
    }
  }, [responseGeneralCatalogPatch]);

  /*Muestra si tubo errores el GET ALL de Catalogo General*/
  useEffect(() => {
    if (errorGeneralCatalog) {
      showErrorToast(errorGeneralCatalog, setSeeToast, t);
    }
  }, [errorGeneralCatalog]);

  /*Muestra si tubo errores el POST de Catalogos Generales*/
  useEffect(() => {
    if (errorGeneralCatalogPost) {
      showErrorToast(errorGeneralCatalogPost, setSeeToast, t);
    }
  }, [errorGeneralCatalogPost]);

  /*Muestra si tubo errores el Patch de Catalogos Generales*/
  useEffect(() => {
    if (errorGeneralCatalogPatch) {
      showErrorToast(errorGeneralCatalogPatch, setSeeToast, t);
    }
  }, [errorGeneralCatalogPatch]);

  const menuItem: MenuItem[] = [
    {
      disabled: hasPermission("admin-catalog-edit") ? false : true,
      icon: {
        color: "#006ac6",
        icon: icons.Edit,
        size: sizesIcon.standard,
      },
      id: "1",
      name: "Editar",
    },
    {
      icon: {
        color: "#006ac6",
        icon: icons.Delete,
        size: sizesIcon.standard,
      },
      disabled: hasPermission("admin-catalog-delete") ? false : true,
      id: "2",
      name: "Eliminar",
    },
    // {
    //   icon: {
    //     color: "#006ac6",
    //     icon: icons.FileCopy,
    //     size: sizesIcon.standard,
    //   },
    //   disabled: false,
    //   id: "3",
    //   name: "Clonar",
    // },
  ];
  // useEffect(() => {
  //   if (responseDeleteEntity) {
  //     setSeeToast({
  //       show: true,
  //       message: "Eliminado con éxito",
  //       type: typeAlert.success,
  //     });
  //     setTimeout(() => {
  //       setSeeToast({
  //         show: false,
  //         message: "",
  //         type: typeAlert.success,
  //       });
  //     }, 3000);
  //     setOpenModal(false);
  //     dispatch(deleteEntityClearData());
  //     setTrigger(true);
  //   } else if (errorDeleteEntity) {
  //     setSeeToast({
  //       show: true,
  //       message: errorDeleteEntity?.message.message,
  //       type: typeAlert.error,
  //     });
  //     setTimeout(() => {
  //       setSeeToast({
  //         show: false,
  //         message: "",
  //         type: typeAlert.error,
  //       });
  //     }, 3000);
  //   }
  // }, [responseDeleteEntity, errorDeleteEntity]);

  const showEdit = (row: any) => {
    setDataForm({
      ...dataForm,
      Id: row.id,
      Type: row.type,
      Code: row.code,
      Description: row.description,
      clientId: clientId,
    });
  };

  /*Submit para actualizar o agregar un registro*/
  const handleSubmitGeneralCatalog = (dataProject: any) => {
    if (isUpdate) {
      /*Se llena la informacion al momento de acutalizar*/
      /*Se crea objeto en base al body del api*/
      setBodyRequest({
        data: {
          clientId: clientId,
          type: dataProject.Type,
          code: dataProject.Code,
          description: dataProject.Description,
        },
      });
      // Se asigna el id del ProjectType para a actualizar
      setIdGeneralCatalog(dataForm.Id);

      /*Llamda al FNX para actualizar PATCH*/
      callGeneralCatalogPatch();
    } else {
      /*Se procede a crear un nuevo Registro*/
      /*Se crea objeto en base al body del api*/
      setBodyRequest({
        data: {
          clientId: clientId,
          type: dataProject.Type,
          code: dataProject.Code,
          description: dataProject.Description,
        },
      });
      /*Llamada al FNX para crear Registro*/
      callGeneralCatalogPost();
    }
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleOpenModalConfirm = () => {
    setOpenModalConfirm(true);
  };

  const handleOpenModalCloneConfirm = () => {
    setOpenModalCloneConfirm(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const onClickClearData = (Data: any) => {
    // Valida clean filtro y limpia los filtros
    if (Data.filter.type_filter === "empty") {
      setValueDescription(undefined);
      setValueCode(undefined);
      setValueType(undefined);
    }
    // Llama a la consulta general sin filtros
    callGeneralCatalog();
  };
  const onClickApplyFilter = (Data: any) => {
    if (Data.filter.type_filter === "type") {
      setValueType(Data.filter.filter_value);
      setValueCode(undefined);
      setValueDescription(undefined);
    } else if (Data.filter.type_filter === "code") {
      setValueCode(Data.filter.filter_value);
      setValueDescription(undefined);
      setValueType(undefined);
    } else if (Data.filter.type_filter === "description") {
      setValueDescription(Data.filter.filter_value);
      setValueCode(undefined);
      setValueType(undefined);
    }
    callGeneralCatalog();
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    console.log("Event", event);
  };
  const handleOnClickAdd = () => {
    setIsUpdate(false);
    setOpenModal(true);
    setDataForm({
      ...dataForm,
      Id: "",
      Type: "",
      Code: "",
      Description: "",
    });
  };
  const onClickContextsMenu = (
    opt: MenuItem,
    card: CardGeneralCatalogProps,
  ) => {
    if (opt.id === "1") {
      // Opcion Editar
      setIsUpdate(true);
      setOpenModal(true);
      showEdit(card);
    } else if (opt.id === "2") {
      // Opcion eliminar
      // setitemModal(item);
      // setOpenModalConfirm(true);
      setSeeToast({
        show: true,
        message: "Operación no permitida!",
        type: typeAlert.warning,
      });

      setOpenModal(false);
    }
    // else if (opt.id === "3") {
    //   setitemModal(item);
    //   setOpenModalCloneConfirm(true);
    // }
  };

  const clickView = (view: MenuItem) => {
    setitemModal(view);
  };

  const handleCloseModalConfirm = () => {
    setOpenModal(false);
    setOpenModalConfirm(false);
  };

  const handleCloseCloneModal = () => {
    setOpenModal(false);
    setOpenModalCloneConfirm(false);
  };

  /*Api que se ejecuta cuando el catalogo tiene la opcion
   * para eliminar registros*/
  const handleDelete = () => {
    setSeeToast({
      show: true,
      message: "Operación no permitida!",
      type: typeAlert.warning,
    });

    setOpenModal(false);
  };

  const handleClone = () => {};

  const onChangeRowsPerPage = (e: number) => {
    setLimitCatalog(e);
    setTrigger(true);
  };

  const onChangePage = (e: number) => {
    setPageCatalog(e);
    setTrigger(true);
  };

  return (
    <React.Fragment>
      <AlertLoadingHeader
        loading={loadingGeneralCatalog}
        seeToast={seeToast}
        showToast={showToast}
        setSeeToast={setSeeToast}
        titleLoading={"Cargando sponsor..."}
      />
      <View
        title="Catalogos Generales"
        permissions={permissions}
        generalCatalogTitle={
          isUpdate ? "Modificar Catalogo General" : "Agregar Catalogo General"
        }
        list={listGeneralCatalog}
        openModal={openModal}
        openModalConfirm={openModalConfirm}
        openModalCloneConfirm={openModalCloneConfirm}
        loadingPost={null}
        dataForm={dataForm}
        modalTitle={itemModal?.title}
        clickView={clickView}
        onSubmit={handleDelete}
        onSubmitClone={handleClone}
        onClose={handleCloseModal}
        onCloseConfirm={handleCloseModalConfirm}
        onCloseCloneModal={handleCloseCloneModal}
        handleOpenModal={handleOpenModal}
        handleOpenModalConfirm={handleOpenModalConfirm}
        handleOpenModalCloneConfirm={handleOpenModalCloneConfirm}
        onFilterApply={onClickApplyFilter}
        onSaveData={handleSubmitGeneralCatalog}
        onClickAdd={handleOnClickAdd}
        menuItem={menuItem}
        onClickContextMenu={onClickContextsMenu}
        onClickCancel={handleCloseModal}
        optionsSelect={optionSelect}
        onChangeSelect={handleSelectChange}
        onFilterEmpty={onClickClearData}
        selectValueDefault={selectValueDefault}
        inputHeigth={"20.3px"}
        clientFilterLbl={"Cliente"}
        optionsSelectClient={optionsSelectClient}
        countPagination={countPagination}
        limitCatalog={limitCatalog}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        pageCatalog={pageCatalog}
      />
    </React.Fragment>
  );
};

export default GeneralCatalogAdmin;
