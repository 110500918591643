import {
    navigateBack,
  } from "../../../dataBase/stepProgress";
  
  const useUI = (
    STEP: any,
    navigate: any,
    setAccountType: any,
    setAccountNumber: any,
    setAccountName: any,
    setCardSelected: any
) => {
  const clickCard = (item: any) => {
    setAccountType(item.cardValues[0]);
    setAccountNumber(item.cardValues[1]);
    setAccountName(item.cardValues[2]);

    setCardSelected(true);
  };

  const handlePrevious = () => {
    navigateBack(STEP, navigate);
  };

return {
    clickCard,
    handlePrevious,
  };
}
export default useUI;
