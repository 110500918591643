import React, { useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";

import {
    select,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";

import { 
    benefit, 
    getBeneficiary, 
    getBeneficiryClearData, 
    patchBeneficiary, 
} from "../../../store/beneficiary/actions";
import { insured } from "../../../store/insured/actions";

import {
    TableBeneInsured,
} from "../../../model/beneficiary";
import {
    newFormattedDate,
    newFormattedDateYYMMDD,
} from "../../../utils/date";
import {
    removeNonNumeric,
} from "../../../services/functions";

const useBeneficiary = (
    setIsUpdate: any,
    setInitialPercentage: any,
    setTitleBeneficiary: any,
    setOpenModalBeneficiary: any,
    dataFormBeneficiary: any,
    setDataFormBeneficiary: any,
    initialPercentage: any,
    responseOneQuote: any,
    clientId: any,
    isUpdate: any,
    setIsMaskedDocument: any,
    setPersonId: any,
    listIdentificationType: any,
) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    // Memoize the translation function for performance, although typically not necessary unless `t` depends on props or state
    const tMemo = useMemo(() => t, [t]);

    // Use useCallback for functions that might be passed down as props to child components
    const setBeneficiaryStorybook = useCallback((responseBeneficiary: TableBeneInsured[]) => {
        const beneficiaryRows = responseBeneficiary?.map((b: TableBeneInsured) => ({
            id: b.id,
            type: b.catalogBeneficiaryTypeInfo?.description ?? "",
            percentage: `${b.percentage} %`,
            names: `${b.personInfo?.firstNameA} ${b.personInfo?.firstNameB}`,
            lastNames: `${b.personInfo?.lastNameA} ${b.personInfo?.lastNameB}`,
            marriedName: b.personInfo?.marriedName ?? "",
            phone1: b.personInfo?.phone1 ?? "",
            dob: newFormattedDate(b.personInfo?.dob) ?? "",
            documentNumber: b.personInfo?.numberId ?? "",
            idType: b.personInfo?.identificationTypeId ?? "",
            idTypeInsured: b.catalogBeneficiaryTypeInfo?.id ?? "",
        }));
        dispatch(getBeneficiryClearData());
        return beneficiaryRows;
    }, [dispatch]);

    const handleAddBeneficiary = () => {
        setIsUpdate(false);
        setInitialPercentage(45);
        setTitleBeneficiary(t("confirmationData.titleBeneficiary"));
        setOpenModalBeneficiary(true);
        setDataFormBeneficiary({
          ...dataFormBeneficiary,
          Id: "",
          DocumentNumber: "",
          Name: "",
          LastNameA: "",
          LastNameB: "",
          marriedName: "",
          Birthday: "",
          PhoneNumber: "",
          IdTypeName: "",
        });
      };

    const handleSubmitBeneficiary = useCallback((
        data: any, 
       
    ) => {
        let openModalBeneficiary = false;
        let body = {
            percentage: data.Percentage ? data.Percentage.toString() : initialPercentage.toString(),
            quoteId: responseOneQuote.data?.id,
            catalogBeneficiaryType: data.idTypeInsured,
            person: {
                identificationTypeId: data.IdType,
                firstNameA: data.Name,
                firstNameB: data.SecondName,
                numberId: data.DocumentNumber?.replace(/-/g, ""),
                lastNameA: data.LastNameA,
                lastNameB: data.LastNameB,
                marriedName: data.marriedName,
                phone1: removeNonNumeric(data.PhoneNumber),
                dob: data.Birthday,
                clientId: clientId,
            },
        };
        if (isUpdate) {
            dispatch(patchBeneficiary({ body, id: data.Id }));
            getInsuredBeneficiary();
            openModalBeneficiary = false;
            setOpenModalBeneficiary(false);
        } else {
            dispatch(benefit(body));
        }
        return openModalBeneficiary;
    }, [dispatch]);

    const getInsuredBeneficiary = useCallback(() => {
        if (responseOneQuote && responseOneQuote.data) {
            let _id = responseOneQuote.data?.id;
            if (_id !== undefined) {
                dispatch(
                    getBeneficiary({
                        enable: true,
                        page: 1,
                        limit: 100,
                        quoteId: _id,
                    }),
                );
                dispatch(
                    insured({
                        enable: true,
                        page: 1,
                        limit: 100,
                        quoteId: _id,
                    }),
                );
            }
        }
    }, [dispatch]);

    const editBeneficiary = ((
        row: any,
        
    ) => {
        setIsMaskedDocument(false);
        setIsUpdate(true);
        setPersonId(row.row.id);
        setTitleBeneficiary(tMemo("confirmation.editBeneficiary"));
        setOpenModalBeneficiary(true);
        setInitialPercentage(parseFloat(row.row.percentage.replace("%", "")));
        const names = row.row.names.split(" ");
        const firstNameA = names[0] || "";
        const firstNameB = names[1] || "";
        const lastNames = row.row.lastNames.split(" ");
        const lastNameA = lastNames[0] || "";
        const lastNameB = lastNames[1] || "";

        listIdentificationType.forEach((item: select) => {
            if (item.name === "Pasaporte" && item.id === row.row.idType) {
                setIsMaskedDocument(true);
            }
        });

        setDataFormBeneficiary({
            ...dataFormBeneficiary,
            Id: row.row.id,
            IdType: row.row.idType,
            DocumentNumber: row.row.documentNumber,
            Name: firstNameA,
            SecondName: firstNameB,
            LastNameA: lastNameA,
            LastNameB: lastNameB,
            marriedName: row.row.marriedName,
            Birthday: newFormattedDateYYMMDD(row.row.dob),
            PhoneNumber: row.row.phone1,
            idTypeInsured: row.row.idTypeInsured,
            Percentage: initialPercentage,
        });
    });

    return useMemo(() => ({
        setBeneficiaryStorybook,
        handleAddBeneficiary,
        handleSubmitBeneficiary,
        getInsuredBeneficiary,
        editBeneficiary
    }), [
        setBeneficiaryStorybook,
        handleAddBeneficiary,
        handleSubmitBeneficiary,
        getInsuredBeneficiary,
        editBeneficiary
    ]);
};

export default useBeneficiary;