import React, { useEffect, useState } from "react";
import View from "./ui/view";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import {
  typeAlert,
  icons,
  sizesIcon,
  ContextMenuAFY,
  IconAFY,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { SponsorModel } from "../../model/sponsor";
import { CobranzaHeader } from "./ui/header";
import { themeCore } from "../../assets/themes/theme";
import LocalStorageService from "../../services/localStorage";
import { getCollections } from "../../resources/helper/collection";




import { useStyles } from "./assets/style";
import "./assets/style.css";

const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);

const Billing = () => {
  const dispatch = useDispatch();
  const [clientId] = React.useState<string>(
    lsLogin.getUserLoginStored().clientId,
  );
  const [collections, setCollections] = React.useState<any[]>([]);
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });
  const [insurancesList, setInsurancesList] = React.useState<any[]>([]);
  const handleOnClickNext = (data: any) => {
    console.log(data);
  }
  const onClickBack = () => {
    navigate("/admin");
  };
  const onClickGenerate = () => {
    navigate("/admin/generate-billing");
  };

  useEffect(() => {



    const getStatusLabel = (estado: string) => {
      switch (estado) {
        case "BANK_PAYMENT_PENDING":
          return "En Proceso";
        case "COMPLETED":
          return "Completado";
        case "FAILED":
          return "Fallido";
        case "PROCESSING_BANK_FILE":
          return "Procesando Pagos";
        case "GENERATING_BANK_FILE":
          return "Generado";
        default:
          return estado;
      }
    };
   
    const formatDate = (dateString: string) => {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    };
    const fetchCollections = async () => {
      setLoading(true);
      try {
        const response = await getCollections(true, 1, 10);
      

        if (response) {
          response.data.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

          //item.batchFiles = item.batchFiles.map((file: any) => file.url)
          response.data.map((item: any) => {
            let newItem = {
              id: item.id,
              tipo: "Recepción de pagos",
              estado: getStatusLabel(item.status),
              usuario: item.parameters.userName,
              fecha: formatDate(item.createdAt),
              archivos: item.batchFiles.length,
              children: item.batchFiles.length > 0 && [
                {
                  id: "5.1",
                  tipo: "Detalles",
                  estado: "",
                  usuario: "",
                  fecha: "",
                  archivos: 0,
                  referencia: "",
                  detalles: item.batchFiles.map((file: any) => file.url),
                  actionText: "Descargar Archivos",
                },
              ],
            };
            setCollections((prev) => [...prev, newItem]);
          });

        }
      } catch (error) {
        console.error("Error fetching collections:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCollections();
  }, []);

  const downloadFiles = (e: any) => {
    const fileUrl = e.detalles; // 
    if (Array.isArray(fileUrl) && fileUrl.length > 0) {
      fileUrl.forEach((url: string) => {
        const link = document.createElement("a");
        link.href = url;
        link.download = url.split("/").pop() || "file";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    } else {
      console.error("No se puede descargar el archivo. URL no válida o vacía.");
    }
  };
  return (
    <React.Fragment>

      <CobranzaHeader
        loading={loading}
        seeToast={seeToast}
        setSeeToast={setSeeToast}
      />

      <View
        onClickNext={handleOnClickNext}
        title={"Cobranza"}
        data={collections}
        columns={[
          {
            accessorKey: "actions",
            header: "",
            size: 50,
            enableResizing: false,
            cell: ({ row }: any) => {

              return (
                <ContextMenuAFY
                  colorButton="#fff"
                  iconButton={icons.MoreVert}
                  iconColor="#3A56A1"
                  menuItem={[
                    {
                      disabled: false,
                      id: "1",
                      name: "Ver Detalles",
                    },
                    {
                      disabled: false,
                      id: "2",
                      name: "Aplicar Pagos",
                    },

                  ]}
                  onClickContextMenu={(item) => {
                    console.log(item, row.original);
                    if (item.id === "1") {
                      navigate("/admin/generate-billing", {
                        state: {
                          id: row.original.id,
                          tipo: row.original.tipo,
                          estado: row.original.estado,
                          usuario: row.original.usuario,
                          fecha: row.original.fecha,
                          archivos: row.original.archivos,
                          referencia: row.original.referencia,
                          readOnly: true,
                        },
                      });
                    }
                    if (item.id === "2") {
                      //console.log("Aplicar Pagos", row.original.children[0].detalles);
                      navigate("/admin/collect", {
                        state: {
                          id: row.original.id,
                          tipo: row.original.tipo,
                          estado: row.original.estado,
                          usuario: row.original.usuario,
                          fecha: row.original.fecha,
                          archivos: row.original.archivos,
                          referencia: row.original.referencia,
                        },
                      }); 
                    }
                  }}
                  sizeIcon={sizesIcon.standard}
                />
              );
            }
          },
          {
            accessorKey: "id",
            header: "",
            cell: ({ row }: any) => {
              const hasChildren = row.original.children?.length > 0;
              return (
                <div style={{ paddingLeft: `${row.depth * 20}px` }} className="cell">
                  {hasChildren && (
                    <button
                      className="expand-btn"
                      onClick={() => row.toggleExpanded()}
                    >
                      {row.getIsExpanded() ? <IconAFY icon={icons.KeyboardArrowUp} /> : <IconAFY icon={icons.KeyboardArrowDown} />}
                    </button>
                  )}

                </div>
              );
            },
          },
          {
            accessorKey: "tipo",
            header: "Tipo",

          },
          {
            accessorKey: "estado",
            header: "Estado",
          },
          {
            accessorKey: "usuario",
            header: "Usuario",
          },
          {
            accessorKey: "fecha",
            header: "Fecha de Creación",
          },
          {
            accessorKey: "archivos",
            header: "Archivos",
          },

        ]}
        onClickGenerate={onClickGenerate}
        onClickDownload={(e: any) => { downloadFiles(e) }}
        backgroundColorBack={themeCore.colors.primary}
        backgroundColorNext={themeCore.colors.primary}

        onClickBack={onClickBack}
        next={{
          nextLabel: "",
          previousLabel: "",
          nextDisabled: false,
        }


        }
      />

    </React.Fragment>
  );
};

export default Billing;
