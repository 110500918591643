import React, { useEffect, useCallback } from "react";
import {
    ButtonAFY,
    GridAFY,
    ModalComponentAFY,
    sizes,
    typeAlert,
    variants
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { themeCore } from "../../../assets/themes/theme";
import { use } from "i18next";

export const Modal = (props: any) => {


    return (
        <>
            <ModalComponentAFY
                openModal={props.openModal}
                onClose={() => {
                    props.close();
                  }}
                border={0}
                backgroundColor={themeCore.colors.background}
                modalHeight="500"
                modalWidth={1000}
                minWidthModal="80%"
                padding={10}
            >
                <div className={"centerTitle"}> {props.title} </div>

                <div className={"box"}>
                    <GridAFY
                        columns={props.columns}
                        height={"371px"}
                        onClick={function noRefCheck() { }}
                        pageSize={5}
                        rows={props.rows}
                        width="100%"
                    />
                </div>

                <div className={"divContainerButtons"}>
                    <ButtonAFY
                        variant={variants.outlined}
                        label={"Cerrar"}
                        size={sizes.large}
                        onClick={props.close}
                    />
                </div>
            </ModalComponentAFY>
        </>
    );
}
