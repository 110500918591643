import * as React from "react";
import { useEffect, useState } from "react";
import { PaymentConfirmationView } from "./ui/view";
import { useDispatch, useSelector } from "react-redux";
import { quoteOne } from "../../store/generateQuote/actions";
import { getCustomerAction } from "../../fnx/customer";
import { PaymentConfirmationHeader } from "./ui/header";

import {
  policyConfirm,
  policyConfirmClearData,
} from "../../store/policy/actions";
import { useNavigate } from "react-router-dom";
import showToast from "../../services/toast";
import LocalStorageService from "../../services/localStorage";
import { Steps } from "../../enum/steps";
import {
  breadcrumb,
  DataFormClient,
  LoadingAFY,
  MenuItem,
  ModalComponentAFY,
  position,
  SuccessAFY,
  typeAlert,
  typeLoaders,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { themeCore } from "../../assets/themes/theme";
import { getDataFormFilters } from "./resources/paymentConfirmationDTO";
import { navigateBack, stepProgress } from "../../dataBase/stepProgress";
import { showErrorToast } from "../../fnx/showError";
import { useTranslation } from "react-i18next";
import { STEP_PAYMENTCONFIRMATION } from "../../const/catalog";
import { formatToTwoDecimals } from "../../utils/getPrice";

import useSelectors from "./components/selectors";
import useUI from "./components/ui";
import usePaymentConfirmation from "./components/paymentConfirmation";

const PaymentConfirmation = () => {
  const STEP = STEP_PAYMENTCONFIRMATION;
  const { t } = useTranslation();
  const storageKey = "afy-steps";
  const localStorageService = new LocalStorageService(storageKey);
  const storageKey2 = "afy-login";
  const lsLogin = new LocalStorageService(storageKey2);
  const navigate = useNavigate();
  const [breadCrumbs, setBreadCrumbs] = React.useState<breadcrumb[]>([]);
  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });
  const [steps, setSteps] = React.useState<MenuItem[]>([]);
  const [showModal, setShowModal] = useState<boolean>(true);
  const [policyId] = useState<string>(
    localStorageService.getStepStored().steps[Steps.pay].id
  );
  const [quoteId] = useState<string>(
    localStorageService.getStepStored().steps[Steps.customer].quoteId || ""
  );
  const [certificates, setCertificates] = useState<any[]>([]);
  const [email, setEmail] = useState<string>(
    localStorageService.getStepStored().steps[Steps.pay].email || ""
  );
  const [dataForm, setDataForm] = useState<
    DataFormClient & { Insurance?: string } & { CoverType?: string } & {
      PaymentMode?: string;
    } & { Currency?: string } & { Prima?: string } & { QuoteId?: string }
  >({});
  const dispatch = useDispatch();

  const {
    responseQuote,
    responseOneQuote,
    responsePolicyConfirm,
    responsePolicyConfirmError,
    responsePolicyConfirmLoading,
    errorOneQuote,
  } = useSelectors();

  const {
    showSuccess,
    handlePrevious,
    handleNext,
    handleDownload,
  } = useUI(STEP, navigate, showModal, setShowModal, responseOneQuote);

  const { handleSendEmail } = usePaymentConfirmation(
    policyId,
    dispatch,
    policyConfirm
  );

  useEffect(() => {
    if (errorOneQuote) {
      showErrorToast(errorOneQuote, setSeeToast, t);
    }
  }, [errorOneQuote]);

  useEffect(() => {
    if (responsePolicyConfirmError) {
      showErrorToast(responsePolicyConfirmError, setSeeToast, t);
    }
  }, [responsePolicyConfirmError]);

  useEffect(() => {
    dispatch(quoteOne({ id: quoteId, fullBody: true }));
    setSteps(stepProgress(STEP));
    setBreadCrumbs(stepProgress(STEP, true));
  }, []);

  useEffect(() => {
    if (responseOneQuote && responseOneQuote?.data) {
      let certs = responseOneQuote.data.certificates;

      if (certs.length > 0) {
        certs = certs.map((c: any, id: any) => {
          return {
            id: c.id,
            name: c.title,
            url: c.url,
          };
        });
      }
      setCertificates(certs);
    }
  }, [responseOneQuote]);

  useEffect(() => {
    if (responsePolicyConfirm && responsePolicyConfirm?.data) {
      setSeeToast({
        show: true,
        message: "Correo enviado con éxito",
        type: typeAlert.success,
      });

      dispatch(policyConfirmClearData());
    }
  }, [responsePolicyConfirm]);

  useEffect(() => {
    if (responseQuote?.data || responseOneQuote?.data) {
      let df = dataForm;
      df.Name = responseOneQuote
        ? `${responseOneQuote?.data?.firstNameA} ${responseOneQuote?.data?.firstNameB}`
        : `${responseQuote.data.firstNameA} ${responseQuote.data.firstNameB}`;
      df.LastNames = responseOneQuote
        ? `${responseOneQuote?.data?.lastNameA} ${responseOneQuote?.data?.lastNameB}`
        : `${responseQuote.data.lastNameA} ${responseQuote.data.lastNameB}`;
      df.DocumentNumber = responseOneQuote
        ? `${responseOneQuote?.data?.numberId}`
        : `${responseQuote.data.numberId}`;
      df.Insurance = responseOneQuote
        ? `${responseOneQuote?.data?.productId?.description}`
        : `${responseQuote.data.productId.description}`;
      df.PlanTypeName = responseOneQuote
        ? `${responseOneQuote?.data?.planId?.name}`
        : `${responseQuote.data.planId.name}`;
      df.CampaignName = responseOneQuote
        ? responseOneQuote &&
        `${responseOneQuote?.data?.productId?.campaign[0]?.name}`
        : `${responseQuote.data.productId.campaign[0].name}`;
      df.CoverType = responseOneQuote?.data?.coverType?.description ?? "";
      df.PaymentMode = responseOneQuote
        ? `${responseOneQuote?.data?.catalogPaymentMode?.description}`
        : `${responseQuote.data.catalogPaymentMode.description}`;
      df.Currency = getCustomerAction().customerAuto.includes(
        lsLogin.getUserLoginStored().subdomain || ""
      )
        ? responseOneQuote?.data.lastPrice?.currencySymbol
        : responseOneQuote?.data?.planId.price.currencySymbol;
      df.Prima = getCustomerAction().customerAuto.includes(
        lsLogin.getUserLoginStored().subdomain || ""
      )
        ? responseOneQuote?.data.lastPrice?.amount
          ? formatToTwoDecimals(responseOneQuote.data.lastPrice.amount)
          : "0.00"
        : responseOneQuote?.data?.planId.price.amount;
      df.QuoteId = responseOneQuote
        ? `${responseOneQuote?.data?.id}`
        : `${responseQuote.data.id}`;

      setDataForm(df);
      setInterval(() => {
        setShowModal(false);
      }, 5000);
    }
  }, [responseQuote, responseOneQuote]);





  return (
    <React.Fragment>

   <PaymentConfirmationHeader
        breadCrumbs={breadCrumbs}
        steps={steps}
        responsePolicyConfirmLoading={responsePolicyConfirmLoading}
        seeToast={seeToast}
        setSeeToast={setSeeToast}
      />

      {showModal && showSuccess()}

      <PaymentConfirmationView
        breadCrumbs={breadCrumbs}
        steps={steps}
        responsePolicyConfirmLoading={responsePolicyConfirmLoading}
        seeToast={seeToast}
        setSeeToast={setSeeToast}
        previousLabel="Ver pólizas"
        title="Pago"
        onNext={handleNext}
        nextLabel={"Finalizar"}
        section1Title="Descarga de documentos"
        section2Title="Enviar por correo"
        dataForm={dataForm}
        defaultEmail={email}
        stepProgress={steps}
        documents={certificates}
        filters={getDataFormFilters(dataForm)}
        onBack={handlePrevious}
        onClickSendEmail={handleSendEmail}
        onClickDownload={handleDownload}
      />
    </React.Fragment>
  );
};

export default PaymentConfirmation;
