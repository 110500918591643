import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { certificateToProject } from "../../store/certificates/actions";

export const PostCertificatesToProject = (
  trigger: boolean,
  idCertificate: string,
  idProject: string,
) => {
  const dispatch = useDispatch();

  const {
    loadingCertificateToProject,
    responseCertificateToProject,
    errorCertificateToProject,
  } = useSelector((state: any) => ({
    loadingCertificateToProject: state.Certificates.loadingCertificateToProject,
    responseCertificateToProject:
      state.Certificates.responseCertificateToProject,
    errorCertificateToProject: state.Certificates.errorCertificateToProject,
  }));

  useEffect(() => {
    if (trigger) {
      dispatch(
        certificateToProject({
          id: idCertificate,
          idProject: idProject,
        }),
      );
    }
  }, [trigger, idCertificate, idProject]);

  return {
    loadingCertificateToProject,
    responseCertificateToProject,
    errorCertificateToProject,
  };
};
