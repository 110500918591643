
import {
    select
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { useDispatch} from "react-redux";
import { personById } from "../../../store/person/actions";
const useClientRecord = (
    setShowNextDisabled: any,
    setDocumentType: any,
    setDocumentName: any,
    setDocumentNumber: any,
    documentNumber: any,
    setSeeToast: any,
    typeAlert: any,
    listIdentificationType: any,
    lsVariables: any,
    documentType: any,

) => {
    const dispatch = useDispatch();
      const isValid = () => {
        let dt = documentType;
        let dn = documentNumber;
    
        listIdentificationType.find((item: select) => {
          if (item.id === documentType) {
            dt = item.name;
          }
        });
        let type = dt;
        dt = dt.toLowerCase();
        dt = dt.replace(/é/g, "e");
        dn = dn.replace(/-/g, "");
        dn = dn.replace(/_/g, "");
        let vars = [
          {
            idType: documentType,
            docNumber: dn,
            type: type,
          },
        ];
        let result =
          (dt === "cedula" && dn.length === 11) ||
          (dt === "pasaporte" && dn.length > 3);
        lsVariables.setVariablesStored(vars);
    
        return result;
      };

    const handleChangeSelect = (e: any, a: any) => {
        setShowNextDisabled(true);
        setDocumentType(a[0].id);
        setDocumentName(a[0].name);
        setDocumentNumber("");
      };
    
      const validDocument = (name: string, number: string) => {
        let dt = name.toLowerCase();
        dt = dt.replace(/é/g, "e");
    
        let dn = number.replace(/-/g, "");
        dn = dn.replace(/_/g, "");
    
        return (
          (dt === "cedula" && dn.length === 11) ||
          (dt === "pasaporte" && dn.length > 3)
        );
      };
      const handleChange = (e: any, a: any) => {
        setDocumentNumber(e);
        setShowNextDisabled(true);
      };
    
      const handleSearch = () => {
        let _term = documentNumber;
        _term = _term.replace(/-/g, "");
        _term = _term.replace(/_/g, "");
    
        if (_term.length > 0) {
          dispatch(
            //person({ enable: enable, page: page, limit: limit, numberId: _term }),
            personById(_term),
          );
        } else {
          setSeeToast({
            show: true,
            message: "¡Debe ingrensar un documento válido!",
            type: typeAlert.info,
          });
        }
      };

      return {
        handleChangeSelect,
        validDocument,
        handleChange,
        handleSearch,
        isValid
      }
}
export default useClientRecord;