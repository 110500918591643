import React from "react";
import {
  breadcrumb,
  InitialInformationAFY,
  select,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { HeaderTitlePage } from "../../components/resources/headerTitlePage/headerTitlePage";

interface InitialInfoViewProps {
  title?: string;
  breadcrumbs?: breadcrumb[];
  showInfo?: boolean;
  tableHeight?: string;
  filters?: any[];
  stepProgress?: any;
  nextLabel?: string;
  previousLabel?: string;
  onClickPrevious?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onClickNext?: (data: any) => void;
  onChangeCurrency?: (data: any) => void;
  nextDisabled?: boolean;
  showPrevious?: boolean;
  hideFilter?: boolean;

  showNext?: boolean;
  resumeDescription?: string;
  resumeValue?: string;
  minHeightGrid?: string;

  titleContent?: string;
  titleContentSectionB?: string;
  titleContentSectionC?: string;
  lblPlanType?: string;
  lblDateBirth?: string;
  lblSex?: string;
  lblSmoke?: string;
  listPlanType?: select[];
  listSex?: select[];
  listSmoke?: select[];
  lblCurrency?: string;
  listCurrency?: select[];
  titleYearSlider?: string;
  subtitleYearSlider?: string;
  minValueYearSlider?: number;
  maxValueYearSlider?: number;
  widthYearSlider?: number;
  stepYearSlider?: number;
  defaultValueYearSlider?: number;
  fontSizeSubtitleYearSlider?: string;
  fontSizeTitleYearSlider?: string;
  inputFontSizeYearSlider?: string;
  inputWidthSizeYearSlider?: string;
  typeModeYearSlider?: string; // or use an enum type if you have one
  titleDecimalSlider?: string;
  subtitleDecimalSlider?: string;
  minValueDecimalSlider?: number;
  maxValueDecimalSlider?: number;
  widthDecimalSlider?: number;
  stepDecimalSlider?: number;
  defaultValueDecimalSlider?: number;
  fontSizeSubtitleDecimalSlider?: string;
  fontSizeTitleDecimalSlider?: string;
  inputFontSizeDecimalSlider?: string;
  inputWidthSizeDecimalSlider?: string;
  typeModeDecimalSlider?: string; // or use an enum type if you have one
  steps?: any;
}

export const InitialInfoView = (props: InitialInfoViewProps) => {
  return (
    <React.Fragment>
      <HeaderTitlePage
        breadCrumbs={props.breadcrumbs}
        titlePage={props.title}
        steps={props.steps}
        hideStep={false}
        stepsTop={"0px"}
      />
      <InitialInformationAFY
        title={""}
        showInfo={props.showInfo}
        stepProgress={props.stepProgress}
        showNext={props.showNext}
        filters={props.filters}
        nextLabel={props.nextLabel}
        previousLabel={props.previousLabel}
        onClickPrevious={props.onClickPrevious}
        resumeDescription={props.resumeDescription}
        resumeValue={props.resumeValue}
        onClickNext={props.onClickNext}
        nextDisabled={props.nextDisabled}
        hideFilter={props.hideFilter}
        showPrevious={props.showPrevious}
        minHeightGrid={props.minHeightGrid}
        titleContent={props.titleContent}
        titleContentSectionB={props.titleContentSectionB}
        titleContentSectionC={props.titleContentSectionC}
        lblPlanType={props.lblPlanType}
        lblDateBirth={props.lblDateBirth}
        lblSex={props.lblSex}
        listPlanType={props.listPlanType}
        listSex={props.listSex}
        lblSmoker={props.lblSmoke}
        listSmoke={props.listSmoke}

        listCurrency={props.listCurrency}
        lblCurrency={props.lblCurrency}
        titleYearSlider={props.titleYearSlider}
        subtitleYearSlider={props.subtitleYearSlider}
        minValueYearSlider={props.minValueYearSlider}
        maxValueYearSlider={props.maxValueYearSlider}
        widthYearSlider={props.widthYearSlider}
        stepYearSlider={props.stepYearSlider}
        defaultValueYearSlider={props.defaultValueYearSlider}
        titleDecimalSlider={props.titleDecimalSlider}
        subtitleDecimalSlider={props.subtitleDecimalSlider}
        minValueDecimalSlider={props.minValueDecimalSlider}
        maxValueDecimalSlider={props.maxValueDecimalSlider}
        widthDecimalSlider={props.widthDecimalSlider}
        stepDecimalSlider={props.stepDecimalSlider}
        defaultValueDecimalSlider={props.defaultValueDecimalSlider}
        handleChangeCurrency={props.onChangeCurrency}
        typeModeDecimalSlider="integer"
        typeModeYearSlider="integer"
      />
    </React.Fragment>
  );
};
