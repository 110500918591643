import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allSchemasTemplate } from "../../store/certificates/actions";

export const GetAllSchemasTemplate = (trigger: boolean) => {
  const dispatch = useDispatch();

  const {
    loadingSchemasTemplate,
    responseSchemasTemplate,
    errorSchemasTemplate,
  } = useSelector((state: any) => ({
    loadingSchemasTemplate: state.Certificates.loadingSchemasTemplate,
    responseSchemasTemplate: state.Certificates.responseSchemasTemplate,
    errorSchemasTemplate: state.Certificates.errorSchemasTemplate,
  }));

  useEffect(() => {
    if (trigger) {
      dispatch(allSchemasTemplate());
    }
  }, [trigger]);

  return {
    loadingSchemasTemplate,
    responseSchemasTemplate,
    errorSchemasTemplate,
  };
};
