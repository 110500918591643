// @ts-ignore
import enTranslations from "ckeditor5/translations/en.js";
// @ts-ignore
import esTranslations from "ckeditor5/translations/es.js";
// @ts-ignore
import enPremiumFeaturesTranslations from "ckeditor5-premium-features/translations/en.js";
// @ts-ignore
import esPremiumFeaturesTranslations from "ckeditor5-premium-features/translations/es.js";

import {
  AccessibilityHelp,
  Alignment,
  Autoformat,
  AutoImage,
  AutoLink,
  Autosave,
  BalloonToolbar,
  BlockQuote,
  Bold,
  CloudServices,
  Code,
  CodeBlock,
  EditorConfig,
  Essentials,
  FindAndReplace,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  Heading,
  Highlight,
  HorizontalLine,
  ImageBlock,
  ImageCaption,
  ImageInline,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
  Indent,
  IndentBlock,
  Italic,
  List,
  ListProperties,
  Mention,
  Minimap,
  PageBreak,
  Paragraph,
  Plugin,
  RemoveFormat,
  SelectAll,
  ShowBlocks,
  SpecialCharacters,
  SpecialCharactersArrows,
  SpecialCharactersCurrency,
  SpecialCharactersEssentials,
  SpecialCharactersLatin,
  SpecialCharactersMathematical,
  SpecialCharactersText,
  Strikethrough,
  Subscript,
  Superscript,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TextTransformation,
  TodoList,
  Underline,
  Undo,
} from "ckeditor5";
import {
  ImportWord,
  MergeFields,
  Pagination,
} from "ckeditor5-premium-features";
import "./certificates.css";

const translationsMap: { [key: string]: any } = {
  en: enTranslations,
  es: esTranslations,
};

const premiumFeaturesTranslationsMap: { [key: string]: any } = {
  en: enPremiumFeaturesTranslations,
  es: esPremiumFeaturesTranslations,
};

class CustomUploadAdapter {
  private loader: any;
  private maxFileSize: number = 300 * 1024; // 300 KB en bytes

  constructor(loader: any) {
    this.loader = loader;
  }

  private resizeImage(file: File): Promise<File> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const reader = new FileReader();

      reader.onload = () => {
        img.src = reader.result as string;
      };

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        if (!ctx) {
          reject(new Error("No se pudo obtener el contexto del lienzo"));
          return;
        }

        const maxWidth = 800;
        const maxHeight = 800;
        const width = img.width;
        const height = img.height;
        let newWidth = width;
        let newHeight = height;

        if (width > height) {
          if (width > maxWidth) {
            newWidth = maxWidth;
            newHeight = (height * maxWidth) / width;
          }
        } else {
          if (height > maxHeight) {
            newHeight = maxHeight;
            newWidth = (width * maxHeight) / height;
          }
        }

        canvas.width = newWidth;
        canvas.height = newHeight;
        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        canvas.toBlob(
          (blob) => {
            if (blob) {
              resolve(new File([blob], file.name, { type: file.type }));
            } else {
              reject(new Error("No se pudo redimensionar la imagen"));
            }
          },
          file.type,
          0.7,
        );
      };

      img.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  upload() {
    return this.loader.file
      .then((file: File) => {
        if (file.size > this.maxFileSize) {
          return this.resizeImage(file).then((resizedFile) => {
            return this.convertToBase64(resizedFile);
          });
        }

        return this.convertToBase64(file);
      })
      .catch((error: any) => {
        console.error("Error al manejar la imagen:", error);
        throw error;
      });
  }

  private convertToBase64(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve({
          default: reader.result as string,
        });
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  abort() {
    console.log("Subida abortada");
  }
}

class CustomUploadAdapterPlugin extends Plugin {
  init() {
    const editor = this.editor;

    // Integra el CustomUploadAdapter en el sistema de subida de CKEditor
    editor.plugins.get("FileRepository").createUploadAdapter = (
      loader: any,
    ) => {
      return new CustomUploadAdapter(loader);
    };
  }
}

export const editorCustom = (configOverrides: Partial<EditorConfig | any>) => {
  const selectedLanguage = configOverrides.language || "en";

  return {
    toolbar: {
      items: [
        "previousPage",
        "nextPage",
        "|",
        "undo",
        "redo",
        "|",
        "showBlocks",
        "|",
        "insertMergeField",
        "|",
        "heading",
        "|",
        "fontSize",
        "fontFamily",
        "fontColor",
        "fontBackgroundColor",
        "|",
        "bold",
        "italic",
        "underline",
        "|",
        // "link",
        "insertImage",
        "insertTable",
        "highlight",
        "blockQuote",
        "codeBlock",
        "|",
        "alignment",
        "|",
        "bulletedList",
        "numberedList",
        "todoList",
        "outdent",
        "indent",
      ],
      shouldNotGroupWhenFull: true,
    },
    plugins: [
      AccessibilityHelp,
      Alignment,
      Autoformat,
      AutoImage,
      AutoLink,
      Autosave,
      BalloonToolbar,
      BlockQuote,
      Bold,
      CloudServices,
      Code,
      CodeBlock,
      Essentials,
      FindAndReplace,
      FontBackgroundColor,
      FontColor,
      FontFamily,
      FontSize,
      Heading,
      Highlight,
      HorizontalLine,
      ImageBlock,
      ImageCaption,
      ImageInline,
      ImageResize,
      ImageStyle,
      ImageTextAlternative,
      ImageToolbar,
      ImageUpload,
      ImportWord,
      Indent,
      IndentBlock,
      Italic,
      List,
      ListProperties,
      Mention,
      MergeFields,
      Minimap,
      Pagination,
      PageBreak,
      Paragraph,
      RemoveFormat,
      ShowBlocks,
      SelectAll,
      SpecialCharacters,
      SpecialCharactersArrows,
      SpecialCharactersCurrency,
      SpecialCharactersEssentials,
      SpecialCharactersLatin,
      SpecialCharactersMathematical,
      SpecialCharactersText,
      Strikethrough,
      Subscript,
      Superscript,
      Table,
      TableCaption,
      TableCellProperties,
      TableColumnResize,
      TableProperties,
      TableToolbar,
      TextTransformation,
      TodoList,
      Underline,
      Undo,
      CustomUploadAdapterPlugin,
    ],
    balloonToolbar: [
      "bold",
      "italic",
      "outdent",
      "indent",
      "|",
      "insertImage",
      "|",
      "bulletedList",
      "numberedList",
    ],
    fontFamily: {
      supportAllValues: true,
    },
    fontSize: {
      options: [10, 12, 14, "default", 18, 20, 22],
      supportAllValues: true,
    },
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 3",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 4",
          class: "ck-heading_heading4",
        },
        {
          model: "heading5",
          view: "h5",
          title: "Heading 5",
          class: "ck-heading_heading5",
        },
        {
          model: "heading6",
          view: "h6",
          title: "Heading 6",
          class: "ck-heading_heading6",
        },
      ],
    },
    image: {
      toolbar: [
        "toggleImageCaption",
        "imageTextAlternative",
        "|",
        "imageStyle:inline",
        "imageStyle:wrapText",
        "imageStyle:breakText",
        "|",
        "resizeImage",
      ],
    },
    language: selectedLanguage,
    licenseKey: configOverrides?.licenseKey,
    list: {
      properties: {
        styles: true,
        startIndex: true,
        reversed: true,
      },
    },
    menuBar: {
      isVisible: true,
    },
    mergeFields: configOverrides?.mergeFields,
    allowedContent: true,
    minimap: {
      container: configOverrides?.container,
      extraClasses:
        "editor-container_include-minimap ck-minimap__iframe-content",
    },
    pagination: {
      pageWidth: "215.9mm",
      pageHeight: "279.4mm",
      displayHeaderFooter: false,
      pageMargins: {
        top: "1.5mm",
        bottom: "1.5mm",
        right: "1.5mm",
        left: "1.5mm",
      },
    },
    placeholder: configOverrides.placeholder,
    table: {
      contentToolbar: [
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "tableProperties",
        "tableCellProperties",
      ],
    },
    translations: [
      translationsMap[selectedLanguage] || translationsMap["en"],
      premiumFeaturesTranslationsMap[selectedLanguage] ||
        premiumFeaturesTranslationsMap["en"],
    ],
    upload: {
      types: ["jpg", "jpeg", "png", "gif"],
    },
  } as EditorConfig;
};
