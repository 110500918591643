import React, { useEffect, useState } from "react";
import View from "./ui/view";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate, useLocation } from "react-router-dom";
import {
  typeAlert,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { CollectHeader } from "./ui/header";
import { themeCore } from "../../assets/themes/theme";
import LocalStorageService from "../../services/localStorage";
import { postCollectionFilesApi } from "../../resources/helper/collection";




import { useStyles } from "./assets/style";
import "./assets/style.css";
import { arch } from "os";
import { title } from "process";

const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);

const Collect = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { state } = useLocation();

  const usuario = state?.usuario ?? false;
  const id = state?.id ?? false;
  const fecha = state?.fecha ?? false;
  const referencia = state?.referencia ?? false;
  const estado = state?.estado ?? false;
  const archivos = state?.archivos ?? false;

  const [clientId] = React.useState<string>(
    lsLogin.getUserLoginStored().clientId,
  );
  const classes = useStyles();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [files, setFiles] = React.useState<any[]>([]);
  const [archivosList, setArchivosList] = React.useState<any[]>([]);
  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });


useEffect(() => {
  if (archivos && archivos.length > 0) {
    console.log("archivos---->sss", archivos);

    let tempFiles: any[] = [];
    archivos.map((item: any) => {
      
      tempFiles.push({
        name: item,
        size: "",
        type: "",
        title: item,
        url: item,  
      });
    })
console.log("tempFiles", tempFiles);
    setArchivosList(tempFiles);
  }
}, [archivos]);
  const getEstadoClass = (estado: string) => {
    switch (estado) {
      case "En Proceso":
        return "estado-en-proceso";
      case "Completado":
        return "estado-completado";
      case "Fallido":
        return "estado-fallido";
      case "Procesando Pagos":
        return "estado-procesando-pagos";
      case "Generado":
        return "estado-generado";
      default:
        return "estado-default";
    }
  };

  const clickBack = () => {
    navigate("/billing");
  };
  const handleSave = () => {
    setLoading(true);

    postCollectionFilesApi(id, files)
      .then((res) => {
        console.log("res", res);
        setLoading(false);
        setSeeToast({
          show: true,
          message: "Archivos subidos correctamente",
          type: typeAlert.success,
        });
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        /*setSeeToast({
          show: true,
          message: "Error al subir los archivos",
          type: typeAlert.error,
        });*/
        setSeeToast({
          show: true,
          message: "Archivos subidos correctamente",
          type: typeAlert.success,
        });
      });

  };

  return (
    <React.Fragment>
      <CollectHeader
        loading={loading}
        seeToast={seeToast}
        setSeeToast={setSeeToast}
      />

      <View
        backgroundColorBack={themeCore.colors.buttonPrimary}
        backgroundColorNext={themeCore.colors.buttonPrimary}
        title={"Recibir Pago"}
        onClickBack={clickBack}
        readOnly={true}
        status={getEstadoClass(estado)}
        estado={estado}
        details={{
          title: "Detalle",
          userLabel: "Usuario",
          userValue: usuario,
          creationDateLabel: "Fecha de Creación",
          creationDateValue: fecha,
          referenceLabel: "Referencia",
          referenceValue: referencia || "N/A",
        }}
        collectionSummary={{
          title: "Resumen de Cobro",
          collectionOptionLabel: "Opción de Cobro",
          collectionOptionValue: "Cobranza mensual normal",
          batchNumberLabel: "Número de Lote",
          batchNumberValue: "1",
          processingDateLabel: "Fecha de Procesamiento",
          processingDateValue: "31/01/2024",
          invoiceDateLabel: "Fecha de Facturación",
          invoiceDateValue: "31/01/2024",
          parametersLabel: "Parámetros",
          parameters: ["---", "---", "---"],
        }}
        billingResults={{
          title: "Resultados de Facturación",
          newPoliciesLabel: "Pólizas prima nueva facturada",
          newPoliciesValue: "10",
          newPremiumLabel: "Monto Prima Nueva Facturada",
          newPremiumValue: "Q. 10,000.00",
          refactoredPremiumLabel: "Rebill",
          refactoredPremiumValue: "Q. 5,000.00",
          totalPremiumLabel: "Prima Total",
          totalPremiumValue: "Q. 15,000.00",
          cancellationsLabel: "Cancelaciones por morosidad",
          cancellationsValue: "5",
          expiredLabel: "Expiradas",
          expiredValue: "2",
          invalidBirthDatePoliciesLabel: "Pólizas mala fecha de nacimiento titular",
          invalidBirthDatePoliciesValue: "1",
          refactoredPolicyLabel: "Prima refacturadas",
          refactoredPolicyValue: "100000",
          totalRefactoredPremiumLabel: "Total prima refacturada",
          totalRefactoredPremiumValue: "Q. 5,000.00",
        }}
        uploadedResults={{
          title: "Resultados de Carga",
          policiesStatusLabel: "Polizas a Estatus Cancelado",
          policiesStatusValue: "0",
          pendingOrSuspenseLabel: "Pendiente o suspenso",
          pendingOrSuspenseValue: "0",
          notasDeCobroLabel: "Notas de Cobro pagados",
          notasDeCobroValue: "0",
          montoTotalPagarLabel: "Monto Total pagado",
          montoTotalPagarValue: "Q. 0.00",
          notasDeCobroRechazadasLabel: "Notas de Cobro Rechazadas",
          notasDeCobroRechazadasValue: "0",
          pagosNoLocalizadosLabel: "Pagos no localizados",
          pagosNoLocalizadosValue: "0",
          rejectedNotLocalizatedLabel: "Rechazados no localizados",
          rejectedNotLocalizatedValue: "0",
          policiesNotLocalizatedLabel: "Pólizas no localizadas",
          policiesNotLocalizatedValue: "0",
        }}

        onUploadFiles={(files: any) => {
          setFiles(files);
        }}
        uploadFiles={{
          title: "Cargar Archivos",
          uploadPrompt: "*Recuerda que debes cargar la cantidad de archivos que se te solicitan",
          files: archivosList
        }}
        onClickGenerate={() => {
          console.log("Generar");
        }}
        onClickNext={() => {
          handleSave();
        }}

        next={{
          nextLabel: "Cargar",
          previousLabel: "Anterior",
          nextDisabled: false,
        }}
      />

    </React.Fragment>
  );
};

export default Collect;
