import { CertificatesEditorAFY } from "../../../components/certificatesEditor/certificatesEditor";
import { languageCkEditor } from "../../../enum/certificatesEditor";
import {
  CertificateType,
  DataForm,
  LicenceKeys,
} from "../resources/certificatesEditorDTOS";
import { TFunction } from "i18next";
import { NavigateFunction } from "react-router-dom";
import { Schemas } from "../../certificates/helpers/getMergeFields";

export const CertificateEditorView = (props: {
  dataCertificate?: DataForm;
  navigate: NavigateFunction;
  openDrawer: boolean;
  setOpenDrawer: (value: boolean | (() => boolean)) => void;
  onSaveCertificate: (
    data: string | undefined,
    fileName: string,
    typeFile: string,
  ) => void;
  translate: TFunction;
  certificateHtml?: string;
  mergeFieldsData?: Schemas[];
}) => {
  return (
    <CertificatesEditorAFY
      licenseKey={LicenceKeys.key}
      language={languageCkEditor.Spanish}
      placeholder={props.translate("certificatesEditor.placeholder")}
      productNameColor="rgba(102, 102, 102, 1)"
      productTitleColor="#3A56A1"
      productTitle={props.translate("certificatesEditor.productTitle")}
      titleCancelButton={props.translate(
        "certificatesEditor.titleCancelButton",
      )}
      titleNextButton={props.translate("certificatesEditor.titleNextButton")}
      titleSaveButton={props.translate("certificatesEditor.titleSaveButton")}
      descriptionDrawer={props.translate(
        "certificatesEditor.descriptionDrawer",
      )}
      errorTextFileName={props.translate(
        "certificatesEditor.errorTextFileName",
      )}
      errorTextTypeFile={props.translate(
        "certificatesEditor.errorTextTypeFile",
      )}
      labelFileName={props.translate("certificatesEditor.labelFileName")}
      labelTypeCertificate={props.translate(
        "certificatesEditor.labelTypeCertificate",
      )}
      productName="Seguro auto FULL"
      initialData={props.certificateHtml}
      dataForm={props.dataCertificate}
      mergeFieldsData={props.mergeFieldsData}
      TypeListDrawer={CertificateType}
      openDrawer={props.openDrawer}
      onOpenDrawer={() => {
        props.setOpenDrawer(true);
      }}
      onCloseDrawer={() => {
        props.setOpenDrawer(false);
      }}
      onCancelButton={() => {
        props.navigate("/certificates");
      }}
      onSaveData={props.onSaveCertificate}
    />
  );
};
