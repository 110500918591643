import { useCallback, useMemo } from "react";
import { MenuItem } from "@affinitysystemsgroup/github-packages-affinity-components/dist";

const useViewTypes = () => {
  const onClickTypeView = useCallback(
    (
      option: MenuItem,
      setPage: (value: number | (() => number)) => void,
      setCertificateTrigger: (value: boolean | (() => boolean)) => void,
    ) => {
      switch (option.name) {
        case "GridView":
          setPage(1);
          setCertificateTrigger(true);
          break;
        case "List":
          setPage(1);
          setCertificateTrigger(true);
          break;
        case "tableView":
          setPage(1);
          setCertificateTrigger(true);
      }
    },
    [],
  );
  return useMemo(
    () => ({
      onClickTypeView,
    }),
    [onClickTypeView],
  );
};

export default useViewTypes;
