import React, { useEffect, useState } from "react"; // @ts-ignore
import { IdentificationType } from "../../model/identificationType";
import showToast from "../../services/toast";
import { setCampaignData, setPaymentModeData } from "../../services/functions";
import { InsurancePageProps } from "./resources/planDto";
import { useDispatch, useSelector } from "react-redux";
import {
  breadcrumb,
  icons,
  itemPlanProps,
  LoadingAFY,
  MenuItem,
  position,
  select,
  sizesIcon,
  typeAlert,
  typeLoaders,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { themeCore } from "../../assets/themes/theme";
import LocalStorageService from "../../services/localStorage";
import { PlanView } from "./ui/view";
import { useNavigate } from "react-router-dom";
import {
  navigateBack,
  navigateNext,
  stepProgress,
} from "../../dataBase/stepProgress";


import { Steps } from "../../enum/steps";
import { PlanCoverTypes, PlanModel, PlanSelectOption } from "../../model/plan";


import { PaymentMode, PaymentModeResponse } from "../../model/paymentMode";
import { showErrorToast } from "../../fnx/showError";
import { useTranslation } from "react-i18next";
import { STEP_PLAN } from "../../const/catalog";
import useSelectors from "./components/selectors";
import useUI from "./components/ui";
import usePlan from "./components/plan";
import { PlansHeader } from "./ui/header";

const STEP = STEP_PLAN;

const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);

const storageKeyVariables = "afy-variables";
const lsVariables = new LocalStorageService(storageKeyVariables);
const Plan = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const storageKey = "afy-steps";
  const localStorageService = new LocalStorageService(storageKey);
  const storageKeyActionFlags = "afy-action-flags";
  // const storageKeyActionFlags = "afy-steps";
  const lsActionFlags = new LocalStorageService(storageKeyActionFlags);
  const navigate = useNavigate();
  const [clientId] = React.useState<string>(
    lsLogin.getUserLoginStored().clientId
  );

  /*Adiciona flag para validar, si se muestran los rangos de edad
   * en el detalle de los planes*/
  const [flagRangeAge] = React.useState(
    lsActionFlags.getPageActionFlagsStored()
  );
  const [enableAgeRange, setEnabledAgeRange] = React.useState<boolean>(false);
  const [filtersQuotes, setFiltersQuotes] = React.useState<any>([]);
  const [rowsQuotes, setRowsQuotes] = React.useState<any>([]);
  const [selectedPlanId, setSelectedPlanId] = React.useState<string>("");
  const [updating, setUpdating] = React.useState<boolean>(false);
  const [changedPlanId, setChangedPlanId] = React.useState<string>("");
  const [error, setError] = React.useState<boolean>(false);
  const [helperText, setHelperText] = React.useState<string>("");
  const [planPage, setPlanPage] = useState<number>(1);
  const [planLimit, setPlanLimit] = useState<number>(20);
  const [countPagination, setCountPagination] = useState<number>(0);

  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  const [seeToastAlertNext, setSeeToastAlertNext] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  const [enable, setEnable] = React.useState<boolean>(true);
  /*Polizas o cotizaciones*/
  const [showModalQuotes, setShowModalQuotes] = React.useState<boolean>(false);
  const onCloseModalQuotes = () => {
    setShowModalQuotes(false);
  };
  const [extraInfoNames, setExtraInfoNames] = React.useState<itemPlanProps[]>(
    []
  );
  const [extraInfoNamesAut, setExtraInfoNamesAut] = React.useState<
    itemPlanProps[]
  >([]);
  const [page, setPage] = React.useState<number>(1);
  const [limit, setLimit] = React.useState<number>(100);
  const [isDetailClick, setIsDetailClick] = React.useState<boolean>(false);
  const [isSingleCompareClick, setIsSingleCompareClick] =
    React.useState<boolean>(false);
  const [paymentModelSelected, setPaymentModelSelected] =
    React.useState<select>({ id: "", name: "", value: "" });
  const [coverTypeSelected, setCoverTypeSelected] = React.useState<select>();
  const [planSelected, setPlanSelected] = React.useState<itemPlanProps>({
    id: "",
  });
  const [planSelectedNextStep, setPlanSelectedNextStep] =
    React.useState<itemPlanProps>({
      id: "",
    });
  const [planSelectedCompare, setPlanSelectedCompare] = React.useState<
    itemPlanProps[]
  >([]);
  const [showDetail, setShowDetail] = React.useState<boolean>(false);
  const [showCompareDetail, setShowCompareDetail] =
    React.useState<boolean>(false);

  const [projectId, setProjectId] = React.useState<string>(
    localStorageService.getStepStored().steps[Steps.insurance].id
  );
  const [namePlan, setNamePlan] = React.useState<string>("");
  const [paymentMode, setPaymentMode] = React.useState<any>([]);
  const [campaignInfo, setCampaignInfo] = React.useState<any>(
    localStorageService.getStepStored().steps[Steps.insurance].campaign
  );
  const [insuranceType] = React.useState<any>(
    localStorageService.getStepStored().steps[Steps.insurance].name
  );
  const [insurance] = React.useState<any>(
    localStorageService.getStepStored().steps[Steps.insurance].name
  );
  const [showDetails, setShowDetails] = React.useState<boolean>(false);
  const [stepsData, setStepsData] = React.useState<MenuItem[]>([]);
  const [breadCrumbs, setBreadCrumbs] = React.useState<breadcrumb[]>([]);
  const [plan, setPlan] = React.useState<InsurancePageProps>({
    titlePage: "Plan",
    viewType: "2",
    titleMetaTag: "Affinity Plan",
    informationPage: {
      backgroundColor: themeCore.colors.background,
      colorText: themeCore.colors.secondaryText,
      description: "Seleccion el plan que necesite el cliente",
      icon: icons.Help,
      iconSize: sizesIcon.standard,
      iconColor: themeCore.colors.primary,
    },
    filter: {
      backgroundColor: "#FFFFFF",
      bgChipInsurers: "#fff",
      bgChipInsurersSelected: "#EBEEF6",
      bgColorButton: "#fff",
      bgColorComparativeButton: "#3A56A1",
      bgColorIconButton: "#EBEEF6",
      bgColorIconButtonSelected: "#3A56A1",
      colorIconButton: "#3A56A1",
      colorIconButtonSelected: "#fff",
      colorIconChip: "#3A56A1",
      colorText: "#3A56A1",
      comparativeTextButton: "ver comparativa",
      comparativeTextColor: "#fff",
      insurers: [],
      title: "Patrocinador",
      titleFirstChip: "Todas",
      comparativeButtonDisabled: false,
    },
    data: [],
  });

  const {
    responsePlans,
    errorPlans,
    loadingPlans,
    responsePaymentMode,
    errorPaymentMode,
    loadingPaymentMode,
    responseAllQuote,
    errorAllQuote,
    loadingAllQuote,

  }
    = useSelectors();

  const {
    onClickBack,
    onChangePage,
    onChangeRowsPerPage,
    closeDetail,
    onClickNewQuoteModal,
    clickView,
    searchFilter
  } = useUI(
    navigateBack,
    STEP,
    navigate,
    setPlanPage,
    setPlanLimit,
    setShowDetails,
    setShowDetail,
    setShowCompareDetail,
    setShowModalQuotes,
    plan,
    setPlan,
    setNamePlan
  );

  const {
    buildDataRowsQuotes,
    setCoverTypeStorybook,
    callDispatchPaymentMode,
    callDispatchPlan,
    updateCoverPrice,
    clickChangePaymentMode,
    clickDetails,
    clickCompareDetails,
    savePlan,
    onClickSelectRowQuote,
    clickCard,
    clickAcquire,
    onClickNext,
    handleCancelSearch,
  } = usePlan(
    setRowsQuotes,
    planSelectedNextStep,
    setShowModalQuotes,
    navigate,
    projectId,
    enable,
    page,
    limit,
    planPage,
    planLimit,
    namePlan,
    paymentModelSelected,
    coverTypeSelected,
    clientId,
    plan,
    setPlan,
    setUpdating,
    changedPlanId,
    setPaymentModeData,
    paymentMode,
    setPaymentMode,
    campaignInfo,
    setCampaignData,
    setPaymentModelSelected,
    setChangedPlanId,
    setSelectedPlanId,
    setCoverTypeSelected,
    selectedPlanId,
    setShowDetails,
    setShowDetail,
    setShowCompareDetail,
    setPlanSelected,
    localStorageService,
    Steps,
    setPlanSelectedNextStep,
    setFiltersQuotes,
    insuranceType,
    insurance,
    navigateNext,
    STEP,
    setSeeToastAlertNext,
    setNamePlan);

  /* Mapeo de polizas y cotizaciones*/
  useEffect(() => {
    if (responseAllQuote) {
      /*Build Filter Quotes*/
      setFiltersQuotes([
        {
          label: insuranceType,
          name: "Tipo de Seguro",
          type: "label",
        },
        {
          label: insurance,
          name: "Seguro",
          type: "label",
        },
        {
          label: planSelectedNextStep.name,
          name: "Plan",
          type: "label",
        },
        {
          label: campaignInfo ? campaignInfo[0].name : "",
          name: "Campaña",
          type: "label",
        },
      ]);
    }
  }, [responseAllQuote]);

  useEffect(() => {
    if (Array.isArray(responseAllQuote)) {
      buildDataRowsQuotes(responseAllQuote);
    }
  }, [responseAllQuote]);

  useEffect(() => {
    callDispatchPaymentMode();
    console.log("Plan::: ", stepProgress(STEP));
    setStepsData(stepProgress(STEP));
    setBreadCrumbs(stepProgress(STEP, true));
    // callDispatchCatalog();
  }, []);

  useEffect(() => {
    if (errorPlans) {
      showErrorToast(errorPlans, setSeeToast, t);
    }
  }, [errorPlans]);

  useEffect(() => {
    if (errorAllQuote) {
      showErrorToast(errorAllQuote, setSeeToast, t);
    }
  }, [errorAllQuote]);

  useEffect(() => {
    if (errorPaymentMode) {
      showErrorToast(errorPaymentMode, setSeeToast, t);
    }
  }, [errorPaymentMode]);

  useEffect(() => {
    if (responsePlans && responsePlans.data) {
      //Vamos
      const dataST: itemPlanProps[] = [];
      responsePlans?.data.map((item: PlanModel) => {
        dataST.push({
          id: item.id,
          name: item.name ? item.name.replace(/"/g, "") : "",
          logo: item.sponsorLogoUrl && item.sponsorLogoUrl,
          backgroundColor: "#ffffff",
          currency: "RD$",
          itemsValue: [],
          option: setCoverTypeStorybook(item.coverTypes ? item.coverTypes : []),
          period: paymentModelSelected.name,
          benefits: item.benefits, // Added 'benefits' property
          scenaries: item.scenaries,
          //price: Array.isArray(item.scenaries) ? item.scenaries[0].price : "0",
          price: item?.price?.amount ? item.price.amount : "0",
          textColor: "#3A56A1",
        });
      });

      flagRangeAge?.forEach((actionFlag) => {
        if (actionFlag.name === "afy-plan-range-age") {
          setEnabledAgeRange(actionFlag.value === "true");
        }
      });

      setPlan({ ...plan, data: dataST });
    }
    if (responsePlans?.pagingInfo) {
      setCountPagination(responsePlans?.pagingInfo.totalItems);
    }
  }, [responsePlans]);

  useEffect(() => {
    if (responsePaymentMode && responsePaymentMode.data) {
      console.log("responsePaymentMode::: ", responsePaymentMode);
      const data = responsePaymentMode as PaymentModeResponse;
      let result = data.data?.map((x: PaymentMode) => {
        return {
          id: x.catalogPaymentMode.id,
          label: x.catalogPaymentMode.description,
          name: x.catalogPaymentMode.description,
          default:
            x.catalogPaymentMode.description?.toLocaleLowerCase() === "mensual"
              ? true
              : false,
        } as select;
      });

      if (result && result.length > 0) {
        let mensual = result.find((x: select) => x.default === true);
        if (mensual) {
          setPaymentModelSelected(mensual);
        } else {
          setPaymentModelSelected(result[0]);
        }
        let ms = setPaymentModeData(null, result);
        setPaymentMode(ms);
      }
    }
  }, [responsePaymentMode]);

  useEffect(() => {
    if (paymentModelSelected.id) {
      callDispatchPlan();
    }
  }, [paymentModelSelected]);

  useEffect(() => {
    if (coverTypeSelected && coverTypeSelected.id) {
      updateCoverPrice(planSelected, coverTypeSelected);
    }
  }, [coverTypeSelected]);

  useEffect(() => {
    if (namePlan) {
      callDispatchPlan();
    }
  }, [namePlan]);






  useEffect(() => {
    let isMounted = true;

    const callPlan = async () => {
      if (isMounted) {
        callDispatchPlan();
      }
    };

    callPlan();

    return () => {
      isMounted = false;
    };
  }, [planPage, planLimit]);



  return (
    <>
      <PlansHeader
        loading={loadingPlans || updating}
        seeToast={seeToast}
        setSeeToast={setSeeToast}
        seeToastAlertNext={seeToastAlertNext}
        setSeeToastAlertNext={setSeeToastAlertNext}
      />

      {paymentMode && paymentMode.length > 0 && (
        <PlanView
          error={error}
          helperText={helperText}
          planSelected={planSelected}
          paymentMode={paymentMode}
          campaignInfo={campaignInfo}
          listIdentificationType={[]}
          showDetail={showDetail}
          showCompareDetail={showCompareDetail}
          closeDetail={closeDetail}
          plan={plan}
          breadCrumbs={breadCrumbs}
          clickDetails={clickDetails}
          clickCompareDetail={clickCompareDetails}
          onClickCompareItem={() => { }}
          clickChangePaymentMode={clickChangePaymentMode}
          searchFilter={searchFilter}
          clickView={clickView}
          clickCard={clickCard}
          steps={stepsData}
          onClickAcquire={clickAcquire}
          clickChip={() => { }}
          onClickNext={() => { }}
          onClickBack={onClickBack}
          extraInfoNames={extraInfoNames}
          insurance={insurance}
          insuranceType={insuranceType}
          showExtraInfo={enableAgeRange}
          showQuotes={showModalQuotes}
          onCloseModalQuote={onCloseModalQuotes}
          filtersQuote={filtersQuotes}
          rowsQuote={rowsQuotes}
          onClickSelectRowQuote={onClickSelectRowQuote}
          onClickNewQuote={onClickNewQuoteModal}
          filterTitleInsuranceInformation={"Información"}
          showInformation={false}
          onCancelSearch={handleCancelSearch}
          insurancePage={planPage}
          insuranceLimit={planLimit}
          countPagination={countPagination}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      )}
    </>
  );
};
export default Plan;
