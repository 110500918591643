export const dataFormCertificates = {
  fileName: "",
  productName: "",
  typeFile: null,
};

export const LicenceKeys = {
  key: "eyJhbGciOiJFUzI1NiJ9.eyJleHAiOjE3NDQ3NjE1OTksImp0aSI6IjlhMDZlYmQ5LWJmMWEtNDMxOC05YmZkLTc3NDljM2MxZDRiMCIsInVzYWdlRW5kcG9pbnQiOiJodHRwczovL3Byb3h5LWV2ZW50LmNrZWRpdG9yLmNvbSIsImRpc3RyaWJ1dGlvbkNoYW5uZWwiOlsiY2xvdWQiLCJkcnVwYWwiLCJzaCJdLCJ3aGl0ZUxhYmVsIjp0cnVlLCJsaWNlbnNlVHlwZSI6InRyaWFsIiwiZmVhdHVyZXMiOlsiKiJdLCJ2YyI6IjFmMDBhMjZlIn0.BykffceSlRZEepTQ_FlTGYunOY51Xwyf65EwrmH8RutNu_gcYzSZoSPpqBHp7dIKZEMWz1IC1fs3LXZwCZzcRg",
};

export const CertificateType = [
  {
    id: "TEMPLATE",
    name: "Template",
  },
  {
    id: "ATTACHMENT",
    name: "Attachment",
  },
  {
    id: "QUICK_PRINT",
    name: "QuickPrint",
  },
];

export const mergeFieldsDataII = [
  {
    definitions: [
      {
        id: "name",
        label: "Nombre",
      },
      {
        id: "birthdate",
        label: "Fecha de nacimiento",
      },
    ],
    groupId: "guest",
    groupLabel: "Informacion del cliente",
  },
  {
    definitions: [
      {
        id: "providerName",
        label: "Provider name",
      },
      {
        id: "providerAddress",
        label: "Provider address",
      },
    ],
    groupId: "InsuranceInfo",
    groupLabel: "Información del seguro",
  },
];

interface Type {
  id: string;
  name: string;
}

export interface DataForm {
  fileName: string;
  productName: string;
  typeFile?: Type | null;
}
