import { fetchRequest } from "../fetchRequest";
import { URLs } from "../url";
import { getHeader, getHeaderUpload } from "../headers";


const postCollectionApi = async (body: any) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.collection;
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(body.data),
    headers: getHeader(),
  };
  try {
    return (await fetchRequest(newURL, requestOptions)) as any;
  } catch (error: any) {
    throw error;
  }
};

const postCollectionFilesApi = async (id:any, body: any) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.collectionsUpload+`/${id}`;
  const formData = new FormData();

    formData.append('file', body); // 'file' is the key your API expects
  const requestOptions = {
    method: "POST",
    body: formData,
    headers: getHeaderUpload(),
  };
  try {
    return (await fetchRequest(newURL, requestOptions)) as any;
  } catch (error: any) {
    throw error;
  }
};
const getCollections = async (enable: boolean, page: number, limit: number) => {
  const newURL =
    process.env.REACT_APP_HOST_API +
    URLs.collections +
    `?enable=${enable}&page=${page}&limit=${limit}`;
  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    return (await fetchRequest(newURL, requestOptions)) as any;
  } catch (error: any) {
    throw error;
  }
};

const getCollection = async (id:any) => {
  const newURL =
    process.env.REACT_APP_HOST_API +
    URLs.collections +
    `/${id}`;
  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    return (await fetchRequest(newURL, requestOptions)) as any;
  } catch (error: any) {
    throw error;
  }
};


export {  postCollectionApi, getCollections, postCollectionFilesApi, getCollection };
