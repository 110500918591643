import React, { useEffect, useState } from "react";
import { FormClientAFY } from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import LocalStorageService from "../../../services/localStorage";
import { Steps } from "../../../enum/steps";
import { hasPermission } from "../../../services/getNavigationPages";
import { useTranslation } from "react-i18next";

export const CustomerFormsView = (props: any) => {
  const { t } = useTranslation();
  const storageKey = "afy-steps";
  const localStorageService = new LocalStorageService(storageKey);

  const [dataForm, setDataForm] = useState<any>({
    Id: "",
    IdType: "",
    Type: "",
    DocumentNumber: "",
    Name: "",
    LastName: "",
    MarriedSurName: "",
    Gender: "",
    Birthday: "",
    Country: "",
    Province: "",
    Town: "",
    Sector: "",
    PostalCode: "",
    Address1: "",
    Address2: "",
    PhoneOffice: "",
    PhoneHome: "",
    PhoneNumber: "",
    Email: "",
  });

  const [plan, setPlan] = React.useState<any>({
    id: localStorageService.getStepStored().steps[Steps.plan].id,
    name: localStorageService.getStepStored().steps[Steps.plan].name,
  });

  const onAcquire = (item: any) => {
    setPlan(item);
    if (props.onAcquire) {
      props.onAcquire(item);
    }
  };

  useEffect(() => {
    if (props.dataForm) {
      setDataForm({
        // Id: props.dataForm.id,
        IdType: props.dataForm.IdType,
        Type: props.dataForm.Type,
        DocumentNumber: props.dataForm.DocumentNumber,
        Name: props.dataForm.Name,
        SecondName: props.dataForm.SecondName,
        //FirstLastName: props.dataForm.FirstLastName,
        //SecondLastName: props.dataForm.SecondLastName,
        MarriedSurName: props.dataForm.MarriedSurName,
        Gender: props.dataForm.Gender,
        Birthday: props.dataForm.Birthday,
        Country: props.dataForm.Country,
        Province: props.dataForm.Province,
        Town: props.dataForm.Town,
        Sector: props.dataForm.Sector,
        PostalCode: props.dataForm.PostalCode,
        Address1: props.dataForm.Address1,
        Address2: props.dataForm.Address2,
        PhoneOffice: props.dataForm.PhoneOffice,
        PhoneHome: props.dataForm.PhoneHome,
        PhoneNumber: props.dataForm.PhoneNumber,
        Email: props.dataForm.Email,
      });
    }
  }, [props.dataForm]);

  return (
    <FormClientAFY
      hideChangePlan={props.hideChangePlan}
      isRequired={!props.isApap}
      hideButtonChangeCampaing={props.hideButtonChangeCampaing}
      nextDisabled={!hasPermission("sale-customer-form-create")}
      title="Validar cliente"
      minHeightGrid={"calc(100vh - 163px)"}
      onBlurInput={props.handleOnBlur}
      hideMarriedName={props.hideMarriedName}
      onChangeInput={() => {}}
      onNext={props.handleSubmit}
      onBack={props.handleBack}
      stepProgress={props.stepProgress}
      TypeList={props.TypeList}
      GenderList={props.GenderList}
      CountryList={props.CountryList}
      onChangeOptions={props.onChangeOptions}
      onChangeSelect={props.onChangeSelect}
      ProvinceList={props.ProvinceList}
      titleSelectButton={t("customer.selectButton")}
      TownList={props.TownList}
      SectorList={props.SectorList}
      dataForm={props.dataForm}
      plans={props.plans}
      hide1={props.hide1}
      hide2={props.hide2}
      hide3={props.hide3}
      insuranceLabel={props.insuranceLabel}
      insuranceValue={props.insuranceValue}
      options1={props.options1}
      options2={props.options2}
      options3={props.options3}
      formsCompleted={[true, false, false]}
      plan={plan}
      showPaymentMethod={props.showPaymentMethod}
      paymentMethodData={props.paymentMethodData}
      onChangePaymentMethod={props.clickChangePaymentMode}
      onChangeCoverType={props.onChangeCoverType}
      onClickAcquire={onAcquire}
      disableSelectCountry={true}
      disableDocumentType={true}
      disableFields={true}
      modalPadding={20}
      filters={props.filters}
      showClientActions={props.showClientActions}
      editClientMode={props.editClientMode}
    />
  );
};
