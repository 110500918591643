import { person } from "../../../store/person/actions";

import { useDispatch } from "react-redux";

const useClients = (enable: any, page: any, limit: any) => {
    const dispatch = useDispatch();
    const handleSearch = (option: string, term: string) => {

        //Document
        if (option === "0") {
            dispatch(person({ enable: enable, page: page, limit: limit, numberId: term }));
        }

        //Name
        if (option === "1") {
            dispatch(person({ enable: enable, page: page, limit: limit, firstNameA: term }));
        }

        //Last Name
        if (option === "2") {
            dispatch(person({ enable: enable, page: page, limit: limit, lastNameA: term }));
        }

        //Email
        if (option === "3") {
            dispatch(person({ enable: enable, page: page, limit: limit, email: term }));
        }

    }
    return {
        handleSearch
    }

}
export default useClients;