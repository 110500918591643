import React from "react";
import { typeAlert } from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { TFunction } from "i18next";

interface ToastState {
  show: boolean;
  message: string;
  type: typeAlert;
}

type SetSeeToastFunction = React.Dispatch<React.SetStateAction<ToastState>>;

export const showErrorToast = (
  error: { errors: string | any[]; message: string },
  setSeeToastFunction: SetSeeToastFunction,
  tl: TFunction,
  showMessageApi?: boolean,
) => {
  if (!error) return;

  let message =
    "Hubo inconvenientes al procesar la solicitud, por favor contacte con la aseguradora";

  if (error.errors && Array.isArray(error.errors) && error.errors.length > 0) {
    if (showMessageApi) {
      message = error.errors.map((err) => err.message).join("\n");
    } else {
      message = error.errors
        .map((err) => tl(`errors.${err.message}`))
        .join("\n");
    }
  } else if (showMessageApi && error.message) {
    message = error.message;
  }

  setSeeToastFunction({
    show: true,
    message,
    type: typeAlert.error,
  });

  error.errors = [];
};
