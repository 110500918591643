import { useCallback, useMemo } from "react";
import {
  typeAlert,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { useDispatch } from "react-redux";

const useActions = (
  setOpenDrawerUpload: any,
  setPdfFile: any,
  setFileName: any,
  setIdDocumentClient: any,
  setModalDocumentClient: any,
  setUrlDocumentClient: any,
  setDocName: any,
  isVidaTermino: any,
  navigate: any,
  navigateNext: any,
  STEP: any,
  id: any,
  setOpenModalBackConfirm: any,
  isPlan: any,
  isDocumentClient: any,

) => {
  const dispatch = useDispatch();

  const handleNext = useCallback(
    () => {
      if (isVidaTermino) {
        navigate("/insurance-policies-summary");
      } else {
        navigateNext(STEP, navigate);
      }
    },
    [] // No dependencies
  );

  const handleSuccess = (message: any, dataClearAction: any, callback: any, setSeeToast: any, showToastSuccessMessage: any, setOpenModal: any, responseOneQuote: any) => {
    showToastSuccessMessage(message, typeAlert.success, setSeeToast);
    setOpenModal(false);
    dispatch(dataClearAction());
    callback(responseOneQuote);
  };

  const handlePrevious = useCallback(
    () => {
      if (id === undefined) {
        setOpenModalBackConfirm(true);
      } else {
        if (isPlan) {
          navigate("/");
        } else {
          navigateNext(STEP, navigate);
        }
      }
    },
    [] // No dependencies
  );

  const onClickBack = useCallback(
    () => {
      if (isDocumentClient === "true") {
        navigate("/pending-info-page/");
      } else {
        handlePrevious();
      }
    },
    [handlePrevious] // Depends on `handlePrevious`
  );

  const handleCloseBackModal = useCallback(() => {
    setOpenModalBackConfirm(false);
  }, []); // No dependencies

  const handleBackFromModal = useCallback(() => {
    navigate("/");
  }, []); // No dependencies




  const handleClickContextMenu = useCallback(
    (
      item: any,
      params: any,

    ) => {
      switch (item.id) {
        case "1":
          setOpenDrawerUpload(true);
          setPdfFile(undefined);
          setFileName("");
          setIdDocumentClient(params?.row?.id);
          break;
        case "2":
          setModalDocumentClient(true);
          setUrlDocumentClient(params?.row?.documentClientUrl);
          setDocName(params?.row?.docType);
          break;
        default:
          break;
      }
    },
    [] // No dependencies
  );

  // Return the memoized object
  return useMemo(
    () => ({
      handleNext,
      handlePrevious,
      onClickBack,
      handleCloseBackModal,
      handleBackFromModal,
      handleClickContextMenu,
      handleSuccess
    }),
    [
      handleNext,
      handlePrevious,
      onClickBack,
      handleCloseBackModal,
      handleBackFromModal,
      handleClickContextMenu,
      handleSuccess
    ] // List of dependencies
  );
};

export default useActions;
