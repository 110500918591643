import React from "react";
import {ListDropdownMultipleProps} from "../../../model/user";
import {useDispatch} from "react-redux";
import {person} from "../../../store/person/actions";
import {plans} from "../../../store/plans/actions";
import {region} from "../../../store/region/actions";

import {itemPlanProps, select, typeAlert,} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import LocalStorageService from "../../../services/localStorage";
import {PlanCoverTypes, PlanSelectOption} from "../../../model/plan";
import {removeNonNumeric, setCampaignData, setPaymentModeData,} from "../../../services/functions";
import {getCustomerAction} from "../../../fnx/customer";
import {formatNumberCustom, parsePrice} from "../../../utils/getPrice";
import {getCurrentDatePlusOneYear} from "../../../services/getDates";
import {quote} from "../../../store/generateQuote/actions";
import {useNavigate} from "react-router-dom";

const useCustomer = (
    coverTypeSelected: any,
    setCoverType: any,
    setPaymentSelected: any,
    setPlanSelected: any,
    responsePlansN: any,
    clientId: any,
    projectId: any,
    setHideMarriedName: any,
    enable: any,
    page: any,
    limit: any,
    level2: any,
    level3: any,
    listGenders: any,
    paymentMode: any,
    namePlan: any,
    company: any,
    carDetails: any,
    projectType: any,
    paymentModelSelected: any,
    setPeriod: any,
    paymentSelected: any,
    coverType: any,
    setListRegionLevel3: any,
    Steps: any,
    campaingSelected: any,
    setCoverTypeSelected: any,
    setPaymentMode: any,
    setPaymentModelSelected: any,
    
) => {
    const dispatch = useDispatch();
    const lsLogin = new LocalStorageService("afy-login");
    const navigate = useNavigate();
    const localStorageService = new LocalStorageService("afy-steps");
      const [seeToast, setSeeToast] = React.useState<{
        show: boolean;
        type: typeAlert;
        message: string;
      }>({
        show: false,
        message: "",
        type: typeAlert.success,
      });

      const onAcquire = (item: any) => {
        if (coverTypeSelected) {
          setCoverType(coverTypeSelected);
        } else {
          setCoverType(item.option[0]);
        }
        setPaymentSelected(item.paymentMethod);
        setPlanSelected({ id: item.id, name: item.name });
    
        callDispatchPlan();
      };
    
      const handleChangeSelect = (event: ListDropdownMultipleProps, id: string) => {
        const value = event.value;
        const name = id;
        if (name === "Province") handleChangeProvince(value);
        else if (name === "Town") handleChangeTown(value);
    
        setHideMarriedName(event.name !== "Femenino");
      };
    
      const handleOnBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, id } = event.target;
        //if (id === "DocumentNumber" && value.length > 3)
        // handleChangeDocument(value);
      };
    
      const handleChangeDocument = (number: string) => {
        dispatch(
          person({ enable: enable, page: page, limit: limit, numberId: number }),
        );
      };
    
      const handleChangeProvince = (id: string) => {
        dispatch(
          region({
            enable: enable,
            page: page,
            limit: limit,
            level: level2,
            parentRegionId: id,
            clientId: lsLogin.getUserLoginStored().clientId,
          }),
        );
        setListRegionLevel3([{ id: "-1", value: "-1", name: " " }]);
      };
      const handleChangeTown = (id: string) => {
        dispatch(
          region({
            enable: enable,
            page: page,
            limit: limit,
            level: level3,
            parentRegionId: id,
            clientId: lsLogin.getUserLoginStored().clientId,
          }),
        );
      };
    

      const handleSubmit = (data: any) => {
        if (data) {
          let numberId = data?.DocumentNumber?.replaceAll("-", "");
    
          const gender = listGenders.find((g:any) => g.id === data.Gender);
          const hideMarriedName = gender?.name !== "Femenino";
          const planIdSelected =
            localStorageService.getStepStored().steps[Steps.plan]?.id;
          let body: any = {
            numberId: numberId,
            identificationTypeId: data.IdType,
            firstNameA: data.Name,
            firstNameB: data.SecondName,
            lastNameA: data.FirstLastName,
            lastNameB: data.SecondLastName,
            marriedName: hideMarriedName ? "" : data.MarriedSurName,
            catalogSexo: data.Gender,
            dob: data.Birthday,
            phone1: removeNonNumeric(data.PhoneHome),
            phone2: removeNonNumeric(data.PhoneOffice),
            phone3: removeNonNumeric(data.PhoneNumber),
            address1: data.Address1,
            address2: data.Address2,
            province: data.Province,
            municipality: data.Town,
            district: data.Sector,
            nationality: data.Country,
            email: data.Email,
            planId: planIdSelected ? planIdSelected : responsePlansN[0]?.id,
            clientId: clientId,
            zip: data.PostalCode,
            campaignId: campaingSelected?.id,
            catalogPaymentMode: paymentSelected
              ? paymentSelected?.id
              : localStorageService.getStepStored().steps[Steps.plan]
                  .paymentModeSelected?.id,
            coverTypeId: coverType
              ? coverType?.id
              : localStorageService.getStepStored().steps[Steps.plan]?.coverType
                  ?.id,
            branch: lsLogin.getUserLoginStored().branchId,
            userId: lsLogin.getUserLoginStored().userId,
          };
          if (getCustomerAction().customerAuto.includes(company)) {
            if (
              localStorageService.getStepStored().steps[Steps.insurance]?.flow
                .metadata.navigation.productType === "VIDATERMINO"
            ) {
              if (responsePlansN && responsePlansN.length > 0) {
                body = {
                  ...body,
                  catalogPaymentMode: "aabedf92-8660-4012-924a-88b3760d47b8",
                  coverTypeId: responsePlansN[0]?.coverTypes ? responsePlansN[0].coverTypes[0]?.id ?? "" : "",
                  contentType:
                    localStorageService.getStepStored().steps[Steps.insurance]?.flow
                      .metadata.navigation.productType,
                  contentMap: {
                    productType:
                      localStorageService.getStepStored().steps[Steps.insurance]
                        ?.flow.metadata.navigation.productType,
                    currency:
                      localStorageService.getStepStored().steps[Steps.initialInfo]
                        ?.initialInfo?.currency?.name,
                    paymentFrequency:
                      localStorageService.getStepStored().steps[Steps.initialInfo]
                        ?.initialInfo?.policyDuration,
                    valorAsegurado: formatNumberCustom(
                      localStorageService.getStepStored().steps[Steps.initialInfo]
                        ?.initialInfo?.InsureAmount,
                    ),
                    branchId: lsLogin.getUserLoginStored().branchId,
                    deductibleTypeId:
                      localStorageService.getStepStored().steps[Steps.initialInfo]
                        ?.initialInfo?.premium, // se esta enviando el valor de la prima
                    productId: "0",
                    usageId: "4",
                    typeId: "1",
                    classId: "1",
                    planId:
                      localStorageService.getStepStored().steps[Steps.initialInfo] // se esta enviado el PLAN TYPE
                        ?.initialInfo?.planType?.id,
                  },
                };
              }
            } else {
              let price = parsePrice(carDetails?.price);
              const dates = getCurrentDatePlusOneYear();
    
              body = {
                ...body,
                contentType: "VEHICLE",
                catalogPaymentMode: "aabedf92-8660-4012-924a-88b3760d47b8",
                contentMap: {
                  employeeCode: carDetails?.employeeNumber,
                  productType: projectType.current,
                  brandId: carDetails.carDetails.brand.id,
                  modelId: carDetails.carDetails.model.id,
                  versionVehicle: carDetails.carDetails.version.id,
                  combustion: carDetails.fuelType.id,
                  colorId: carDetails.carColor.id,
                  cylinders: Number(carDetails.numberCylinders.id),
                  yearVehicle: carDetails.year,
                  condition: carDetails.carCondition.id,
                  registerVehicle: carDetails.chassisNumber,
                  plate: carDetails.registrationNumber,
                  codeId: data.PostalCode,
                  branchId: lsLogin.getUserLoginStored().branchId,
                  shortChassis: carDetails.shortChassis,
                  paymentPlanId:
                    localStorageService.getStepStored().steps[Steps.plan]
                      .paymentModeSelected?.id,
                  deductibleTypeId: price,
                  productId: "0",
                  passengerCantVehicle: carDetails.carDetails.passengers,
                  weightVehicle: carDetails.carDetails.weight,
                  tonnageVehicle: carDetails.carDetails.tonnage,
                  usageId: "4",
                  typeId: carDetails.carDetails.type.id,
                  classId: carDetails.carDetails.class.id,
                  startDate: dates.startDate,
                  endDate: dates.endDate,
                  planId: "0",
                },
              };
            }
          }
          dispatch(quote(body));
        }
      };
 
      
      const choiceInsuranceType = (value: string) => {
        navigate("/insurance");
      };
    
      const setCoverTypeStorybook = (ct: PlanCoverTypes[]) => {
        const optionConverType: PlanSelectOption[] = [];
        ct.map((item: PlanCoverTypes) => {
          optionConverType.push({
            name: item.description ? item.description : "",
            value: item.id,
            id: item.id,
          });
        });
        return optionConverType;
      };
    
      const callDispatchPlan = () => {
        if (paymentMode[0].id !== "-1") {
          dispatch(
            plans({
              enable: enable,
              page: page,
              limit: limit,
              projectId: projectId,
              name: namePlan,
              paymentModeId: paymentModelSelected.id,
              coverTypeId: coverTypeSelected?.id,
              clientId: clientId,
            }),
          );
        }
      };
      
      const onChangeCoverType = (event: any) => {
        setCoverTypeSelected(event);
      };
    
      const clickChangePaymentMode = (event: select, item: itemPlanProps) => {
        const t = paymentMode.find((p: any) => p.id === event);
        if (t) {
          setPeriod(t?.name);
          setPaymentModelSelected(t);
    
          //Set selected payment mode
          let pm = paymentMode;
          pm.map((item: any) => {
            item.default = false;
            if (item.id === t.id) {
              item.default = true;
            }
          });
          setPaymentMode(pm);
        } else {
          const pm = paymentMode.find((p: any) => p.value === event);
          if (pm) {
            if (t){
                setPeriod(t.name);
            }
            let ms = setPaymentModeData(event, paymentMode);
            setPaymentMode(ms);
            setPaymentModelSelected(pm);
          } else {
            setCampaignData(event);
          }
        }
        callDispatchPlan();
      };
    return {
        onAcquire,
        handleChangeSelect,
        handleOnBlur,
        handleChangeDocument,
        handleChangeProvince,
        handleChangeTown,
        handleSubmit,
        choiceInsuranceType,
        setCoverTypeStorybook,
        onChangeCoverType,
        clickChangePaymentMode,
        
      };
    }
    export default useCustomer;