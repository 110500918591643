import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { putCertTemplate } from "../../store/certificates/actions";

export const useApiUpdateCertificate = (
  trigger: boolean,
  id: string,
  certificateBody: any,
) => {
  const dispatch = useDispatch();

  const { loadingPutCertificate, responsePutCertificate, errorPutCertificate } =
    useSelector((state: any) => ({
      loadingPutCertificate: state.Certificates.loadingPutCertificate,
      responsePutCertificate: state.Certificates.responsePutCertificate,
      errorPutCertificate: state.Certificates.errorPutCertificate,
    }));
  useEffect(() => {
    if (trigger) {
      dispatch(putCertTemplate(certificateBody, id));
    }
  }, [trigger]);

  return {
    loadingPutCertificate,
    responsePutCertificate,
    errorPutCertificate,
  };
};
