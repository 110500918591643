// Code Generated with love
import { RowsModel } from "../../../model/insurancePoliciesSummary";
import { PlanCoverTypes, PlanModel, PlanSelectOption } from "../../../model/plan";
import { useDispatch, useSelector } from "react-redux";
import { paymentModeAction } from "../../../store/paymentModes/action";
import { plans, plansClearData } from "../../../store/plans/actions";
import { getPlans } from "../../../resources/helper/plans";
import {
    breadcrumb,
    icons,
    itemPlanProps,
    LoadingAFY,
    MenuItem,
    position,
    select,
    sizesIcon,
    typeAlert,
    typeLoaders,
  } from "@affinitysystemsgroup/github-packages-affinity-components/dist";
  import { InsurancePageProps } from "../resources/planDto";
const usePlan = (
setRowsQuotes: any,
planSelectedNextStep: any,
setShowModalQuotes: any,
navigate: any,
projectId: any,
enable: any,
page: any,
limit: any,
planPage: any,
planLimit: any,
namePlan: any,
paymentModelSelected: any,
coverTypeSelected: any,
clientId: any,
plan: any,
setPlan: any,
setUpdating: any,
changedPlanId: any,
setPaymentModeData: any,
paymentMode: any,
setPaymentMode: any,
campaignInfo: any,
setCampaignData: any,
setPaymentModelSelected: any,
setChangedPlanId: any,
setSelectedPlanId: any,
setCoverTypeSelected: any,
selectedPlanId: any,
setShowDetails: any,
setShowDetail: any,
setShowCompareDetail: any,
setPlanSelected: any,
localStorageService: any,
Steps: any,
setPlanSelectedNextStep: any,
setFiltersQuotes: any,
insuranceType: any,
insurance: any,
navigateNext: any,
STEP: any,
setSeeToastAlertNext: any,
setNamePlan: any,
) => {
      const dispatch = useDispatch();
    const buildDataRowsQuotes = (responseAllQuote: RowsModel[]) => {
        const dataR = responseAllQuote?.map((item: RowsModel) => ({
          id: item.id,
          storeChannel: "Sucursal 1",
          quote: item.policyId ? item.policyId?.code : item.code,
          policyId: item.policyId?.id,
          product: item.productId?.description,
          status: item.catalogStatusQuote?.description,
          name: `${item.firstNameA} ${item.firstNameB} ${item.lastNameA} ${item.lastNameB}`,
        }));
    
        setRowsQuotes(dataR);
    
        if (planSelectedNextStep.id) {
          if (dataR?.length > 0) {
            setShowModalQuotes(true);
          } else {
            navigate("/customerForm");
          }
        }
      };
    
      const setCoverTypeStorybook = (ct: PlanCoverTypes[]) => {
        const optionConverType: PlanSelectOption[] = [];
        ct.map((item: PlanCoverTypes) => {
          optionConverType.push({
            name: item.description ? item.description : "",
            value: item.id,
            id: item.id,
          });
        });
        return optionConverType;
      };
    

    
    
      // const callDispatchCatalog = () => {
      //   if (!responsePlan) {
      //     dispatch(catalog({ enable: enable, page: page, limit: limit }));
      //   }
      // };
    
      const callDispatchPaymentMode = () => {
        if (projectId)
          dispatch(paymentModeAction({ enable: enable, page, limit, projectId }));
      };
    
      const callDispatchPlan = () => {
        // if (paymentMode[0].id !== "-1") {
        dispatch(
          plans({
            enable: enable,
            page: planPage,
            limit: planLimit,
            projectId: projectId,
            name: namePlan,
            paymentModeId: paymentModelSelected.id,
            coverTypeId: coverTypeSelected?.id,
            clientId: clientId,
          })
        );
        //}
      };
    
      const updateCoverPrice = (item: itemPlanProps, event: select) => {
        //vamos
        setUpdating(true);
        getPlans(
          enable,
          page,
          limit,
          projectId,
          namePlan,
          paymentModelSelected.id ?? "",
          event.id ?? "",
          clientId
        )
          .then((response: any) => {
            setUpdating(false);
            let result = response.data.find(
              (plan: any) => plan.id === changedPlanId
            );
            if (result) {
              let newPlan: InsurancePageProps = plan;
    
              newPlan.data?.forEach((plan) => {
                if (plan.id === result.id) {
                  plan.price = result.price?.amount;
                }
              });
              setPlan({ ...newPlan });
            }
          })
          .catch((error: any) => {
            setUpdating(false);
          });
      };
    
      const clickChangePaymentMode = (
        event: select,
        item: itemPlanProps,
        row: any
      ) => {
        if (item?.id) {
          console.log(
            "Opcion de pago seleccionada: ",
            event,
            "Plan seleccionado: ",
            item.id,
            "Fila seleccionada: ",
            row
          );
          setChangedPlanId(item.id);
          setCoverTypeSelected(event);
          savePlan(null, event, item.id);
        } else {
          if (row) {
          } else {
            const t = paymentMode.find((p: any) => p.id === event);
            console.log("paymentModeSelected: ", t);
            if (t) {
              setPaymentModelSelected(t);
              let ms = setPaymentModeData(event, paymentMode);
              setPaymentMode(ms);
              if (coverTypeSelected && coverTypeSelected.id) {
                savePlan(null, coverTypeSelected, selectedPlanId);
              }
            } else {
              setCampaignData(event);
            }
            dispatch(plansClearData());
          }
        }
      };
    
      const clickDetails = (item: itemPlanProps) => {
        /*Valida si el plan a mostrar el detalle
         * ya tiene la respuesta del api solo muestra
         * el modal  si no la tiene llama al api para
         * recuperar los detalles */
        setShowDetails(true);
        /* if (planSelected.id === item.id) {
               console.log("--->",planSelected)
               setPlanSelected(planSelected);
               setShowDetail(true);
             }
         */
        // setIsDetailClick(true);
        setPlanSelected(item);
        setShowDetail(true);
      };
    
      const clickCompareDetails = (items: itemPlanProps[]) => {
        // setIsDetailClick(false);
        setShowCompareDetail(true);
      };
    

    
      const savePlan = (item: any, cv: any, cp: any) => {
        if (item) {
          localStorageService.setStepStored({
            id: item.id,
            name: item.name as string,
            step: Steps.plan,
            completed: true,
            paymentMode: paymentMode,
            paymentModeSelected: paymentModelSelected,
            coverType: cv ? cv : item.option ? item.option[0] : "",
            campaign: campaignInfo,
            campaignSelected: campaignInfo ? campaignInfo[0] : "",
          });
        } else {
          if (cp === selectedPlanId) {
            localStorageService.setStepStored({
              id: localStorageService.getStepStored().steps[Steps.plan].id,
              name: localStorageService.getStepStored().steps[Steps.plan]
                .name as string,
              step: Steps.plan,
              completed: true,
              paymentMode:
                localStorageService.getStepStored().steps[Steps.plan].paymentMode,
              paymentModeSelected:
                localStorageService.getStepStored().steps[Steps.plan]
                  .paymentModeSelected,
              coverType: cv,
              campaign:
                localStorageService.getStepStored().steps[Steps.plan].campaign,
              campaignSelected:
                localStorageService.getStepStored().steps[Steps.plan]
                  .campaignSelected,
            });
          }
        }
      };
    
      const onClickSelectRowQuote = (params: any) => {
        if (params.status.toLowerCase() === "cotizada") {
          navigate("/confirmationForm/" + params.id + "/true/true");
        } else {
          navigate("/confirmationForm/" + params.id + "/false/true");
        }
      };
    
      const clickCard = (card: itemPlanProps) => {
        console.log("clickCard::: ", card);
        let coverType = card.option?.find((i: any) => i.id === card.selected);
        setSelectedPlanId(card.id);
        setCoverTypeSelected(coverType);
        setPlanSelectedNextStep(card);
        savePlan(card, coverType, null);
        onClickNext(card);
      };
    
      const clickAcquire = (item: any) => {
        console.log("acquire::: ", item);
        let options: any = [];
        plan &&
          plan.data?.map((it: any) => {
            if (it.id === item.id) {
              options.push(it);
            }
          });
        item.option = options;
        savePlan(item, coverTypeSelected, null);
      };
    
      const onClickNext = (card: any) => {
        let sizeDoc = 0;
    
        if (card.id.length > 0) {
          /*
                 let vars = [
                   {
                     idType: idType,
                     docNumber: dns,
                   },
                 ];
         
                 lsVariables.setVariablesStored(vars);
         
                 dispatch(
                   quoteAll({
                     clientId: clientId,
                     enable: enable,
                     page: page,
                     limit: limit,
                     numberId: dns,
                     fields: "abc",
                   }),
                 );
                 */
          setFiltersQuotes([
            {
              label: insuranceType,
              name: "Tipo de Seguro",
              type: "label",
            },
            {
              label: insurance,
              name: "Seguro",
              type: "label",
            },
            {
              label: card.name,
              name: "Plan",
              type: "label",
            },
            {
              label: campaignInfo ? campaignInfo[0].name : "",
              name: "Campaña",
              type: "label",
            },
          ]);
    
          //navigate("/client-record");
          navigateNext(STEP, navigate);
        } else {
          /*Cuando no se tiene ningun plan selecionado */
          setSeeToastAlertNext({
            show: true,
            message: "¡Se debe de seleccionar un plan!",
            type: typeAlert.info,
          });
        }
      };

      const handleCancelSearch = () => {
          setNamePlan("");
          dispatch(
            plans({
              enable: enable,
              page: page,
              limit: limit,
              projectId: projectId,
              name: "",
              paymentModeId: paymentModelSelected.id,
              coverTypeId: coverTypeSelected?.id,
              clientId: clientId,
            })
          );
        };
      

    return {
        buildDataRowsQuotes,
        setCoverTypeStorybook,
        callDispatchPaymentMode,
        callDispatchPlan,
        updateCoverPrice,
        clickChangePaymentMode,
        clickDetails,
        clickCompareDetails,
        savePlan,
        onClickSelectRowQuote,
        clickCard,
        clickAcquire,
        onClickNext,
        handleCancelSearch,
    };
}
export default usePlan;