import React, { useEffect, useState } from "react";
import {
  breadcrumb,
  MenuItem,
  typeAlert,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { InitialInfoView } from "./view";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { quoteAllClearData } from "../../store/generateQuote/actions";
import { postCalculationsAPI } from "../../resources/helperAPAP/calculations";
import "./style.css";
import showToast from "../../services/toast";
import LocalStorageService from "../../services/localStorage";
import { Steps } from "../../enum/steps";
import {
  navigateBack,
  navigateNext,
  stepProgress,
} from "../../dataBase/stepProgress";
import { hasPermission } from "../../services/getNavigationPages";
import { STEP_INITIALINFO } from "../../const/catalog";
import { formatToTwoDecimals } from "../../utils/getPrice";
import { AlertLoadingHeader } from "../../components/alertLoadingComponent/alertLoading";

const storageKey2 = "afy-login";

export const InitialInfoPage = () => {
  const STEP = STEP_INITIALINFO;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [steps, setSteps] = React.useState<MenuItem[]>([]);
  const [filters, setFilters] = useState<any[]>([]);
  const [breadCrumbs, setBreadCrumbs] = React.useState<breadcrumb[]>([]);
  const [showNextDisabled, setShowNextDisabled] = useState<boolean>(true);
  const storageKeyVariables = "afy-variables";
  const [valorEstimado, setValorEstimado] = useState<string>("0.00");
  const [currency, setCurrency] = useState<string>("DOP");
  const [maxValue, setMaxValue] = useState<number>(18000000);
  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  const [loading, setLoading] = useState<boolean>(false);
  const permissions = {
    create: hasPermission("initial-info"),
  };

  const storageKey = "afy-steps";
  const localStorageService = new LocalStorageService(storageKey);

  useEffect(() => {
    dispatch(quoteAllClearData());
    setBreadCrumbs(stepProgress(STEP, true));
    setSteps(stepProgress(STEP));
    // Comes from the client page

    setFilters([
      {
        name: "Producto",
        label:
          localStorageService.getStepStored().steps[Steps.insurance]?.name ||
          "Vida",
        type: "label",
      },
    ]);
  }, []);

  const handleClickPrevious = (e: React.MouseEvent<HTMLButtonElement>) => {
    navigateBack(STEP, navigate);
  };
  const calculateAge = (dateString: string) => {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  const calculateInterestRate = (currency: string) => {
    let interestRate = 0;
    if (currency === "DOP") {
      interestRate = 7;
    } else {
      interestRate = 3;
    }
    return interestRate;
  };

  const handleClickNext = (e: any) => {
    setLoading(true);
    if (e.data) {
      let data = {
        age: calculateAge(e.data.dateBirth),
        interval: e.data.policyDuration,
        interestRate: calculateInterestRate(e.data.currency.id),
        utility: 5.0,
        coverageAmount: e.data.InsureAmount,
        reinsuranceRatePerThousand: 1,
        productType: e.data.planType.id,
        gender: e.data.sexType.id,
        currency: e.data.currency.id,
        smokes: e.data.smoke.id,
      };

      postCalculationsAPI(data)
        .then((response) => {
          setLoading(false);
          let premium = response.data[0]?.premiumRate;
          const formattedPremium = new Intl.NumberFormat("es-DO", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(premium);

          let newPrice = parseFloat(formattedPremium.replace(/,/g, "")) * 1.16;

          setValorEstimado(formatToTwoDecimals(newPrice.toString()));
          e.data.premium = formattedPremium;

          localStorageService.setStepStored({
            id: "",
            name: "",
            step: Steps.initialInfo,
            completed: true,
            initialInfo: e.data,
          });
          if (premium === 0 || premium === null || premium === undefined) {
            setSeeToast({
              show: true,
              message: "El valor estimado debe ser mayor a 0.00",
              type: typeAlert.warning,
            });
            setShowNextDisabled(true);
            return;
          }

          setSeeToast({
            show: true,
            message: "Los datos han sido guardados",
            type: typeAlert.success,
          });
          setShowNextDisabled(false);
        })
        .catch((error) => {
          console.log("postCalculationsAPI error", error);
          setLoading(false);
          setShowNextDisabled(true);
          console.log("error", error);
          setSeeToast({
            show: true,
            message: "Error al realizar los cálculos",
            type: typeAlert.error,
          });
        });
    } else {
      navigateNext(STEP, navigate);
    }
  };

  const onChangeCurrency = (data: any) => {
    if (data.id === "DOP") {
      setCurrency("DOP");
      setMaxValue(18000000);
    } else {
      setCurrency("USD");
      setMaxValue(300000);
    }
  };

  return (
    <React.Fragment>
      <AlertLoadingHeader
        loading={loading}
        seeToast={seeToast}
        showToast={showToast}
        setSeeToast={setSeeToast}
        titleLoading={"Calculando..."}
      />
      <InitialInfoView
        title={"Datos iniciales"}
        steps={steps}
        breadcrumbs={breadCrumbs}
        stepProgress={steps}
        previousLabel={"Anterior"}
        nextLabel={"Siguiente"}
        nextDisabled={showNextDisabled}
        onClickNext={handleClickNext}
        onClickPrevious={handleClickPrevious}
        filters={filters}
        minHeightGrid="calc(100vh - 197px)"
        resumeDescription={"Valor estimado"}
        resumeValue={`RD$ ${valorEstimado}`}
        titleContent={"Datos iniciales"}
        titleContentSectionB={"Coberturas opcionales"}
        titleContentSectionC={"Tipo de NCF requerido"}
        lblPlanType={"Tipo de plan"}
        lblDateBirth={"Fecha de nacimiento"}
        lblSex={"Sexo"}
        listPlanType={[
          { id: "TEMPORAL", name: "Temporal" },
          { id: "DOTAL_MIXTO", name: "Dotal mixto" },
          { id: "DOTAL", name: "Dotal" },
        ]}
        listSex={[
          { id: "M", name: "Masculino" },
          { id: "F", name: "Femenino" },
        ]}
        lblSmoke={"Fuma"}
        listSmoke={[
          { id: "N", name: "NO" },
          { id: "S", name: "SI" },
        ]}
        listCurrency={[{ id: "DOP", name: "DOP" }]}
        lblCurrency={"Moneda"}
        titleYearSlider={"Duración de la Póliza"}
        subtitleYearSlider={"Años"}
        minValueYearSlider={5}
        maxValueYearSlider={20}
        widthYearSlider={730}
        stepYearSlider={5}
        defaultValueYearSlider={5}
        fontSizeSubtitleYearSlider={"14px"}
        fontSizeTitleYearSlider={"14px"}
        inputFontSizeYearSlider={"12px"}
        inputWidthSizeYearSlider={"40px"}
        titleDecimalSlider={"Suma asegurada"}
        subtitleDecimalSlider={currency}
        minValueDecimalSlider={100}
        maxValueDecimalSlider={maxValue}
        widthDecimalSlider={730}
        stepDecimalSlider={100}
        defaultValueDecimalSlider={8000}
        fontSizeSubtitleDecimalSlider={"14px"}
        fontSizeTitleDecimalSlider={"14px"}
        inputFontSizeDecimalSlider={"12px"}
        inputWidthSizeDecimalSlider={"170px"}
        onChangeCurrency={onChangeCurrency}
      />
    </React.Fragment>
  );
};
