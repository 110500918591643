
import {
    typeAlert,
    position,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import showToast from "../../../../services/toast";

export const AuditPageHeader = (props: any) => {
    return (
        <>
            {props.seeToast.show &&
                showToast(position.topRight, props.seeToast.type, props.seeToast.message, () => {
                    props.setSeeToast({ show: false, message: "", type: typeAlert.success });
                })}
        </>
    )
}
