import React from "react";
import { useDispatch } from "react-redux";
import { getBeneficiary } from "../../../store/beneficiary/actions";
import { insured } from "../../../store/insured/actions";
import { personByIdClearData } from "../../../store/person/actions";

import { quoteAllClearData } from "../../../store/generateQuote/actions";
import { typeAlert } from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { useNavigate } from "react-router-dom";
import { navigateBack, navigateNext } from "../../../dataBase/stepProgress";

const useUI = (
  setShowBeneficiariesModal: any,
  setShowInsuredModal: any,
  id: any,
  STEP: any,
  isValid: any,
  setSeeToast: any,
) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClickContextMenu = (item: any, row: any) => {
    let data = row.row;
    let productId = data?.productId;
    if (item.name === "Beneficiarios") {
      dispatch(
        getBeneficiary({
          enable: true,
          page: 1,
          limit: 100,
          quoteId: data.id,
        }),
      );

      setShowBeneficiariesModal(true);
    }
    if (item.name === "Asegurados") {
      dispatch(
        insured({
          enable: true,
          page: 1,
          limit: 100,
          quoteId: data.id,
        }),
      );
      setShowInsuredModal(true);
    }
    if (item.name === "Ver detalles") {
      if (
        data.estado === "Expirada" ||
        data.estado === "Cancelada" ||
        data.estado === "Emitida"
      ) {
        navigate(`/confirmationForm/${data.id}/false/false/${productId}`);
      } else {
        navigate(`/confirmationForm/${data.id}/true/false/${productId}`);
      }
    }
  };
  const handleClickPrevious = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (id) {
      navigate("/clients");
    } else {
      dispatch(personByIdClearData());
      dispatch(quoteAllClearData());
      navigateBack(STEP, navigate);
      //navigate("/plan");
    }
  };

  const handleClickNext = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (isValid() === true) {
      dispatch(personByIdClearData());
      //navigate("/customerForm");
      navigateNext(STEP, navigate);
    } else {
      /*Cuando no se tiene ningun plan selecionado */
      setSeeToast({
        show: true,
        message: "¡Debe ingrensar un documento válido!",
        type: typeAlert.info,
      });
    }
  };
  return {
    handleClickContextMenu,
    handleClickPrevious,
    handleClickNext,
  };
};
export default useUI;
