import { useSelector } from "react-redux";
import { useProjectsByCustomerId } from "../../../fnx/projectsByCustomerId";
const useSelectors = (
    clientId: any,
    trigger: any,
    sponsorId: any
) => {

    const {
      responseSponsor,
      errorSponsor,
      loadingSponsor,
      responseInsurance,
      loadingInsurance,
      errorInsurance,
    } = useProjectsByCustomerId(clientId, trigger, "", "", sponsorId);
    
    const { responseCancelPolicy, loadingCancelPolicy, errorCancelPolicy } =
        useSelector((state: any) => ({
            responseCancelPolicy: state.Policy.responseCancelPolicy,
            loadingCancelPolicy: state.Policy.loadingCancelPolicy,
            errorCancelPolicy: state.Policy.errorCancelPolicy,
        }));

    const { responseDeleteEntity, loadingDeleteEntity, errorDeleteEntity } =
        useSelector((state: any) => ({
            responseDeleteEntity: state.DeleteEntity.responseDeleteEntity,
            loadingDeleteEntity: state.DeleteEntity.loadingDeleteEntity,
            errorDeleteEntity: state.DeleteEntity.errorDeleteEntity,
        }));

    const { responseCancelQuote, loadingCancelQuote, errorCancelQuote } =
        useSelector((state: any) => ({
            responseCancelQuote: state.CancelQuote.responseCancelQuote,
            loadingCancelQuote: state.CancelQuote.loadingDeleteEntity,
            errorCancelQuote: state.CancelQuote.errorDeleteEntity,
        }));

    const { responsePlan, errorPlan, loading } = useSelector((state: any) => ({
        responsePlan: state.Plan.responsePlan,
        errorPlan: state.Plan.errorPlan,
        loading: state.Plan.loadingPlan,
    }));

    const { responseProject, errorProject, loadingProject } = useSelector(
        (state: any) => ({
            responseProject: state.Project.responseProject?.data,
            errorProject: state.Project.errorProject,
            loadingProject: state.Project.loadingProject,
        })
    );

    const {
        responseGetCatalogStatusQuote,
        errorGetCatalogStatusQuote,
        loadingGetCatalogStatusQuote,
    } = useSelector((state: any) => ({
        responseGetCatalogStatusQuote:
            state.Catalog.responseGetCatalogStatusQuote?.data,
        errorGetCatalogStatusQuote: state.Catalog.errorGetCatalogStatusQuote,
        loadingGetCatalogStatusQuote: state.Catalog.loadingGetCatalogStatusQuote,
    }));

    const { responseAllQuote, errorAllQuote, loadingAllQuote } = useSelector(
        (state: any) => ({
            responseAllQuote: state.Quote.responseAllQuote,
            errorAllQuote: state.Quote.errorAllQuote,
            loadingAllQuote: state.Quote.loadingAllQuote,
        })
    );

    const { responseBranches, errorBranches, loadingBranches } = useSelector(
        (state: any) => ({
            responseBranches: state.Branches.responseBranches?.data,
            errorBranches: state.Branches.errorBranches,
            loadingBranches: state.Branches.loadingBranches,
        })
    );

    const { responseOneQuote, errorOneQuote, loadingOneQuote } = useSelector(
        (state: any) => ({
            responseOneQuote: state.Quote.responseOneQuote,
            errorOneQuote: state.Quote.errorOneQuote,
            loadingOneQuote: state.Quote.loadingOneQuote,
        })
    );

    return {
        responseCancelPolicy,
        loadingCancelPolicy,
        errorCancelPolicy,
        responseDeleteEntity,
        loadingDeleteEntity,
        errorDeleteEntity,
        responseCancelQuote,
        loadingCancelQuote,
        errorCancelQuote,
        responsePlan,
        errorPlan,
        loading,
        responseProject,
        errorProject,
        loadingProject,
        responseGetCatalogStatusQuote,
        errorGetCatalogStatusQuote,
        loadingGetCatalogStatusQuote,
        responseAllQuote,
        errorAllQuote,
        loadingAllQuote,
        responseBranches,
        errorBranches,
        loadingBranches,
        responseOneQuote,
        errorOneQuote,
        loadingOneQuote,
        responseSponsor,
        errorSponsor,
        loadingSponsor,
        responseInsurance,
        loadingInsurance,
        errorInsurance,
        
    }
}
export default useSelectors;