import { useCallback, useMemo, useState } from "react";
import { BodyRequest } from "../../../model/certificates";

const useFnUpdateCertificate = () => {
  const [updateBodyRequest, setUpdateBodyRequest] = useState<BodyRequest>();

  const updateCertificate = useCallback(
    (
      data: string | undefined,
      fileName: string,
      typeFile: string,
      setTriggerUpdate: (value: boolean | (() => boolean)) => void,
    ) => {
      setUpdateBodyRequest({
        title: fileName,
        type: typeFile,
        fileName: fileName,
        html: data,
        physicalName: fileName,
        quickPrintable: false,
      });

      setTriggerUpdate(true);
    },
    [],
  );

  return useMemo(
    () => ({
      updateCertificate,
      updateBodyRequest,
    }),
    [updateCertificate, updateBodyRequest],
  );
};

export default useFnUpdateCertificate;
