import React from "react";
import { useStyles } from "./style";
import "./style.css";

import {
  AdminSponsorAFY,
  ButtonAFY,
  CardProps,
  icons,
  ListDropdownMultipleProps,
  LoadingAFY,
  MenuItem,
  ModalComponentAFY,
  sizes,
  sizesIcon,
  sizesSelect,
  typeLoaders,
  variants,
  variantsAvatar,
  variantsTextField,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { buttonActionOptions, insuranceTypeView, optionsSelect } from "./data";
import { themeCore } from "../../assets/themes/theme";
import { DeleteModal } from "../../components/deleteModal/deleteModal";

export const ViewInsuranceType = (props: {
  title: string;
  permissions: any;
  loadingInsuranceType: any;
  insuranceTypeOptions: ListDropdownMultipleProps[];
  insuranceTypeList: CardProps[];
  listClientId: any;
  openModal: boolean;
  openModalConfirmDelete: boolean;
  setOpenModalConfirmDelete: (value: boolean | (() => boolean)) => void;
  openModalCloneConfirm: boolean;
  loadingPostInsuranceType: any;
  dataForm: any;
  modalTitle: string;
  onSaveData: any;
  clickView: (view: MenuItem) => void;
  onClickDelete: () => void;
  onSubmitClone: () => void;
  onCloseCloneModal: () => void;
  onClickAdd: () => void;
  onClickContextMenu: () => void;
  filterStatus: (item: any) => void;
  searchFilter: (term: any) => void;
  onCloseSearch: () => void;
  projectTypeTitle: string;
  openDrawer?: boolean;
  onCloseDrawer: () => void;
  insuranceTypePage: number;
  insuranceTypeLimit: number;
  countPagination: number;
  onChangePage: (e: number) => void;
  onChangeRowsPerPage: (e: number) => void;
  titleDrawer: string;
}) => {
  const classes = useStyles();
  const buttonActionOptionsList: MenuItem[] = [
    {
      disabled: !props.permissions.update,
      id: "1",
      name: "Modificar",
      divider: false,
      icon: {
        color: "#006ac6",
        icon: icons.Edit,
        size: sizesIcon.standard,
      },
    },
    {
      disabled: !props.permissions.delete,
      id: "2",
      name: "Eliminar",
      divider: false,
      icon: {
        color: "#006ac6",
        icon: icons.DeleteForever,
        size: sizesIcon.standard,
      },
    },
  ];

  return (
    <>
      <LoadingAFY
        loading={props.loadingInsuranceType || props.loadingPostInsuranceType}
        backgroundColor={themeCore.colors.backgroundLoader}
        typeAnimation={typeLoaders.LogoAfy}
        sizeAnimation={65}
        textLoading={"Cargando Tipo de Seguro..."}
        textLoadingColor={themeCore.colors.third}
        spinnerColor={themeCore.colors.third}
      />

      <DeleteModal
        openModalConfirmDelete={props.openModalConfirmDelete}
        modalTitle={props.modalTitle}
        setOpenModalConfirmDelete={props.setOpenModalConfirmDelete}
        onClickDelete={props.onClickDelete}
      />

      <ModalComponentAFY
        openModal={props.openModalCloneConfirm}
        backgroundColor="#ffffff"
        onClose={props.onCloseCloneModal}
        border={0}
        modalWidth={500}
        padding={30}
      >
        <div style={{ marginTop: "10px" }}>
          <h2 className={classes.title}>Clonar {props.modalTitle}</h2>
          <p className={classes.description}>
            {`¿Está seguro que desea clonar ${props.modalTitle}?`}
          </p>
          <div className={classes.buttonsContainer}>
            <div className={classes.button}>
              <ButtonAFY
                variant={variants.contained}
                size={sizes.large}
                label={"No"}
                onClick={props.onCloseCloneModal}
              />
            </div>
            <div className={classes.button}>
              <ButtonAFY
                variant={variants.contained}
                size={sizes.large}
                label={"Si, Clonar"}
                onClick={(e) => {
                  e.stopPropagation();
                  props.onSubmitClone();
                }}
              />
            </div>
          </div>
        </div>
      </ModalComponentAFY>

      <AdminSponsorAFY
        title={props.title}
        showFilters={true}
        permissions={props.permissions}
        backgroundColor="#ffffff"
        titleSelectSponsor="Tipo de Seguro"
        titleClearButton="Limpiar filtros"
        clientLabel="Cliente"
        textFilterSearch="Buscar"
        textFilterSearchDrop="Buscar"
        textBackSearch="Volver"
        backgroundColorSearch="#F6F7FB"
        titleColor="#3A56A1"
        colorClientLabel="#3A56A1"
        iconBgColor="#F6F7FB"
        iconColor="#3A56A1"
        iconColorSearch="#3A56A1"
        heightAddNew="120px"
        heightAddNewList="50px"
        heightCard="120px"
        heightCardList="50px"
        iconBackgroundColor={""}
        textColor={""}
        iconAvatar={icons.Search}
        iconSearch={icons.Search}
        selectSize={sizesSelect.small}
        avatarVariant={variantsAvatar.rounded}
        selectVariant={variantsTextField.outlined}
        sizeIcon={sizesIcon.standard}
        sizesIconSearch={sizesIcon.standard}
        disabledSelect={true}
        insurersView={insuranceTypeView}
        buttonActionOptions={buttonActionOptions}
        menuItem={buttonActionOptionsList}
        optionsSelect={optionsSelect}
        data={props.insuranceTypeList}
        onClickAdd={props.onClickAdd}
        // onClickButtonOptionsDropdown={props.onClickButtonOptionsDropdown}
        onClickContextMenu={props.onClickContextMenu}
        onCloseSearch={props.onCloseSearch}
        onChangeData={props.filterStatus}
        onChangeSearch={props.searchFilter}
        onClickButtonView={(option) => {
          props.clickView && props.clickView(option);
        }}
        openDrawer={props.openDrawer}
        dataForm={props.dataForm}
        titleDrawer={props.titleDrawer}
        textDescription={
          "Ingrese el tipo de producto o seguro. Esto ayudará a clasificar y organizar los seguros de manera más eficiente, facilitando la gestión y búsqueda de productos específicos. La clasificación puede basarse en el tipo de cobertura, el mercado objetivo, o cualquier otro criterio relevante."
        }
        topLogoUpload={"145px"}
        rightLogoUpload={"549px"}
        widthLabelLinearForm={"300px"}
        topNameLogoUpload={""}
        rightNameLogoUpload={""}
        viewLinearForm={true}
        onCloseDrawer={props.onCloseDrawer}
        onSaveData={props.onSaveData}
        titlePagination={"Registros por página"}
        countPagination={props.countPagination}
        rowsPerPage={props.insuranceTypeLimit}
        onChangePage={props.onChangePage}
        rowsPerPageOption={[20, 25, 50, 100]}
        onChangeRowsPerPage={props.onChangeRowsPerPage}
        pagePagination={props.insuranceTypePage}
      />
    </>
  );
};
