import {
  navigateBack,
} from "../../../dataBase/stepProgress";

import { ModalComponentAFY, SuccessAFY } from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { themeCore } from "../../../assets/themes/theme";

const useUI = (
  STEP: any,
  navigate: any,
  showModal: any,
  setShowModal: any,
  responseOneQuote: any,

) => {
  const showSuccess = () => {
    let policyCode = responseOneQuote?.data?.policyId?.code || ""; // Default the current one

    try {
      const finalizersMetadata =
        responseOneQuote?.data?.policyId?.finalizersMetadata;

      if (finalizersMetadata) {
        const parsedMetadata = JSON.parse(finalizersMetadata);
        const dataMap = parsedMetadata?.dataMap || [];

        if (dataMap.length > 0 && dataMap[0].result?.code) {
          policyCode = dataMap[0].result.code;
        }
      }
    } catch (error) {
      console.error("Error parsing finalizersMetadata:", error);
    }

    return (
      <ModalComponentAFY
        openModal={showModal}
        onClose={closeModal}
        backgroundColor={themeCore.colors.background}
        BgBackdrop={themeCore.colors.backgroundLoader}
        modalWidthNew={"auto"}
        minWidthModal={"250px"}
      >
        <SuccessAFY
          message="La póliza ha sido creada."
          numberAmount={policyCode}
          numberMessage="No. Póliza"
          onClickButton={() => {}}
          buttonColor={""}
          buttonText={""}
          title="Póliza creada"
          animate={false}
          colorText={"#FFFFFF"}
          fontSizeTitle={"1.3rem"}
          fontSizeDescriptionMsg={"14px"}
        />
      </ModalComponentAFY>
    );
  };

  const handlePrevious = () => {
    navigateBack(STEP, navigate);
  };

  const handleNext = () => {
    navigate("/insurance-policies-summary");
  };
  const handleDownload = (obj: any) => {
    window.open(obj.url, "_blank");
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return {
    showSuccess,
    handlePrevious,
    handleNext,
    handleDownload
  };
}
export default useUI;
