import {
  ContextMenuAFY,
  icons,
  ListDropdownMultipleProps,
  MenuItem,
  sizesIcon,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { LabelAutoCompleteProps } from "../../../model/user";
import React from "react";

interface TableColumn {
  field: string;
  headerName: string;
  width: number;
  renderCell?: (params: any) => JSX.Element;
}

export const insurersView: MenuItem[] = [
  {
    active: true,
    icon: {
      icon: icons.GridView,
    },
    id: "1",
    name: "GridView",
  },
  {
    active: false,
    icon: {
      icon: icons.TableRows,
    },
    id: "2",
    name: "List",
  },
  {
    active: false,
    icon: {
      icon: icons.TableView,
    },
    id: "3",
    name: "tableView",
  },
];

export const buttonOptions: MenuItem[] = [
  {
    disabled: false,
    id: "1",
    name: "Modificar",
    divider: false,
    icon: {
      color: "#006ac6",
      icon: icons.Edit,
      size: sizesIcon.standard,
    },
  },
  {
    disabled: false,
    id: "2",
    name: "Eliminar",
    divider: false,
    icon: {
      color: "#006ac6",
      icon: icons.DeleteForever,
      size: sizesIcon.standard,
    },
  },
  {
    disabled: false,
    id: "3",
    name: "Asociar a producto",
    divider: false,
    icon: {
      color: "#006ac6",
      icon: icons.MenuBook,
      size: sizesIcon.standard,
    },
  },
];

export const dropdownCertTypeList: ListDropdownMultipleProps[] = [
  {
    id: "TEMPLATE",
    name: "Template",
    value: "TEMPLATE",
    active: false,
  },
  {
    id: "ATTACHMENT",
    name: "Attachment",
    value: "ATTACHMENT",
    active: false,
  },
  {
    id: "QUICK_PRINT",
    name: "QuickPrint",
    value: "QUICK_PRINT",
    active: false,
  },
];

export const columns = (
  handleClickContextMenu: (id: any, params: any) => void,
) => {
  const columns: TableColumn[] = [
    {
      field: "actions",
      headerName: "Acciones",
      width: 150,
      renderCell: (param: any) => {
        return (
          <div style={{ display: "flex" }}>
            <ContextMenuAFY
              colorButton="transparent"
              iconButton={icons.MoreVert}
              iconColor="#3A56A1"
              menuItem={buttonOptions}
              onClickContextMenu={(item) =>
                handleClickContextMenu(item, param?.row)
              }
              sizeIcon={sizesIcon.standard}
            />
          </div>
        );
      },
    },
    {
      field: "title",
      headerName: "Titulo de certificado",
      width: 300,
    },
    {
      field: "type",
      headerName: "Tipo de certificado",
      width: 150,
    },
    {
      field: "physicalName",
      headerName: "URL documento",
      width: 250,
    },
    {
      field: "quickPrintable",
      headerName: "Imprimible",
      width: 150,
    },
  ];
  return columns;
};

export const defaultProjectItems: LabelAutoCompleteProps[] = [];

export const defaultInsuranceItems = [
  {
    id: "",
    name: "",
    value: "",
  },
];

export const defaultOtherItems = [
  {
    id: "",
    name: "",
    value: "",
  },
];
