export enum languageCkEditor {
  Spanish = "es",
  English = "en",
  // Afrikaans = "af",
  // Arabic = "ar",
  // Azerbaijani = "az",
  // Bulgarian = "bg",
  // Bengali = "bn",
  // Bosnian = "bs",
  // Catalan = "ca",
  // Czech = "cs",
  // Welsh = "cy",
  // Danish = "da",
  // German = "de",
  // Greek = "el",
  // EnglishAustralia = "en-au",
  // EnglishCanada = "en-ca",
  // EnglishUK = "en-gb",
  // Esperanto = "eo",
  // Estonian = "et",
  // Basque = "eu",
  // Persian = "fa",
  // Finnish = "fi",
  // Faroese = "fo",
  // French = "fr",
  // FrenchCanada = "fr-ca",
  // Galician = "gl",
  // Gujarati = "gu",
  // Hebrew = "he",
  // Hindi = "hi",
  // Croatian = "hr",
  // Hungarian = "hu",
  // Indonesian = "id",
  // Icelandic = "is",
  // Italian = "it",
  // Japanese = "ja",
  // Georgian = "ka",
  // Khmer = "km",
  // Korean = "ko",
  // Lithuanian = "lt",
  // Latvian = "lv",
  // Macedonian = "mk",
  // Mongolian = "mn",
  // Malay = "ms",
  // NorwegianBokmal = "nb",
  // Dutch = "nl",
  // Norwegian = "no",
  // Polish = "pl",
  // Portuguese = "pt",
  // PortugueseBrazil = "pt-br",
  // Romanian = "ro",
  // Russian = "ru",
  // Slovak = "sk",
  // Slovenian = "sl",
  // Albanian = "sq",
  // Serbian = "sr",
  // SerbianLatin = "sr-latn",
  // Swedish = "sv",
  // Thai = "th",
  // Turkish = "tr",
  // Tatar = "tt",
  // Uyghur = "ug",
  // Ukrainian = "uk",
  // Vietnamese = "vi",
  // ChineseSimplified = "zh",
  // ChineseSimplifiedCN = "zh-cn",
  // ChineseTraditional = "zh-tw",
}
