import {
  BreadcrumbAFY,
  LoadingAFY,
  position,
  StepProgressAFY,
  typeAlert,
  typeLoaders,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import showToast from "../../../services/toast";
import { themeCore } from "../../../assets/themes/theme";
import React from "react";

export const CustomerHeader = (props: any) => {
  return (
    <>
      <BreadcrumbAFY options={props.breadCrumbs} />
      <article
        style={{
          position: "absolute",
          top: "15px",
          right: "409px",
          zIndex: "9",
        }}
      >
        <StepProgressAFY
          onClickStepProgress={() => {}}
          stepProgress={props.stepProgress}
          collapsed
          sticky
        />
      </article>
      <LoadingAFY
        loading={props.loading}
        backgroundColor={themeCore.colors.backgroundLoader}
        typeAnimation={typeLoaders.LogoAfy}
        sizeAnimation={65}
        textLoading={"Cargando..."}
        textLoadingColor={themeCore.colors.third}
        spinnerColor={themeCore.colors.third}
      />

      {props.seeToast.show &&
        showToast(
          position.topRight,
          props.seeToast.type,
          props.seeToast.message,
          () => {
            props.setSeeToast({
              show: false,
              message: "",
              type: typeAlert.success,
            });
          },
        )}
    </>
  );
};
