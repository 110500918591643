import { useSelector } from "react-redux";

const useSelectors = (

) => {
    const { responsePerson, errorPerson, loadingPerson } = useSelector(
        (state: any) => ({
            responsePerson: state.Person.responsePersonById?.data,
            errorPerson: state.Person.errorPersonById,
            loadingPerson: state.Person.loadingPersonById,
        }),
    );

    const {
        responseIdentificationType,
        errorIdentificationType,
        loadingIdentificationType,
    } = useSelector((state: any) => ({
        responseIdentificationType:
            state.IdentificationType.responseIdentificationType,
        errorIdentificationType: state.IdentificationType.errorIdentificationType,
        loadingIdentificationType:
            state.IdentificationType.loadingIdentificationType,
    }));
    const { responseAllQuote, errorAllQuote } = useSelector((state: any) => ({
        responseAllQuote: state.Quote.responseAllQuote,
        errorAllQuote: state.Quote.errorAllQuote,
        //loadingAllQuote: state.Quote.loadingAllQuote,
    }));

    const { responseBeneficiary, errorBeneficiary, loadingBeneficiary } =
        useSelector((state: any) => ({
            responseBeneficiary: state.Beneficiary.responseBeneficiary,
            errorBeneficiary: state.Beneficiary.errorBeneficiary,
            loadingBeneficiary: state.Beneficiary.loadingBeneficiary,
        }));

    const { responseInsured, errorInsured, loadingInsured } = useSelector(
        (state: any) => ({
            responseInsured: state.Insured.responseInsured,
            errorInsured: state.Insured.errorInsured,
            loadingInsured: state.Insured.loadingInsured,
        }),
    );

    return {
        responsePerson,
        errorPerson,
        loadingPerson,
        responseIdentificationType,
        errorIdentificationType,
        loadingIdentificationType,
        responseAllQuote,
        errorAllQuote,
        responseBeneficiary,
        errorBeneficiary,
        loadingBeneficiary,
        responseInsured,
        errorInsured,
        loadingInsured,

    }
}
export default useSelectors;