import styled from "styled-components";
import { makeStyles } from "tss-react/mui";

export const HeaderContainer = styled.section`
  height: 50px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgb(204, 204, 204, 0.2);
  padding: 10px 12px 10px 12px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
`;

export const ContentContainer = styled.div`
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const ContentFileName = styled.div`
  display: flex;
  align-items: center;
  width: 650px;
`;

export const ContentProductName = styled.div`
  display: flex;
  align-items: center;
  margin-right: 25px;
`;

export const Title = styled.h5`
  margin: -1px 5px 0 0;
  padding: 0;
  font-size: 15px;
  font-weight: 600;
`;

export const CertificatesProductName = styled.p`
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 700;
  line-height: 17px;
`;

export const CertificatesTitleLabel = styled.h5`
  margin: -1px 5px 0 0;
  padding: 0;
  font-size: 15px;
  font-weight: 600;
  white-space: nowrap;
  flex-shrink: 0;
`;

export const ContainerRows = styled.section`
  display: flex;
  align-items: start;
  flex-direction: column;
  width: 60%;
`;

export const StyleColumns = styled.section`
  width: 100%;
  margin-bottom: 7px;
`;

export const StyleColumnsRight = styled.section`
  width: 100%;
  margin-bottom: 7px;
`;

export const useStyles = makeStyles()(() => ({
  styledTitle: {
    margin: "0",
    padding: "0",
    cursor: "pointer",
    width: "100%",
    height: "auto",
  },
}));
