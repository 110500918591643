// useSelectors.ts
import { useSelector } from "react-redux";


const useSelectors = () => {

const { responseQuote } = useSelector((state: any) => ({
    responseQuote: state.Quote.responseQuote,
  }));

  const { responseOneQuote, errorOneQuote } = useSelector((state: any) => ({
    responseOneQuote: state.Quote.responseOneQuote,
    errorOneQuote: state.Quote.errorOneQuote,
  }));

  const {
    responsePolicyConfirm,
    responsePolicyConfirmError,
    responsePolicyConfirmLoading,
  } = useSelector((state: any) => ({
    responsePolicyConfirm: state.Policy.responsePolicyConfirm,
    responsePolicyConfirmError: state.Policy.errorPolicyConfirm,
    responsePolicyConfirmLoading: state.Policy.loadingPolicyConfirm,
  }));


  return {
    responseQuote,
    responseOneQuote,
    errorOneQuote,
    responsePolicyConfirm,
    responsePolicyConfirmError,
    responsePolicyConfirmLoading,
  };
};

export default useSelectors;