import { useCallback, useMemo } from "react";

const usePagination = () => {
  const onChangePage = useCallback(
    (
      setPage: (value: number | (() => number)) => void,
      i: number,
      setCertificateTrigger: (value: boolean | (() => boolean)) => void,
    ) => {
      setPage(i);
      setCertificateTrigger(true);
    },
    [],
  );
  const onChangeItemPerPage = useCallback(
    (
      setLimit: (value: number | (() => number)) => void,
      i: number,
      setCertificateTrigger: (value: boolean | (() => boolean)) => void,
    ) => {
      setLimit(i);
      setCertificateTrigger(true);
    },
    [],
  );
  return useMemo(
    () => ({
      onChangePage,
      onChangeItemPerPage,
    }),
    [onChangePage, onChangeItemPerPage],
  );
};

export default usePagination;
