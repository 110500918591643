import React, { useEffect, useState } from "react";
import { Container, Content, WrapperFooter, WrapperSidebar } from "./style";
import { getCustomerAction } from "../../fnx/customer";
import {
  FooterAFY,
  HeaderAFY,
  icons,
  iconsSvg,
  iconType,
  MenuItem,
  SideBarAFY,
  sizesIcon,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import LocalStorageService from "../../services/localStorage";
import { useAuth } from "../../authContext";
import { useDispatch } from "react-redux";
import { loginUserClearData } from "../../store/auth/actions";
import { themeCore } from "../../assets/themes/theme";
import { ItemMenu } from "./data";
import AssuredHubLogo from "../../assets/images/New_LogoAFY.svg";

const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);

export const View = (props: any) => {
  const dispatch = useDispatch();
  const [menuHeader, setMenuHeader] = React.useState<MenuItem[]>([]);
  const [menusSideBar, setMenusSideBar] = React.useState<MenuItem[]>([]);
  const { status, setStatus } = useAuth();
  const [showLogo, setShowLogo] = useState(true);

  const currentDate: Date = new Date();
  const year = currentDate.getFullYear();
  const [name] = React.useState<string>(
    lsLogin.getUserLoginStored().idToken?.payload.name,
  );
  const [lastName] = React.useState<string>(
    lsLogin.getUserLoginStored().idToken?.payload.family_name,
  );
  const [userName] = React.useState<string>(
    lsLogin.getUserLoginStored().userName,
  );
  const [clientId] = React.useState<string>(
    lsLogin.getUserLoginStored().clientId,
  );

  const [token] = React.useState<string>(
    lsLogin.getUserLoginStored().idToken?.jwtToken,
  );

  const [company] = React.useState<string>(
    lsLogin.getUserLoginStored().subdomain || "",
  );

  useEffect(() => {
    if (props.menus) {
      setMenuHeader(props.menus);
    }
  }, [props.menus]);

  useEffect(() => {
    if (getCustomerAction().customerAuto.includes(company)) {
      setShowLogo(false);
    }
  }, [props.menus]);

  useEffect(() => {
    if (props.menusSB) {
      setMenusSideBar(props.menusSB);
    }
  }, [props.menusSB]);

  useEffect(() => {
    const appId = process.env.REACT_APP_ID_CHAT;
    const reportId = process.env.REACT_APP_REPORT_ID_CHAT;
    const script = document.createElement("script");
    script.src = `${process.env.REACT_APP_HOST_CHAT}`;
    script.type = "module";
    script.async = true;
    script.onload = () => {
      if (window.ChatWidget) {
        window.ChatWidget.initializeChatWidget({
          containerId: "chat-widget-container",
          appId: props.rolMenu === null ? appId : reportId,
          secMode: "internal_auth",
          clientId,
          token: `Bearer ${token}`,
        });
      }
    };

    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, [props.rolMenu]);

  const logout = (e: MenuItem) => {
    switch (e.id) {
      case "1": {
        lsLogin.setLogout();
        setStatus("no-authenticated");
        dispatch(loginUserClearData());
        //limpia todos los key del storage
        localStorage.clear();
        localStorage.removeItem("mergeFieldsData");
        break;
      }
      default: {
        break;
      }
    }
  };
  return (
    <React.Fragment>
      <HeaderAFY
        logoUrl={AssuredHubLogo}
        first_name={""}
        last_name={""}
        username={userName}
        paddingButtonBadge={"4px"}
        widthBadgeIcon={"16px"}
        widthButtonBadge={"24px"}
        bRadiusButtonBadge={"4px"}
        heightBadgeIcon={"16px"}
        heightButtonBadge={"24px"}
        primaryColorText={themeCore.colors.V_Text}
        secondaryColorText={themeCore.colors.VI_Text}
        backgroundColor={themeCore.colors.third}
        badgeColorText={"#FFFFFF"}
        bgColorBadge={"#BDBDBD"}
        bgColorButtonBadge={"#F5F5F5"}
        bgColorIcon={"#F5F5F5"}
        bgColorIconBadge={"#F5F5F5"}
        bgColorOptionButton={"#F5F5F5"}
        colorTextUser={"#0208B3"}
        customIcon={true}
        disableRipple={true}
        showLogo={true}
        hideBadge={false}
        iconSvg={iconsSvg.userLogo}
        iconSvgBadge={iconType.Bell}
        iconUser={icons.Person}
        sizeIconButton={sizesIcon.smallXs}
        countBadge={props.countBadge}
        menu={ItemMenu}
        menus={menuHeader}
        onClickMenu={props.onClickMenu}
        onClickBadge={props.onClickBadge}
        onClickContextMenu={(e) => {
          logout(e);
        }}
        onClickLogo={function noRefCheck() {}}
      />
      <Container>
        <WrapperSidebar
          style={{
            width: props.showSideBar ? "268px" : "40px",
            minWidth: props.showSideBar ? "240px" : "29px",
          }}
        >
          <SideBarAFY
            title={"Inicio"}
            titleColor={"#0208b3"}
            backgroundColor={"#FFFFFF"}
            bgIconButton={"#1D34FE"}
            activeTextColor={"#1D34FE"}
            bgColorItemActive={"#E8EEFF"}
            hoverColor={"#0208B3"}
            hoverColorItem={"#f5f5f5"}
            inactiveTextColor={"#595959"}
            itemIconSize={"16px"}
            bRadiusButton={"8px"}
            onClickMenu={props.onClickSideBar}
            onClickStatus={props.onClickStatus}
            sizeButtonH={"32px"}
            sizeButtonW={"32px"}
            sizeIconButtonH={"18px"}
            sizeIconButtonW={"18px"}
            menuSideBar={menusSideBar}
          />
        </WrapperSidebar>
        <div id="chat-widget-container" style={{ zIndex: 9999 }}></div>
        <section
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            height: "97%",
          }}
        >
          <Content>{props.children}</Content>
        </section>
      </Container>
      <WrapperFooter>
        <FooterAFY
          copyRight={"Affinity · 2023 · Derechos reservados © Guatemala"}
          version={"version 1.4.01 beta"}
          fontSize={"10px"}
          iconHeight={"15.83px"}
          iconWidth={"18.1px"}
          backgroundColor={"#FAFAFA"}
          bgColorIcon={"#FAFAFA"}
          borderColor={"#D9D9D9"}
          textColor={"#8C8C8C"}
        />
      </WrapperFooter>
    </React.Fragment>
  );
};
