import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { certificateTemplate } from "../../store/certificates/actions";

export const GetCertificatesTemplate = (
  trigger: boolean,
  enable: boolean,
  page: number,
  limit: number,
  title: string,
  projectId: string,
  certType: string,
) => {
  const dispatch = useDispatch();

  const {
    loadingCertificateTemplate,
    responseCertificateTemplate,
    errorCertificateTemplate,
  } = useSelector((state: any) => ({
    loadingCertificateTemplate: state.Certificates.loadingCertificateTemplate,
    responseCertificateTemplate: state.Certificates.responseCertificateTemplate,
    errorCertificateTemplate: state.Certificates.errorCertificateTemplate,
  }));

  useEffect(() => {
    if (trigger) {
      dispatch(
        certificateTemplate({
          title: title,
          enable: enable,
          projectId: projectId,
          certType: certType,
          page: page,
          limit: limit,
        }),
      );
    }
  }, [trigger, title, projectId, certType]);

  return {
    loadingCertificateTemplate,
    responseCertificateTemplate,
    errorCertificateTemplate,
  };
};
