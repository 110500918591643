import { RowsModel } from "../../../../model/insurancePoliciesSummary";
import { useDispatch, useSelector } from "react-redux";
import LocalStorageService from "../../../../services/localStorage";
import { projectOneClearData } from "../../../../store/project/actions";
import { useNavigate, useParams } from "react-router-dom";
import { navigateNext, stepProgress } from "../../../../dataBase/stepProgress";

const useUI = (
    setDocumentCode: any,
    setOpenDrawer: any,
    setOpenModal: any,
    setIdDocument: any,
    setUrlDocument: any,
    Steps: any,
    setRows: any,
    idQuoteParams: any,
    STEP: any,
) => {
    const dispatch = useDispatch();
    const storageKey = "afy-steps";
      const navigate = useNavigate();
const localStorageService = new LocalStorageService(storageKey);

    const handleClickContextMenu = (item: any, params: any) => {
        switch (item.id) {
          case "1":
            setDocumentCode(params.row.code);
            setOpenDrawer(true);
            break;
          case "2":
            setOpenModal(true);
            setIdDocument(params.row.id);
            setUrlDocument(params.row.url);
            // navigate(
            //   `/visor-pdf/${params.row.id}/${
            //     idQuoteParams ? idQuoteParams : quoteId
            //   }`,
            // );
            localStorageService.setStepStored({
              id: "",
              name: "",
              step: Steps.auditDocumentUrl,
              completed: true,
              auditDocumentUrl: params.row.url,
            });
            break;
          case "3":
            console.log("case3", item, params.row);
            break;
          default:
            break;
        }
      };
    
      const setProjectRows = (responseOneProject: RowsModel[]) => {
        const DocumentRows = responseOneProject?.map(
          (item: RowsModel, index: number) => ({
            id: index.toString(),
            code: item.code,
            docType: item.document,
            state: item.state ? item.state : "Sin documento",
            date: item.dateUpdated,
            user: item.user,
            comments: item.comentario,
          })
        );
        setRows(DocumentRows);
        dispatch(projectOneClearData());
      };
    
     
    
      const onCloseDrawer = () => {
        setOpenDrawer(false);
      };
    
      const onClickButtonBack = () => {
        if (idQuoteParams) {
          navigate("/insurance-policies-summary");
        }
      };
      const onClickNext = () => {
        navigateNext(STEP, navigate);
      };
  return {
    handleClickContextMenu,
    setProjectRows,
    onCloseDrawer,
    onClickButtonBack,
    onClickNext,
  };
}
export default useUI;