import LocalStorageService from "../services/localStorage";

const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);

export const getHeader = (): any => {
  return {
    "Content-Type": "application/json",
    Accept: "application/json, text/plain, */*",
    ip: "123456789",
    "X-Client-Id": lsLogin.getUserLoginStored().clientId,
    "x-no-cache": "true",
    Authorization: "Bearer " + lsLogin.getUserLoginStored().idToken.jwtToken,
  };
};

export const getHeaderUpload = (): any => {
  return {
    ip: "123456789",
    "X-Client-Id": lsLogin.getUserLoginStored().clientId,
    Authorization: "Bearer " + lsLogin.getUserLoginStored().idToken.jwtToken,
  };
};

export const getHeader2 = (contentType: string): any => {
  return {
    "Content-Type": `application/${contentType}`,
    Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ip: "123456789",
    "X-Client-Id": lsLogin.getUserLoginStored().clientId,
    Authorization: "Bearer " + lsLogin.getUserLoginStored().idToken.jwtToken,
  };
};

export const getHeaderLogin = (): any => {
  return {
    "Content-Type": "application/json",
    Accept: "application/json, text/plain, */*",
    ip: "123456789",
  };
};
