import {
  ButtonAFY,
  icons,
  LoadingAFY,
  position,
  sizes,
  typeAlert,
  typeLoaders,
  variants,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { themeCore } from "../../../assets/themes/theme";
import { Container } from "../assets/style";
import React from "react";

export const ClientRecordHeader = (props: any) => {
  return (
    <>
      {props.seeToast.show &&
        props.showToast(
          position.topRight,
          props.seeToast.type,
          props.seeToast.message,
          () => {
            props.setSeeToast({
              show: false,
              message: "",
              type: typeAlert.success,
            });
          },
        )}
      <LoadingAFY
        loading={props.loading}
        backgroundColor={themeCore.colors.backgroundLoader}
        typeAnimation={typeLoaders.LogoAfy}
        sizeAnimation={65}
        textLoading={"Cargando"}
        textLoadingColor={themeCore.colors.third}
        spinnerColor={themeCore.colors.third}
      />
      {props.id && (
        <Container>
          <ButtonAFY
            variant={variants.text}
            label={"Regresar"}
            icon={icons.ArrowBackIosNew}
            size={sizes.small}
            color={themeCore.colors.primaryText}
            onClick={props.handleClickPrevious}
          />
        </Container>
      )}
    </>
  );
};
